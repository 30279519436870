export class ExtraInvoice {
	id: string;
	created_timestamp: Date;
	provider: string;
	invoice_number: string;
	invoice_foreign_id: string;
	invoice_date_issued: Date;
	invoice_date_due: Date;
	invoice_amount_total: number;
	invoice_amount_paid: number;
	invoice_include_in_summary: boolean;
	invoice_status: Extra_Invoice_Status_Type;
	invoice_items: string[];
	comment: string;
}


export class ExtraInvoiceLookupData {
	provider: string;
	invoice_number: string;
	invoice_foreign_id: string;
	invoice_date_issued: Date;
	invoice_date_due: Date;
	invoice_amount_total: number;
	invoice_amount_paid: number;
	client_summary: string;
	items_summary: string[];
}

export const ExtraInvoiceLookupDataEmptyEntity: ExtraInvoiceLookupData = {
	provider: "",
	invoice_number: "",
	invoice_foreign_id: "",
	invoice_date_issued: null,
	invoice_date_due: null,
	invoice_amount_total: 0,
	invoice_amount_paid: 0,
	client_summary: "",
	items_summary: []
}

export class ExtraInvoiceEditorData {
	provider: string;
	invoice_foreign_id: string;
	invoice_status: Extra_Invoice_Status_Type;
	invoice_include_in_summary: boolean;
	comment: string;
}

export const ExtraInvoiceEditorDataEmptyEntity: ExtraInvoiceEditorData = {
	provider: "",
	invoice_foreign_id: "",
	invoice_status: null,
	invoice_include_in_summary: false,
	comment: ""
}

export type Extra_Invoice_Status_Type = "ISSUED" | "PARTIALLY_PAID" | "COMPLETE";

export const EXTERNAL_INVOICE_STATUS_OPTIONS = [
	{ value: 'ISSUED', label: 'Wystawiona' },
	{ value: 'PARTIALLY_PAID', label: 'Częściowo opłacona' },
	{ value: 'COMPLETE', label: 'Opłacona'}
];