<div class="row mx-1">
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div class="row mx-1">
    <div class="col-12">
        <div class="table-responsive">
            <table *ngIf="!loading" class="table" small="true">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 50px; text-align: center"> L.p. </th>
                    <th scope="col" style="text-align: center"> Nazwa </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Data rozp. </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Data zak. </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Liczba<br/>uczestników </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Cena </th>
                    <th scope="col" style="min-width: 100px; text-align: center"> Lokalizacja </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Rejestracja </th>
                    <th scope="col" style="min-width: 50px; text-align: center"> Aktywny </th>
                </tr>
                </thead>
                <tbody>
                <tr >
                    <td>1. </td>
                    <td>Obóz naukowy</td>
                    <td>2022-06-24</td>
                    <td>2022-07-04</td>
                    <td>1/60</td>
                    <td>2 000 zł</td>
                    <td>Krasnobród</td>
                    <td>x</td>
                    <td>x</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>