import {Camp} from "../../_models";

export class CampDataManipulation {
	public getTotalNumberOfActiveParticipants(camp: Camp) : number {
		let count = 0;

		if (camp && camp.participants_count_statistics){
			for (let singleCount of camp.participants_count_statistics) {
				switch (singleCount.string_value) {
					case "DRAFT":
						break;

					case "SUBMITTED":
						count += singleCount.items_count;
						break;

					case "ENROLLED":
						count += singleCount.items_count;
						break;

					case "WAITING_LIST":
						count += singleCount.items_count;
						break;

					case "CLIENT_RESIGNED":
						break;

					case "CANCELED":
						break;

					case "CONTRACT_SIGNED":
						count += singleCount.items_count;
						break;

					case "FINISHED":
						count += singleCount.items_count;
						break;

					default:
						break;
				}
			}
		}
		
		return count;
	}
}