import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatFileSize'
})

export class FormatFileSizePipe implements PipeTransform {
  transform(value : string | number, ...args: any[]) : string {
    if (!value) {
      return '';
    }

    // return formatted file size showing kb, mb, gb etc.
    let size = Number(value);
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let unitIndex = 0;

    while (size >= 1024) {
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(1) + ' ' + units[unitIndex];

  }
}
