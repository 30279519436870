import {Address} from "./address";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone_number: string;
  is_confirmed: boolean;
  is_active: boolean;
  confirmation_code: string;
  last_login_timestamp: string;
  created_by: number;
  created_timestamp: string;
  addresses: Address[];
  billing_address: Address;
  mailing_address: Address;
  is_admin: boolean;
  is_manager: boolean;
  is_backoffice_employee: boolean;
  is_camp_employee: boolean;
  is_client: boolean;
}
