import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../_services';
import {passwordMatchValidator, passwordStrengthValidator, StrengthParameters} from '../_validators';

import { ModalDirective } from 'ng-uikit-pro-standard';
import {RegisterResult} from '../types';
import {accountPasswordStrengthParams} from "../global-config";

@Component({templateUrl: 'register.component.html'})
export class RegisterComponent implements OnInit {
    @ViewChild('modalErrorMessage', { static: true }) public modalErrorMessage: ModalDirective;
    @ViewChild('modalSuccessMessage', { static: true }) public modalSuccessMessage: ModalDirective;

    public registerForm: FormGroup;
    public loading = false;
    protected submitted = false;
    public errorMessage = '';
    public successMessage = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i) ]],
            password: ['', [Validators.required, Validators.minLength(8), passwordStrengthValidator(accountPasswordStrengthParams)]],
            confirmPassword: ['']
        },
{
          // check whether our password and confirm password match
          validator: passwordMatchValidator
        }
        );
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        if (this.loading) {
          return;
        }

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            this.errorMessage = 'Formularz zawiera błędy. Uzupełnij pola przed naciśnięciem przycisku \'Zarejestruj się\'.';
            this.modalErrorMessage.show();
            return;
        }

        this.submitted = true;

        this.loading = true;
        this.userService.register(this.f.firstName.value, this.f.lastName.value, this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    // this.alertService.success('Registration successful', true);
                    this.loading = false;

                    // show modal

                    if (data.status === 'OK'){
                      this.successMessage = data.message;
                      this.modalSuccessMessage.show();
                    } else {
                      this.errorMessage = data.message;
                      this.modalErrorMessage.show();
                    }
                },
                error => {
                    this.errorMessage = error.message;
                    this.modalErrorMessage.show();
                    this.loading = false;
                });
    }

  onSuccessModalClosed(event: any) {
    this.router.navigate(['/login']);
  }
}
