export class InsurancePolicy {
	id: number;
	name: string;
	description: string;
	issuing_company_name: string;
	policy_number: string;
	config_object: string;
	is_active: boolean;

	/**
	 * This static method returns a string containing the price formula for given insurance policy
	 * It also replaces $BASE_PRICE$ placeholder in config_object.price_formula with the contents of
	 * config_object.base_price field
	 *
	 *
	 *
	 * @param sourceObject
	 */
	static getPrice_formula(sourceObject: InsurancePolicy) : string {
		let price_formula = "brak formuły";

		if (sourceObject.config_object != null){
			const config_object = JSON.parse(sourceObject.config_object);

			if (config_object.price_formula){
				price_formula = config_object.price_formula;
			}

			if (config_object.base_price_formula){
				let base_price_formula = "(" + config_object.base_price_formula + ")";

				price_formula = price_formula.replace("$BASE_PRICE$", base_price_formula);
			}
		}

		return price_formula;
	}
}