<div class="row mx-1 mb-1">
    <div class="col-12" style="min-height: 5px;">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div *ngIf="!loading" class="row mx-1 mx-lg-5">
    <div class="col-12">
        <div class="card card-list z-depth-1">
            <div class="card-header white d-flex align-items-center py-3">
                <p class="h5-responsive font-weight-bold mb-0">
                    Polisy &nbsp;&nbsp;
                </p>
                <div *ngIf="networkOperationInProgress" class="spinner-border text-light" role="status">
                    <span class="sr-only">Przetwarzanie danych...</span>
                </div>
            </div>
            <div class="card-body">
                <h4 class="mb-3">Wybierz jedną z aktywnych polis, aby wystawić dla niej zgłoszenie</h4>

                <div class="list-group policies-list-group">
                    <ng-template ngFor let-item let-singleConfig [ngForOf]="insuranceSubmissionPoliciesConfig" let-i="index">
                        <a
                            class="list-group-item list-group-item-action waves-light flex-column align-items"
                            mdbWavesEffect
                            (click)="selectPolicy(singleConfig)"
                            [ngClass]="{'active': singleConfig && selectedPolicy ? singleConfig == selectedPolicy : false }"
                        >
                            <div class="d-flex w-100 justify-content-between">
                                <p>
                                    {{singleConfig.insurancepolicy_name}}<br/><small>[{{singleConfig.insurancepolicy_issuing_company_name}}]</small>
                                </p>

                                <div>
                                    <button *ngIf="canIssueSubmissionForPolicyID(singleConfig)" (click)="issueSubmissionForPolicy(singleConfig)" mdbBtn type="button" class="btn px-3 my-0 mr-0" size="sm" color="amber" mdbWavesEffect>Zgłoszenie dla polisy</button>
                                </div>
                            </div>
                        </a>
                        <a [@openClose]="(singleConfig == selectedPolicy) && shouldShowCampsSelectors(selectedPolicy) ? 'open' : 'closed'"
                            class="list-group-item list-group-item-action waves-light"
                            mdbWavesEffect>
                            <h4 class="mb-3">
                                Wybierz obozy, dla których chcesz wygenerować zgłoszenie
                            </h4>

                            <span *ngIf="(singleConfig == selectedPolicy) && shouldShowRadioForm(singleConfig)">
                                <form [formGroup]="radioForm">
                                    <!--
                                    <div class="form-check my-2">
                                        <input type="radio" class="form-check-input" id="radioCamp_none" value="-1" formControlName="campRadioSelectControl">
                                        <label class="form-check-label" for="radioCamp_none">Nie wybieraj obozu</label>
                                    </div>
                                    -->

                                    <div *ngFor="let singleCamp of singleConfig.camps; let i_camp = index" class="form-check my-2">
                                        <input type="radio" class="form-check-input" [id]="'radioCamp_' + i_camp" [value]="singleCamp.camp_id"
                                                formControlName="campRadioSelectControl">
                                        <label class="form-check-label" [for]="'radioCamp_' + i_camp">{{ singleCamp.camp_name }}</label>
                                    </div>
                                </form>
                            </span>

                            <span *ngIf="(singleConfig == selectedPolicy) && shouldShowCheckboxForm(singleConfig)">
                                <form [formGroup]="checkboxForm">
                                    <div formArrayName="checkCamps" *ngFor="let singleCamp of singleConfig.camps; let i_camp = index;" class="my-2">
                                        <mdb-checkbox [id]="singleCamp.camp_id" [formControl]="getCheckCampsControlAtIndex(i_camp)" [name]="'camp_' + i_camp">{{ singleCamp.camp_name }}</mdb-checkbox>
                                    </div>
                                </form>
                            </span>

                            <div *ngIf="(singleConfig == selectedPolicy) && shouldShowRadioForm(singleConfig)" class="d-flex py-3">
                                <button class="btn btn-primary btn-md px-3 my-0 mr-0" (click)="issueSubmissionForRadioCamp()">Utwórz zgłoszenie dla wybranego obozu</button>
                            </div>

                            <div *ngIf="(singleConfig == selectedPolicy) && shouldShowCheckboxForm(singleConfig)" class="d-flex py-3">
                                <button class="btn btn-primary btn-md px-3 my-0 mr-0" (click)="issueSubmissionForCheckedCamps()">Utwórz zgłoszenie dla wybranych obozów</button>
                            </div>
                        </a>
                    </ng-template>
                </div>
            </div>
            <div class="card-footer white py-3 d-flex">

            </div>
        </div>
    </div>
</div>