// This is an angular component that is used to display a form for composing an explanation message for rejecting an attachment.

import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";


@Component({
  selector: 'attachment-rejection-form',
  templateUrl: './attachment-rejection-form.component.html',
  styleUrls: ['./attachment-rejection-form.component.css']
})

export class AttachmentRejectionFormComponent implements OnInit {
  @Input() attachmentName: string = '';
  @Output() onMessage = new EventEmitter<string | null>();
  @Output() onCancel = new EventEmitter<void>();

  public _messageFormOptions: any[] = [
    {value: 'bad_quality', label: 'Zła jakość', content: 'Przesłany plik jest nieczytelny lub nie da się go wydrukować w wystarczającej jakości.'},
    {value: 'extra_objects', label: 'Inne elementy widoczne na zdjęciu', content: 'Na zdjęciu widoczne są także inne obiekty (np. blat) poza dokumentem, który miał być przesłany.'},
    {value: 'page_cut', label: 'Dokument przycięty', content: 'Skan/zdjęcie dokumentu jest przycięte, co uniemożliwia jego poprawny wydruk.'},
    {value: 'incomplete', label: 'Brakuje stron', content: 'W przesłanym dokumencie brakuje stron - proszę przesłać skany wszystkich stron dokumentu.'},
    {value: 'few_pages', label: 'Kilka stron na zdjęciu', content: 'Na jednym zdjęciu znajduje się kilka stron dokumentu - proszę przesłać każdą stronę osobno, lub zeskanować wszystkie strony w jednym pliku PDF.'},
    {value: 'mixed_documents', label: 'Wiele dokumentów w jednym pliku', content: 'W pojedynczym pliku PDF może znajdować się tylko jeden dokument (proszę nie łączyć np. karty kwalifikacyjnej z oświadczeniami)'},
    {value: 'wrong_reservation', label: 'Błędna rezerwacja', content: 'Przesłano dokumenty do niewłaściwej rezerwacji.'},
  ];

  public rejectionReasonsForm: FormGroup;

  public compiledRejectionMessage: string = '';

  constructor() { }

  ngOnInit(): void {
    this.compiledRejectionMessage = "";

    // create form
    this.rejectionReasonsForm = new FormGroup({});

    // now dynamically add form controls for each message in _messageFormOptions with name containing index
    this._messageFormOptions.forEach((option, index) => {
      this.rejectionReasonsForm.addControl(`chk-reason-${index}`, new FormControl(false));
    });

    this.rejectionReasonsForm.addControl("customReason", new FormControl(""));

    //subscribe to form changes
    this.rejectionReasonsForm.valueChanges.subscribe(() => {
      this.compileMessage();
    });
  }

  public compileMessage() {
    // check which reasons are selected and take corresponding content from _messageFormOptions
    let selectedReasons = this._messageFormOptions.filter((option, index) => {
      return this.rejectionReasonsForm.get(`chk-reason-${index}`)?.value;
    });

    let _compiledMessage = '<ul>';

    selectedReasons.forEach((option) => {
      _compiledMessage += `<li>${option.content}</li>`;
    });

    if (this.rejectionReasonsForm.get("customReason")?.value) {
      // escape html tags
      let customReason = this.rejectionReasonsForm.get("customReason")?.value;
      customReason = customReason.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      _compiledMessage += '<li>' + customReason.trim() + '</li>';

    }
    _compiledMessage += '</ul>';

    this.compiledRejectionMessage = _compiledMessage;
  }


  public completeMessage() {
    this.onMessage.emit(this.compiledRejectionMessage);
  }

  public cancel() {
    this.onCancel.emit();
  }
}
