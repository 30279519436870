import { Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { Subscription} from "rxjs";
import {
	Address,
	AddressHelper,
	Camp,
	Camp_EmptyEntity, Parent, ParentHelper,
	Participant,
	ParticipantHelper,
	User
} from "../_models";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "../_services";
import { MDBModalService, MdbStepperComponent, ModalDirective } from "ng-uikit-pro-standard";
import { SignupElementsDataResult } from "../graphQL/results";
import { getSignupElementsDataQuery } from "../graphQL/queries";
import {
	AddressDataManipulations,
	Camp_participantDataManipulations,
	ParticipantDataManipulations
} from "../graphQL/data-manipulation";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {ActivatedRoute, Router} from "@angular/router";
import _ from "underscore";
import * as moment from "moment";
import {Option} from "@angular/cli/models/interface";
import {TransportOption} from "../_models/transportoption";
import {ReservationsTransportOptions} from "../_models/reservationsTransportOptions";

@Component({
	templateUrl: './participant-signup-component.html',
	styleUrls: ['./participant-signup-component.scss']
})
export class ParticipantsSignupComponent implements OnInit, OnDestroy {
	@ViewChild('campDescriptionModal', { static: true }) public campDescriptionModal: ModalDirective;
	@ViewChild('stepper', { static: true }) public formStepper: MdbStepperComponent;

	public firstFormGroup: FormGroup;
	public secondFormGroup: FormGroup;
	public filterCampsFormGroup: FormGroup;
	public thirdFormGroup: FormGroup;
	public fourthFormGroup: FormGroup;
	public fifthFormGroup: FormGroup;
	public sixthFormGroup: FormGroup;
	public seventhFromGroup: FormGroup;
	public eighthFormGroup: FormGroup;

	private loggedInUserSubscription: Subscription;
	private signupElementsSubscription: Subscription;
	private mailingAddressOptionSubscription: Subscription;
	private billingAddressOptionSubscription: Subscription;
	private filterCampTagsSubscription: Subscription;
	private participantOptionSubscription: Subscription;
	private participantsAddressSubscription: Subscription;

	public addressOptions: Array<any>;         // array of objects used as options in address select controls
	public participantAddressOptions: Array<any>; // array of objects used as options in participant address select controls
	public participantOptions: Array<any>;     // array of objects used as options in participant select controls
	public transportOptionsTo: Array<TransportOption>;       // array of transport options
	public transportOptionsFrom: Array<TransportOption>;       // array of transport options
	public insuranceOptions: Array<any>;       // array of insurance options - in practice it is deserialized selectedCamp.insurance_options

	public mailingAddress: Address;    // address selected as mailing address for this contract
	public billingAddress: Address;    // address selected as billing address for this contract
	public participantsAddress: Address;   // participants address of residence

	public selectedCamp: Camp;         // camp to enroll participant to

	public selectedParticipant: Participant;   // participant to enroll

	public currentUser: User;

	public addressesValue: Address[];           // array of all user's addresses
	public campsValue: Camp[];                  // array of all active camps
	public decodedCampsValue: Camp[];           // array of all active camps with 'camp_details_public' and 'camp_details_backoffice' decoded from JSON
	public filteredCamps: Camp[];               // array of active camps having selected group name tag (step 2)
	public campGroupNameTagsOptions: Array<any>;         // list of available tags to select from
	public selectedCampNameTag: string;         // currently selected name tag

	public participantsValue: Participant[];    // array of all user's participants


	public campDetails: Camp = null;  //camp which details are displayed in camp details dialog

	private genericModalHandler: ModalGenericHandler = null;

	public savingData: boolean = false;    // blocks user from submitting the form before it gets handled by the server
	public loading: boolean = false;
	public networkOperationInProgress: boolean = false;

	constructor(private apollo: Apollo,
	            private authService: AuthenticationService,
	            private modalService: MDBModalService,
	            private _formBuilder: FormBuilder,
	            private router: Router,
	            private route: ActivatedRoute,
	            private campParticipantDataManipulator: Camp_participantDataManipulations,
              private addressDataManipulator: AddressDataManipulations,
              private participantDataManipulator: ParticipantDataManipulations,
	            ) {

		this.campDetails = Camp_EmptyEntity;

		this.genericModalHandler = new ModalGenericHandler();

		// Configure forms

		// STEP 1 - basic client data
		this.firstFormGroup = new FormGroup({
			clientNameFirst: new FormControl('', [Validators.required]),
			clientNameLast: new FormControl('', [Validators.required]),
			clientPhoneNumber: new FormControl('', [Validators.required]),
			clientEmail: new FormControl('', [Validators.required, Validators.email]),
			sameBillingAddress: new FormControl(true),
			mailingAddressID: new FormControl(null, [Validators.required]),
			billingAddressID: new FormControl(null, [Validators.required])
		});


		// STEP 2 - select camp
		this.secondFormGroup = new FormGroup({
			selectedCampID: new FormControl(null, Validators.required)
		});

		this.filterCampsFormGroup = new FormGroup({
			campTagsFilterSelect: new FormControl(null)
		})

		// STEP 3 - select participant
		this.thirdFormGroup = new FormGroup({
			selectedParticipantID: new FormControl(null, Validators.required),
			participantsAddressID: new FormControl(null, Validators.required),
			canParticipantBeAssignedToCamp: new FormControl(true, Validators.requiredTrue),
		});

		// STEP 4 - additional participant data
		this.fourthFormGroup = new FormGroup({
			healthIssues: new FormControl(''),
			specialNeeds: new FormControl(''),
			dietaryNeeds: new FormControl(''),
			vaccination_DTaP: new FormControl('', Validators.required),
			vaccination_IPV: new FormControl('', Validators.required),
			vaccination_MMR: new FormControl('nie dotyczy', Validators.required),
			vaccination_other: new FormControl(''),
		});

		// STEP 5 - transport options
		this.fifthFormGroup = new FormGroup({
			radioTransportOptionTo: new FormControl('', Validators.required),
			radioTransportOptionFrom: new FormControl('', Validators.required)
		});

		// STEP 6 - insurance options is empty since it is filled upon choosing the camp
		this.sixthFormGroup = this._formBuilder.group({
			checkInsuranceOptions: this._formBuilder.array([])
		});

		// STEP 7 - promo codes and general remarks
		this.seventhFromGroup = new FormGroup(({
			promo_codes: new FormControl(''),
			general_remarks: new FormControl('')
		}));

		// STEP 8 - terms and conditions
		this.eighthFormGroup = new FormGroup({
			checkDataIsTrueStatement: new FormControl(false, [Validators.requiredTrue]),
			checkTermsAndConditionsAccepted: new FormControl(false, [Validators.requiredTrue]),
			checkRODOAccepted: new FormControl(false, [Validators.requiredTrue]),
			checkElectronicInvoiceAccepted: new FormControl(false, [Validators.required]),
		});
	}

	ngOnInit() {
		this.mailingAddress = null;
		this.billingAddress = null;
		this.participantsAddress = null;

		// show progress bar
		this.loading = true;

		//start listening to changes in select controls
		this.configureSelectControlsListeners();

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.loading = false;
					this.addressOptions = [];
					this.participantAddressOptions = [];
					this.addressesValue = [];
					this.mailingAddress = null; // selected mailing address
					this.billingAddress = null; // selected billing address
					this.participantOptions     = [];
					this.participantsValue      = [];
					this.selectedParticipant    = null;
					this.campsValue = [];
					this.selectedCamp = null;
					this.transportOptionsTo = [];
					this.transportOptionsFrom = [];
					this.insuranceOptions = [];

					this.sameBillingAddress = true;
				}
				else {
					// fill hidden form in STEP 1 with newly obtained user data
					this.configureClientFormControls();

					let addressHelper : AddressHelper = new AddressHelper();
					let participantHelpher : ParticipantHelper = new ParticipantHelper();

					// clear former subscription if any
					if (this.signupElementsSubscription) this.signupElementsSubscription.unsubscribe();

					this.signupElementsSubscription = this.apollo.watchQuery<SignupElementsDataResult>({
						query: getSignupElementsDataQuery,
						variables: {
							user_id: user.id,
							enrollment_active: true
						}
					})
					.valueChanges
					.subscribe(
						(( { data, loading }) => {
							this.addressOptions = [];   // clear options for address select controls
							this.participantAddressOptions = []; // like above
							this.addressesValue = data.user_addresses;  // array of raw addresses received from the server

							this.mailingAddress = null; // selected mailing address
							this.billingAddress = null; // selected billing address

							//fill all address select fields with received values
							if (data.user_addresses){
								for (let i = 0; i < data.user_addresses.length; i++){
									this.addressOptions.push({
										'value': data.user_addresses[i].id,
										'label' : addressHelper.singleString(data.user_addresses[i]),
									});

									this.participantAddressOptions.push({
										'value': data.user_addresses[i].id,
										'label': addressHelper.singleStringWithoutName(data.user_addresses[i]),
									});

									// remember to restore selected values!!!
									if (this.addressesValue[i].id == this.firstFormGroup.get('mailingAddressID').value) this.mailingAddress = this.addressesValue[i];
									if (this.addressesValue[i].id == this.firstFormGroup.get('billingAddressID').value) this.billingAddress = this.addressesValue[i];
									if (this.addressesValue[i].id == this.thirdFormGroup.get('participantsAddressID').value) this.participantsAddress = this.addressesValue[i];
								}
							}

							// update fields associated with participant
							this.participantOptions     = [];
							this.participantsValue      = data.user_participants;
							this.selectedParticipant    = null;

							if (this.participantsValue){
								for (let i = 0; i < this.participantsValue.length; i++){
									this.participantOptions.push({
										'value' : this.participantsValue[i].id,
										'label' : participantHelpher.singleString(this.participantsValue[i])
									});

									// remember to restore selected values!!!
									if (this.participantsValue[i].id == this.thirdFormGroup.get('selectedParticipantID').value) this.selectedParticipant = this.participantsValue[i];
								}
							}

							//now update camps
							this.campsValue = data.active_camps;
							this.decodedCampsValue = [];
							this.filteredCamps = [];
							this.campGroupNameTagsOptions = [];
							this.selectedCampNameTag = "-1";

							this.selectedCamp = null;
							this.transportOptionsTo = [];
							this.transportOptionsFrom = [];
							this.insuranceOptions = [];

							if (this.campsValue){
								this.decodeCampsFields();
							}

							this.filterCampsFormGroup.get('campTagsFilterSelect').setValue(this.selectedCampNameTag);

							// finally hide progress bar if necessary
							this.loading = loading;
						})
					)
				}
			}
		);


	}

	ngOnDestroy(): void {
		let subscriptions : Subscription[] = [];

		subscriptions.push(this.loggedInUserSubscription);
		subscriptions.push(this.signupElementsSubscription);
		subscriptions.push(this.mailingAddressOptionSubscription);
		subscriptions.push(this.billingAddressOptionSubscription);
		subscriptions.push(this.filterCampTagsSubscription);
		subscriptions.push(this.participantOptionSubscription);
		subscriptions.push(this.participantsAddressSubscription);

		subscriptions.forEach((sub: Subscription) => {
			if (sub){
				sub.unsubscribe();
				sub = null;
			}
		});
	}

	get sameBillingAddress() : boolean {
		return this.firstFormGroup.get('sameBillingAddress').value;
	}
	set sameBillingAddress(newValue: boolean) {
		this.firstFormGroup.get('sameBillingAddress').setValue(newValue);
	}

	get selectedCampIDControl() { return this.secondFormGroup.get('selectedCampID'); }
	get checkInsuranceOptions() {
		return this.sixthFormGroup.get('checkInsuranceOptions') as FormArray;
	}

	decodeCampsFields(){
		let tmpTags = [];

		// remember to restore selected values!!!
		for (let i = 0; i < this.campsValue.length; i++){
			this.decodedCampsValue.push(new Camp());

			_.extendOwn(this.decodedCampsValue[i], this.campsValue[i]);

			this.decodedCampsValue[i].decodeJSONFields();

			tmpTags = tmpTags.concat(this.decodedCampsValue[i].camp_group_name_tags);

			if (this.campsValue[i].id == this.secondFormGroup.get('selectedCampID').value) {
				// this will show camp details in the form
				this.selectedCamp = this.campsValue[i];

				// this will update transport options
				this.transportOptionsTo = this.selectedCamp.transport_options_to;
				this.transportOptionsFrom = this.selectedCamp.transport_options_from;
				this.insuranceOptions = JSON.parse(this.selectedCamp.insurance_options);
			}
		}



		this.campGroupNameTagsOptions.push({
			'label': "pokaż wszystkie obozy",
			'value': "-1"
		});

		tmpTags = _.uniq(tmpTags).sort();

		tmpTags.map((label: String) => {
			this.campGroupNameTagsOptions.push({
				label: label,
				value: label
			})
		});
	}

	filterCamps(){
		this.decodedCampsValue.map((camp) => {
			if (this.selectedCampNameTag == "-1" || camp.camp_group_name_tags.findIndex((tag) => tag == this.selectedCampNameTag) != -1){
				camp['show'] = true;
			}
			else {
				camp['show'] = false;
			}
		})
	}

	configureClientFormControls(){
		this.firstFormGroup.get('clientNameFirst').setValue(this.currentUser ? this.currentUser.firstName : null);
		this.firstFormGroup.get('clientNameLast').setValue(this.currentUser ? this.currentUser.lastName : null);
		this.firstFormGroup.get('clientPhoneNumber').setValue(this.currentUser ? this.currentUser.phone_number : null);
		this.firstFormGroup.get('clientEmail').setValue(this.currentUser ? this.currentUser.email : null);
		this.firstFormGroup.get('mailingAddressID').setValue(this.currentUser && this.currentUser.mailing_address ? this.currentUser.mailing_address.id : null);

		if (this.currentUser && this.currentUser.billing_address !== null){
			this.sameBillingAddress = false;
			this.firstFormGroup.get('billingAddressID').setValue(this.currentUser && this.currentUser.billing_address ? this.currentUser.billing_address.id : null);
		}
		else {
			this.sameBillingAddress = true;
			this.firstFormGroup.get('billingAddressID').setValue(this.firstFormGroup.get('mailingAddressID').value);    // whatever the value is
		}
	}

	/**
	 * Listen to change events fired by form selects and act accordingly
	 */
	configureSelectControlsListeners(){
		this.mailingAddressOptionSubscription = this.firstFormGroup.get('mailingAddressID').valueChanges.subscribe( (value) => {
			this.mailingAddress = null;
			for (let i = 0; this.addressesValue && i < this.addressesValue.length; i++){
				if (this.addressesValue[i].id == value) {
					this.mailingAddress = this.addressesValue[i];

					// if sameBillingAddress checkbox is checked, also set billing address
					if (this.sameBillingAddress) {
						this.firstFormGroup.get('billingAddressID').setValue(this.firstFormGroup.get('mailingAddressID').value);
					}
				}
			}
		});

		this.billingAddressOptionSubscription = this.firstFormGroup.get('billingAddressID').valueChanges.subscribe( (value) => {
			this.billingAddress = null;
			for (let i = 0; this.addressesValue && i < this.addressesValue.length; i++){
				if (this.addressesValue[i].id == value) this.billingAddress = this.addressesValue[i];
			}
		});

		this.filterCampTagsSubscription = this.filterCampsFormGroup.get('campTagsFilterSelect').valueChanges.subscribe((value) => {
			this.selectedCampNameTag = value;
			this.filterCamps();
		})

		this.participantOptionSubscription = this.thirdFormGroup.get('selectedParticipantID').valueChanges.subscribe( (value) => {
			this.selectedParticipant = null;
			for (let i = 0; this.participantsValue && i < this.participantsValue.length; i++){
				if (this.participantsValue[i].id == value) this.selectedParticipant = this.participantsValue[i];
			}

			this.thirdFormGroup.get('canParticipantBeAssignedToCamp').setValue(this.canParticipantBeSignedUpToCamp(this.selectedParticipant, this.selectedCamp));
		});

		this.participantsAddressSubscription = this.thirdFormGroup.get('participantsAddressID').valueChanges.subscribe( (value) => {
			this.participantsAddress = null;
			for (let i = 0; this.addressesValue && i < this.addressesValue.length; i++){
				if (this.addressesValue[i].id == value) this.participantsAddress = this.addressesValue[i];
			}
		});
	}


	editAddress(address: Address){
		this.addressDataManipulator.editAddressDialog(address);
	}

	addAddress(whichAddress: "MAILING" | "BILLING" | "PARTICIPANT"){
		if (this.networkOperationInProgress) return;

		this.networkOperationInProgress = true;

		let myDataSubscription = this.addressDataManipulator.addAddressDialog(this.currentUser).subscribe(
			(data) =>{
				myDataSubscription.unsubscribe();
				this.networkOperationInProgress = false;

				// if adding new address is successful, set it as selected address in options dropdown
				if (data !== null){
					switch (whichAddress) {
						case "MAILING":
							this.firstFormGroup.get('mailingAddressID').setValue(data.id);
							break;

						case "BILLING":
							this.firstFormGroup.get('billingAddressID').setValue(data.id);
							break;

						case "PARTICIPANT":
							this.thirdFormGroup.get('participantsAddressID').setValue((data.id));
							break;
					}
				}
			}
		);
	}

	editParticipant(participant: Participant){
		this.participantDataManipulator.editParticipantDialog(participant, this.apollo, this.modalService);
	}

	addParticipant(){
		if (this.networkOperationInProgress) return;
		this.networkOperationInProgress = true;

		// to make thing easier for the user, let's add the first parent to the list
		let defaultParent: Parent = null;

		if (this.mailingAddress !== null){
			const helper = new ParentHelper();
			defaultParent = helper.createParentFromAddressObject(this.mailingAddress);
		}

		let myDataSubscription = this.participantDataManipulator.addParticipantDialog(this.currentUser, this.apollo, this.modalService, [defaultParent])
			.subscribe(
				(data) => {
					myDataSubscription.unsubscribe();
					this.networkOperationInProgress = false;

					// if new participant is created, let's automatically select it from the dropdown
					if (data !== null){
						this.thirdFormGroup.get('selectedParticipantID').setValue(data.id);
					}
				}
			);
	}



	/**
	 * Select given camp to enroll participant to
	 *
	 * @param camp
	 */
	chooseCamp(camp: Camp){
		this.selectedCamp = camp;

		// this will update transport options
		if (camp){
			this.transportOptionsTo = this.selectedCamp.transport_options_to;
			this.transportOptionsFrom = this.selectedCamp.transport_options_from;
			this.insuranceOptions = JSON.parse(this.selectedCamp.insurance_options);
		}
		else {
			this.transportOptionsTo = null;
			this.transportOptionsFrom = null;
			this.insuranceOptions = null;
		}


		// remember to update form control
		this.selectedCampIDControl.setValue(camp ? camp.id : null);

		// update transport radio options
		this.fifthFormGroup.get('radioTransportOptionTo').setValue('');
		this.fifthFormGroup.get('radioTransportOptionFrom').setValue('');


		// create dynamic controls for insurance options
		this.sixthFormGroup.setControl('checkInsuranceOptions', this._formBuilder.array([]));
		for (let i = 0; this.insuranceOptions && i < this.insuranceOptions.length; i++){
			this.checkInsuranceOptions.push(this._formBuilder.control(false))
		}
	}

	/**
	 * Show modal with camp's description
	 *
	 * @param camp
	 */
	showCampDescriptionModal(camp: Camp){
		this.campDetails = camp;
		this.campDescriptionModal.show();
	}

	/**
	 * Checks if selected participant has selected camp's id in his camp_ids array
	 *
	 * @param participant
	 * @param camp
	 */
	canParticipantBeSignedUpToCamp(participant: Participant, camp: Camp) : boolean {
		if (!participant || !camp) return true;

		if (participant.camp_ids && participant.camp_ids.length){
			for (let i = 0; i < participant.camp_ids.length; i++){
				if (participant.camp_ids[i] == camp.id) return false;
			}
		}

		return true;
	}

	getInsuranceOptionControlAtIndex(i: number){
		if (this.checkInsuranceOptions && i < this.checkInsuranceOptions.length)
			return this.checkInsuranceOptions.at(i);

		return null;
	}

	showModal(){
		const myResult = this.genericModalHandler.showModal('Sukces!', 'to jest wiadomość, że wszsytko poszło ok :)',
			this.modalService, 'INFO','OK', null, 'Tak', 'Nie');

		let mySub = myResult.subscribe(data => {
			console.log(data);
			mySub.unsubscribe();
		})
	}

	/*
		Submit the whole form
	 */
	onSubmit() {
		if (this.savingData) return;

		const allForms: Array<FormGroup> = [
			this.firstFormGroup,
			this.secondFormGroup,
			this.thirdFormGroup,
			this.fourthFormGroup,
			this.fifthFormGroup,
			this.sixthFormGroup,
			this.seventhFromGroup,
			this.eighthFormGroup,
		];

		// make sure all forms are valid
		for (let i = 0; i < allForms.length; i++){
			allForms[i].updateValueAndValidity();
			if (!allForms[i].valid){
				const myResult = this.genericModalHandler.showModal('Błąd!', 'Upewnij się, że wszystkie kroki formularza zostały poprawnie uzupełnione.',
					this.modalService, 'DANGER','OK', null, null, null);

				let mySub = myResult.subscribe(data => {
					mySub.unsubscribe();
				});

				return;
			}
		}

		const vaccinationsSnapshot = {
			'DTaP': this.fourthFormGroup.get('vaccination_DTaP').value,
			'IPV': this.fourthFormGroup.get('vaccination_IPV').value,
			'MMR': this.fourthFormGroup.get('vaccination_MMR').value,
			'Other': this.fourthFormGroup.get('vaccination_other').value,
		};

		let transportSnapshot  = {
			update_reservation_price: false
		};

		for (let i = 0; i < this.transportOptionsTo.length; i++){
			if (this.transportOptionsTo[i].code == this.fifthFormGroup.get('radioTransportOptionTo').value){
				transportSnapshot["to"] = {
					'code': this.transportOptionsTo[i].code
				};
			}
		}

		for (let i = 0; i < this.transportOptionsFrom.length; i++){
			if (this.transportOptionsFrom[i].code == this.fifthFormGroup.get('radioTransportOptionFrom').value){
				transportSnapshot["from"] = {
					'code': this.transportOptionsFrom[i].code
				};
			}
		}

		const insuranceSnapshot = {};
		for (let i = 0; i < this.checkInsuranceOptions.length; i++){
			if (this.checkInsuranceOptions.at(i).value){
				insuranceSnapshot[this.insuranceOptions[i].value] = this.insuranceOptions[i].label;
			}
		}

		const signUpData = {
			participant_id: this.thirdFormGroup.get('selectedParticipantID').value,
			camp_id: this.secondFormGroup.get('selectedCampID').value,
			client_phone_number: this.firstFormGroup.get('clientPhoneNumber').value,
			clients_mailing_address_id: this.firstFormGroup.get('mailingAddressID').value,
			clients_billing_address_id: this.firstFormGroup.get('billingAddressID').value,
			participants_address_id: this.thirdFormGroup.get('participantsAddressID').value,
			client_name_full: this.firstFormGroup.get('clientNameFirst').value.toString().trim() + ' ' + this.firstFormGroup.get('clientNameLast').value.toString().trim(),
			health_issues: this.fourthFormGroup.get('healthIssues').value,
			dietary_needs: this.fourthFormGroup.get('dietaryNeeds').value,
			special_needs: this.fourthFormGroup.get('specialNeeds').value,
			vaccinations_snapshot: JSON.stringify(vaccinationsSnapshot),
			promo_codes: this.seventhFromGroup.get('promo_codes').value,
			general_remarks: this.seventhFromGroup.get('general_remarks').value,
			terms_accepted: this.eighthFormGroup.get('checkTermsAndConditionsAccepted').value,
			rodo_accepted: this.eighthFormGroup.get('checkRODOAccepted').value,
			data_is_true_statement: this.eighthFormGroup.get('checkDataIsTrueStatement').value,
			electronic_invoice_accepted: this.eighthFormGroup.get('checkElectronicInvoiceAccepted').value,
			transport_options: transportSnapshot,
			insurance_snapshot: JSON.stringify(insuranceSnapshot),
			price: this.selectedCamp.price_special ? this.selectedCamp.price_special : this.selectedCamp.price,
		};

		this.savingData = true;

		this.campParticipantDataManipulator.signUpParticipantToCamp(signUpData).subscribe(
			(data) => {
				// show message box
				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status === 'SUCCESS'){
					dlgTitle = 'Sukces!';
					dlgMessage = '<p>Dane uczestnika zostały pomyślnie zapisane na serwerze. Za chwilę otrzymasz od nas wiadomość e-mail z dalszymi informacjami.</p>' +
						'<p>Zostaniesz teraz przeniesiona(-y) na stronę "Moje rezerwacje", na której możesz zarządzać swoimi rezerwacjami.</p>';
					dlgStyle = 'SUCCESS';
				}
				else {
					dlgTitle = 'Błąd!';
					dlgMessage = '<p>Podczas zapisywania danych uczestnika na serwerze wystąpił nieoczekiwany błąd. Spróbuj wysłać formularz jeszcze raz.</p>' +
						'<p>Prosimy o kontakt z AlphaCamp jeżeli problem będzie się powtarzał.</p>';
					dlgStyle = 'DANGER';
				}

				const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
					this.modalService, dlgStyle, 'Zamknij', null, null, null);

				let netOperationStatus = data;

				let mySub = myResult.subscribe(data => {
					// perform operations after user closes the window
					this.savingData = false;

					if (netOperationStatus.status === 'SUCCESS') {
						// reset the stepper form and move user to 'my reservations' subpage
						this.router.navigate(['/reservations', 'client', 'ACTIVE']);
					}

					mySub.unsubscribe();
				})
			}
		);
	}



}
