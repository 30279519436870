export class Tools {

	/**
	 * The following method makes sure that only properties present in T object literal are copied onto the result.
	 * Here is an example of using this method:
	 *
	 *      const extractAddressInput = tools.extract<AddressInput>(AddressInputLiteral);
     *      let data = extractAddressInput(sourceObject);
	 *
	 * Note the use of AddressInputLiteral - this must be an object containing all properties of AddressInput (and only those properties!!!)
	 * and all those properties must be set to any boolean value (true or false).
	 * sourceObject can contain extra fields, but they won't be copied!!! Nice :)))
	 *
	 * @param properties
	 */
	extract<T>(properties: Record<keyof T, boolean>){
		return function<TActual extends T>(value: TActual){
			let result = {} as T;
			for (const property of Object.keys(properties) as Array<keyof T>) {
				result[property] = value[property];
			}
			return result;
		}
	}

  /**
   * The following method creates a string from a number adds leading zeros if needed.
   */
  public addLeadingZeros(value: number, length: number) : string {
    return value.toString().padStart(length, '0');
  }
}
