import {Observable, Subject} from "rxjs";
import {NetworkOperationResult} from "../../types";
import {
	Insurance_submission_downloadQueryResult,
	InvoiceDownloadResult,
	issueInsuranceSubmissionMutationResult
} from "../results";
import {Insurance_submission_downloadQuery, InvoiceDownloadQuery} from "../queries";
import * as b64toblob from "b64-to-blob";
import {saveAs as importedSaveAs} from "file-saver";
import {Apollo} from "apollo-angular";
import {InsuranceSubmissionMutationInput} from "../../_models/insurancesubmission";
import {issueInsuranceSubmissionMutation} from "../mutations";

export class InsuranceSubmissionDataManipulations {
	constructor(private apollo: Apollo) {
	}

	downloadDocumentForInsuranceSubmission(insurancesubmission_id: number) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<Insurance_submission_downloadQueryResult>({
			mutation: Insurance_submission_downloadQuery,
			variables: {
				insurancesubmission_id
			}
		}).subscribe(({data}) => {
				if (data.insurance_submission_download.status.startsWith('Error')){
					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas pobierania dokumentu zgłoszenia ubezpieczonych!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie pobrano plik!"
					});
					retVal.complete();

					// @ts-ignore
					let blob = b64toblob(data.insurance_submission_download.file, "application/xlsx");
					importedSaveAs(blob, 'zgłoszenie_ubezpieczonych.xlsx');
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas pobierania dokumentu zgłoszenia ubezpieczonych!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	issueInsuranceSubmission(inputData: InsuranceSubmissionMutationInput) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<issueInsuranceSubmissionMutationResult>({
			mutation: issueInsuranceSubmissionMutation,
			variables: inputData
		}).subscribe(({data}) => {
				if (data.issueInsuranceSubmission.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.issueInsuranceSubmission.status.replace('Error: ', ''),
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wystawiono dokument!"
					});
					retVal.complete();

					if (data.issueInsuranceSubmission.file){
						// @ts-ignore
						let blob = b64toblob(data.issueInsuranceSubmission.file, "application/xlsx");
						importedSaveAs(blob, 'zgłoszenie_ubezpieczonych.xlsx');
					}
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas wystawiania dokumentu zgłoszenia ubezpieczonych!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}
}