import {User} from "./user";
import {Parent, Parent_EmptyEntity} from "./parent";

export class Participant {
	id: number;
	user: User;
	name_first: string;
	name_middle: string;
	name_last: string;
	date_birth: Date;
	national_id_number: string;
	gender: string;
	parents_list: [ Parent ];
	camp_ids: [ number ];
}

export const Participant_EmptyEntity = {
	id: -1,
	user: null,
	name_first: "",
	name_middle: "",
	name_last: "",
	date_birth: null,
	national_id_number: "",
	gender: "",
	parents_list: [ Parent_EmptyEntity ],
	camp_ids: null,
} as Participant;

export class ParticipantHelper {
	public singleString(participant: Participant) : string {
		let retVal : string = '';

		retVal += participant.name_first.trim();
		retVal += participant.name_middle.trim() !== '' ? ' ' + participant.name_middle.trim() : '';
		retVal += " " + participant.name_last.trim();

		return retVal;
	}
}

export const ParticipantGenderSelectConfig = [
	{ value: 'F', label: 'Kobieta' },
	{ value: 'M', label: 'Mężczyzna' }
];