<div class="attachments-manager">
  <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

  <div class="row ml-0 mr-0 p-0" *ngIf="!loading">
    <div class="col-12">
      <div class="row mt-1">
        <div class="col-12">
          <div class="h5" style="color: #7c7985">
            Załączniki do rezerwacji
            <i class="fas fa-sync fa-sm" style="margin-left: 30px; color: #4e96e6; cursor: pointer" mdbTooltip="Odśwież listę załączników" (click)="refreshAttachments()"></i>
          </div>
          <div style="color: #7c7985; font-size: 0.8em">
            <b style="font-weight: bold">Uczestnik:</b> {{ reservation.participant.name_first + ' ' + reservation.participant.name_last }}, <strong style="font-weight: bold">obóz:</strong> {{ reservation.camp.name }}
          </div>
          <div class="mb-2" style="color: #7c7985; font-size: 0.8em">
            <b style="font-weight: bold">Termin od:</b> {{ reservation.camp.date_start | formatDate : 'DD-MM-YYYY' }}, <strong style="font-weight: bold">do:</strong> {{ reservation.camp.date_end | formatDate : 'DD-MM-YYYY' }}
          </div>
          <div class="mb-3" style="color: #7c7985; font-size: 0.8em">
            <a [routerLink]="['/reservation-review', reservation.id]" >[przejdź do szczegółów rezerwacji]</a>
          </div>

          <mdb-progress-bar *ngIf="attachmentsRefreshInProgress" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
          <div *ngIf="!attachmentsRefreshInProgress" style="height: 5px"></div>
          <table mdbTable small="true" responsive="true" striped="true" style="width: 100%">
            <thead class="bg-info white-text">
              <tr>
                <th style="width: 50px;">#</th>
                <th style="width: 100%">Nazwa pliku</th>
                <th style="min-width: 100px;">Rozmiar</th>
                <th style="min-width: 150px;">Data dodania</th>
                <th style="min-width: 110px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let attachment of sortedAtAttachments; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <span class="link_behavior" style="margin-right: 10px" (click)="downloadFile(attachment)">{{ attachment.file_name }}</span>
                  <span *ngIf="!attachment.accepted" class="badge badge-info">Czeka na zatwierdzenie</span>
                  <attachment-rejection-form *ngIf="attachment.key == keyOfAttachmentBeingDeleted" (onCancel)="cancelDelete()" (onMessage)="deleteAttachmentConfirmed(attachment, $event)"></attachment-rejection-form>
                </td>
                <td>{{ attachment.size | formatFileSize }}</td>
                <td>{{ attachment.date_created | formatDate: 'DD-MM-YYYY HH:mm'}}</td>
                <td>
                  <i *ngIf="!attachment.accepted || currentUser.is_admin || currentUser.is_manager" class="fas fa-trash action_button" (click)="deleteAttachment(attachment)"></i>
                  <i *ngIf="currentUser.is_admin || currentUser.is_manager" class="fas fa-check action_button" [ngClass]="{accepted: attachment.accepted}" (click)="toggleAttachmentsAcceptanceFlag(attachment)"></i>
                </td>
            </tbody>

          </table>
          <div class="mt-2" style="color: #bf4f06; font-size: 0.8em">
            Jeżeli po naciśnięciu na link nie zacznie się pobieranie pliku, sprawdź czy w przeglądarce nie są zablokowane
            wyskakujące okienka (opcja ta najczęśniej dostępna jest w pasku adresu.<br/>
            Jeżeli po przesłaniu pliku nie pojawia się on na liście, naciśnij ten przycisk: <i class="fas fa-sync fa-sm" style="color: #4e96e6; cursor: pointer" mdbTooltip="Odśwież listę załączników" (click)="refreshAttachments()"></i>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <div class="h5" style="color: #7c7985">
            <i class="fas fa-plus"></i>&nbsp; Dodawanie nowego załącznika
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <div style="margin-bottom: 10px">
            Przesyłane załączniki muszą spełniać następujące warunki:
          </div>
          <ul style="font-size: 0.9em; color: #878787;">
            <li>
              Upewnij się, że nazwa pliku odzwierciedla jego zawartość - skorzystaj z formualrza (poniżej), który automatycznie
              sugeruje nazwę. Jeśli nie ma odpowiedniego rodzaju dokumentu, wpisz jego nazwę ręcznie. Wybierz także numer strony.
            </li>
            <li>
              Format pliku: <i>JPG, GIF, PNG, PDF</i>. Mogą to być zarówno skany jak i zdjęcia dokumentów wykonane telefonem.
            </li>
            <li>
              Rozmiar pojedynczego pliku: max 10MB
            </li>
            <li>
              Pliki muszą być czytelne - w jakości pozwalającej na ich wydrukowanie. Na skanie/zdjęciu może być
              widoczny wyłącznie dokument - nie akceptujemy zdjęć z fragmentami mebli (np. blat biurka), czy innych przedmiotów
            </li>
            <li>
              PDFy mogą zawierać wszystkie strony dokumentu, ale nie mogą zawierać skanów różnych dokumentów
              (prosimy nie łączyć w jednym pliku stron karty kwalifikacyjnej i szczepień)
            </li>
            <li>
              Pliki graficzne mogą zawierać wyłącznie pojedyncze strony dokumentu (proszę nie umieszczać na zdjęciu kilku kartek jednocześnie)
            </li>
            <li>
              Prosimy wysyłać wszystkie strony dokumentu, a nie tylko te z podpisami
            </li>
            <li>
              W przypadku zapisania uczestnika na kilka obozów, prosimy o przesłanie dokumentów dla każdego z nich osobno.
              Upewnij się, że przsyłasz dokumenty dotyczącę właściwego obozu.
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-2" [ngClass]="{display_muted: networkOperationInProgress}">
        <div class="col-12 col-md-4 mb-4" style="background-color: #f9f9f9; padding: 15px;">
          <file-drop (fileOpen)="onFileOpen($event)" [acceptedFileExtensions]="['jpeg', 'jpg', 'png', 'gif', 'pdf']" [acceptedContentTypes]="['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/pdf']" (fileOpen)="null"></file-drop>
        </div>
        <div class="col-12 col-md-8">
          <div class="row mb-3">
            <div class="col-12">
              <span *ngIf="selectedFile">
                <h5>Wybrany plik:</h5>
                <span style="color: #b5b5b5">
                  {{ selectedFile?.name }}
                </span>
              </span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <h5>Opisz co przesyłasz:</h5>
            </div>
          </div>

          <form [formGroup]="fileDescriptionForm">
            <div class="form-row mt-3">
              <div class="col-9">
                <div class="md-form mt-0 mb-2">
                  <mdb-select id="uploadedDocumentName" [options]="documentNames" formControlName="fileUploadDocumentNameSelect" placeholder="Przesyłany dokument"></mdb-select>
                  <label for="uploadedDocumentName">Dokument</label>
                </div>
              </div>

              <div class="col-3">
                <div class="md-form mt-0 mb-2">
                  <mdb-select id="uploadedDocumentPageNo" [options]="pageNumbers" formControlName="uploadedDocumentPageNo" placeholder="Strona"></mdb-select>
                  <label for="uploadedDocumentPageNo">Nr strony</label>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-12">
                <div *ngIf="showCustomFileNameInput" class="md-form mt-0 mb-2">
                  <input type="text" id="uploadedDocumentCustomName" [formControl]="uploadedDocumentCustomName" class="form-control" mdbInput mdbValidate>
                  <label for="uploadedDocumentCustomName">Wpisz nazwę dokumentu</label>
                  <mdb-error *ngIf="uploadedDocumentCustomName.invalid && (uploadedDocumentCustomName.dirty || uploadedDocumentCustomName.touched)">Błąd</mdb-error>
                </div>
              </div>
            </div>
          </form>


          <div class="row mt-3">
            <div class="col-12">
              <mdb-progress-bar *ngIf="networkOperationInProgress" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
              <button *ngIf="selectedFile && !networkOperationInProgress" type="button" mdbBtn color="primary" size="sm" class="relative waves-light" [disabled]="!fileDescriptionForm.valid || networkOperationInProgress" (click)="uploadFile()" mdbWavesEffect>Wyślij plik</button>
            </div>
          </div>


        </div>
      </div>



    </div>
  </div>
</div>
