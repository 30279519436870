import {Apollo} from "apollo-angular";
import {Observable, Subject} from "rxjs";
import {NetworkOperationResult} from "../../types";
import {
	sendEmailConfirmAccountReminderMutationResult,
	sendEmailEnrolledReminderMutationResult
} from "../results";
import {sendEmailConfirmAccountReminderMutation, sendEmailEnrolledReminderMutation} from "../mutations";

export class MailingDataManipulations {
	constructor(private apollo: Apollo) {
	}

	sendEmailParticipantEnrolledReminder(camp_participant_id: number) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<sendEmailEnrolledReminderMutationResult>({
			mutation: sendEmailEnrolledReminderMutation,
			variables: {
				camp_participant_id
			}
		}).subscribe(({data}) => {
				if (data.sendEmailEnrolledReminder.status.startsWith('Error')){
					console.error(data.sendEmailEnrolledReminder.status);

					retVal.next({
						status: "ERROR",
						message: "<p>Coś poszło nie tak:</p><p><strong>"  + data.sendEmailEnrolledReminder.status.replace('Error: ', '') +  "</strong></p>",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wysłano przypomnienie."
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas wysyłania maila z przypomnieniem o zakwalifikowaniu dziecka."
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	sendEmailConfirmAccountReminder(user_id: number, comment?: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<sendEmailConfirmAccountReminderMutationResult>({
			mutation: sendEmailConfirmAccountReminderMutation,
			variables: {
				user_id,
				comment
			}
		}).subscribe(({data}) => {
				if (data.sendEmailConfirmAccountReminder.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: "<p>Coś poszło nie tak:</p><p><strong>" + data.sendEmailConfirmAccountReminder.status.replace('Error: ', '') + "</strong></p>",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wysłano przypomnienie."
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak."
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}
}