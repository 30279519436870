<div class="row mx-1">
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div *ngFor="let singleItem of reservationInsuranceItems; let i = index;" class="row mb-3 mx-1 mx-sm-3">
    <div class="col-12">
        <div class="card z-depth-1">
            <div class="card-header white d-flex align-items-center py-3">
                <p class="h5-responsive font-weight-bold mb-0">{{ singleItem.name }}</p>
                <span *ngIf="singleItem.problem_flag">
                    &nbsp;&nbsp;
                    <span class="badge badge-warning">Wymaga uwagi</span>
                </span>
            </div>
            <div class="card-body">
                <div class="table-responsive-md">
                    <table class="table w-auto mx-1">
                        <thead>
                        <tr>
                            <th scope="col" style="min-width: 30px">Lp.</th>
                            <th scope="col" style="width: 100%; min-width: 300px">Imię i nazwisko</th>
                            <th scope="col" style="min-width: 200px">Opcje ubezp.</th>
                        </tr>
                        </thead>
                        <tbody>

                        <ng-template ngFor let-item let-singleParticipant [ngForOf]="singleItem.camp_participants" let-i_p="index">
                            <tr>
                                <td class="grey lighten-5">
                                    {{ i_p + 1}}.
                                </td>
                                <td class="grey lighten-5">
                                    <a [routerLink]="['/reservation-review', singleParticipant.camp_participant_id]">
                                        <strong>{{singleParticipant.name_first}} {{singleParticipant.name_middle}} {{singleParticipant.name_last}}</strong> &nbsp;&nbsp;
                                        <span *ngIf="!singleParticipant.insurance_policies || !singleParticipant.insurance_policies.length" class="badge badge-warning">BRAK POLIS</span>
                                    </a>
                                </td>
                                <td class="grey lighten-5">
                                    <span class="light-blue-text">
                                        {{ getParticipantsSelectedPolicies(singleParticipant.insurance_snapshot) }}
                                    </span>
                                </td>
                            </tr>
                            <tr *ngFor="let singlePolicy of singleParticipant.insurance_policies; let i_policy = index;">
                                <td></td>
                                <td>{{ singlePolicy.insurancepolicy_name }}</td>
                                <td>
                                    <span *ngIf="singlePolicy['campparticipant_campinsurance_insurancesubmission_id']">
                                        <a (click)="downloadSubmissionDocument(singlePolicy.campparticipant_campinsurance_insurancesubmission_id)" class="badge badge-success text-white">ZGŁOSZENIE</a>
                                    </span>

                                    <span *ngIf="singlePolicy['campparticipant_campinsurance_insurancesubmission_id'] == null">
                                        <span class="badge badge-light text-black-50">niezgłoszony</span>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--
            <div class="card-footer white py-3 d-flex justify-content-between">
                <button class="btn btn-primary btn-md px-3 my-0 mr-0">Place New Order</button>
                <button class="btn btn-light btn-md px-3 my-0 ml-0">View All Orders</button>
            </div>
            //-->
        </div>
    </div>
</div>