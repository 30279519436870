import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first, map} from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../_services';
import {LoginResult} from '../types';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {of, Subscription} from "rxjs";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    @ViewChild('modalErrorMessage', { static: true }) public modalErrorMessage: ModalDirective;

    public loginForm: FormGroup;
    public loading = false;
    public submitted = false;
    public returnUrl: string;
    public errorMessage = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(data => {
                this.loading = false;

                const loginResult: LoginResult = { ...data };

                if (loginResult.status == 'OK') {
                  this.router.navigate([this.returnUrl]);
                } else {
                  this.errorMessage = loginResult.message;
                  this.modalErrorMessage.show();
                }
              },
              error => {
                this.alertService.error(error);
                this.loading = false;
              });
    }
}
