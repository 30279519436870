import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AlertService, AuthenticationService, UserService} from "../_services";
import {first} from "rxjs/operators";
import {RegisterResult} from "../types";
import {passwordMatchValidator, passwordStrengthValidator} from "../_validators";
import {accountPasswordStrengthParams} from "../global-config";

@Component({
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
	@ViewChild('modalErrorMessage', { static: true }) public modalErrorMessage: ModalDirective;
	@ViewChild('modalSuccessMessage', { static: true }) public modalSuccessMessage: ModalDirective;

	public emailForm: FormGroup;
	public passwordChangeForm: FormGroup;
	public loading = false;
	public submitted = false;
	public errorMessage = '';
	public successMessage = '';
	public currentStep = 1;      // which form to show

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private alertService: AlertService
	) {
		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate(['/']);
		}

		this.currentStep = 1;
	}

	ngOnInit() {
		this.emailForm = this.formBuilder.group({
			email: ['', [Validators.email, Validators.required]]
		});

		this.passwordChangeForm = this.formBuilder.group({
			email: ['', [Validators.email, Validators.required]],
			resetCode: ['', [Validators.required]],
			password: ['', [Validators.required, Validators.minLength(8), passwordStrengthValidator(accountPasswordStrengthParams)]],
			confirmPassword: ['']
		},
			{
				// check whether our password and confirm password match
				validator: passwordMatchValidator
			});

		this.currentStep = 1;


		this.route.paramMap.subscribe((params: ParamMap) => {
			if (params.get('code') && params.get('code').trim() !== ''){
				this.currentStep = 2;
				this.passwordChangeForm.patchValue({resetCode: params.get('code')});
			}
		});
	}

	// convenience getter for easy access to form fields
	get f() { return this.emailForm.controls; }
	get f2() { return this.passwordChangeForm.controls; }

	showStep(step: number){
		this.currentStep = step;
	}


	onStep1Submit() {
		if (this.loading) {
			return;
		}

		// stop here if form is invalid
		this.emailForm.updateValueAndValidity();
		if (this.emailForm.invalid) {
			this.errorMessage = 'Formularz zawiera błędy. Uzupełnij pola przed naciśnięciem przycisku \'Resetuj hasło\'.';
			this.modalErrorMessage.show();
			return;
		}

		const email = this.f.email.value;
		this.requestRemoteAction(email);
	}

	onStep2Submit(){
		if (this.loading) return;

		this.passwordChangeForm.updateValueAndValidity();
		if (this.passwordChangeForm.invalid){
			this.errorMessage = 'Formularz zawiera błędy. Uzupełnij pola przed naciśnięciem przycisku \'Zmień hasło\'.';
			this.modalErrorMessage.show();
			return;
		}

		const email         = this.f2.email.value;
		const resetCode     = this.f2.resetCode.value;
		const newPassword   = this.f2.password. value;

		this.requestRemoteAction(email, resetCode, newPassword);
	}

	requestRemoteAction(email: string, resetCode?: string, newPassword?: string){
		this.submitted = true;

		this.loading = true;
		this.userService.resetPassword(email, resetCode, newPassword)
			.pipe(first())
			.subscribe(
				data => {
					this.loading = false;

					// show modal
					if (data.status === 'OK') {
						this.successMessage = data.message;
						this.modalSuccessMessage.show();
					} else {
						this.errorMessage = data.message;
						this.modalErrorMessage.show();
					}
				},
				error => {
					this.errorMessage = error.message;
					this.modalErrorMessage.show();
					this.loading = false;
				});
	}

	onSuccessModalClosed(event: any) {
		this.router.navigate(['/login']);
	}
}