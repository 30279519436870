import { Observable, Subject } from "rxjs";
import { NetworkOperationResult } from "../../types";
import {issueInvoiceForPaymentMutation, newReservationPaymentMutation} from "../mutations";
import { Apollo } from "apollo-angular";
import {InvoiceDownloadResult, issueInvoiceForPaymentResult, newReservationPaymentResult} from "../results";
import {InvoiceDownloadQuery} from "../queries";
import {saveAs as importedSaveAs} from "file-saver";
import * as b64toblob from "b64-to-blob";
import {InvoiceTypeEnum} from "../../_models/payment";

export class PaymentDataManipulations {
	constructor(private apollo: Apollo) {
	}

	newReservationPayment(inputData: any) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<newReservationPaymentResult>({
			mutation: newReservationPaymentMutation,
			variables: {
				...inputData
			}
		}).subscribe(({data}) => {
				if (data.newReservationPayment.status.startsWith('Error')){
					console.error(data.newReservationPayment.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas zapisywania danych o płatności w bazie danych.",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zapisano dane do bazy."
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas zapisywania informacji o płatności w bazie danych."
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	issueInvoiceForPayment(payment_id: number, invoice_type: InvoiceTypeEnum) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<issueInvoiceForPaymentResult>({
			mutation: issueInvoiceForPaymentMutation,
			variables: {
				payment_id,
				invoice_type
			}
		}).subscribe(({data}) => {
				if (data.issueInvoiceForPayment.status.startsWith('Error')){
					console.error(data.issueInvoiceForPayment.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas wystawiania faktury w zewnętrznej aplikacji Fakturownia.pl!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wystawiono fakturę!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas wystawiania faktury w zewnętrznej aplikacji Fakturownia.pl!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	downloadInvoiceForPayment(payment_id: number) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<InvoiceDownloadResult>({
			mutation: InvoiceDownloadQuery,
			variables: {
				payment_id
			}
		}).subscribe(({data}) => {
				if (data.invoice_download.status.startsWith('Error')){
					console.error(data.invoice_download.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas pobierania faktury w zewnętrznej aplikacji Fakturownia.pl!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wystawiono fakturę!"
					});
					retVal.complete();

					// @ts-ignore
					let blob = b64toblob(data.invoice_download.pdf_file, "application/pdf");
					importedSaveAs(blob, 'Faktura AlphaCamp.pdf');
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas pobierania faktury w zewnętrznej aplikacji Fakturownia.pl!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}
}