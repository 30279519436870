import { Component, OnInit, OnDestroy } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';

import { AuthUser } from '../_models';
import { UserService, AuthenticationService } from '../_services';

import {Apollo} from 'apollo-angular';
import {User} from "../_models/user";
import { updateUsersAdminFieldsMutation, updateUsersBasicFieldsMutation} from "../graphQL/mutations";
import { getAllUsersQuery } from '../graphQL/queries';
import { AllUsersQueryResult } from '../graphQL/results';
import { AdminUsersFieldsInput, BasicUsersFieldsInput } from "../graphQL/inputs";
import {MailingDataManipulations, UserDataManipulations} from "../graphQL/data-manipulation";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {MDBModalService} from "ng-uikit-pro-standard";



@Component({
  templateUrl: 'all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit, OnDestroy {
  public users: Observable<User[]>;
  public editCanceled: boolean;

  private userDataManipulator: UserDataManipulations;
  private mailingDataManipulator: MailingDataManipulations;

  private genericModalHandler: ModalGenericHandler;

  public networkOperationInProgress: boolean;

  constructor(
      private apollo: Apollo,
      private modalService: MDBModalService,
  ) {
      this.genericModalHandler = new ModalGenericHandler();
      this.editCanceled = false;  //was ESC key pressed during contenteditable editing?
      this.userDataManipulator = new UserDataManipulations();
      this.mailingDataManipulator = new MailingDataManipulations(apollo);
      this.networkOperationInProgress = false;
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.networkOperationInProgress = false;

    this.users = this.apollo.watchQuery<AllUsersQueryResult>({
        query: getAllUsersQuery,
        fetchPolicy: "network-only",
    })
        .valueChanges
        .pipe(
            map(result => {
              return result.data.users;
            })
        );
  }

  /**
   * Update user's basic fields - meaning fields requiring no special permissions or roles
   *
   * @param userObject
   * @param property
   * @param event
   */
  updateBasicFields(userObject: User, property: string, event: any) {
    if (this.editCanceled){
      //handle pressing ESC key
      this.editCanceled = false;
      event.target.textContent = userObject[property];
      return;
    }

    const editField = event.target.textContent;

    const data: BasicUsersFieldsInput = {
      id: userObject.id,
      firstName: property === "firstName" ? editField.trim() : userObject.firstName,
      lastName:  property === "lastName" ? editField.trim() : userObject.lastName,
      phone_number: property === "phone_number" ? editField.trim() : userObject.phone_number,
      billing_address_id: userObject.billing_address ? userObject.billing_address.id : null,
      mailing_address_id: userObject.mailing_address ? userObject.mailing_address.id : null,
    };

    this.userDataManipulator.updateUsersBasicFields(this.apollo, userObject, data);
  }

  /**
   * Handle pressing of special keys (i.e. Enter or ESC)
   *
   * @param userObject
   * @param property
   * @param event
   */
  keyDownHandler(userObject: User, property: string, event: any){
    if (event.key == 'Escape'){
      //undo changes and leave edit mode
      this.editCanceled = true;
      event.currentTarget.blur();
    }
    else if (event.key == 'Enter'){
      event.preventDefault();
      event.currentTarget.blur();
    }
  }

  /**
   * Handle toggling of boolean properties set by admins
   *
   * @param userObject
   * @param property
   */
  toggleRolePermission(userObject: User, property: string){
    const data : AdminUsersFieldsInput = {
      id: userObject.id,
      email: userObject.email,
      is_confirmed: property === 'is_confirmed' ? !userObject.is_confirmed : userObject.is_confirmed,
      is_active: property === 'is_active' ? !userObject.is_active : userObject.is_active,
      is_admin: property === 'is_admin' ? !userObject.is_admin : userObject.is_admin,
      is_manager: property === 'is_manager' ? !userObject.is_manager : userObject.is_manager,
      is_backoffice_employee: property === 'is_backoffice_employee' ? !userObject.is_backoffice_employee : userObject.is_backoffice_employee,
      is_camp_employee: property === 'is_camp_employee' ? !userObject.is_camp_employee : userObject.is_camp_employee,
      is_client: property === 'is_client' ? !userObject.is_client : userObject.is_client,
    };

    this.apollo.mutate({
      mutation: updateUsersAdminFieldsMutation,
      variables: {
        updatedData: data
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUsersAdminFields: {
          //__typename: 'User',
          ...userObject,
          id: data.id,
          email: data.email,
          is_confirmed: data.is_confirmed,
          is_active: data.is_active,
          is_admin: data.is_admin,
          is_manager: data.is_manager,
          is_backoffice_employee: data.is_backoffice_employee,
          is_camp_employee: data.is_camp_employee,
          is_client: data.is_client,
        },
      },
    }).subscribe();
  }

  /**
   * Handle updating of fields requiring admin/manager permissions
   *
   * @param userObject
   * @param property
   * @param event
   */
  updateAdminField(userObject: User, property: string, event: any) {
    if (this.editCanceled){
      //handle pressing ESC key
      this.editCanceled = false;
      event.target.textContent = userObject[property];
      return;
    }

    const editField = event.target.textContent;

    const data : AdminUsersFieldsInput = {
      id: userObject.id,
      email: property === 'email' ? editField.trim() : userObject.email,
      is_confirmed: property === 'is_confirmed' ? !userObject.is_confirmed : userObject.is_confirmed,
      is_active: property === 'is_active' ? !userObject.is_active : userObject.is_active,
      is_admin: property === 'is_admin' ? !userObject.is_admin : userObject.is_admin,
      is_manager: property === 'is_manager' ? !userObject.is_manager : userObject.is_manager,
      is_backoffice_employee: property === 'is_backoffice_employee' ? !userObject.is_backoffice_employee : userObject.is_backoffice_employee,
      is_camp_employee: property === 'is_camp_employee' ? !userObject.is_camp_employee : userObject.is_camp_employee,
      is_client: property === 'is_client' ? !userObject.is_client : userObject.is_client,
    };

    this.apollo.mutate({
      mutation: updateUsersAdminFieldsMutation,
      variables: {
        updatedData: data
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUsersAdminFields: {
          //__typename: 'User',
          ...userObject,
          id: data.id,
          email: data.email,
          is_confirmed: data.is_confirmed,
          is_active: data.is_active,
          is_admin: data.is_admin,
          is_manager: data.is_manager,
          is_backoffice_employee: data.is_backoffice_employee,
          is_camp_employee: data.is_camp_employee,
          is_client: data.is_client,
        },
      },
    }).subscribe();
  }

  /**
   * Using track by reduces time used to render the list after update
   *
   * @param index
   * @param userObject
   */
  trackByFn(index: number, userObject: User){
    return userObject ? userObject.id : null;
  }

  remove(id: any) {

  }

  sendActivationReminder(user: User){
    if (this.networkOperationInProgress) return;

    // confirm
    let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz wysłanie maila przypominającego?</p>",
        this.modalService, "INFO", null, null, "Tak", "Nie").subscribe(
        data => {
          msgSub.unsubscribe();

          if (data == "No") return;

          this.networkOperationInProgress = true;

          let localSub = this.mailingDataManipulator.sendEmailConfirmAccountReminder(
              user.id,
              ''
          ).subscribe(
              (data) => {
                this.networkOperationInProgress = false;

                let dlgTitle: string;
                let dlgMessage: string;
                let dlgStyle: ModalStyleType;

                if (data.status == "ERROR"){
                  dlgTitle = "Błąd!";
                  dlgMessage = data.message;
                  dlgStyle = "WARNING";

                  const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
                      this.modalService, dlgStyle, "OK", null, null, null);
                }
                else {
                  dlgTitle = "Sukces!";
                  dlgMessage = data.message;
                  dlgStyle = "SUCCESS";

                  const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
                      this.modalService, dlgStyle, "OK", null, null, null);
                }
              }
          );
        }
    );
  }
}
