export class PaymentsSummary {
	paid: string;
	overdue: string;
	upcoming: string;
	total: string;
}

export const PaymentsSummary_EmptyEntity = {
	paid: "0.00",
	overdue: "0.00",
	upcoming: "0.00",
	total: "0.00",
} as PaymentsSummary;