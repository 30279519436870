import {Participant} from "./participant";
import {Camp} from "./camp";
import {Address} from "./address";
import {CampParticipant_CampInsurance} from "./campparticipant_campinsurance";
import {Payment_plan_entry} from "./payment_plan_entry";
import {Payment} from "./payment";
import {DocumentToSubmit} from "./documenttosubmit";
import {ExtraInvoice} from "./extra_invoice";
import {DescriptiveField} from "./descriptivefield";
import {ReservationsTransportOptions} from "./reservationsTransportOptions";
import {PriceExtraComponents} from "./priceExtraComponents";
import {FileAttachment} from "./file_attachment";

export type Camp_Participant_Status = 'DRAFT' | 'SUBMITTED' | 'WAITING_LIST' | 'ENROLLED' | 'CONTRACT_SIGNED' | 'CANCELED' | 'CLIENT_RESIGNED' | 'FINISHED';
export type Contract_Signature_Type = 'MAIL' | 'SCAN' | 'PAYMENT' | 'IN_APP' | 'IN_PERSON';
export type Document_Type = "ENROLLMENT_CARD" | "VACCINATIONS_BOOK";

export class Camp_Participant {
	id: number;
	participant: Participant;
	camp: Camp;
	insurance_policies?: CampParticipant_CampInsurance[];
	payment_plan_entries?: Payment_plan_entry[];
	payments?: Payment[];

	participant_snapshot: string;

	clients_mailing_address?: Address;
	clients_mailing_address_snapshot: string;
	clients_billing_address?: Address;
	clients_billing_address_snapshot: string;
	participants_address?: Address;
	participants_address_snapshot: string;
	client_name_full: string;

	vaccinations_book_received_date?: Date;
	enrollment_card_received_date?: Date;

	dietary_needs: DescriptiveField;
	health_issues: DescriptiveField;
	special_needs: DescriptiveField;
	accommodation: DescriptiveField;
	general_remarks: DescriptiveField;
	promo_codes: DescriptiveField;
	authorizations: DescriptiveField;

	terms_accepted: boolean;
	rodo_accepted: boolean;
	data_is_true_statement: boolean;
	electronic_invoice_accepted: boolean;

	is_data_confirmed: boolean;
	pending_changes: boolean;

	created_timestamp: Date;
	status: Camp_Participant_Status;
	contract_sign_type: Contract_Signature_Type;
	contract_sign_date?: Date;

	vaccinations_snapshot: string;
	selected_transport_options: ReservationsTransportOptions;
	insurance_snapshot: string;
	payment_plan_snapshot: string;
	events_array?: string;

	price?: number;
	price_extra_options?: number;
	price_extra_components: PriceExtraComponents;
	total_price?: number;
	totalPaymentsSum?: number;

	order_number?: number;
	documents_to_submit: DocumentToSubmit[];
	unsubmitted_documents: boolean;

	extra_invoices: ExtraInvoice[];
	extra_invoices_total_amount: number;
	extra_invoices_amount_left: number;

  attachments: FileAttachment[];
  unaccepted_attachments: boolean;

	static getPriceForTransportation(sourceObject : Camp_Participant) : number {
		if (sourceObject?.price_extra_components?.TRANSPORTATION){
			return sourceObject.price_extra_components.TRANSPORTATION;
		}

		return 0;
	}
}
