import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';


@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  private _acceptedFileExtensions: Array<string> | null = null;
  get acceptedFileExtensions(): Array<string> | null {
    return this._acceptedFileExtensions;
  }
  @Input() set acceptedFileExtensions(value: Array<string> | null) {
    // set all extensions to uppercase
    if (value !== null){
      this._acceptedFileExtensions = value.map((ext) => ext.toUpperCase());
    }
    else{
      this._acceptedFileExtensions = null;
    }
  }
  @Input() acceptedContentTypes: Array<string> | null = null;
  @Output() fileOpen = new EventEmitter<File>();

  constructor(

  ) { }

  ngOnInit(): void {
  }

  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.fileBrowseHandler($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(targetOrFiles: any) {
    let files: FileList | null = null;

    if (targetOrFiles instanceof HTMLInputElement){
      files = (targetOrFiles as HTMLInputElement).files;
    }
    else if (targetOrFiles instanceof FileList){
      files = targetOrFiles;
    }

    if (files !== null){
      //check if file extension is allowed
      if (this.acceptedFileExtensions !== null){
        if (files.length && this.acceptedFileExtensions.includes(files[0].name.split('.').pop()?.toUpperCase())){
          this.fileOpen.emit(files[0]);
        }
      }
    }
  }


  public getAlloweFileExtensions(): string {
    return this.acceptedFileExtensions.join(", ");
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    /*
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
   */
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
