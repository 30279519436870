import {Component, OnDestroy, OnInit} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
	EXTERNAL_INVOICE_STATUS_OPTIONS,
	Extra_Invoice_Status_Type,
	ExtraInvoiceEditorData,
	ExtraInvoiceEditorDataEmptyEntity,
	ExtraInvoiceLookupData,
	ExtraInvoiceLookupDataEmptyEntity
} from "../_models";
import { IMyOptions, MDBModalRef } from "ng-uikit-pro-standard";
import * as _ from "underscore";
import {Camp_participantDataManipulations} from "../graphQL/data-manipulation";
import {Apollo} from "apollo-angular";


export type ExtraInvoiceModalModeType = "LOOKUP" | "EDIT";

@Component({
	templateUrl: './modal-extra-invoice-editor.component.html',
	styleUrls: ['./modal-extra-invoice-editor.component.scss']
})
export class ModalExtraInvoiceEditorComponent implements OnInit {
	public extraInvoiceIDForm: FormGroup;
	public extraInvoiceEditForm: FormGroup;   //form controls

	public invoiceStatuses;
	public invoiceLookupData: ExtraInvoiceLookupData;
	public extraInvoiceEditorData: ExtraInvoiceEditorData;

	public bLookupOperationError: boolean;
	public bShowExtraInvoiceDetails: boolean;
	public cLookupErrorMessage: string;
	public cMode: ExtraInvoiceModalModeType;

	public saveButtonPressed: boolean;

	public networkOperationInProgress: boolean;

	constructor(public modalRef: MDBModalRef,
	            private apollo: Apollo,
	            private _formBuilder: FormBuilder,
	            private reservationsDataManipulator: Camp_participantDataManipulations
	) {
		this.extraInvoiceEditForm = this._formBuilder.group({
			comment: ['', [Validators.required]],
			include_in_summary: [true, [Validators.required]],
			invoice_status: ['ISSUED', [Validators.required]],
		});

		this.extraInvoiceIDForm = this._formBuilder.group({
			extraInvoiceID: ['', [Validators.required]]
		});

		this.invoiceStatuses = EXTERNAL_INVOICE_STATUS_OPTIONS;

		this.extraInvoiceEditorData = _.clone(ExtraInvoiceEditorDataEmptyEntity);
	}

	ngOnInit(): void {
		this.networkOperationInProgress = false;
		this.bLookupOperationError = false;
		this.bShowExtraInvoiceDetails = false;
		this.saveButtonPressed = false;

		this.resetForms();

		if (this.cMode == 'EDIT'){
			this.invoiceLookup(this.extraInvoiceEditorData.invoice_foreign_id, this.extraInvoiceEditorData.provider);
		}
	}

	private resetForms() {
		this.invoiceLookupData = _.clone(ExtraInvoiceLookupDataEmptyEntity);

		this.extraInvoiceIDForm.reset();
		this.extraInvoiceEditForm.reset();

		// if this modal has been opened in 'edit' mode, fill the forms
		if (this.cMode == 'EDIT'){
			this.extraInvoiceID.setValue(this.extraInvoiceEditorData.invoice_foreign_id);
			this.invoice_status.setValue(this.extraInvoiceEditorData.invoice_status);
			this.include_in_summary.setValue(this.extraInvoiceEditorData.invoice_include_in_summary);
			this.comment.setValue(this.extraInvoiceEditorData.comment);
		}
		else {
			this.extraInvoiceEditorData.provider = "fakturownia";
		}
	}

	// convenience getter for easy access to form fields
	get f_newextrainvoice() {
		return this.extraInvoiceIDForm.controls;
	}

	get extraInvoiceID() : FormControl {
		return this.f_newextrainvoice.extraInvoiceID as FormControl;
	}

	// convenience getter for easy access to form fields
	get f_extrainvoice() {
		return this.extraInvoiceEditForm.controls;
	}

	get comment() : FormControl {
		return this.f_extrainvoice.comment as FormControl;
	}

	get invoice_status() : FormControl {
		return this.f_extrainvoice.invoice_status as FormControl;
	}

	get include_in_summary() : FormControl {
		return this.f_extrainvoice.include_in_summary as FormControl;
	}

	invoiceLookupButtonPressed(){
		if (this.networkOperationInProgress || !this.extraInvoiceIDForm.valid) return;

		this.invoiceLookup(this.extraInvoiceID.value.toString().trim(), 'fakturownia');
	}

	invoiceLookup(invoice_foreign_id: string, provider: string = 'fakturownia'){
		if (this.networkOperationInProgress || !this.extraInvoiceIDForm.valid) return;

		this.networkOperationInProgress = true;
		this.bShowExtraInvoiceDetails = false;

		let localSub = this.reservationsDataManipulator.extraInvoiceLookup(
			invoice_foreign_id,
			provider
		).subscribe(
			(data) => {
				if (data.status == 'SUCCESS'){
					this.bLookupOperationError = false;
					this.bShowExtraInvoiceDetails = true;
					this.invoiceLookupData = data.data as ExtraInvoiceLookupData;

					if (this.cMode == "LOOKUP"){
						/*
							Automatically fill form fields based on data received from external app
						 */
						this.invoice_status.setValue(this.calculateInvoiceStatus());
						this.include_in_summary.setValue(true);
						this.comment.setValue('Faktura za dodatkowe usługi');
					}
				}
				else {
					this.bLookupOperationError = true;
					this.cLookupErrorMessage = "Błąd podczas pobierania danych faktury - być może podany identyfikator faktury nie istnieje lub wystąpił inny błąd po stronie serwera. Spórbuj ponownie lub skontaktuj się z administratorem."
				}


				this.networkOperationInProgress = false;
			},
			(error) => {
				this.networkOperationInProgress = false;
				this.bLookupOperationError = true;
				this.cLookupErrorMessage = "Błąd w komunikacji z serwerem - sprawdź połączenie sieciowe lub skontaktuj się z administratorem."
			}
		);
	}

	calculateInvoiceStatus() : Extra_Invoice_Status_Type {
		let amountPaid = Math.abs(this.invoiceLookupData.invoice_amount_paid);
		let amountTotal = Math.abs(this.invoiceLookupData.invoice_amount_total);

		if (!amountPaid){
			return 'ISSUED';
		}
		else if (amountPaid >= amountTotal){
			return 'COMPLETE';
		}
		else {
			return 'PARTIALLY_PAID';
		}
	}

	checkCalculatedInvoiceStatus(statusToCheck: Extra_Invoice_Status_Type) : boolean {
		let status : Extra_Invoice_Status_Type = this.calculateInvoiceStatus();

		return (status == statusToCheck);
	}

	saveData(){
		if (this.extraInvoiceEditForm.valid){
			this.saveButtonPressed = true;

			//save data to data exchange object
			this.extraInvoiceEditorData.invoice_foreign_id = this.extraInvoiceID.value.toString().trim();
			//this.extraInvoiceEditorData.provider   - don't change it
			this.extraInvoiceEditorData.invoice_status = this.invoice_status.value;
			this.extraInvoiceEditorData.invoice_include_in_summary = this.include_in_summary.value;
			this.extraInvoiceEditorData.comment = this.comment.value.toString().trim();

			//close the window
			this.modalRef.hide();
		}
	}
}