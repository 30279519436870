import {deleteParticipantsMutation, updateOrCreateParticipantMutation} from "../mutations";
import {getUserParticipantsQuery} from "../queries";
import {Apollo} from "apollo-angular";
import {ParticipantInput, ParticipantInputLiteral} from "../inputs";
import {Parent, Participant, Participant_EmptyEntity, User} from "../../_models";
import * as _ from "underscore";
import {ModalParticipantEditComponent} from "../../modal-participant-edit";
import {Tools} from "../../_helpers/tools";
import {MDBModalService} from "ng-uikit-pro-standard";
import {Observable, Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class ParticipantDataManipulations {
	addParticipant(apollo: Apollo, participantData: ParticipantInput, expectedResponseData: any, userId: number, parentsList: Array<any>) : Observable<Participant>{
		let retVal = new Subject<Participant>();

		let localSub = apollo.mutate({
			mutation: updateOrCreateParticipantMutation,
			variables: {
				participant: participantData
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateOrCreateParticipant: {
					...expectedResponseData,
					__typename: 'Participant',
					parents_list: this.decorateParentsList(parentsList),
				},
			},
			update: (proxy, { data: { updateOrCreateParticipant }}) => {
				// Read the data from our cache for this query.
				const data = proxy.readQuery({ query: getUserParticipantsQuery, variables: { user_id: userId } });
				let dataCopy = JSON.parse(JSON.stringify(data));    // data['user_participants'] is immutable so we must make a DEEP clone of data!

				// Add our address from the mutation to the end.
				dataCopy['user_participants'].push(updateOrCreateParticipant);

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserParticipantsQuery, variables: { user_id: userId }, data: dataCopy });
			},
		}).subscribe(
			({data}) => {
				retVal.next(data.updateOrCreateParticipant);
				retVal.complete();

				localSub.unsubscribe();
			},
			error => {
				retVal.next(null);
				retVal.complete();

				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	editParticipant(apollo: Apollo, participantData: ParticipantInput, expectedResponseData: any, userId: number, parentsList: Array<any>){
		apollo.mutate({
			mutation: updateOrCreateParticipantMutation,
			variables: {
				participant: participantData
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateOrCreateParticipant: {
					...expectedResponseData,
					__typename: 'Participant',
					parents_list: this.decorateParentsList(parentsList),
				},
			},
		}).subscribe();
	}

	deleteParticipant(apollo: Apollo, participantId: number, userId: number){
		apollo.mutate({
			mutation: deleteParticipantsMutation,
			variables: {
				deleteIDs: [participantId]
			},
			optimisticResponse: {
				__typename: 'Mutation',
				deleteParticipants: [participantId],
			},
			update: (proxy, { data: { deleteParticipants }}) => {
				// Read the data from our cache for this query.
				let data = proxy.readQuery({ query: getUserParticipantsQuery, variables: { user_id: userId } });

				//create a hash object which will have deleted ids as its keys
				const idsHash = deleteParticipants.reduce((a, b) => {a[b] = ''; return a}, {});

				//now leave only those addresses which haven't been deleted
				data['user_participants'] = data['user_participants'].filter((participant: Participant) => {
					return !idsHash.hasOwnProperty(participant.id);
				});

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserParticipantsQuery, variables: { user_id: userId }, data });
			},
		}).subscribe();
	}

	private decorateParentsList(parentsList: Array<any>) : Array<any> {
		let retVal : Array<any> = [];

		for (let i = 0; i < parentsList.length; i++){
			retVal.push({
				...parentsList[i],
				__typename: "Parent"
			});
		}

		return retVal;
	}

	editParticipantDialog(participant: Participant, apollo: Apollo, modalService: MDBModalService){
		/*
			Prepare modal for editing data
		 */
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				participant: _.clone(participant),
				saveButtonPressed: false,       //very important
			}
		};

		/*
			Show the modal
		 */
		let modalRef = modalService.show(ModalParticipantEditComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let participantEditSubscription = modalService.closed.subscribe(() => {
      // use a workaround to ignore events coming from modals opened by this participant edit modal
      if (modalRef.content.modalComplete){
        //don't react to further events coming from the modal
        participantEditSubscription.unsubscribe();

        if (modalRef.content.saveButtonPressed){
          //save address to the database

          //make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
          const tools = new Tools();
          const extractParticipantInput = tools.extract<ParticipantInput>(ParticipantInputLiteral);
          let data = extractParticipantInput(modalRef.content.participant);
          data.user_id = modalRef.content.participant.user.id;

          this.editParticipant(apollo, data, modalRef.content.participant, data.user_id, modalRef.content.participant.parents_list);
        }
      }


		});
	}

	addParticipantDialog(owner: User, apollo: Apollo, modalService: MDBModalService, parentsPreset: [Parent] = null) : Observable<Participant>{
		let retVal = new Subject<Participant>();

		/*
			Prepare an empty data object to be edited
		 */
		let newEmptyParticipant = {
			..._.clone(Participant_EmptyEntity),
			user: owner
		};

		if (parentsPreset !== null && parentsPreset.length){
			newEmptyParticipant.parents_list = parentsPreset;
		}

		/*
			Prepare modal for editing data
		 */
		let modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				participant: newEmptyParticipant,
				saveButtonPressed: false
			}
		};

		/*
			Show the modal
		 */
		let modalRef = modalService.show(ModalParticipantEditComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let participantEditSubscription = modalService.closed.subscribe(() => {
			if (modalRef.content.modalComplete){
        // avoid reacting to events coming from modals opened by this participant edit modal

        if (modalRef.content.saveButtonPressed){
          //save address to the database

          //make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
          const tools = new Tools();
          const extractParticipantInput = tools.extract<ParticipantInput>(ParticipantInputLiteral);
          let data = extractParticipantInput(modalRef.content.participant);
          const user_id = modalRef.content.participant.user.id;
          data.user_id = user_id;

          let dataSubscription = this.addParticipant(apollo, data, modalRef.content.participant, user_id, modalRef.content.participant.parents_list)
            .subscribe(
              (data) => {
                dataSubscription.unsubscribe();

                retVal.next(data);
                retVal.complete();
              }
            );
        }
        else {
          retVal.next(null);
          retVal.complete();
        }

        //don't react to further events coming from the modal
        participantEditSubscription.unsubscribe();
      }

		});

		return retVal.asObservable();
	}
}
