import { Component, OnInit } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import {ButtonPressedType, ButtonsCaptionsType, ButtonsToShowType} from "../modal-generic-handler";

@Component({
	templateUrl: './modal-generic.component.html',
	styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {
	public title: string;
	public message: string;
  public unsanitizedMessage: string;
	public buttonsToShow: ButtonsToShowType;
	public buttonsCaptions: ButtonsCaptionsType;

	public buttonPressed: ButtonPressedType;

	constructor(public modalRef: MDBModalRef) {
		this.buttonsToShow = {
			OK: false,
			Cancel: false,
			Yes: false,
			No: false,
		};

		this.buttonsCaptions = {
			OK: 'OK',
			Cancel: 'Anuluj',
			Yes: 'Tak',
			No: 'Nie'
		};
	}

	ngOnInit(): void {
	}

	close(buttonPressed?: ButtonPressedType) {
		if (buttonPressed){
			this.buttonPressed = buttonPressed;
		}

		this.modalRef.hide();
	}
}
