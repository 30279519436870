import {ACCEPTED_SUBMISSION_TYPES} from "./insurancesubmission";

export class InsuranceSubmissionCampConfig {
	camp_id: number;
	camp_name: string;
	camp_date_start: Date;
	camp_date_end: Date;
}

export class InsuranceSubmissionPolicyConfig {
	insurancepolicy_id: number;
	insurancepolicy_name: string;
	insurancepolicy_issuing_company_name: string;
	accepted_submission_types: ACCEPTED_SUBMISSION_TYPES[];
	camps: InsuranceSubmissionCampConfig[];
}