import {Component, Input, OnInit, Output} from '@angular/core';
import {DocumentToSubmit, DocumentToSubmitSelectConfig} from "../_models/documenttosubmit";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {MDBModalService} from "ng-uikit-pro-standard";
import { EventEmitter } from '@angular/core';

export type DocumentsToSubmitFormViewStyleType = "CAMP" | "RESERVATION_FULL" | "RESERVATION_SHORT";

@Component({
  selector: 'documents-to-submit-form',
  templateUrl: './documents-to-submit-form.component.html',
  styleUrls: ['./documents-to-submit-form.component.css']
})
export class DocumentsToSubmitFormComponent implements OnInit {
  @Output() commitChanges = new EventEmitter<DocumentToSubmit[]>();

  @Input()
  get view_style(){
    return this._view_style;
  }
  set view_style(newViewStyle: DocumentsToSubmitFormViewStyleType){
    this._view_style = newViewStyle;
  }

  @Input()
  get documents_to_submit(){
    return this._documents_to_submit;
  }
  set documents_to_submit(newDocumentsToSubmit: DocumentToSubmit[]){
    this._documents_to_submit = newDocumentsToSubmit;

    //initialize documents array
    this.documentsToSubmitList.clear();

    for (let i = 0; i < this._documents_to_submit.length; i++){
      this.documentsToSubmitList.push(this.createDocument(this._documents_to_submit[i]));
    }
  }

  private genericModalHandler: ModalGenericHandler;

  private _documents_to_submit: DocumentToSubmit[] = null;
  private _view_style: DocumentsToSubmitFormViewStyleType = "CAMP";


  public documentsToSubmitForm: FormGroup;   //form controls
  public documentsToSubmitStatusesConfig;


  constructor(private _formBuilder: FormBuilder, private modalService: MDBModalService) {
    this.genericModalHandler = new ModalGenericHandler();
    this.documentsToSubmitStatusesConfig = DocumentToSubmitSelectConfig;

    this.documentsToSubmitForm = this._formBuilder.group({
      documentsToSubmitList: this._formBuilder.array([])
    });
  }

  ngOnInit(): void {

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.documentsToSubmitForm.controls;
  }

  get documentsToSubmitList(){
    return this.f.documentsToSubmitList as FormArray;
  }

  private createDocument(document: DocumentToSubmit) : FormGroup {
    let configObj: Object = {
      conditionalExpression: (x)=> (x.documentEditFormDocumentSubmittedStatus == 'CONTAINS_ERRORS')
    };

    return this._formBuilder.group({
      documentEditFormDocumentId: [document.document_id, [Validators.required, RxwebValidators.unique()]],
      documentEditFormDocumentName: [document.document_name, [Validators.required, RxwebValidators.minLength({value:8 })]],
      documentEditFormDocumentRequired: [document.document_required, [Validators.required]],
      documentEditFormDocumentRequiredOriginal: [document.document_required_original, [Validators.required]],
      documentEditFormDocumentSubmittedStatus: [document.document_submitted_status, [Validators.required]],
      documentEditFormDocumentSubmittedDate: [document.document_submitted_date],
      documentEditFormDocumentComment: [document.comment, [
        RxwebValidators.required(configObj),
        RxwebValidators.minLength({value: 8})
      ]],
    })
  }

  public addNewDocument() {
    this.documentsToSubmitList.push(this.createDocument({
      document_id: '',
      document_name: '',
      document_required: false,
      document_required_original: false,
      document_submitted_status: null,
      document_submitted_date: null,
      comment: '',
    }));
  }

  public deleteDocument(i: number){
    this.documentsToSubmitList.removeAt(i);
  }

  private iterateFormControlsInSearchForInvalidState(form: FormGroup | FormArray) : boolean {
    if (form){
      if (form.status == 'INVALID'){
        return false;
      }
      else if (form.controls){
        for (let singleFormControl in form.controls){
          if (!this.iterateFormControlsInSearchForInvalidState(form.controls[singleFormControl])) return false;
        }
      }
    }

    return true;  //no errors
  }

  public save() {
    this.documentsToSubmitForm.markAllAsTouched();
    this.documentsToSubmitForm.markAsDirty();

    if (!this.documentsToSubmitForm.valid || !this.iterateFormControlsInSearchForInvalidState(this.documentsToSubmitForm)){

      // show error
      let msgSub = this.genericModalHandler.showModal("Niepoprawne dane!", "<p>Dane w formularzu zawierają błędy.</p>",
          this.modalService, "INFO", "OK", null, null, null).subscribe(
          data => {
            msgSub.unsubscribe();
          }
      );
      return;
    }

    let allDocuments: Array<DocumentToSubmit> = [];

    // create an array of new values
    for (let a = 0; a < this.documentsToSubmitList.controls.length; a++){
      let updatedDocument: DocumentToSubmit = {
        document_id: this.documentsToSubmitList.at(a).value.documentEditFormDocumentId,
        document_name: this.documentsToSubmitList.at(a).value.documentEditFormDocumentName,
        document_required: this.documentsToSubmitList.at(a).value.documentEditFormDocumentRequired,
        document_required_original: this.documentsToSubmitList.at(a).value.documentEditFormDocumentRequiredOriginal,
        document_submitted_status: this.documentsToSubmitList.at(a).value.documentEditFormDocumentSubmittedStatus,
        document_submitted_date: this.documentsToSubmitList.at(a).value.documentEditFormDocumentSubmittedStatus == 'SUBMITTED' ? this.documentsToSubmitList.at(a).value.documentEditFormDocumentSubmittedDate : null,
        comment: this.documentsToSubmitList.at(a).value.documentEditFormDocumentComment
      }

      allDocuments.push(updatedDocument);
    }

    this.commitChanges.emit(allDocuments);
  }
}
