<div class="modal-header">
    <h4 class="heading modal-title w-100" id="myModalLabel">{{ windowTitle }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 modal-text-edit-description-text" [innerHTML]="descriptionText">
        </div>
    </div>
    <form [formGroup]="textEditForm">
        <div class="form-row">
            <div class="col-12">
                <div class="md-form">
                    <textarea #focusInput type="text" id="textEditModalForm-value" [formControl]="textValue" class="md-textarea form-control" mdbInput mdbValidate></textarea>
                    <label for="textEditModalForm-value">{{ textareaHintText }}</label>
                    <mdb-error *ngIf="textValue.invalid && (textValue.dirty || textValue.touched)">Błąd</mdb-error>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" mdbBtn color="secondary" size="sm" class="waves-light" aria-label="Close" (click)="modalRef.hide()" mdbWavesEffect>Anuluj</button>
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" [disabled]="!textEditForm.valid" (click)="saveData()" mdbWavesEffect>Zapisz zmiany</button>
</div>