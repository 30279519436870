import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {ModalGenericHandler} from "../modal-generic-handler";
import {Camp, Camp_EmptyEntity, User} from "../_models";
import {Active_camps_statisticsQueryResult, AllInsurancesubmissionsQueryResult} from "../graphQL/results";
import {Active_camps_statisticsQuery, AllInsurancesubmissionsQuery, AllPaymentsQuery} from "../graphQL/queries";
import {of, Subscription} from "rxjs";
import {FormControl, FormGroup } from "@angular/forms";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {CampDataManipulation} from "../graphQL/data-manipulation";
import * as _ from "underscore";

@Component({
	templateUrl: './active-camps-statistics.component.html',
	styleUrls: ['./active-camps-statistics.component.scss']
})
export class ActiveCampsStatisticsComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public networkOperationInProgress: boolean;

	private loggedInUserSubscription: Subscription = null;
	private dataSubscription: Subscription = null;
	private uriParametersSubscription: Subscription = null;

	public currentUser: User;

	private genericModalHandler: ModalGenericHandler;

	private campDataHandler: CampDataManipulation;

	private camps: Camp[];
	public queried_camps: Camp[];
	public cumulative_camp_statistics_object: Camp = null;   // camp object containing summary for all queried_camps

	public search_form: FormGroup;
	private search_string: string;
	private search_stringSubscription: Subscription = null;


	constructor(private router: Router,
	            private route: ActivatedRoute,
	            private apollo: Apollo,
	            private authService: AuthenticationService){

		this.genericModalHandler = new ModalGenericHandler();
		this.campDataHandler = new CampDataManipulation();
		this.loading = false;
		this.networkOperationInProgress = false;
		this.search_string = "";


		this.search_form = new FormGroup({
			search_string: new FormControl(this.search_string, []),
		});

		this.search_stringSubscription = this.search_form.controls["search_string"].valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged()
			)
			.subscribe((value) => {
				this.loading = true;
				this.search_string = value;
				setTimeout(() => {
					// update displayed URL
					this.router.navigate(['/activecampsstats'], {queryParams: {search: value}, queryParamsHandling: 'merge'});
					this.queryCamps();
				}, 200);     // a little trick to display loading bar and make
									// interface more user friendly
				}
			);
	}

	ngOnInit(): void {
		this.networkOperationInProgress = false;

		// react to change of camp_status parameter
		this.uriParametersSubscription = this.route.queryParams
			.subscribe( params => {

			if (params.search != this.search_string){
				this.search_string = params.search ? params.search.trim() : '';
				this.search_form.controls["search_string"].setValue(this.search_string);
				this.queryCamps();
			}
		});

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.camps = [];
				}
				else {
					this.loading = true;
					this.dataSubscription = this.apollo.watchQuery<Active_camps_statisticsQueryResult>({
						query: Active_camps_statisticsQuery,
						fetchPolicy: "network-only"
					})
						.valueChanges
						.subscribe(
							(({ data, loading}) => {
								this.loading = loading;
								this.camps = data.active_camps;
								this.queryCamps();
							})
						);
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.dataSubscription) this.dataSubscription.unsubscribe();
		if (this.search_stringSubscription) this.search_stringSubscription.unsubscribe();
		if (this.uriParametersSubscription) this.uriParametersSubscription.unsubscribe();
	}

	// Copy camps items to queried_camps, but only those matching search criteria
	private queryCamps(): void {
		if (!this.camps) return;

		this.loading = true;


		// first delete all existing items from queried_camps
		this.queried_camps = new Array();

		let escapdedSearchString = this.search_string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

		for (let singleCamp of this.camps){
			if (this.checkCampAgainstQuery(singleCamp, escapdedSearchString)){
				this.queried_camps.push(singleCamp);
			}
		}

		this.calculate_cumulative_camp_statistics();

		this.loading = false;
	}

	// this method checks whether search_string is contained by camp name or camp location using regular expressions
	// case insensitive
	private checkCampAgainstQuery(camp: Camp, search_string: string) : boolean {
		if (search_string.trim() == "") return true;

		let search_string_upper = search_string.toUpperCase();
		const regex : RegExp = new RegExp("(?:" + search_string_upper + ")", "gm");

		//console.log();
		if (camp.name.toUpperCase().match(regex) !== null || camp.location_name.toUpperCase().match(regex) !== null)
			return true;

		return false;
	}

	private calculate_cumulative_camp_statistics(){
		this.cumulative_camp_statistics_object = JSON.parse(JSON.stringify(Camp_EmptyEntity));

		let tmpLocationNames : Object = {};
		let tmpAgeStatisticsObject : Object = {};

		this.cumulative_camp_statistics_object.name = "Podsumowanie";
		this.cumulative_camp_statistics_object.price_special = null;

		for (let singleCamp of this.queried_camps){
			this.cumulative_camp_statistics_object.participants_capacity += singleCamp.participants_capacity;

			// generate participants_count_statistics
			if (singleCamp.participants_count_statistics){
				for (let singleCount of singleCamp.participants_count_statistics){
					let index = _.findIndex(this.cumulative_camp_statistics_object.participants_count_statistics, {string_value: singleCount.string_value});

					if (index != -1){
						this.cumulative_camp_statistics_object.participants_count_statistics[index].items_count += singleCount.items_count;
					}
					else {
						this.cumulative_camp_statistics_object.participants_count_statistics.push(_.clone(singleCount));
					}
				}
			}

			// generate age_statistics object
			if (singleCamp.age_statistics){
				/*
					Age_statistics object contains 4 arrays having the exact same number of elements (age groups)
					The only difference is the value of items_count in each element of the array,
					but all 4 arrays have the same numerical_value at the same index.

					This is why we can iterate over one array only and assume all other arrays hold the same age
					group (numerical_value) at any given index
				 */
				for (let i: number = 0; i < singleCamp.age_statistics.Total.length; i++){
					let index = _.findIndex(this.cumulative_camp_statistics_object.age_statistics.Total, { numerical_value: singleCamp.age_statistics.Total[i].numerical_value });

					if (index != -1) {    //found
						this.cumulative_camp_statistics_object.age_statistics.Total[index].items_count += singleCamp.age_statistics.Total[i].items_count;
						this.cumulative_camp_statistics_object.age_statistics.F[index].items_count += singleCamp.age_statistics.F[i].items_count;
						this.cumulative_camp_statistics_object.age_statistics.M[index].items_count += singleCamp.age_statistics.M[i].items_count;
						this.cumulative_camp_statistics_object.age_statistics.O[index].items_count += singleCamp.age_statistics.O[i].items_count;
					}
					else {
						this.cumulative_camp_statistics_object.age_statistics.Total.push(_.clone(singleCamp.age_statistics.Total[i]));
						this.cumulative_camp_statistics_object.age_statistics.F.push(_.clone(singleCamp.age_statistics.F[i]));
						this.cumulative_camp_statistics_object.age_statistics.M.push(_.clone(singleCamp.age_statistics.M[i]));
						this.cumulative_camp_statistics_object.age_statistics.O.push(_.clone(singleCamp.age_statistics.O[i]));
					}
				}

				// now sort the results!
				this.cumulative_camp_statistics_object.age_statistics.Total = _.sortBy(this.cumulative_camp_statistics_object.age_statistics.Total, 'numerical_value');
				this.cumulative_camp_statistics_object.age_statistics.F = _.sortBy(this.cumulative_camp_statistics_object.age_statistics.F, 'numerical_value');
				this.cumulative_camp_statistics_object.age_statistics.M = _.sortBy(this.cumulative_camp_statistics_object.age_statistics.M, 'numerical_value');
				this.cumulative_camp_statistics_object.age_statistics.O = _.sortBy(this.cumulative_camp_statistics_object.age_statistics.O, 'numerical_value');
			}

			//sum up payments
			if (singleCamp.payment_statistics){
				for (let singlePaymentKey of Object.keys(singleCamp.payment_statistics)){
					//console.log(singlePaymentKey);
					if (singlePaymentKey != "__typename"){
						this.cumulative_camp_statistics_object.payment_statistics[singlePaymentKey] =
							(Number(singleCamp.payment_statistics[singlePaymentKey]) +
								Number(this.cumulative_camp_statistics_object.payment_statistics[singlePaymentKey])).toString();
					}

				}
			}

			// update locations hash object
			tmpLocationNames[singleCamp.location_name] = "";
		}

		// concatenate all location names
		for (let locationName of Object.keys(tmpLocationNames)){
			if (this.cumulative_camp_statistics_object.location_name != ''){
				this.cumulative_camp_statistics_object.location_name += ', ';
			}

			this.cumulative_camp_statistics_object.location_name += locationName;
		}
	}


}