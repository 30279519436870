import {Component, OnInit} from '@angular/core';
import {MdbCheckboxChange, MDBModalRef} from 'ng-uikit-pro-standard';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Address} from "../_models";
import {conditionalRequiredValidator} from "../_validators";
import * as _ from "underscore";

@Component({
	templateUrl: './modal-address-edit.component.html',
	styleUrls: ['./modal-address-edit.component.css']
})
export class ModalAddressEditComponent implements OnInit {
	public addressEditForm: FormGroup;   //form controls
	public address: Address; //source data

	public isBusiness: boolean;

	public saveButtonPressed: boolean;

	constructor(public modalRef: MDBModalRef) {}

	ngOnInit(): void {
		this.addressEditForm = new FormGroup({
			addressEditModalNameFirst: new FormControl(this.address.name_first),        //see validators below
			addressEditModalNameLast: new FormControl(this.address.name_last),          //see validators below
			addressEditModalIsBusiness: new FormControl(this.address.is_business, [Validators.required]),
			addressEditModalCompanyName: new FormControl(this.address.company_name),    //see validators below
			addressEditModalVatNumber: new FormControl(this.address.vat_number),        //see validators below
			addressEditModalStreetName: new FormControl(this.address.street_name, [Validators.required]),
			addressEditModalStreetNumber: new FormControl(this.address.street_number),
			addressEditModalFlatNumber: new FormControl(this.address.flat_number,),
			addressEditModalZipCode: new FormControl(this.address.zip_code, [Validators.required]),
			addressEditModalCity: new FormControl(this.address.city, [Validators.required]),
			addressEditModalCountry: new FormControl(this.address.country, [Validators.required]),
			addressEditModalPhoneNumber: new FormControl(this.address.phone_number),
		});

		this.addressEditModalCompanyName.setValidators(conditionalRequiredValidator({sourceCheckbox: this.addressEditModalIsBusiness, sourceCheckboxValue: true }));
		this.addressEditModalNameFirst.setValidators(conditionalRequiredValidator({sourceCheckbox: this.addressEditModalIsBusiness, sourceCheckboxValue: false }));
		this.addressEditModalNameLast.setValidators(conditionalRequiredValidator({sourceCheckbox: this.addressEditModalIsBusiness, sourceCheckboxValue: false }));

		this.isBusiness = this.address.is_business;
	}

	// convenience getter for easy access to form fields
	get f() { return this.addressEditForm.controls; }

	get addressEditModalNameFirst() {
		return this.f.addressEditModalNameFirst;
	}

	get addressEditModalNameLast() {
		return this.f.addressEditModalNameLast;
	}

	get addressEditModalIsBusiness() {
		return this.f.addressEditModalIsBusiness;
	}

	get addressEditModalCompanyName() {
		return this.f.addressEditModalCompanyName;
	}

	get addressEditModalVatNumber() {
		return this.f.addressEditModalVatNumber;
	}

	get addressEditModalStreetName() {
		return this.f.addressEditModalStreetName;
	}

	get addressEditModalStreetNumber() {
		return this.f.addressEditModalStreetNumber;
	}

	get addressEditModalFlatNumber() {
		return this.f.addressEditModalFlatNumber;
	}

	get addressEditModalZipCode() {
		return this.f.addressEditModalZipCode;
	}

	get addressEditModalCity() {
		return this.f.addressEditModalCity;
	}

	get addressEditModalCountry() {
		return this.f.addressEditModalCountry;
	}

	get addressEditModalPhoneNumber() {
		return this.f.addressEditModalPhoneNumber;
	}

	/**
	 * Clicking on checkbox doesn't update the state of reactive form - we must do it manually.
	 *
	 * Also - changing the state of checkbox requires revalidating of dependent fields.
	 *
	 * @param $event
	 */
	onIsBusinessClick($event: MdbCheckboxChange) {
		this.f.addressEditModalIsBusiness.setValue($event.checked);
		this.validateAllFormFields(this.addressEditForm);

		this.isBusiness = this.f.addressEditModalIsBusiness.value;
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.updateValueAndValidity();
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	saveData(){
		if (this.addressEditForm.valid){
			this.saveButtonPressed = true;

			//copy form values to address object
			const tmpObject = {
				name_first: this.addressEditModalNameFirst.value,
				name_last: this.addressEditModalNameLast.value,
				is_business: this.addressEditModalIsBusiness.value,
				company_name: this.addressEditModalCompanyName.value,
				vat_number: this.addressEditModalVatNumber.value,
				street_name: this.addressEditModalStreetName.value,
				street_number: this.addressEditModalStreetNumber.value,
				flat_number: this.addressEditModalFlatNumber.value,
				zip_code: this.addressEditModalZipCode.value,
				city: this.addressEditModalCity.value,
				country: this.addressEditModalCountry.value,
				phone_number: this.addressEditModalPhoneNumber.value,
			};

			//overwrite adress object properties with new values leaving fields such as id untouched
			_.extend(this.address, tmpObject);

			this.modalRef.hide();
		}
	}
}