import {Component, OnDestroy, OnInit} from '@angular/core';
import {MdbCheckboxChange, MDBModalRef} from 'ng-uikit-pro-standard';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Address, User} from "../_models";
import {Subscription} from "rxjs";
import {UserAddressesQueryResult} from "../graphQL/results";
import {getUserAddressesQuery} from "../graphQL/queries";
import {AuthenticationService} from "../_services";
import {Apollo} from "apollo-angular";

@Component({
  selector: 'app-modal-address-select',
  templateUrl: './modal-address-select.component.html',
  styleUrls: ['./modal-address-select.component.css']
})
export class ModalAddressSelectComponent implements OnInit {
  public addressSelectForm: FormGroup;  //form controls

  public addresses: Address[];          //user's addresses



  // following variables are set by the parent component upon opening the modal
  public saveButtonPressed: boolean;    // has user pressed the save button?
  public dialogTitle: string;           // dialog title
  public defaultAddressID?: number;     //previusly selected address ID
  public replaceAddressNameWith?: string;       //should address' client's name be replaced with this string?
  public addressTypeName: string;       //address type name (e.g. "do rozliczeń", "korespondencyjny", "uczestnika", etc.)
  public selectedAddressID?: number;     //selected address ID

  constructor(public modalRef: MDBModalRef, private apollo: Apollo, private authService: AuthenticationService) {

  }


  ngOnInit(): void {

    // initialize form controls
    this.addressSelectForm = new FormGroup({
      addressSelectModalAddressId: new FormControl(null, [Validators.required]),
    });

    this.addressSelectModalAddressId.setValue(null);

    // set default address if it was not set before
    if (this.defaultAddressID !== undefined && this.defaultAddressID !== null && this.addresses.length > 0) {
      // set address id value only if the id is valid - this means that the address is in the list of user's addresses
      // with a given id. Otherwise, the default address id is not valid and should not be set.
      let defaultAddress = this.addresses.find(address => address.id.toString() == this.defaultAddressID.toString());
      if (defaultAddress) this.addressSelectModalAddressId.setValue(this.defaultAddressID.toString());
    }

    this.selectedAddressID = null;

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.addressSelectForm.controls;
  }

  get addressSelectModalAddressId() : FormControl {
    return this.addressSelectForm.get("addressSelectModalAddressId") as FormControl;
  }

  cancelAndClose()
  {
    this.saveButtonPressed = false;
    this.modalRef.hide();
  }

  saveData()
  {
    this.saveButtonPressed = true;
    this.selectedAddressID = this.addressSelectModalAddressId.value;
    this.modalRef.hide();
  }
}
