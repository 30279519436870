import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Address, Participant, Participant_EmptyEntity, User } from "../_models";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "../_services";
import { MDBModalService } from "ng-uikit-pro-standard";
import { UserParticipantsQueryResult } from "../graphQL/results";
import { getUserParticipantsQuery } from "../graphQL/queries";

import {ParticipantDataManipulations} from "../graphQL/data-manipulation";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";

@Component({
	templateUrl: './my-participants.component.html',
	styleUrls: ['./my-participants.component.scss']
})
export class MyParticipantsComponent implements OnInit, OnDestroy {

	public participants: Participant[];
	private loggedInUserSubscription: Subscription;
	private participantsSubscription: Subscription;
	public currentUser: User;

	public loading: boolean;

	private genericModalHandler: ModalGenericHandler;

	constructor(private apollo: Apollo,
              private authService: AuthenticationService,
              private modalService: MDBModalService,
              private participantDataManipulator: ParticipantDataManipulations) {

		this.genericModalHandler = new ModalGenericHandler();
	}

	ngOnInit(): void {
		this.loading = true;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.participants = [];
					this.loading = false;
				}
				else {
					if (this.participantsSubscription) this.participantsSubscription.unsubscribe();

					this.participantsSubscription = this.apollo.watchQuery<UserParticipantsQueryResult>({
						query: getUserParticipantsQuery,
						variables: {
							user_id: user.id
						}
					})
					.valueChanges
					.subscribe(
						(({data, loading}) => {
							this.loading = loading;
							this.participants = data.user_participants;
						})
					);
				}
			}
		);
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) {
			this.loggedInUserSubscription.unsubscribe();
			this.loggedInUserSubscription = null;
		}

		if (this.participantsSubscription) {
			this.participantsSubscription.unsubscribe();
			this.participantsSubscription = null;
		}
	}

	trackByFn(index: number, addressObject: Address) {
		return addressObject.id;
	}

	deleteParticipant(participant: Participant){
		if (participant.camp_ids && participant.camp_ids.length){
			// show message box
			let dlgTitle: string;
			let dlgMessage: string;
			let dlgStyle: ModalStyleType;

			dlgTitle = 'Błąd!';
			dlgMessage = '<p>Nie można usunąć uczestnika, który jest lub był zapisany na obóz</p>';
			dlgStyle = 'WARNING';

			this.genericModalHandler.showModal(dlgTitle, dlgMessage,
				this.modalService, dlgStyle, 'Zamknij', null, null, null);

			return;
		}

		let dlgTitle: string    = 'Potwierdź!';
		let dlgMessage: string  = '<p>Czy na pewno chcesz usunąć wybranego uczestnika? Operacja jest nieodwracalna!</p>';
		let dlgStyle: ModalStyleType = 'DANGER';


		const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
			this.modalService, dlgStyle, null, null, 'Tak', 'Nie');


		let mySub = myResult.subscribe(data => {
			if (data == 'Yes') {
				// delete address
				this.participantDataManipulator.deleteParticipant(this.apollo, +participant.id, participant.user.id);
			}

			mySub.unsubscribe();
		});
	}

	editParticipant(participant: Participant){
		this.participantDataManipulator.editParticipantDialog(participant, this.apollo, this.modalService);
	}

	addParticipant(){
		this.participantDataManipulator.addParticipantDialog(this.currentUser, this.apollo, this.modalService);
	}
}
