export type DocumentToSubmitStatus = 'NOT_SUBMITTED' | 'CONTAINS_ERRORS' | 'SUBMITTED';

export class DocumentToSubmit {
	document_id: string;
	document_name: string;
	document_required: boolean;
	document_required_original: boolean;
	document_submitted_status?: DocumentToSubmitStatus;
	document_submitted_date?: Date;
	comment?: string;
}

export const DocumentToSubmit_EmptyEntity = {
	document_id: 'DOCID_01',
	document_name: '',
	document_required: true,
	document_required_original: false,
	document_submitted_date: null,
	document_submitted_status: "NOT_SUBMITTED",
	comment: ''
} as DocumentToSubmit;

export const DocumentToSubmitSelectConfig = [
	{ value: 'NOT_SUBMITTED', label: 'niedostarczony' },
	{ value: 'CONTAINS_ERRORS', label: 'zawiera błędy' },
	{ value: 'SUBMITTED', label: 'dostarczony' }
];