<form [formGroup]="transportOptionsEditorForm" (ngSubmit)="save()">
    <!-- TO //-->
    <div class="row">
        <div class="col-12">
            <div class="md-form mb-2 mt-1">
                <mdb-select [options]="optionsCampTransportOptionsTo" formControlName="transportOptionToCode" label="Forma transportu na miejsce" placeholder="Wybierz jedną z opcji">
                </mdb-select>
            </div>
        </div>
        <div class="col-11 col-md-9 col-sm-12">
            <div class="md-form mt-0 mb-2">
                <input type="text" id="transportOptionsEditorForm-to-remarks" formControlName="transportOptionToRemarks" class="form-control" mdbInput mdbValidate>
                <label htmlFor="transportOptionsEditorForm-to-price">Szczegółowe informacje</label>
            </div>
        </div>
        <div class="col-1 col-md-3 col col-sm-12">
            <div class="md-form mt-0 mb-2">
                <input type="number" min="0" step="0.01" id="transportOptionsEditorForm-to-price" formControlName="transportOptionToPrice" class="form-control" mdbInput mdbValidate>
                <label htmlFor="transportOptionsEditorForm-to-price">Cena</label>
            </div>
        </div>
    </div>

    <!-- FROM //-->
    <div class="row mt-4">
        <div class="col-12">
            <div class="md-form mb-2 mt-1">
                <mdb-select [options]="optionsCampTransportOptionsFrom" formControlName="transportOptionFromCode" label="Forma transportu z powrotem" placeholder="Wybierz jedną z opcji">
                </mdb-select>
            </div>
        </div>
        <div class="col-11 col-md-9 col-sm-12">
            <div class="md-form mt-0 mb-2">
                <input type="text" id="transportOptionsEditorForm-from-remarks" formControlName="transportOptionFromRemarks" class="form-control" mdbInput mdbValidate>
                <label htmlFor="transportOptionsEditorForm-from-price">Szczegółowe informacje</label>
            </div>
        </div>
        <div class="col-1 col-md-3 col col-sm-12">
            <div class="md-form mt-0 mb-2">
                <input type="number" min="0" step="1" id="transportOptionsEditorForm-from-price" formControlName="transportOptionFromPrice" class="form-control" mdbInput mdbValidate>
                <label htmlFor="transportOptionsEditorForm-from-price">Cena</label>
            </div>
        </div>
    </div>

    <!-- Automatic price update checkbox //-->
    <div class="row mt-4">
        <div class="col-12">
            <mdb-checkbox formControlName="transportOptionUpdateReservationPrice">Automatycznie zaktualizuj cenę i plan płatności</mdb-checkbox>
        </div>
        <div *ngIf="showPriceUpdateAlert" class="col-12 mt-3">
            <blockquote class="blockquote bq-warning">
                <p><small>Pamiętaj o RĘCZNYM wystawieniu dodatkowych faktur (lub korekt) w przypadku zmian wpływających na cenę dla Klienta.</small></p>
            </blockquote>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex w-100 justify-content-center">
            <button type="button" [disabled]="_lockControls" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="cancel()" mdbWavesEffect>Anuluj</button>

            <button mdbBtn [disabled]="_lockControls" size="sm" type="submit" color="success" mdbWavesEffect>Zapisz</button>
        </div>
    </div>
</form>

