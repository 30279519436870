
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" class="white-text">×</span>
    </button>
    <h4 class="modal-title white-text w-100" id="myModalLabel">{{ dialogTitle }}</h4>
  </div>

  <div class="modal-body modal-address-select">
    <div class="row mb-2">
      <div class="col-12">
        <h5>Wybierz nowy adres z poniższej listy:</h5>
        <p class="missing-address-notice">
          <b>Uwaga!</b> Jeżeli nie widzisz właściwego adresu na liście lub potrzebujesz najpierw zmienić dane w adresie,
            przejdź do okna 'moje adresy' (dostępne w menu), a następnie powróć tutaj.
        </p>
      </div>
    </div>

    <div>
      <form [formGroup]="addressSelectForm">
        <div *ngFor="let address of addresses; let i = index" class="form-row align-items-center address-choice mt-2 py-2" [ngClass]="{oddRow: i % 2 == 1}">
          <div class="col-12">
            <div class="form-check">
              <input type="radio" mdbInput class="form-check-input" [id]="'radioAddress_' + i" [value]="address.id"
                     formControlName="addressSelectModalAddressId" name="addressSelectModalAddressId">

              <label class="form-check-label" [for]="'radioAddress_' + i">
                <span *ngIf="address.is_business" class="badge badge-pill badge-info">Firma</span>
                <span *ngIf="address.is_business">&nbsp; {{address.company_name}} [NIP: {{address.vat_number}}]<br/></span>
                <span *ngIf="replaceAddressNameWith !== null && replaceAddressNameWith.trim() !== ''">{{ replaceAddressNameWith }}<br/></span>
                <span *ngIf="replaceAddressNameWith === null || replaceAddressNameWith.trim() == ''">{{ address.name_first }} {{ address.name_last}}<br/></span>
                <span class="address-details">
                  {{ address.street_name }} {{ address.street_number }}
                  <span *ngIf="address.flat_number !== null && address.flat_number.trim() !== ''">/ {{ address.flat_number }}</span><br/>
                  {{address.zip_code}} {{ address.city }}, {{ address.country }}<br/>
                  <span *ngIf="address.phone_number !== null && address.phone_number.trim() !== ''">tel. {{ address.phone_number }}<br/></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>


  </div>

  <div class="modal-footer">
    <button type="button" mdbBtn color="secondary" size="sm" class="waves-light" aria-label="Close" (click)="cancelAndClose()" mdbWavesEffect>Anuluj</button>
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="saveData()" [disabled]="!addressSelectForm.valid"mdbWavesEffect>Zapisz zmiany</button>
  </div>

