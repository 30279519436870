import {Component, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subscription, of} from 'rxjs';
import {Address, Address_EmptyEntity, User} from "../_models";
import {Apollo} from "apollo-angular";
import {UserAddressesQueryResult} from "../graphQL/results";
import {getUserAddressesQuery} from "../graphQL/queries";
import {AuthenticationService} from "../_services";
import {MDBModalRef, MDBModalService} from "ng-uikit-pro-standard";
import {AddressDataManipulations, UserDataManipulations} from "../graphQL/data-manipulation";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";



@Component({
	templateUrl: './my-addresses.component.html',
	styleUrls: ['./my-addresses.component.css']
})
export class MyAddressesComponent implements OnInit, OnDestroy {
	public addresses: Address[];
	private loggedInUserSubscription: Subscription;
	private addressesSubscription: Subscription;
	public currentUser: User;
	private userDataManipulator: UserDataManipulations;
	private genericModalHandler: ModalGenericHandler;

	public loading: boolean = true;

	constructor(private apollo: Apollo, private authService: AuthenticationService, private modalService: MDBModalService,
              private addressDataManipulator: AddressDataManipulations) {
		this.userDataManipulator = new UserDataManipulations();

		this.genericModalHandler = new ModalGenericHandler();

	}

	ngOnInit(): void {
		this.loading = true;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user) {
					this.addresses = [];
					this.loading = false;
				} else {
				    if (this.addressesSubscription) this.addressesSubscription.unsubscribe();
					this.addressesSubscription = this.apollo.watchQuery<UserAddressesQueryResult>({
						query: getUserAddressesQuery,
						variables: {
							user_id: user.id
						}
					})
						.valueChanges
                        .subscribe(
                            ({data, loading}) => {
                                this.loading = loading;
                                this.addresses = data.user_addresses;
                            });
				}
			}
		);
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) {
			this.loggedInUserSubscription.unsubscribe();
			this.loggedInUserSubscription = null;
		}

		if (this.addressesSubscription) {
            this.addressesSubscription.unsubscribe();
            this.addressesSubscription = null;
        }
	}

	trackByFn(index: number, addressObject: Address) {
		return addressObject.id;
	}

	editAddress(address: Address) {
		this.addressDataManipulator.editAddressDialog(address);
	}

	addAddress() {
		this.addressDataManipulator.addAddressDialog(this.currentUser);
	}

	deleteAddress(address: Address) {
		let dlgTitle: string = 'Potwierdź!';
		let dlgMessage: string = '<p>Czy na pewno chcesz usunąć wybrany adres? Operacja jest nieodwracalna!</p>';
		let dlgStyle: ModalStyleType = 'DANGER';


		const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
			this.modalService, dlgStyle, null, null, 'Tak', 'Nie');


		let mySub = myResult.subscribe(data => {
			if (data == 'Yes') {
				// delete address
				this.addressDataManipulator.deleteAddress(address.id, address.user.id);
			}

			mySub.unsubscribe();
		});
	}

	setAddress(address: Address, type: "mailing" | "billing") {
		this.userDataManipulator.setAddress(this.apollo, this.currentUser, address, type);
	}

	getAddressClassName(address: Address): string {
		if ((this.currentUser.billing_address && address.id == this.currentUser.billing_address.id)
			|| (this.currentUser.mailing_address && address.id == this.currentUser.mailing_address.id)) {
			return "light-blue lighten-5";
		}
		return "bg-grey-lighten-5";
	}
}
