import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {MDBModalService} from "ng-uikit-pro-standard";
import {ModalGenericHandler} from "../modal-generic-handler";
import {User} from "../_models";
import {Subscription} from "rxjs";
import {Payment, PaymentHelper, PaymentMethodEnum, PaymentStatusEnum} from "../_models/payment";
import {AllPaymentsResult, PaymentResult} from "../graphQL/results";
import {AllPaymentsQuery} from "../graphQL/queries";
import {PaymentDataManipulations} from "../graphQL/data-manipulation/payment-data-manipulations";

@Component({
	templateUrl: './all-payments.component.html',
	styleUrls: ['./all-payments.component.scss']
})
export class AllPaymentsComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public networkOperationInProgress: boolean;

	private loggedInUserSubscription: Subscription;
	private paymentsSubscription: Subscription;

	public currentUser: User;
	public payments: PaymentResult[];

	private genericModalHandler: ModalGenericHandler;
	private paymentsDataManipulator: PaymentDataManipulations;
	private paymentsHelper: PaymentHelper;

	constructor(private router: Router,
	            private route: ActivatedRoute,
	            private apollo: Apollo,
	            private authService: AuthenticationService,
	            private modalService: MDBModalService) {

		this.genericModalHandler = new ModalGenericHandler();
		this.loading = false;
		this.networkOperationInProgress = false;
		this.paymentsDataManipulator = new PaymentDataManipulations(apollo);
		this.paymentsHelper = new PaymentHelper();
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.paymentsSubscription) this.paymentsSubscription.unsubscribe();
	}

	ngOnInit(): void {
		this.networkOperationInProgress = false;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.payments = [];
				}
				else {
					this.loading = true;
					this.paymentsSubscription = this.apollo.watchQuery<AllPaymentsResult>({
						query: AllPaymentsQuery,
						fetchPolicy: "network-only"
					})
						.valueChanges
						.subscribe(
							(({ data, loading}) => {
								this.loading = loading;
								this.payments = data.all_payments;
							})
						);
				}
			});
	}

	downloadInvoice(payment_id: number){
		if (this.networkOperationInProgress || payment_id === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.paymentsDataManipulator.downloadInvoiceForPayment(payment_id).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania faktury wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}

	paymentMethodToHumanReadable(paymentMethod: PaymentMethodEnum){
		return this.paymentsHelper.humanReadableMethod(paymentMethod);
	}

	paymentStatusToColor(paymentStatus: PaymentStatusEnum){
		switch (paymentStatus) {
			case "COMPLETE":
				return 'green';

			default:
				return 'red';
		}
	}
}