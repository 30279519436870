import {Camp_Participant} from "./camp_participant";

export type PaymentMethodEnum = 'BANK_TRANSFER' | 'CASH' | 'ONLINE_PAYMENT' | 'CREDIT_CARD';
export type PaymentStatusEnum = 'COMPLETE';
export type InvoiceTypeEnum   = 'advance' | 'final';

export class Payment {
	id: number;
	camp_participant: Camp_Participant;
	amount: number;
	transaction_timestamp: Date;
	is_income: boolean;
	bank_account: string;
	method: PaymentMethodEnum;
	status: PaymentStatusEnum;
	invoice_number?: string;
	invoice_type?: InvoiceTypeEnum;
	created_timestamp: Date;
	description?: string;
}

export class PaymentHelper {
	humanReadableMethod(method: PaymentMethodEnum) : string {
		switch (method) {
			case "BANK_TRANSFER":
				return "przelew";

			case "CASH":
				return "gotówka";

			case "CREDIT_CARD":
				return "płatność kartą";

			case "ONLINE_PAYMENT":
				return "przelew online";
		}
	}

	humanReadableStatus(status: PaymentStatusEnum) : string {
		switch (status) {
			case "COMPLETE":
				return "zakończona";
		}
	}
}