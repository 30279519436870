<div class="row px-2 px-sm-0 deep-blue-text">
  <div class="col-12">
    <h2>Lista Twoich adresów</h2>
    <p class="lead">
      Adresy wykorzystywane są w różnych częściach aplikacji <strong>Copernicus</strong>. W tym miejscu możesz dodawać,
      usuwać i edytować adresy przypisane do Twojego konta.
    </p>
    <p>
      Jednym z przykładów wykorzystania adresów jest zapisywanie dziecka na obóz. W tym celu na formularzu rejestracyjnym
      zostaniesz poproszona(-y) o podanie adresu korespondencyjnego, rozliczeniowego oraz adresu zamieszkania dziecka. Ten
      sam adres może być wykorzystany wielokrotnie.
    </p>
    <p>
      Aby usunąć adres kliknij na ikonie kosza w prawym górnym rogu tabelki z adresem. Edycja istniejącego adresu możliwa
      jest po kliknięciu ikony z ołówkiem.
    </p>
    <p>
      Możliwe jest ustawienie domyślnego adresu korespondencyjnego i adresu rozliczeniowego dla konta - w tym celu użyj
      odpowiedniego przycisku w stopce tabelki adresu.
    </p>
  </div>
</div>

<mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

<div *ngIf="!loading" class="row px-2 px-sm-0">
  <div *ngFor="let address of addresses; trackBy: trackByFn" class="col-12 col-sm-6 col-lg-4">
    <mdb-card class="mb-3" borderColor="border-light">
      <mdb-card-header *ngIf="!address.is_business" class="grey lighten-5">
        {{ address.name_first }} {{ address.name_last }}
        <div class="float-right hoverable-address-links">
          <a title="Usuń adres" (click)="deleteAddress(address)"><mdb-icon far icon="trash-alt"></mdb-icon></a>
          &nbsp;&nbsp;
          <a title="Edytuj adres" (click)="editAddress(address)"><mdb-icon far icon="edit"></mdb-icon></a>
        </div>
      </mdb-card-header>

      <mdb-card-header *ngIf="address.is_business" class="grey lighten-5"><mdb-icon fas icon="building" title="Adres firmowy">
        </mdb-icon>&nbsp;&nbsp; {{ address.company_name }}
        <div class="float-right hoverable-address-links">
          <a title="Usuń adres" (click)="deleteAddress(address)"><mdb-icon far icon="trash-alt"></mdb-icon></a>
          &nbsp;&nbsp;
          <a title="Edytuj adres" (click)="editAddress(address)"><mdb-icon far icon="edit"></mdb-icon></a>
        </div>
      </mdb-card-header>

      <mdb-card-body>
        <!--
        <mdb-card-title>
          <h5>Secondary Panel title</h5>
        </mdb-card-title>
        -->
        <mdb-card-text>
          <div class="row">
            <div class="col-12 col-sm-5">
              <strong>Imię i nazwisko</strong>
            </div>
            <div class="col-12 col-sm-7">
              {{ address.name_first }} {{ address.name_last }}
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-5">
              <strong>Adres</strong>
            </div>
            <div class="col-12 col-sm-7">
              {{ address.street_name }} {{ address.street_number }} <span *ngIf="address.flat_number !== null && address.flat_number.trim() !== ''">/</span> {{ address.flat_number }}
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-5">
              <strong></strong>
            </div>
            <div class="col-12 col-sm-7">
              {{ address.zip_code }} {{ address.city }}, {{ address.country }}
            </div>
          </div>

          <div *ngIf="address.is_business" class="row">
            <div class="col-12 col-sm-5">
              <strong>NIP</strong>
            </div>
            <div class="col-12 col-sm-7">
              {{ address.vat_number }}
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-5">
              <strong>tel.</strong>
            </div>
            <div class="col-12 col-sm-7">
              {{ address.phone_number }}
            </div>
          </div>

        </mdb-card-text>
      </mdb-card-body>
      <mdb-card-footer>
        <button [disabled]="!(currentUser.mailing_address && currentUser.mailing_address.id != address.id)" mdbBtn block="true" class="my-2" color="blue-grey" size="sm" (click)="setAddress(address, 'mailing')">korespondencyjny</button>
        <button [disabled]="!(currentUser.billing_address && currentUser.billing_address.id != address.id)" mdbBtn block="true" class="my-2" color="blue-grey" size="sm" (click)="setAddress(address, 'billing')">rozliczeniowy</button>
      </mdb-card-footer>
    </mdb-card>
  </div>
</div>


<div *ngIf="!loading" class="row">
  <div class="col-12">
    <button mdbBtn color="primary" size="sm" (click)="addAddress()">Dodaj nowy adres</button>
  </div>
</div>
