import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export interface conditionalRequiredParameters {
	sourceCheckbox: AbstractControl,
	sourceCheckboxValue: boolean,
}

/**
 * This validator makes given field required only if checkbox given in params object is checked.
 *
 * @param params
 */
export function conditionalRequiredValidator(params: conditionalRequiredParameters): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const error = {
			conditionalRequiredValidator: {
				valid: false
			}
		};

		if (params.sourceCheckbox && params.sourceCheckbox.value == params.sourceCheckboxValue && (!control.value || control.value.toString().trim() == ''))
			return error;

		// if true, return no error (no error), else return error passed in the second parameter
		return null;
	};
}
