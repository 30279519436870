export class FileAttachment {
  key: string;
  file_name: string;
  date_created: Date;
  size: number;
  accepted: boolean;
}


export const FileAttachmentEmptyEntity: FileAttachment = {
  key: "",
  file_name: "",
  date_created: new Date(),
  size: 0,
  accepted: false,
}
