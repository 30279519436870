import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './_services';
import { User } from './_models';
import {Subscription} from "rxjs";
import {MDBSpinningPreloader} from "ng-uikit-pro-standard";


@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentUserSubscription: Subscription;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private mdbSpinningPreloader: MDBSpinningPreloader,
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => this.currentUser = user);
    }

    ngOnInit(): void {
        this.mdbSpinningPreloader.stop();
    }

    ngOnDestroy(): void {
      this.currentUserSubscription.unsubscribe();
    }

  logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
