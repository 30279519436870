<mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

<span *ngIf="!loading && reservation.contract_sign_date && reservation.contract_sign_date !== null">

        <div class="row my-3 mx-1">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Podstawowe informacje o rezerwacji <a [routerLink]="['/reservation-review', reservation.id]" ><small class=" white-text">[więcej <mdb-icon far icon="eye"></mdb-icon>]</small></a></h5>
            </div>
        </div>

        <div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Uczestnik</strong>
            </div>
            <div class="col-12 col-md-9">
                <strong>{{ reservation.participant.name_first }} {{ reservation.participant.name_middle }} {{ reservation.participant.name_last }}</strong>
            </div>
        </div>
        <div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Nazwa i miejsce obozu</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ reservation.camp.name }} <br/>
                {{ reservation.camp.location_name }}
            </div>
        </div>

        <div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Daty rozpoczęcia i zakończenia</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ reservation.camp.date_start | formatDate: 'Do MMM YYYY' }} ({{ reservation.camp.date_start | formatDate: 'dddd' }}) -
                {{ reservation.camp.date_end | formatDate: 'Do MMM YYYY' }} ({{ reservation.camp.date_end | formatDate: 'dddd' }})
            </div>
        </div>

        <div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Cena całkowita</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ reservation.total_price|currency: 'PLN':'code':'1.2-2':'pl-PL' }}
            </div>
        </div>

        <div *ngIf="reservation.order_number" class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Dokument zamówienia</strong>
            </div>
            <div class="col-12 col-md-9 light-blue-text">
                <a (click)="downloadOrder()">{{ reservation.order_number }} <mdb-icon fas icon="cloud-download-alt"></mdb-icon></a>
            </div>
        </div>

        <div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 my-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Rachunek do przelewów:</strong>
            </div>

            <div class="col-12 col-md-9">
                <div *ngIf="contract_details_data_snapshot && contract_details_data_snapshot.bank_account">
                    <strong>{{ contract_details_data_snapshot['bank_account'] }}</strong>
                </div>
                <div>
                    Prosimy pamiętać o wpisaniu w opisie przelewu imienia i nazwiska dziecka oraz obozu, którego dotyczy wpłata.
                </div>

            </div>
        </div>




        <div class="row my-3">
            <div class="col-12 col-xl-6 my-2 my-xl-0 mx-1 mx-sm-0">
                <div class="row mx-0">
                    <div class="col-12 blue-gradient">
                        <h5 class="mt-2 white-text">Harmonogram płatności</h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <form [formGroup]="formPaymentPlanEntries">
                            <div class="table-responsive">
                                <table *ngIf="!loading" class="table w-auto mx-1" small="true">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="min-width: 40px; vertical-align: middle; text-align: center"> Lp. </th>
                                            <th scope="col" style="min-width: 110px; vertical-align: middle; text-align: center"> Data </th>
                                            <th scope="col" style="min-width: 140px; vertical-align: middle; text-align: center"> Kwota raty</th>
                                            <th scope="col" style="min-width: 140px; vertical-align: middle; text-align: center"> Pozostało </th>
                                            <th *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee" scope="col" style="width: 100%; min-width: 200px; vertical-align: middle; text-align: left"> Nowa wpłata </th>
                                            <th *ngIf="!(currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" scope="col" style="width: 100%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="payment_plan_entries" *ngFor="let singlePaymentPlanEntry of reservation.payment_plan_entries; let i = index;">
                                            <td style="vertical-align: bottom; padding-bottom: 27px"> {{ i + 1 }}. </td>
                                            <td style="vertical-align: bottom; padding-bottom: 27px"> {{ singlePaymentPlanEntry.date_due| formatDate: 'DD-MM-YYYY' }} </td>
                                            <td style="text-align: right; vertical-align: bottom; padding-bottom: 27px"> {{ singlePaymentPlanEntry.amount|currency: 'PLN':'code':'1.2-2':'pl-PL' }} </td>
                                            <td style="text-align: right; vertical-align: bottom; padding-bottom: 27px; padding-right: 10px;"> {{ singlePaymentPlanEntry.amount_left|currency: 'PLN':'code':'1.2-2':'pl-PL' }} </td>
                                            <td style="vertical-align: bottom" *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
                                                <div class="md-form mt-1 mb-1" [formGroupName]="i">
                                                    <input type="number" min="0" [max]="singlePaymentPlanEntry.amount_left" [id]="'payment_plan_entry_' + i"  class="form-control" [formControl]="amount_input_payment_plan_entryControlAtIndex(i)" mdbInput mdbValidate>
                                                    <label [htmlFor]="'payment_plan_entry_' + i">wpłata</label>
                                                </div>
                                            </td>
                                            <td *ngIf="!(currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><strong>Podsumowanie</strong></td>
                                            <td style="text-align: right; "> {{ getPaymentPlanValue("INSTALLMENT_SUM") }} </td>
                                            <td style="text-align: right; padding-right: 10px;"> {{ getPaymentPlanValue("LEFT_SUM") }} </td>
                                            <td *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee" >
                                                {{ newPaymentsSum|currency: 'PLN':'code':'1.2-2':'pl-PL' }}
                                            </td>
                                            <td *ngIf="!(currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>

                        <form [formGroup]="form_regenerate_payment_plan" *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
                        <div class="row">
                            <div class="col-sm-12 col-lg-8">
                                <div class="md-form mt-1 mb-1">
                                    <input type="text" id="regenerate_payment_plan_comment"  class="form-control" [formControl]="regenerate_payment_plan_comment" mdbInput mdbValidate>
                                    <label for="regenerate_payment_plan_comment">obowiązkowy komentarz do aktualizacji harmonogramu</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div  class="btn-group" [isDisabled]="networkOperationInProgress" mdbDropdown>
                                    <button type="button" mdbBtn color="warning" class="waves-light" size="sm" mdbWavesEffect (click)="regeneratePaymentPlan(false)" [disabled]="networkOperationInProgress">Aktualizuj plan płatności</button>
                                    <button type="button" mdbDropdownToggle mdbBtn color="warning" size="sm" class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
                                            mdbWavesEffect></button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" (click)="regeneratePaymentPlan(true)">Pobierz nową konfiurację i aktualizuj</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-6 my-2 my-xl-0 mx-1 mx-sm-0">
                <div class="row mx-0">
                    <div class="col-12 blue-gradient">
                        <h5 class="mt-2 white-text">Wpłaty i dokumenty - raty</h5>
                    </div>
                </div>

                <div *ngIf="reservation.order_number === null && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" class="row mx-0 my-3 p-0">
                    <div class="col-12 m-0 p-0">
                        <p class="note note-warning"><strong>Uwaga:</strong> Do wystawienia faktur zaliczkowych niezbędne jest wcześniejsze wygenerowanie dokumentu zamówienia.</p>
                    </div>
                </div>

                <div *ngIf="reservation.order_number === null && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" class="row mx-0 mb-4 mt-0 p-0">
                    <div class="col-12 p-0 text-center">
                        <button type="button" class="btn btn-outline-primary btn-rounded waves-effect" (click)="issueOrderForReservation()">Wygeneruj zamówienie</button>
                    </div>
                </div>

                <div *ngIf="reservation.order_number" class="row mx-0 p-0">
                    <div class="col-12 m-0 p-0">
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table w-auto mx-1" small="true">
                                <thead>
                                    <tr>
                                        <th scope="col" style="min-width: 40px; vertical-align: middle; text-align: center"> Lp. </th>
                                        <th scope="col" style="min-width: 100px; vertical-align: middle; text-align: center"> Data księgowania</th>
                                        <th scope="col" style="min-width: 120px; vertical-align: middle; text-align: center"> Kwota </th>
                                        <th scope="col" style="min-width: 200px; width:100%; vertical-align: middle; text-align: center"> Opis i status transakcji </th>
                                        <th scope="col" style="min-width: 150px; vertical-align: middle; text-align: center"> Faktura </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let singlePayment of this.reservation.payments; let i = index">
                                        <td> {{ i + 1 }}. </td>
                                        <td> {{ singlePayment.transaction_timestamp| formatDate: 'DD-MM-YYYY' }} </td>
                                        <td class="text-right"> {{ singlePayment.amount|currency: 'PLN':'code':'1.2-2':'pl-PL' }} </td>
                                        <td>
                                            <small>
                                                <span *ngIf="singlePayment.method == 'BANK_TRANSFER'">
                                                    Przelew na rachunek <br/> {{ singlePayment.bank_account }} <br/>
                                                </span>
                                                Status: {{ humanReadablePaymentStatus(singlePayment.status) }}
                                            </small>
                                        </td>
                                        <td class="text-center" style="padding-top: 0.5rem; padding-left: 5px; padding-right: 5px">
                                            <div *ngIf="singlePayment.invoice_number && singlePayment.invoice_number !== null" class="light-blue-text">
                                                <small><a (click)="downloadInvoice(singlePayment)"><mdb-icon fas icon="download"></mdb-icon>&nbsp;{{ singlePayment.invoice_number }}</a></small>
                                            </div>
                                            <div *ngIf="(!singlePayment.invoice_number || singlePayment.invoice_number == null) && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" class="green-text">
                                                <div class="btn-group" mdbDropdown>
                                                    <mdb-badge color="green" mdbDropdownToggle classInside="dropdown-toggle" class="waves-effect" mdbWavesEffect>Wystaw fakturę</mdb-badge>
                                                        <div class="dropdown-menu dropdown-primary">
                                                            <a class="dropdown-item" (click)="issueInvoice(singlePayment, 'advance')">Zaliczkową</a>
                                                            <a class="dropdown-item" (click)="issueInvoice(singlePayment, 'final')">Końcową</a>
                                                        </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(!singlePayment.invoice_number || singlePayment.invoice_number == null) &&!(currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" class="grey-text">
                                                <h2>niewystawiona</h2>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div *ngIf="!this.reservation.payments || !this.reservation.payments.length" class="text-center">
                            <h4 class="blue-grey-text">Brak wpłat</h4>
                        </div>
                    </div>
                </div>

                <div class="row mx-0">
                    <div class="col-12 blue-gradient">
                        <h5 class="mt-2 white-text">Faktury za dodatkowe usługi</h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table w-auto mx-1" small="true">
                                <thead>
                                    <tr>
                                        <th scope="col" style="min-width: 40px; vertical-align: middle; text-align: center"> Lp. </th>
                                        <th scope="col" style="min-width: 120px; vertical-align: middle; text-align: center"> Termin płatności</th>
                                        <th scope="col" style="min-width: 120px; vertical-align: middle; text-align: center"> Kwota </th>
                                        <th scope="col" style="min-width: 200px; width:100%; vertical-align: middle; text-align: center"> Opis </th>
                                        <th scope="col" style="min-width: 150px; vertical-align: middle; text-align: center"> Faktura </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let singleExtraInvoice of this.reservation.extra_invoices; let i = index">
                                        <td>{{ i + 1 }}.</td>
                                        <td>{{ singleExtraInvoice.invoice_date_due | date: 'dd-MM-YYYY' }}</td>
                                        <td>{{ singleExtraInvoice.invoice_amount_total | currency: 'PLN':'code':'1.2-2':'pl-PL' }}</td>
                                        <td class="reservation_payment_view_details_cell">
                                            <span>
                                                <small>
                                                    {{ singleExtraInvoice.comment }}<br/>
                                                    <span *ngIf="extraInvoiceAmountLeftAbsolute(singleExtraInvoice) > 0" style="color: red; font-weight: bold">
                                                        Pozostało do zapłaty: {{singleExtraInvoice.invoice_amount_total - singleExtraInvoice.invoice_amount_paid | currency: 'PLN':'code':'1.2-2':'pl-PL'}}<br/>
                                                    </span>
                                                    {{ invoiceStatusHumanReadable(singleExtraInvoice.invoice_status) }}
                                                </small>
                                            </span>

                                            <div *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee" class="reservation_payment_view_external_invoice_toolbar_container animated fadeIn">
                                                <mdb-icon fas icon="edit" mdbTooltip="Edytuj" placement="top" (click)="editExtraInvoice(singleExtraInvoice)"></mdb-icon> |
                                                <mdb-icon fas icon="sync-alt" mdbTooltip="Automatyczna aktualizacja statusu" placement="top" (click)="autoUpdateExtraInvoice(singleExtraInvoice)"></mdb-icon> |
                                                <mdb-icon fas icon="trash-alt" mdbTooltip="Usuń" placement="top" (click)="deleteExtraInvoice(singleExtraInvoice)"></mdb-icon>
                                            </div>

                                        </td>
                                        <td style="padding-top: 0.5rem; padding-left: 5px; padding-right: 5px"><a (click)="downloadExtraInvoice(singleExtraInvoice.id)"><mdb-icon fas icon="download"></mdb-icon>&nbsp;{{singleExtraInvoice.invoice_number}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row mx-0 p-0">
                    <div class="col-12 p-0 text-center">
                        <button type="button" class="btn btn-outline-primary btn-rounded waves-effect" (click)="assignExtraInvoice()">Dołącz fakturę</button>
                    </div>
                </div>
            </div>
        </div>

        <span *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
            <div class="row my-3 mx-1">
                <div class="col-12 peach-gradient">
                    <h5 class="mt-2 white-text">Nowa wpłata</h5>
                </div>
            </div>

            <form [formGroup]="formNewPayment">
                <div class="row my-1 mx-2 mx-sm-0">
                    <div class="col-12 col-md-3 mt-3">
                        <strong class="deep-purple-text">Data księgowania:</strong>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="md-form mt-0 mb-2">
                            <mdb-date-picker
                                    [inline]="true"
                                    name="mydate"
                                    [options]="datePickerOptions"
                                    [placeholder]="'Data transakcji'"
                                    [formControl]="transaction_timestamp"
                                    required
                                    #paymentDatePicker></mdb-date-picker>
                        </div>
                    </div>
                </div>

                <div class="row my-1 mx-2 mx-sm-0">
                    <div class="col-12 col-md-3 mt-3">
                        <strong class="deep-purple-text">Konto bankowe:</strong>
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="md-form mt-1 mb-1">
                            <input type="text" id="payment_bank_account" class="form-control" formControlName="bank_account" mdbInput mdbValidate>
                            <label for="payment_bank_account">konto bankowe</label>
                        </div>
                    </div>
                </div>

                <div class="row my-1 mx-2 mx-sm-0">
                    <div class="col-12 col-md-3 mt-3">
                        <strong class="deep-purple-text">Kwota:</strong>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="md-form mt-1 mb-1">
                            <input type="number" min="0" id="payment_amount" class="form-control text-right" formControlName="amount" mdbInput mdbValidate>
                            <label for="payment_amount">kwota wpłaty</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 pt-2">
                        <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="warning" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="savePayment()">Zapisz</button>
                        <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             Pracuję...
                        </button>
                    </div>
                </div>

                <div class="row my-1 mx-2 mx-sm-0">
                    <div class="col-12 col-md-3">
                        <strong class="deep-purple-text">Metoda:</strong>
                    </div>
                    <div class="col-12 col-md-2">
                        Przelew bankowy
                    </div>
                </div>

                <div class="row my-1 mx-2 mx-sm-0 mb-3">
                    <div class="col-12 col-md-3">
                        <strong class="deep-purple-text">Status:</strong>
                    </div>
                    <div class="col-12 col-md-2">
                        Zakończona
                    </div>
                </div>
            </form>
        </span>
</span>