import {Injectable} from "@angular/core";
import {Camp_Participant} from "../_models/camp_participant";
import {Apollo} from "apollo-angular";
import {MDBModalService} from "ng-uikit-pro-standard";
import {Observable, Subject} from "rxjs";
import {ModalExtraInvoiceEditorComponent} from "../modal-extra-invoice-editor/modal-extra-invoice-editor.component";
import {ExtraInvoice, ExtraInvoiceEditorData} from "../_models";
import _ from "underscore";
import {Camp_participantDataManipulations} from "../graphQL/data-manipulation";

export interface ExtraInvoiceDialogResultType {
	action: "ADD" | "SAVE" | "CANCEL";
	provider?: string;
	invoice_foreign_id?: string;
	invoice_include_in_summary?: string;
	comment?: string;
}

@Injectable({providedIn: "root"})
export class ModalHelpersService {
	constructor(
		private apollo: Apollo,
		private modalService: MDBModalService,
		private reservationsDataManipulator: Camp_participantDataManipulations
	) {
	}

	editExtraInvoiceDialog(reservation: Camp_Participant, invoice: ExtraInvoice) : Observable<ExtraInvoiceDialogResultType>{
		let retVal = new Subject<ExtraInvoiceDialogResultType>();

		let modalData : ExtraInvoiceEditorData = {
			provider: invoice.provider,
			invoice_foreign_id: invoice.invoice_foreign_id,
			invoice_status: invoice.invoice_status,
			invoice_include_in_summary: invoice.invoice_include_in_summary,
			comment: invoice.comment
		};


		let modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				saveButtonPressed: false,
				cMode: "EDIT",
				extraInvoiceEditorData: modalData
			}
		};

		/*
			Show the modal
		 */
		let modalRef = this.modalService.show(ModalExtraInvoiceEditorComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let extraInvoiceEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				//save invoice data to the database
				let invoiceData : ExtraInvoiceEditorData = _.clone<ExtraInvoiceEditorData>(modalRef.content.extraInvoiceEditorData);

				let dataSubsription = this.reservationsDataManipulator.addOrEditExtraInvoice(
					reservation.id,
					invoiceData.provider,
					invoiceData.invoice_foreign_id,
					invoiceData.invoice_status,
					invoiceData.invoice_include_in_summary,
					invoiceData.comment
				).subscribe(
					(data) => {
						retVal.next(null);
						retVal.complete();

						dataSubsription.unsubscribe();
					},
					(error) => {
						retVal.next(null);
						retVal.complete();

						dataSubsription.unsubscribe();
					}
				)
			}
			else {
				retVal.next(null);
				retVal.complete();
			}

			//don't react to further events coming from the modal
			extraInvoiceEditSubscription.unsubscribe();
		});

		return retVal.asObservable();
	}


	addExtraInvoiceDialog(reservation: Camp_Participant) : Observable<ExtraInvoiceDialogResultType>{
		let retVal = new Subject<ExtraInvoiceDialogResultType>();

		/*
			Prepare modal for editing data
		 */
		let modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				saveButtonPressed: false,
				cMode: "LOOKUP",
			}
		};

		/*
			Show the modal
		 */
		let modalRef = this.modalService.show(ModalExtraInvoiceEditorComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let extraInvoiceEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				//save invoice in the database
				let invoiceData : ExtraInvoiceEditorData = _.clone<ExtraInvoiceEditorData>(modalRef.content.extraInvoiceEditorData);


				let dataSubsription = this.reservationsDataManipulator.addOrEditExtraInvoice(
					reservation.id,
					invoiceData.provider,
					invoiceData.invoice_foreign_id,
					invoiceData.invoice_status,
					invoiceData.invoice_include_in_summary,
					invoiceData.comment
				).subscribe(
					(data) => {
						retVal.next(null);
						retVal.complete();

						dataSubsription.unsubscribe();
					},
					(error) => {
						retVal.next(null);
						retVal.complete();

						dataSubsription.unsubscribe();
					}
				)
			}
			else {
				retVal.next(null);
				retVal.complete();
			}

			//don't react to further events coming from the modal
			extraInvoiceEditSubscription.unsubscribe();
		});

		return retVal.asObservable();
	}
}