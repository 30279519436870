import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';

import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';


import {MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, BaseChartDirective} from 'ng-uikit-pro-standard';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ConfirmComponent } from './confirm';
import { RemindActivationCodeComponent } from './remind-activation-code';
import { AllUsersComponent } from './all-users/all-users.component';
import { MyAddressesComponent } from "./my-addresses";
import { ModalAddressEditComponent } from "./modal-address-edit";
import { MyParticipantsComponent } from "./my-participants";
import { ModalParticipantEditComponent } from "./modal-participant-edit";
import { ParticipantsSignupComponent } from "./participant-signup";
import { PasswordResetComponent} from "./password-reset";
import { ModalGenericComponent} from "./modal-generic";
import { ModalTextEditComponent} from "./modal-text-edit";
import { ReservationsComponent} from "./reservations";
import { FormatDatePipe} from "./_pipes/format-date.pipe";

import * as moment from 'moment';
import {ReservationReviewComponent} from "./reservation-review";
import {ReservationPaymentsComponent} from "./reservation-payments";
import { AllPaymentsComponent} from "./all-payments";
import {ReservationInsuranceBrowserComponent} from "./reservation-insurance-browser";
import {AllInsurancesubmissionsComponent} from "./all-insurancesubmissions";
import {InsuranceSubmissionGeneratorComponent} from "./insurance-submission-generator";
import {NewlinePipe} from "./_pipes/newline.pipe";
import {ActiveCampsStatisticsComponent} from "./active-camps-statistics";
import {SingleCampStatisticsComponent} from "./sigle-camp-statistics";
import { DocumentsToSubmitFormComponent } from './documents-to-submit-form/documents-to-submit-form.component';
import { ModalExtraInvoiceEditorComponent} from "./modal-extra-invoice-editor/modal-extra-invoice-editor.component";
import { AllCampsComponent} from "./all-camps/all-camps.component";
import { ReservationsTransportOptionsEditorFormComponent } from './reservations-transport-options-editor-form/reservations-transport-options-editor-form.component';
import { ModalAddressSelectComponent } from './modal-address-select/modal-address-select.component';
import { KeepHtmlPipe } from './_pipes/keep-html.pipe';
import { AttachmentsManagerComponent } from './file-upload/attachments-manager.component';
import { FileDnDDirective } from "./_directives/file-dnd/filednd.directive";
import { FileDropComponent } from "./_widgets/file-drop/file-drop.component";
import {FormatFileSizePipe} from "./_pipes/format-file-size.pipe";
import {
  AttachmentRejectionFormComponent
} from "./_widgets/attachment-rejection-form/attachment-rejection-form.component";

// set default language to PL
registerLocaleData(localePl, 'pl-PL');

@NgModule({
    imports: [
        BrowserModule,
        MDBBootstrapModulesPro.forRoot(),
        ToastModule.forRoot(),
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        HttpClientModule,
        routing
    ],
    declarations: [
        FormatDatePipe,
        NewlinePipe,
        FormatFileSizePipe,
        AppLayoutComponent,
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ConfirmComponent,
        RemindActivationCodeComponent,
        AllUsersComponent,
        MyAddressesComponent,
        ReservationsComponent,
        ModalAddressEditComponent,
        ModalParticipantEditComponent,
        ModalGenericComponent,
        ModalExtraInvoiceEditorComponent,
        ModalTextEditComponent,
        MyParticipantsComponent,
        ParticipantsSignupComponent,
        PasswordResetComponent,
        ReservationReviewComponent,
        ReservationPaymentsComponent,
        AllPaymentsComponent,
        ReservationInsuranceBrowserComponent,
        AllInsurancesubmissionsComponent,
        InsuranceSubmissionGeneratorComponent,
        ActiveCampsStatisticsComponent,
        SingleCampStatisticsComponent,
        DocumentsToSubmitFormComponent,
        AllCampsComponent,
        ReservationsTransportOptionsEditorFormComponent,
        ModalAddressSelectComponent,
        KeepHtmlPipe,
        AttachmentsManagerComponent,
        FileDropComponent,
        FileDnDDirective,
        AttachmentRejectionFormComponent,
    ],
    entryComponents: [
        ModalAddressEditComponent,
        ModalParticipantEditComponent,
        ModalGenericComponent,
    ],
    providers: [
        MDBSpinningPreloader,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    schemas:      [ NO_ERRORS_SCHEMA ],
    bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink
  ) {

      const uri = '/api/graphql.php';

      const basic = setContext((operation, context) => ({
          headers: {
              Accept: 'charset=utf-8'
          }
      }));

      const auth = setContext((operation, context) => {
          const token = localStorage.getItem('token');

          if (token === null){
              return {};
          }
          else {
              return {
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  }
              }
          }
      });

      const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
      const cache = new InMemoryCache();


    // set locale for dates
    moment.locale('pl');



    apollo.create({
      cache: cache,
      link: link,
    });
  }
}


