import { Component, OnInit } from '@angular/core';
import {FileAttachment, User} from "../_models";
import {distinctUntilChanged, switchMap} from "rxjs/operators";
import {ReservationReviewDetailsQueryResult} from "../graphQL/results";
import {getReservationReviewDetailsQuery} from "../graphQL/queries";
import {of, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {IOption, MDBModalService} from "ng-uikit-pro-standard";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Camp_Participant} from "../_models/camp_participant";
import {Tools} from "../_helpers/tools" ;
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {S3FileManipulations} from "../graphQL/data-manipulation/s3-file-manipulations";
import _ from "underscore";
import {Camp_participantDataManipulations} from "../graphQL/data-manipulation";

@Component({
  selector: 'app-file-upload',
  templateUrl: './attachments-manager.component.html',
  styleUrls: ['./attachments-manager.component.css']
})
export class AttachmentsManagerComponent implements OnInit {
  public loading: boolean = false;
  public networkOperationInProgress: boolean = false;
  public attachmentsRefreshInProgress: boolean = false;
  public keyOfAttachmentBeingDeleted?: string = null;

  public fileDescriptionForm: FormGroup;
  public pageNumbers: Array<IOption>;
  public documentNames: Array<IOption>;

  public fullFileName: string = "";
  public selectedFile: File = null;
  public maxAllowedFileSize: number = 10 * 1024 * 1024; // 10MB
  public showCustomFileNameInput: boolean = false;

  private loggedInUserSubscription: Subscription;
  private reservationSubscription: Subscription;

  private documentTypeSubscription: Subscription;
  private documentPageSubscription: Subscription;
  private customFileNameSubscription: Subscription;

  private genericModalHandler: ModalGenericHandler;

  public currentUser: User;

  private reservationID: number;



  // members of query response object
  public reservation: Camp_Participant

  // sorted copy of reservatio's attachments
  public sortedAtAttachments: Array<FileAttachment> = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private authService: AuthenticationService,
    private modalService: MDBModalService,
    private _formBuilder: FormBuilder,
    private s3FileManipulations: S3FileManipulations,
    private campParticipantDataManipulations: Camp_participantDataManipulations,
  ) {
    this.genericModalHandler = new ModalGenericHandler();

    this.loading = false;
    this.networkOperationInProgress = false;
    this.attachmentsRefreshInProgress = false;
    this.keyOfAttachmentBeingDeleted = null;

    this.fullFileName = "";
  }

  ngOnInit(): void {
    this.networkOperationInProgress = false;
    this.attachmentsRefreshInProgress = false;
    this.keyOfAttachmentBeingDeleted = null;
    this.loading = false;

    this.loggedInUserSubscription = this.authService.currentUser.subscribe(
      (user: User) => {
        this.currentUser = user;

        if (!user) {
          this.reservation = null;
        } else {
          // react to change of id parameter
          this.route.paramMap.pipe(
            switchMap(params => {
              if (params.get('id') !== null){
                this.reservationID = +params.get('id'); // + converts value to number :)
                this.loading = true;

                // fetch reservation data
                if (this.reservationSubscription) this.reservationSubscription.unsubscribe();
                this.reservationSubscription = this.apollo.watchQuery<ReservationReviewDetailsQueryResult>({
                  query: getReservationReviewDetailsQuery,
                  //don't force reload of data
                  fetchPolicy: 'cache-first',
                  variables: {
                    camp_participant_id: this.reservationID,
                  },
                })
                  .valueChanges
                  .subscribe(
                    ({ data, loading}) => {
                      this.loading = loading;

                      this.reservation = data.reservation_review_details.camp_participant;
                      // sort based of file name
                      this.sortedAtAttachments = _.clone(this.reservation.attachments);
                      this.sortedAtAttachments = this.sortedAtAttachments.sort((a, b) => {
                        return a.file_name.localeCompare(b.file_name);
                      });
                      //this.resetForms();
                    },
                    (error) => {
                      this.loading = false;
                    },
                  );
              }

              return of('');
            })
          ).subscribe();
        }
      });

    this.documentNames = this.generateDocumentNames();
    this.pageNumbers = this.generatePageNumbers();
    this.fullFileName = "";

    //initialize form controls
    this.fileDescriptionForm = this._formBuilder.group({
      fileUploadDocumentNameSelect: ['', [Validators.required]],
      uploadedDocumentCustomName: ['', [Validators.required]],
      uploadedDocumentPageNo: ['', [Validators.required]],
    });

    this.documentTypeSubscription = this.fileUploadDocumentNames.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value === "other"){
          // clear the value of custom file name
          this.uploadedDocumentCustomName.setValue('');

          this.showCustomFileNameInput = true;
        } else {
          // set the value of custom file name to the selected document name
          this.uploadedDocumentCustomName.setValue(value);

          this.showCustomFileNameInput = false;
        }

        this.fullFileName = this.createFileName();
      });

    this.documentPageSubscription = this.uploadedDocumentPageNo.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((value) => {
        // set the value of custom file name to the selected document name
        this.fullFileName = this.createFileName();
      });

    this.customFileNameSubscription = this.uploadedDocumentCustomName.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (!this.showCustomFileNameInput) return;

        // set the value of custom file name to the selected document name
        this.fullFileName = this.createFileName();
      });
  }

  ngOnDestroy() {
    this.sortedAtAttachments = null;

    if (this.loggedInUserSubscription) {
      this.loggedInUserSubscription.unsubscribe();
      this.loggedInUserSubscription = null;
    }

    if (this.reservationSubscription) {
      this.reservationSubscription.unsubscribe();
      this.reservationSubscription = null;
    }

    if (this.documentTypeSubscription) {
      this.documentTypeSubscription.unsubscribe();
      this.documentTypeSubscription = null;
    }

    if (this.documentPageSubscription) {
      this.documentPageSubscription.unsubscribe();
      this.documentPageSubscription = null;
    }

    if (this.customFileNameSubscription) {
      this.customFileNameSubscription.unsubscribe();
      this.customFileNameSubscription = null;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.fileDescriptionForm.controls;
  }

  get fileUploadDocumentNames() : FormControl {
    return this.f.fileUploadDocumentNameSelect as FormControl;
  }

  get uploadedDocumentCustomName() : FormControl {
    return this.f.uploadedDocumentCustomName as FormControl;
  }

  get uploadedDocumentPageNo() : FormControl {
    return this.f.uploadedDocumentPageNo as FormControl;
  }

  public onFileOpen($event: any) {
    //make sure file size is not exceeded
    if ($event.size > this.maxAllowedFileSize){
      let dlgTitle = "Błąd!";
      let dlgMessage = "Przekroczony został maksymalny rozmiar pliku: " + this.fileSizeToString(this.maxAllowedFileSize);

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, "DANGER", "OK", null, null, null);

      return;
    }

    this.selectedFile = $event;

    // regenerate full file name
    this.fullFileName = this.createFileName();
  }

  public uploadFile() {
    if (this.networkOperationInProgress) return;

    this.networkOperationInProgress = true;

    let fileUploadSubscription = this.s3FileManipulations.uploadReservationAttachment(this.reservationID, this.selectedFile, this.fullFileName).subscribe(
      (data) => {
        this.networkOperationInProgress = false;
        fileUploadSubscription.unsubscribe();

        let dlgTitle = "";
        let dlgMessage = "";
        let dlgStyle: ModalStyleType = "SUCCESS";

        if (data.status === "OK"){
          dlgTitle = "Sukces!";
          dlgMessage = "Plik został przesłany na serwer.";
          dlgStyle = "SUCCESS";

          this.prepareFormForNextUpload();
          this.refreshAttachments();
        } else {
          dlgTitle = "Błąd!";
          dlgMessage = "Podczas próby wysyłania pliku na serwer wystąpił błąd. Spróbuj ponownie.";
          dlgStyle = "DANGER";
        }

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, dlgStyle, "OK", null, null, null);
      },
      (error) => {
        this.networkOperationInProgress = false;
        fileUploadSubscription.unsubscribe();

        let dlgTitle = "Błąd!";
        let dlgMessage = "Podczas próby wysyłania pliku na serwer wystąpił błąd. Spróbuj ponownie.";

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, "DANGER", "OK", null, null, null);
      },
      () => {
        this.networkOperationInProgress = false;
        fileUploadSubscription.unsubscribe();
      }
    )
  }

  /*
    This method first requests the server to generate a presigned URL for the file download, and then downloads the file.
   */
  public downloadFile(attachment: FileAttachment){
    if (this.networkOperationInProgress || this.attachmentsRefreshInProgress) return;

    this.attachmentsRefreshInProgress = true;

    let downloadSubscription = this.s3FileManipulations.downloadReservationAttachment(this.reservationID, attachment.key, attachment.file_name).subscribe(
      (data) => {
        this.attachmentsRefreshInProgress = false;
        downloadSubscription.unsubscribe();

        if (data.status !== "OK"){
          let dlgTitle = "Błąd!";
          let dlgMessage = "Podczas próby pobrania pliku wystąpił błąd. Spróbuj ponownie.";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, "DANGER", "OK", null, null, null);
        }
      },
      (error) => {
        this.attachmentsRefreshInProgress = false;
        downloadSubscription.unsubscribe();

        let dlgTitle = "Błąd!";
        let dlgMessage = "Podczas próby pobrania pliku wystąpił błąd. Spróbuj ponownie.";

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, "DANGER", "OK", null, null, null);
      },
      () => {
        this.attachmentsRefreshInProgress = false;
        downloadSubscription.unsubscribe();
      }
    )
  }

  public toggleAttachmentsAcceptanceFlag(attachment: FileAttachment){
    if (this.networkOperationInProgress || this.attachmentsRefreshInProgress) return;

    // user must be admin or manager or backoffice
    if (!this.currentUser.is_admin && !this.currentUser.is_manager && !this.currentUser.is_backoffice_employee){
      return;
    }

    this.attachmentsRefreshInProgress = true;

    let toggleSubscription = this.campParticipantDataManipulations.toggleAttachmentAcceptance(this.reservationID, attachment.key).subscribe(
      (data) => {
        this.attachmentsRefreshInProgress = false;
        toggleSubscription.unsubscribe();

        if (data.status != "OK"){
          let dlgTitle = "Błąd!";
          let dlgMessage = "Podczas próby zmiany statusu załącznika wystąpił błąd. Spróbuj ponownie.";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, "DANGER", "OK", null, null, null);
        }
      },
      (error) => {
        this.attachmentsRefreshInProgress = false;
        toggleSubscription.unsubscribe();

        let dlgTitle = "Błąd!";
        let dlgMessage = "Podczas próby zmiany statusu załącznika wystąpił błąd. Spróbuj ponownie.";

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, "DANGER", "OK", null, null, null);
      },
      () => {
        this.attachmentsRefreshInProgress = false;
        toggleSubscription.unsubscribe();
        toggleSubscription = null;
      }
    )
  }

  public deleteAttachment(attachment: FileAttachment){
    if (this.networkOperationInProgress || this.attachmentsRefreshInProgress || this.keyOfAttachmentBeingDeleted) return;

    // if attachment is accepted, only admins, magagers and backoffice employees can delete it
    if (attachment.accepted &&
        !this.currentUser.is_admin &&
        !this.currentUser.is_manager &&
        !this.currentUser.is_backoffice_employee){
      return;
    }

    // if current user is admin, manager or backoffice employee, show the rejection form
    if (this.currentUser.is_admin || this.currentUser.is_manager || this.currentUser.is_backoffice_employee){
      this.keyOfAttachmentBeingDeleted = attachment.key;
      return;
    } else {
      // ask for confirmation
      let dlgTitle = "Potwierdzenie";
      let dlgMessage = "Czy na pewno chcesz usunąć załącznik:<br/><br/><b>" + attachment.file_name + "</b><br/><br/>Operacja jest nieodwracalna!";
      let dlgStyle: ModalStyleType = "WARNING";

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, dlgStyle, "TAK", "NIE", null, null, true);

      let decisionSubscription = myResult.subscribe((result) => {
        if (result === "OK"){
          this.deleteAttachmentConfirmed(attachment, null);
        }
        decisionSubscription.unsubscribe();
        decisionSubscription = null;
      },
      (error) => {
        decisionSubscription.unsubscribe();
        decisionSubscription = null;
      },
    () => {
        decisionSubscription.unsubscribe();
        decisionSubscription = null;
      });
    }
  }

  public cancelDelete(){
    this.keyOfAttachmentBeingDeleted = null;
  }

  public deleteAttachmentConfirmed(attachment: FileAttachment, comment?: string){
    this.attachmentsRefreshInProgress = true;

    let deleteSubscription = this.campParticipantDataManipulations
      .deleteAttachment(this.reservationID, attachment.key, comment)
      .subscribe(
        (data) => {
          this.attachmentsRefreshInProgress = false;
          deleteSubscription.unsubscribe();
          this.keyOfAttachmentBeingDeleted = null;

          if (data.status != "OK"){
            let dlgTitle = "Błąd!";
            let dlgMessage = "Podczas próby usunięcia załącznika wystąpił błąd. Spróbuj ponownie.";

            const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
              this.modalService, "DANGER", "OK", null, null, null);
          }
        },
        (error) => {
          this.attachmentsRefreshInProgress = false;
          deleteSubscription.unsubscribe();
          this.keyOfAttachmentBeingDeleted = null;

          let dlgTitle = "Błąd!";
          let dlgMessage = "Podczas próby usunięcia załącznika wystąpił błąd. Spróbuj ponownie.";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, "DANGER", "OK", null, null, null);
        },
        () => {
          this.attachmentsRefreshInProgress = false;
          this.keyOfAttachmentBeingDeleted = null;
          deleteSubscription.unsubscribe();
          deleteSubscription = null;
        }
      )
  }

  public refreshAttachments() {
    if (this.networkOperationInProgress || this.attachmentsRefreshInProgress) return;

    this.attachmentsRefreshInProgress = true;

    let refreshSubscription = this.s3FileManipulations.refreshReservationsAttachments(this.reservationID).subscribe(
      (data) => {
        this.attachmentsRefreshInProgress = false;
        refreshSubscription.unsubscribe();
      },
      (error) => {
        this.attachmentsRefreshInProgress = false;
        refreshSubscription.unsubscribe();

        let dlgTitle = "Błąd!";
        let dlgMessage = "Podczas próby odświeżenia załączników wystąpił błąd. Spróbuj ponownie.";

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, "DANGER", "OK", null, null, null);
      },
      () => {
        this.attachmentsRefreshInProgress = false;
        refreshSubscription.unsubscribe();
      }
    )
  }

  private prepareFormForNextUpload() {
    this.selectedFile = null;
    this.uploadedDocumentPageNo.setValue(null);
  }

  private fileSizeToString(size: number): string {
    let units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;

    while (size > 1024 && unitIndex < units.length){
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(2) + " " + units[unitIndex];
  }

  private generateDocumentNames(): Array<IOption> {
    return [
      {
        value: this.safeFileName("Karta kwalifikacyjna"),
        label: "Karta kwalififikacyjna",
        icon: '',
      },
      {
        value: this.safeFileName("Oświadczenie o szczepieniach obowiązkowych"),
        label: "Oświadczenie o szczepieniach obowiązkowych",
        icon: '',
      },
      {
        value: this.safeFileName("Książeczka zdrowia"),
        label: "Książeczka zdrowia",
        icon: '',
      },
      {
        value: this.safeFileName("Wycofanie zgody na wykorzystanie wizerunku"),
        label: "Wycofanie zgody na wykorzystanie wizerunku",
        icon: '',
      },
      {
        value: this.safeFileName("other"),
        label: "Inny dokument",
        icon: '',
      },
    ]
  }

  private generatePageNumbers(): Array<IOption> {
    let pageNumbers: Array<IOption> = [];
    let toolBox = new Tools();

    pageNumbers.push({
      value: "wszystkie",
      label: "Wszystkie strony",
      icon: '',
    });

    for (let i = 1; i <= 20; i++){
      pageNumbers.push({
        value: toolBox.addLeadingZeros(i, 2),
        label: toolBox.addLeadingZeros(i, 2),
        icon: '',
      });
    }

    return pageNumbers;
  }

  private safeFileName(fileName: string): string {
    //replace polish letters to their latin substitutes
    let replacemap = {
      'ą': 'a',
      'ć': 'c',
      'ę': 'e',
      'ł': 'l',
      'ń': 'n',
      'ó': 'o',
      'ś': 's',
      'ź': 'z',
      'ż': 'z',
      'Ą': 'A',
      'Ć': 'C',
      'Ę': 'E',
      'Ł': 'L',
      'Ń': 'N',
      'Ó': 'O',
      'Ś': 'S',
      'Ź': 'Z',
      'Ż': 'Z',
    }

    for (let key in replacemap){
      fileName = fileName.replace(key, replacemap[key]);
    }

    //replace all non-alphanumeric characters with underscores
    return fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  }

  private createFileName(): string {
    let fileName = this.safeFileName(this.uploadedDocumentCustomName.value);
    fileName += "_strona_" + this.uploadedDocumentPageNo.value;

    // if file is selected, add its extension
    if (this.selectedFile !== null){
      fileName += "." + this.selectedFile.name.split('.').pop();
    }

    return fileName;
  }

}
