<div class="row mx-1">
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div class="row mx-1">
    <div class="col-12">
        <div class="table-responsive">
            <table *ngIf="!loading" class="table w-auto mx-1" small="true">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 50px; text-align: center"> L.p. </th>
                    <th scope="col" style="min-width: 120px; text-align: center"> Data<br/>przelewu </th>
                    <th scope="col" style="min-width: 120px; text-align: center"> Data<br/>księgowania </th>
                    <th scope="col" style="min-width: 100px; text-align: center"> Kwota </th>
                    <th scope="col" style="min-width: 190px; text-align: center"> Faktura </th>
                    <th scope="col" style="min-width: 90px; text-align: center"> Status </th>
                    <th scope="col" style="min-width: 200px;; text-align: center"> Klient </th>
                    <th scope="col" style="min-width: 200px;; text-align: center"> Uczestnik </th>
                    <th scope="col" style="min-width: 250px; width: 100%; text-align: center"> Obóz, lokalizacja i termin</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let singlePayment of payments; let i = index">
                    <td scope="col"> {{ i + 1 }}. </td>
                    <td scope="col"> <a [routerLink]="['/reservation-payments', singlePayment.camp_participant_id]">{{ singlePayment.payment_transaction_timestamp |formatDate: 'DD-MM-YYYY' }}</a> </td>
                    <td scope="col"> {{ singlePayment.payment_created_timestamp |formatDate: 'DD-MM-YYYY'}} </td>
                    <td scope="col"> {{ singlePayment.payment_amount|currency: 'PLN':'code':'1.2-2':'pl-PL' }} </td>
                    <td scope="col">
                        <div *ngIf="singlePayment.payment_invoice_number !== null" class="light-blue-text">
                            <a (click)="downloadInvoice(singlePayment.payment_id)">{{ singlePayment.payment_invoice_number }} &nbsp;<mdb-icon fas icon="cloud-download-alt"></mdb-icon></a>
                        </div>
                    </td>
                    <td scope="col" style="text-align: center;"> <mdb-badge  color="red"> <!-- [color]="paymentStatusToColor(singlePayment.payment_status)" //-->{{ paymentMethodToHumanReadable( singlePayment.payment_method )}}</mdb-badge> </td>
                    <td scope="col"> {{ singlePayment.client_name_full }} </td>
                    <td scope="col"> {{ singlePayment.participant_name_full }} </td>
                    <td scope="col">
                        {{ singlePayment.camp_name }} <br/>
                        {{ singlePayment.camp_location_name }}, {{ singlePayment.camp_date_start | formatDate: 'Do MMM' }} - {{ singlePayment.camp_date_end | formatDate: 'Do MMM YYYY' }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>