import {Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, of, Subscription} from "rxjs";
import {Participant, User} from "../_models";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {MDBModalService} from "ng-uikit-pro-standard";
import {Camp_Participant, Camp_Participant_Status} from "../_models/camp_participant";
import {
	AllReservationsQueryResult,
	ReservationSummariesQueryResult,
	UserReservationsQueryResult
} from "../graphQL/results";
import {getAllReservationsQuery, getReservationSummaries, getUserReservationsQuery} from "../graphQL/queries";
import {map, switchMap} from "rxjs/operators";
import * as moment from 'moment';
import * as _ from 'underscore';
import {Camp_participantDataManipulations, MailingDataManipulations} from "../graphQL/data-manipulation";
import {ActivatedRoute, Router} from "@angular/router";
import {ReservationSummary} from "../_models/reservationSummary";

@Component({
	templateUrl: './reservations.component.html',
	styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit, OnDestroy {
	private loggedInUserSubscription: Subscription = null;
	private reservationsSubscription: Subscription = null;
	private uriParametersSubscription: Subscription = null;

	private currentUser: User;
	public reservations : ReservationSummary[];

	private genericModalHandler: ModalGenericHandler;
	private mailingDataManipulator: MailingDataManipulations;

	public loading: boolean;
	public itemsBeingProcessed: any;
	public networkOperationInProgress: boolean;
	public campStatus: string = '';
	public campStatusChanged: boolean;
	public viewType: string = '';
	public viewTypeChanged: boolean;
	public reservationStatus: string = '';
	public reservationStatusChanged: boolean;

	public optionsReservationStatuses: Array<any>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apollo: Apollo,
		private authService: AuthenticationService,
		private modalService: MDBModalService,
		private reservationsDataManipulator: Camp_participantDataManipulations
	) {
		this.genericModalHandler = new ModalGenericHandler();
		this.loading = false;

		this.itemsBeingProcessed = {};

		this.mailingDataManipulator = new MailingDataManipulations(apollo);

		this.optionsReservationStatuses = [
			{value: 'SUBMITTED', label: 'zgłoszenie przyjęte'},
			{value: 'WAITING_LIST', label: 'lista oczekujących'},
			{value: 'ENROLLED', label: 'zaakceptowano'},
			{value: 'CONTRACT_SIGNED', label: 'umowa podpisana'},
			{value: 'CANCELED', label: 'anulowane'},
			{value: 'CLIENT_RESIGNED', label: 'rezygnacja klienta'},
			{value: 'FINISHED', label: 'zakończona'},
		]
	}

	ngOnInit(): void {
		/*
		this.campStatus = 'ACTIVE';
		this.reservationStatus = 'SUBMITTED';
		this.viewType = 'client';
		*/

		this.campStatusChanged          = false;
		this.viewTypeChanged            = false;
		this.reservationStatusChanged   = false;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.reservations = [];
				}
				else {
					// react to change of camp_status parameter
					this.uriParametersSubscription = this.route.paramMap.pipe(
						switchMap(params => {
							this.campStatusChanged          = false;
							this.viewTypeChanged            = false;
							this.reservationStatusChanged   = false;

							if (params.get('view_type') != this.viewType){
								this.viewTypeChanged = true;
								this.viewType = params.get('view_type');

								if (!this.viewType || !_.contains(['admin', 'client'], this.viewType)){
									this.viewType = 'client';
								}
							}

							if (this.viewType == "client"){
								if (params.get('status') != this.campStatus){
									this.campStatusChanged = true;
									this.campStatus = params.get('status');

									if (!this.campStatus || !_.contains(['ACTIVE', 'NOT_ACTIVE', 'ALL'], this.campStatus)){
										this.campStatus = 'ACTIVE';
									}
								}
							}
							else if (this.viewType == 'admin'){
								if (params.get('status') != this.viewType){
									this.reservationStatusChanged = true;
									this.reservationStatus = params.get('status');

									if (!this.reservationStatus || (!_.contains(['DRAFT', 'SUBMITTED', 'WAITING_LIST', 'ENROLLED', 'CONTRACT_SIGNED', 'CANCELED', 'CLIENT_RESIGNED', 'FINISHED'], this.reservationStatus))){
										this.reservationStatus = 'SUBMITTED';
									}
								}
							}

							this.fetchReservations();
							return of(this.reservationStatus);
						})
					).subscribe();
				}
			});
	}

	changeCampStatus(newStatus: 'ACTIVE' | 'NOT_ACTIVE' | 'ALL'){
		this.router.navigate(['/reservations', this.viewType, newStatus]);
	}

	changeReservationStatus(newStatus: Camp_Participant_Status){
		this.router.navigate(['/reservations', this.viewType, newStatus]);
	}

	fetchReservations(){
		// if nothing has changed, do nothing
		if (!this.reservationStatusChanged && !this.campStatusChanged && !this.viewTypeChanged) return;

		this.loading = true;

		if (this.reservationsSubscription) this.reservationsSubscription.unsubscribe();

		// fetch reservations
		if (this.viewType == 'client'){
			this.reservationsSubscription = this.apollo.watchQuery<UserReservationsQueryResult>({
				query: getUserReservationsQuery,
				fetchPolicy: "network-only",
				variables: {
					user_id: this.currentUser.id,
					camp_status: this.campStatus,
				}
			})
				.valueChanges
				.subscribe(
					(({ data, loading}) => {
						this.loading = loading;
						this.reservations = data.user_reservations;
					})
				);
		}
		else if (this.viewType == 'admin'){
			this.reservationsSubscription = this.apollo.watchQuery<ReservationSummariesQueryResult>({
				query: getReservationSummaries,
				fetchPolicy: "network-only",
				variables: {
					camp_participant_status: this.reservationStatus,
				}
			})
				.valueChanges
				.subscribe(
					(({ data, loading}) => {
						this.loading = loading;
						this.reservations = data.reservation_summaries;
					})
				);
		}

	}

	ngOnDestroy(): void {
		if (this.uriParametersSubscription) this.uriParametersSubscription.unsubscribe();
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.reservationsSubscription) this.reservationsSubscription.unsubscribe();
	}

	humanReadableStatus(status: Camp_Participant_Status){
		return this.reservationsDataManipulator.humanReadableStatus(status);
	}

	showReservationDetails(reservation: ReservationSummary){
		this.router.navigate(['/reservation-review', reservation.id]);
	}

	cancelReservation(reservation: ReservationSummary){
		let dlgTitle: string    = 'Potwierdź!';
		let dlgMessage: string  = '<p>Czy na pewno chcesz anulować następującą rezerwację? Operacja jest nieodwracalna!</p>' +
		'<p>Uczestnik: <strong>' + reservation.participant_name_first + ' ' + reservation.participant_name_middle + ' ' + reservation.participant_name_last + '</strong></p>' +
		'<p>Obóz: <strong>' + reservation.camp_name + '</strong></p>' +
		'<p>Termin: <strong>' +	moment(reservation.camp_date_start).format('Do MMM') + ' do ' + moment(reservation.camp_date_end).format('Do MMM YYYY') + '</strong></p>';
		let dlgStyle: ModalStyleType = 'DANGER';


		const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
			this.modalService, dlgStyle, null, null, 'Tak', 'Nie - przerwij operację');


		let deletedReservationID = reservation.id;

		let mySub = myResult.subscribe(data => {
			if (data == 'Yes'){
				// cancel reservation

				this.itemsBeingProcessed[deletedReservationID] = true;

				let localSub = this.reservationsDataManipulator.cancelReservation(
					reservation.id, reservation.camp_id, this.currentUser.id, this.campStatus )
					.subscribe(
					(data) => {
						// show message box
						if (data.status === 'SUCCESS'){
							dlgTitle = 'Sukces!';
							dlgMessage = '<p>Rezerwacja została pomyślnie usunięta z systemu.</p>';
							dlgStyle = 'SUCCESS';
						}
						else {
							dlgTitle = 'Błąd!';
							dlgMessage = '<p>Podczas anulowania rezerwacji w systemie Copernicus wystąpił nieoczekiwany błąd. Spróbuj odświeżyć stronę i anulować rezerwację jeszcze raz.</p>' +
								'<p>Prosimy o kontakt z AlphaCamp jeżeli problem będzie się powtarzał.</p>';
							dlgStyle = 'DANGER';
						}

						let dlgSub = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
							this.modalService, dlgStyle, 'Zamknij', null, null, null).subscribe(
							(data) => {
								dlgSub.unsubscribe();
							}
						);

						delete this.itemsBeingProcessed[deletedReservationID];

						localSub.unsubscribe();
					}
				)
			}

			mySub.unsubscribe();
		})
	}

	showPaymentDetails(reservation: ReservationSummary){
		this.router.navigate(['/reservation-payments', reservation.id]);
	}

	sendEmailEnrolledReminder(reservation: ReservationSummary){
		if (this.networkOperationInProgress) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "Czy chcesz wysłać maila przypominającego z informacją o zakwalifikowaniu uczestnika wraz z dokumentami?",
			this.modalService, "INFO", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data == "No") return;

				this.networkOperationInProgress = true;

				let localSub = this.mailingDataManipulator.sendEmailParticipantEnrolledReminder(reservation.id).subscribe(
					data => {
						this.networkOperationInProgress = false;
						localSub.unsubscribe();

						if (data.status.startsWith("ERROR")){
							this.genericModalHandler.showModal("Błąd!", "Podczas wysyłania wiadomości wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
								this.modalService, "DANGER", "OK", null, null, null);
						}
						else {
							this.genericModalHandler.showModal("Gratulujemy!", "Wiadomość została wysłana!",
								this.modalService, "SUCCESS", "OK", null, null, null);
						}
					}
				)
			}
		)
	}

  public navigateToAttachmentsManager(reservationID: number) {
    this.router.navigate(['/attachments-manager', reservationID]);
  }
}
