<mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

<div class="row ml-2 mr-2 mr-sm-0 ml-sm-0 cprncs-reservation-review" *ngIf="!loading">
    <div class="col-12">
        <div *ngIf="reservation.status == 'ENROLLED'" class="row mt-1">
            <div class="col-12">
                <blockquote class="blockquote bq-success">
                    <p class="bq-title">Fantastycznie! Twoje dziecko zostało zakwalifikowane!</p>
                    <p>
                        W Twojej skrzynce mailowej powinna znajdować się wiadomość z informacją o zakwalifikowaniu dziecka
                        na nasz obóz. Do wiadomość dołączyliśmy komplet dokumentów. Jeżeli zapoznałaś(-łeś) się z tymi dokumentami
                        i chcesz podpisać umowę, przewiń tę stronę do samego dołu - upewniając się, że wszystkie dane
                        są poprawne - wpisz otrzymany w mailu kod i naciśnij przycisk AKCEPTUJĘ.
                    </p>
                </blockquote>
            </div>
        </div>

        <div *ngIf="reservation.status == 'CONTRACT_SIGNED'" class="row mt-1">
            <div class="col-12">
                <blockquote class="blockquote bq-success">
                    <p class="bq-title">Umowa podpisana - czekamy na obóz!</p>
                    <p>
                        Wszystkich rodziców i opiekunów uczestników prosimy o pilne zapoznanie się z ważnymi informacjami, które
                        zamieściliśmy w <a href="https://www.alphacamp.pl/przewodnik-rodzica-uczestnika-alphacamp/" target="_blank">Przewodniku Rodzica Uczestnika AlphaCamp</a>.
                        Są to:
                    </p>
                    <ul style="font-size: 16px; color: #ee8917">
                        <li style="color:orangered"><b>Dodatkowe dokumenty, które należy odesłać do organizatora</b></li>
                        <li>Ostrzeżenie o kleszczach</li>
                        <li>Instrukcja odbioru/pozostawienia dziecka przez osobę niebędącą rodzicem lub prawnym opiekunem</li>
                        <li>Wskazówki dotycząca pakowania</li>
                        <li>Jak skontaktować się z dzieckiem i kadrą podczas obozu</li>
                        <li>Kieszonkowe</li>
                    </ul>
                    <p>
                        Uprzejmie przypominamy o konieczności wysłania skanów podpisanej karty kwalifikacyjnej i
                        oświadczenia o szczepieniach obowiązkowych - mechanizm przesyłania skanów na serwer zostanie
                        uruchomiony w kwietniu 2024 roku.
                    </p>
                </blockquote>
            </div>
        </div>

        <div *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes" class="row mt-1">
            <div class="col-12">
                <blockquote class="blockquote bq-warning">
                    <p class="bq-title">Potwierdź dane w formularzu</p>
                    <p>
                        Organizator prosi o pilne potwierdzenie poprawności wszystkich danych. Pod każdą sekcją
                        formularza znajduje się odpowiedni przycisk zatwierdzający dane. Po zatwierdzeniu przez Państwa
                        wszytkich sekcji rezerwacja zostanie potwierdzona na serwerze.
                    </p>
                    <p>
                        Proszę upewnić się, że dane dotyczące dostarczonych dokumentów, zakwaterowania, diety, zdrowia i upoważnień do odbioru są poprawne.
                        Bardzo ważne jest potwierdzenie środków transportu. Na koniec proszę sprawdzić czy poprawna jest
                        kwota Państwa wpłat zarejestrowanych w systemie Copernicus.
                    </p>
                    <p>
                        W przypadku jakichkolwiek rozbieżności prosimy skorzystać z przycisków 'zgłoś zmianę...' lub
                        skontaktować się bezpośrednio z nami: email: <a href="mailto:info@alphacamp.pl">info@alphacamp.pl</a>,
                        telefon: <a href="tel:781683198">781 683 198</a>.
                    </p>
                </blockquote>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Podstawowe informacje</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Data rejestracji złgoszenia</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ reservation.created_timestamp|formatDate: 'DD-MM-YYYY HH:mm' }}
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Aktualny status</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ humanReadableStatus(reservation.status) }}
            </div>
        </div>

        <span *ngIf="reservation.contract_sign_date">
            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Data i forma podpisania umowy</strong>
                </div>
                <div class="col-12 col-md-9">
                    {{ reservation.contract_sign_date|formatDate: 'DD-MM-YYYY HH:mm' }} - {{ humanReadableContractSignType(reservation.contract_sign_type) }}
                </div>
            </div>
        </span>

        <span *ngIf="reservation.contract_sign_date || reservation.status == 'ENROLLED'">
            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Dokumenty i załączniki</strong>
                </div>
                <div class="col-12 col-md-9">
                    <a (click)="downloadDocuments()" class="hoverable-link light-blue-text"><mdb-icon fas icon="cloud-download-alt"></mdb-icon>&nbsp;kliknij tutaj</a> aby pobrać wszystkie dokumenty związane z rezerwacją w jednym spakowanym pliku .ZIP

                    <div *ngIf="sortedAttachments.length" class="row mt-2">
                      <div class="col-12">
                        <div *ngFor="let attachment of sortedAttachments; let i = index" class="clickable_attachment" (click)="downloadAttachmentFile(attachment)">
                          <i class="fas fa-paperclip"></i> {{ attachment.file_name }}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                          <button mdbBtn size="sm" type="button" color="warning" rounded="true" outline="true" mdbWavesEffect
                                  (click)="navigateToAttachmentsManager()">
                            Dodawanie i zarządzanie załącznikami
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <form [formGroup]="form_regenerate_documents">
            <span *ngIf="(reservation.contract_sign_date || reservation.status == 'ENROLLED' || reservation.status == 'CONTRACT_SIGNED') && (currentUser.is_admin || currentUser.is_manager)">
                <div class="row mt-1 p-1">
                    <div class="col-12 col-md-3">

                    </div>
                    <div class="col-12 col-md-6 red lighten-5">
                        <div class="md-form mt-1 mb-1">
                            <input type="text" id="new_contract_documents_comment" class="form-control" formControlName="new_contract_documents_comment" mdbInput mdbValidate>
                            <label for="new_contract_documents_comment">Obowiązkowy komentarz do operacji wygenerowania nowych dokumentów</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-3 text-center red lighten-5 pt-1">
                        <button *ngIf="!networkOperationInProgress" mdbBtn size="sm" type="button" color="danger" rounded="true" outline="true" mdbWavesEffect
                                (click)="regenerateContractDocuments()">
                            Ponownie wygeneruj i wyślij dokumenty
                        </button>
                        <button *ngIf="networkOperationInProgress" mdbBtn size="sm" type="button" color="danger" outline="true" rounded="true" mdbWavesEffect disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Przesyłam dane na serwer...
                        </button>
                    </div>
                </div>
            </span>
        </form>

        <span *ngIf="reservation.contract_sign_date">
            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Wysłanie obowiązkowych dokumentów</strong>
                </div>
                <div class="col-12 col-md-9">
                    <div *ngIf="!documents_to_submit_edit_form_visible && !reservation.documents_to_submit || !reservation.documents_to_submit.length">
                        Nie wymagamy przysłania żadnych dodatkowych dokumentów
                    </div>
                    <ul *ngIf="!documents_to_submit_edit_form_visible && reservation.documents_to_submit && reservation.documents_to_submit.length" class="list-group list-group-flush">
                        <li *ngFor="let singleDocumentToSubmit of reservation.documents_to_submit; let docIndex = index;" class="list-group-item flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <b [ngClass]="{'orange-text': singleDocumentToSubmit.document_submitted_status == 'NOT_SUBMITTED', 'red-text': singleDocumentToSubmit.document_submitted_status == 'CONTAINS_ERRORS'}" class="orange-text">
                                    <mdb-icon *ngIf="singleDocumentToSubmit.document_submitted_status != 'SUBMITTED'" far icon="square" class="mr-1"></mdb-icon>
                                    <mdb-icon *ngIf="singleDocumentToSubmit.document_submitted_status == 'SUBMITTED'" far icon="check-square" class="mr-1"></mdb-icon>
                                    {{singleDocumentToSubmit.document_name}}
                                </b>
                                <small *ngIf="singleDocumentToSubmit.document_submitted_date != null" class="grey-text">Otrzymano {{singleDocumentToSubmit.document_submitted_date}}</small>
                            </div>
                            <div *ngIf="singleDocumentToSubmit.document_submitted_status != 'SUBMITTED' && (singleDocumentToSubmit.document_required || singleDocumentToSubmit.document_required_original || singleDocumentToSubmit.comment)" class="mb-1">
                                <div *ngIf="singleDocumentToSubmit.document_submitted_status != 'SUBMITTED'" class="blue-grey-text">
                                    <mdb-badge *ngIf="singleDocumentToSubmit.document_required" color="red" class="mr-1">wymagany</mdb-badge>
                                    <mdb-badge *ngIf="singleDocumentToSubmit.document_required_original" color="red" class="mr-1">oryginał</mdb-badge>
                                    <span *ngIf="(!singleDocumentToSubmit.comment || singleDocumentToSubmit.comment.trim() == '') && (singleDocumentToSubmit.document_required || singleDocumentToSubmit.document_required_original)">Prosimy o jak najszybsze dostarczenie dokumentu</span>
                                    <span *ngIf="(singleDocumentToSubmit.comment && singleDocumentToSubmit.comment.trim() != '')">{{singleDocumentToSubmit.comment.trim()}}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-12 my-2 d-flex w-100 justify-content-center">
                            <div *ngIf="!documents_to_submit_edit_form_visible && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee)" class="btn-group" [isDisabled]="networkOperationInProgress" mdbDropdown>
                            <button type="button" mdbBtn color="info" class="waves-light" size="sm" mdbWavesEffect (click)="editDocumentsToSubmit(false)" [disabled]="networkOperationInProgress">Edytuj</button>
                            <button type="button" mdbDropdownToggle mdbBtn color="info" size="sm" class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
                                    mdbWavesEffect></button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" (click)="editDocumentsToSubmit(true)">Edytuj listę dokumentów - widok zaawansowany</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="updateReservationsDocumentsToSubmitFromCampConfig()">Aktualizuj listę dokumentów</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <documents-to-submit-form *ngIf="documents_to_submit_edit_form_visible" [documents_to_submit]="reservation.documents_to_submit" [view_style]="documents_to_submit_edit_form_style" (commitChanges)="onDocumentsToSubmitCommitChanges($event)"></documents-to-submit-form>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <form [formGroup]="form_confirm_documents_received">
            <span *ngIf="(reservation.contract_sign_date || reservation.status == 'CONTRACT_SIGNED') && (currentUser.is_admin || currentUser.is_manager)">
                <div class="row mt-1 p-1">
                    <div class="col-12 col-md-3">

                    </div>
                    <div class="col-12 col-md-9 red lighten-5">
                        <div class="md-form mt-1 mb-1">
                            <input type="text" id="contract_documents_received_comment" class="form-control" formControlName="contract_documents_received_comment" mdbInput mdbValidate>
                            <label for="contract_documents_received_comment">Obowiązkowy komentarz do operacji potwierdzenia otrzymania / nieotrzymania dokumentów</label>
                        </div>
                    </div>
                </div>
            </span>
        </form>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed  && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(1)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_1"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(1) }}
                </button>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Dane Klienta</strong>
            </div>
            <div class="col-12 col-md-3">
                {{ reservation.client_name_full }}
            </div>
            <div class="col-12 col-md-3">
                <a href="mailto:{{ reservation.participant.user.email }}">{{ reservation.participant.user.email }}</a>
            </div>
            <div class="col-12 col-md-3">
                <a [href]="'tel:' + reservation.participant.user.phone_number ">{{ reservation.participant.user.phone_number }}</a>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Adresy</strong>
            </div>

            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 text-center light-blue lighten-5 text-black p-1">
                        Korespondencyjny
                      <span *ngIf="!networkOperationInProgress && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee || currentUser.id == reservation.participant.user.id)" (click)="updateAddress(AddressType.MAILING)">
                        <mdb-icon fas icon="pen" mdbTooltip="Zmień adres korespondencyjny wybierając go z listy" class="update-address-button"></mdb-icon>
                      </span>
                      <span *ngIf="networkOperationInProgress">
                        <span class="update-participant-info-button spinner-border spinner-border-sm" role="status" aria-hidden="true" mdbTooltip="Poczekaj na zakończenie operacji..."></span>
                      </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ client_mailing_address_snapshot.name_first }} {{ client_mailing_address_snapshot.name_last }}
                    </div>
                </div>
                <div *ngIf="client_mailing_address_snapshot.is_business" class="row">
                    <div class="col">
                        {{ client_mailing_address_snapshot.company_name }} (firma)
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        ul. {{ client_mailing_address_snapshot.street_name }} {{ client_mailing_address_snapshot.street_number }}
                        <span *ngIf="client_mailing_address_snapshot.flat_number && client_mailing_address_snapshot.flat_number.trim() !== ''"> lok {{ client_mailing_address_snapshot.flat_number }} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ client_mailing_address_snapshot.zip_code }} {{ client_mailing_address_snapshot.city }}, {{ client_mailing_address_snapshot.country }}
                    </div>
                </div>
                <div *ngIf="client_mailing_address_snapshot.is_business" class="row">
                    <div class="col">
                        NIP {{ client_mailing_address_snapshot.vat_number }}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        tel. {{ client_mailing_address_snapshot.phone_number }}
                    </div>
                </div>
            </div>


            <div class="col-md-1 d-none d-sm-block"> </div>


            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 text-center light-blue lighten-5 text-black p-1">
                      Dane na fakturze
                      <span *ngIf="!networkOperationInProgress && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee || currentUser.id == reservation.participant.user.id)" (click)="updateAddress(AddressType.BILLING)">
                        <mdb-icon fas icon="pen" mdbTooltip="Zmień adres do dokumentów księgowych wybierając go z listy" class="update-address-button"></mdb-icon>
                      </span>
                      <span *ngIf="networkOperationInProgress">
                        <span class="update-participant-info-button spinner-border spinner-border-sm" role="status" aria-hidden="true" mdbTooltip="Poczekaj na zakończenie operacji..."></span>
                      </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ client_billing_address_snapshot.name_first }} {{ client_billing_address_snapshot.name_last }}
                    </div>
                </div>
                <div *ngIf="client_billing_address_snapshot.is_business" class="row">
                    <div class="col">
                        {{ client_billing_address_snapshot.company_name }} (firma)
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        ul. {{ client_billing_address_snapshot.street_name }} {{ client_billing_address_snapshot.street_number }}
                        <span *ngIf="client_billing_address_snapshot.flat_number && client_billing_address_snapshot.flat_number.trim() !== ''"> lok {{ client_billing_address_snapshot.flat_number }} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ client_billing_address_snapshot.zip_code }} {{ client_billing_address_snapshot.city }}, {{ client_billing_address_snapshot.country }}
                    </div>
                </div>
                <div *ngIf="client_billing_address_snapshot.is_business" class="row">
                    <div class="col">
                        NIP {{ client_billing_address_snapshot.vat_number }}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        tel. {{ client_billing_address_snapshot.phone_number }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(2)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_2"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(2) }}
                </button>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Dane uczestnika</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text"></strong>
            </div>
            <div class="col-12 col-md-5">
                <p>
                    {{ participant_snapshot.name_first }} {{ participant_snapshot.name_middle }} {{ participant_snapshot.name_last }}
                    <span *ngIf="!networkOperationInProgress && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee || currentUser.id == reservation.participant.user.id)" (click)="updateParticipantInfo()">
                      <mdb-icon fas icon="sync-alt" mdbTooltip="Zaktualizuj dane uczestnika" class="update-participant-info-button"></mdb-icon>
                    </span>
                    <span *ngIf="networkOperationInProgress">
                      <span class="update-participant-info-button spinner-border spinner-border-sm" role="status" aria-hidden="true" mdbTooltip="Poczekaj na zakończenie operacji..."></span>
                    </span>
                </p>
                <p>
                    ur. {{ participant_snapshot.date_birth }} PESEL {{ participant_snapshot.national_id_number }}<br/>
                    (wiek w dniu rozpoczęcia obozu: <strong>{{ participantAgeAtCampStart() }}</strong> lat)
                </p>
                <div *ngIf="participantAgeAtCampStart() < reservation.camp.age_min || participantAgeAtCampStart() > reservation.camp.age_max" class="alert alert-danger" role="alert">
                    Uczestnik nie mieści się w przedziale wiekowym ustalonym dla wybranego obozu!
                </div>
                <h6>Kontakty do rodziców i opiekunów:</h6>
                <div *ngFor="let parent of participant_snapshot.parents_list">
                    {{ parent.name_full }} - {{ parent.address }}, tel. {{ parent.phone_number }}
                </div>

                <div *ngIf="participant_snapshot.parents_list.length < 2" class="alert alert-warning mt-2" role="alert">
                  Podano dane tylko jednego rodzica/opiekuna.<br/><br/>
                  <div class="show-missing-parent-info-button" (click)="showMissingParentInfo()">
                    <mdb-icon fas icon="info-circle"></mdb-icon> dowiedz się więcej
                  </div>
                </div>
            </div>

            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 text-center light-blue lighten-5 text-black p-1">
                      Adres zamieszkania
                      <span *ngIf="!networkOperationInProgress && (currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee || currentUser.id == reservation.participant.user.id)" (click)="updateAddress(AddressType.PARTICIPANT)">
                        <mdb-icon fas icon="pen" mdbTooltip="Zmień adres uczestnika wybierając go z listy" class="update-address-button"></mdb-icon>
                      </span>
                      <span *ngIf="networkOperationInProgress">
                        <span class="update-participant-info-button spinner-border spinner-border-sm" role="status" aria-hidden="true" mdbTooltip="Poczekaj na zakończenie operacji..."></span>
                      </span>
                    </div>
                </div>

                <!-- Let's not show first name and last name since we only use the address, not personal info //-->
                <!--
                <div class="row">
                    <div class="col">
                        {{ participant_address_snapshot.name_first }} {{ participant_address_snapshot.name_last }}
                    </div>
                </div>

                <div *ngIf="participant_address_snapshot.is_business" class="row">
                    <div class="col">
                        {{ participant_address_snapshot.company_name }} (firma)
                    </div>
                </div>
                //-->

                <div class="row">
                    <div class="col">
                        ul. {{ participant_address_snapshot.street_name }} {{ participant_address_snapshot.street_number }}
                        <span *ngIf="participant_address_snapshot.flat_number && participant_address_snapshot.flat_number.trim() !== ''"> lok {{ participant_address_snapshot.flat_number }} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ participant_address_snapshot.zip_code }} {{ participant_address_snapshot.city }}, {{ participant_address_snapshot.country }}
                    </div>
                </div>
                <div *ngIf="participant_address_snapshot.is_business" class="row">
                    <div class="col">
                        NIP {{ participant_address_snapshot.vat_number }}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        tel. {{ participant_address_snapshot.phone_number }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(3)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_3"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(3) }}
                </button>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Dodatkowe informacje na temat uczestnika</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Uwagi dotyczące zdrowia</strong>
            </div>
            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('HEALTH', reservation.health_issues.value.trim())" *ngIf="reservation.health_issues.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('HEALTH', reservation.health_issues.value.trim())">{{reservation.health_issues.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('HEALTH', reservation.health_issues.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'uwagi dotyczące zdrowia'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.health_issues.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.health_issues.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('HEALTH', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('HEALTH', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Specjalne potrzeby</strong>
            </div>
            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('SPECIAL_NEEDS', reservation.special_needs.value.trim())" *ngIf="reservation.special_needs.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('SPECIAL_NEEDS', reservation.special_needs.value.trim())">{{reservation.special_needs.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('SPECIAL_NEEDS', reservation.special_needs.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'specjalne potrzeby'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.special_needs.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.special_needs.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('SPECIAL_NEEDS', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('SPECIAL_NEEDS', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Uwagi dotyczące wymagań/ograniczeń dotyczących diety</strong>
            </div>
            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('DIET', reservation.dietary_needs.value.trim())" *ngIf="reservation.dietary_needs.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('DIET', reservation.dietary_needs.value.trim())">{{reservation.dietary_needs.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('DIET', reservation.dietary_needs.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'uwagi/ograniczenia dotyczące diety'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.dietary_needs.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.dietary_needs.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('DIET', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('DIET', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Uwagi dotyczące zakwaterowania (np. z kim powinno być zakwaterowane dziecko)</strong>
            </div>
            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('ACCOMMODATION', reservation.accommodation.value.trim())" *ngIf="reservation.accommodation.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('ACCOMMODATION', reservation.accommodation.value.trim())">{{reservation.accommodation.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('ACCOMMODATION', reservation.accommodation.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'zakwaterowanie'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.accommodation.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.accommodation.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('ACCOMMODATION', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('ACCOMMODATION', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Upoważnienia (np. odbiór dziecka po zakończeniu obozu)</strong>
            </div>
            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('AUTHORIZATIONS', reservation.authorizations.value.trim())" *ngIf="reservation.authorizations.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('AUTHORIZATIONS', reservation.authorizations.value.trim())">{{reservation.authorizations.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('AUTHORIZATIONS', reservation.authorizations.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'upoważnienia'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.authorizations.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.authorizations.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('AUTHORIZATIONS', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('AUTHORIZATIONS', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>


        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Oświadczenie w sprawie dat szczepień</strong>
            </div>

            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col vaccination-name-small">
                        błonica
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ participant_vaccinations.DTaP }}
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col vaccination-name-small">
                        tężec
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ participant_vaccinations.IPV }}
                    </div>
                </div>
            </div>

            <!--
            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col vaccination-name-small">
                        MMR
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ participant_vaccinations.MMR }}
                    </div>
                </div>
            </div>
            //-->

            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col vaccination-name-small">
                        Inne
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ participant_vaccinations.Other }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(4)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_4"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(4) }}
                </button>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Dane obozu</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Opis</strong>
            </div>
            <div class="col-12 col-md-9">
                <p><strong><a [href]="reservation.camp.url" target="_blank">{{reservation.camp.name}}</a></strong></p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text"></strong>
            </div>
            <div class="col-12 col-md-5">
                Data:
                {{ reservation.camp.date_start | formatDate: 'Do MMM YYYY' }} ({{ reservation.camp.date_start | formatDate: 'dddd' }}) -
                {{ reservation.camp.date_end | formatDate: 'Do MMM YYYY' }} ({{ reservation.camp.date_end | formatDate: 'dddd' }})
            </div>
            <div class="col-12 col-md-4">
                Wiek uczestników:
                od {{ reservation.camp.age_min }} do {{ reservation.camp.age_max }} lat
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Lokalizacja</strong>
            </div>
            <div class="col-12 col-md-9">
                {{ reservation.camp.location_name }}
            </div>
        </div>

        <div class="row mt-1 mb-md-2 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Wybrane opcje transportu</strong>
            </div>
            <div class="col-12 col-md-9 transport-options">
                <div class="row mb-4">
                    <div class="col-12">
                        <div class="transport-code">{{reservation.selected_transport_options.to.code}}</div>
                        <div class="short-description mb-2" *ngIf="getTransportOptionByCode('to', reservation.selected_transport_options.to.code) !== null">DOJAZD: {{getTransportOptionByCode("to", reservation.selected_transport_options.to.code)?.short_description}}</div>
                        <div class="long-description mb-2" *ngIf="getTransportOptionByCode('to', reservation.selected_transport_options.to.code) !== null">{{getTransportOptionByCode("to", reservation.selected_transport_options.to.code)?.long_description}}</div>
                        <div class="error-message mb-2" *ngIf="getTransportOptionByCode('to', reservation.selected_transport_options.to.code) === null">
                            Przepraszamy, ale pojawił się nieoczekiwany problem z Twoją podróżą - kod środka transportu nie został rozpoznany. Prosimy o kontakt w celu wyjaśnienia błędu.
                        </div>
                        <div class="price">{{reservation.selected_transport_options.to.price|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</div>
                        <div *ngIf="reservation.selected_transport_options.to.remarks.trim() !== ''" class="remarks">Twoje uwagi: {{reservation.selected_transport_options.to.remarks}}</div>
                    </div>
                </div>
                <div class="row pt-4 pb-4" style="border-top: 1px dotted #999999; border-bottom: 1px dotted #999999;">
                    <div class="col-12" >
                        <div class="transport-code">{{reservation.selected_transport_options.from.code}}</div>
                        <div class="short-description mb-2" *ngIf="getTransportOptionByCode('from', reservation.selected_transport_options.from.code) !== null">POWRÓT: {{getTransportOptionByCode("from", reservation.selected_transport_options.from.code)?.short_description}}</div>
                        <div class="long-description mb-2" *ngIf="getTransportOptionByCode('from', reservation.selected_transport_options.from.code) !== null">{{getTransportOptionByCode("from", reservation.selected_transport_options.from.code)?.long_description}}</div>
                        <div class="error-message mb-2" *ngIf="getTransportOptionByCode('from', reservation.selected_transport_options.to.code) === null">
                            Przepraszamy, ale pojawił się nieoczekiwany problem z Twoją podróżą - kod środka transportu nie został rozpoznany. Prosimy o kontakt w celu wyjaśnienia błędu.
                        </div>
                        <div class="price">{{reservation.selected_transport_options.from.price|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</div>
                        <div *ngIf="reservation.selected_transport_options.from.remarks.trim() !== ''" class="remarks">Twoje uwagi: {{reservation.selected_transport_options.from.remarks}}</div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('TRANSPORT')">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'transport'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.selected_transport_options.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.selected_transport_options.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('TRANSPORT', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('TRANSPORT', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
                <div class="row mt-4">
                    <div class="col-12">
                        <p style="font-weight: bold; color: #565656">Uwaga</p>
                        <p style="color: #565656">
                            Przypominamy, że ostateczną decyzję dotyczącą wyboru środków transportu mogą Państwo podjąć
                            najpóźniej na 14 dni przed rozpoczęciem turnusu.
                        </p>
                    </div>
                </div>
                <div class="row" *ngIf="(currentUser.is_admin || currentUser.is_manager) && !showTransportOptionsEditorForm && !networkOperationInProgress">
                    <div class="col-12 d-flex w-100 justify-content-center">
                        <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="edit_trasport_options()" mdbWavesEffect>Zmień dane dotyczące transportu</button>
                    </div>
                </div>
                <div class="row" *ngIf="(currentUser.is_admin || currentUser.is_manager) && showTransportOptionsEditorForm">
                    <div class="col-12">
                        <reservations-transport-options-editor-form
                                (onCommitChanges) = "transportOptionsEditorFormSave($event)"
                                [lockControls] = "networkOperationInProgress"
                                [camp_transport_options_to] = "reservation.camp.transport_options_to"
                                [camp_transport_options_from] = "reservation.camp.transport_options_from"
                                [reservationsSelectedTransportOptions] = "reservation.selected_transport_options"
                                #transportOptionsEditor>
                        </reservations-transport-options-editor-form>
                    </div>

                </div>
            </div>
        </div>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(5)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_5"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(5) }}
                </button>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Zgody</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Oświadczenia i zgody udzielone na formularzu zgłoszenia dziecka</strong>
            </div>

            <div class="col-12 col-md-9 mb-2">
                <span *ngIf="reservation.data_is_true_statement">
                    <strong>TAK, </strong> Niniejszym oświadczam, że wszystkie dane podane w interaktywnym formularzu Zapisu są prawdziwe.
                </span>

                <h4 *ngIf="!reservation.data_is_true_statement" class="red-text">
                    <strong>NIE </strong>
                    <small>Niniejszym oświadczam, że wszystkie dane podane w interaktywnym formularzu Zapisu są prawdziwe.</small>
                </h4>
            </div>


            <div class="col-12 col-md-9 offset-md-3 mb-2">
                <span *ngIf="reservation.terms_accepted">
                    <strong>TAK, </strong>
                    Niniejszym oświadczam, że zapoznałam/zapoznałem  się z treścią <strong>Regulaminu</strong>
                        (pełna treść Regulaminu dostępna
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-13%20regulamin.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                        i akceptuję jego warunki.
                </span>

                <h4 *ngIf="!reservation.terms_accepted" class="red-text">
                    <strong>NIE </strong>
                    <small>
                        Niniejszym oświadczam, że zapoznałam/zapoznałem  się z treścią <strong>Regulaminu</strong>
                        (pełna treść Regulaminu dostępna
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-13%20regulamin.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                        i akceptuję jego warunki.
                    </small>
                </h4>
            </div>

            <div class="col-12 col-md-9 offset-md-3 mb-2">
                <span *ngIf="reservation.rodo_accepted">
                    <strong>TAK, </strong>
                    Niniejszym oświadczam, że zapoznałam/zapoznałem się z treścią Polityki Ochrony Danych Osobowych
                        Organizatora (pełna treść Polityki Ochrony Danych Osobowych Organizator dostępna
                        <strong><a href="https://www.alphacamp.pl/rodo/" target="_blank" class="p-0">tutaj</a></strong>)
                        i wyrażam zgodę na przetwarzanie przez Organizatora danych osobowych podanych w interaktywnym
                        formularzu Zapisu wskazanych w Karcie Kwalifikacyjnej w celu zapewnienia bezpieczeństwa i ochrony
                        zdrowia uczestników Obozów AlphaCamp (wzór Karty Kwalifikacyjnej dostępny
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-28%20Karta%20Kwalifikacyjna.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                </span>

                <h4 *ngIf="!reservation.rodo_accepted" class="red-text">
                    <strong>NIE </strong>
                    <small>
                        Niniejszym oświadczam, że zapoznałam/zapoznałem się z treścią Polityki Ochrony Danych Osobowych
                        Organizatora (pełna treść Polityki Ochrony Danych Osobowych Organizator dostępna
                        <strong><a href="https://www.alphacamp.pl/rodo/" target="_blank" class="p-0">tutaj</a></strong>)
                        i wyrażam zgodę na przetwarzanie przez Organizatora danych osobowych podanych w interaktywnym
                        formularzu Zapisu wskazanych w Karcie Kwalifikacyjnej w celu zapewnienia bezpieczeństwa i ochrony
                        zdrowia uczestników Obozów AlphaCamp (wzór Karty Kwalifikacyjnej dostępny
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-28%20Karta%20Kwalifikacyjna.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                    </small>
                </h4>
            </div>

            <div class="col-12 col-md-9 offset-md-3 mb-2">
                <span *ngIf="reservation.electronic_invoice_accepted">
                    <strong>TAK, </strong>
                    Niniejszym wyrażam zgodę na stosowanie faktur elektronicznych w przypadku zawarcia Umowy.
                        W przypadku braku zgody na stosowanie faktur elektronicznych, prosimy o bezpośredni kontakt z
                        Organizatorem na adres poczty elektronicznej Organizatora: <a href="mailto:info@alphacamp.pl" class="p-0">info@alphacamp.pl</a>.
                </span>

                <h4 *ngIf="!reservation.electronic_invoice_accepted" class="red-text">
                    <strong>NIE </strong>
                    <small>
                        Niniejszym wyrażam zgodę na stosowanie faktur elektronicznych w przypadku zawarcia Umowy.
                        W przypadku braku zgody na stosowanie faktur elektronicznych, prosimy o bezpośredni kontakt z
                        Organizatorem na adres poczty elektronicznej Organizatora: <a href="mailto:info@alphacamp.pl" class="p-0">info@alphacamp.pl</a>.
                    </small>
                </h4>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Opcje dodatkowe</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Opcje zaznaczone na formularzu</strong>
            </div>

            <div class="col-12 col-md-9">
                <span *ngIf="insurance_options">
                    <p *ngFor="let key of objectKeys(insurance_options)">
                    {{  insurance_options[key] }}
                </p>
                </span>
            </div>
        </div>

        <form [formGroup]="form_assigned_insurances">
            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Polisy uczestnika</strong>
                </div>

                <div class="col-12 col-md-9">
                    <span formArrayName="insurance_policies" *ngFor="let policy of reservation.insurance_policies; let i = index;">
                        <div [formGroupName]="i" class="row mb-2">
                            <div class="col-9">
                                <div *ngIf="((currentUser.is_admin || currentUser.is_manager) || (!reservation.contract_sign_date && (currentUser.is_backoffice_employee)))" class="orange lighten-5 p-2">
                                    <mdb-checkbox
                                            [id]="'insurance_policy_' + policy.id" name="checkbox_insurance_policy" [formControl]="checkbox_assigned_insurance_policyControlAtIndex(i)" formControlName="checkbox_insurance_policy">
                                        <strong>{{ policy.insurance_policy.name }}</strong>
                                    </mdb-checkbox>
                                </div>

                                <p>
                                    <strong *ngIf="!((currentUser.is_admin || currentUser.is_manager) || (!reservation.contract_sign_date && (currentUser.is_backoffice_employee)))">{{ policy.insurance_policy.name }}</strong><br/>
                                    {{ policy.insurance_policy.description }}
                                </p>
                            </div>
                            <div class="col-3">
                                <strong> {{ policy.client_price|currency: 'PLN':'code':'1.2-2':'pl-PL' }} </strong>
                            </div>
                        </div>
                    </span>

                    <div *ngIf="((currentUser.is_admin || currentUser.is_manager) || (!reservation.contract_sign_date && (currentUser.is_backoffice_employee)))" class="row">
                        <div class="col-12 col-md-8 orange lighten-5">
                            <div class="md-form mt-1 mb-1">
                                <input type="text" id="assigned_insurance_policy_comment" class="form-control" formControlName="insurance_policy_comment" mdbInput mdbValidate>
                                <label for="assigned_insurance_policy_comment">Obowiązkowy komentarz do operacji usunięcia</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-4 text-center orange lighten-5 pt-1">
                            <button *ngIf="!networkOperationInProgress" mdbBtn size="sm" type="button" color="warning" rounded="true" outline="true" mdbWavesEffect
                                    (click)="addOrRemoveSelectedInsurancePolicies('REMOVE')">
                                Usuń wybrane polisy
                            </button>
                            <button *ngIf="networkOperationInProgress" mdbBtn size="sm" type="button" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Przesyłam dane na serwer...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(6)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_6"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(6) }}
                </button>
            </div>
        </div>

        <span *ngIf="availableInsurancePolicies && availableInsurancePolicies.length &&
                    ((currentUser.is_admin || currentUser.is_manager) || (!reservation.contract_sign_date && (currentUser.is_backoffice_employee)))">
            <form [formGroup]="form_insurances">
                <div class="row mt-1 p-1">
                    <div class="col-12 col-md-2 orange lighten-4 pt-4 pb-3">
                        <strong class="deep-purple-text">Dostępne polisy</strong>
                    </div>

                    <div class="col-12 col-md-10 orange lighten-5 pb-3">
                        <div *ngIf="reservation.contract_sign_date" class="row pt-2">
                            <div class="col-12">
                                <div class="alert alert-danger" role="alert">
                                    UWAGA! Umowa została podpisana - edytuj listę polis tylko w wyjątkowych przypadkach!!!
                                </div>
                            </div>
                        </div>

                        <div formArrayName="insurance_policies" *ngFor="let avail_policy of availableInsurancePolicies; let i = index;">
                            <!--
                                Don't use *ngIf on span node below! We're just not displaying unavailable policies since number
                                of controls in insurance_policies form array must match all policies available for this Camp (not reservation)
                            -->
                            <span [className]="isInsurancePolicyAvailableForUser(avail_policy) ? '' : 'insurancepolicy-no-show'">
                                <div [formGroupName]="i">
                                    <div class="row pt-2">
                                        <div class="col-12 col-md-10 pt-4">
                                            <mdb-checkbox [id]="'insurance_policy_' + avail_policy.id" name="checkbox_insurance_policy" [formControl]="checkbox_insurance_policyControlAtIndex(i)" formControlName="checkbox_insurance_policy">{{ avail_policy.insurance_policy.name }}</mdb-checkbox>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="md-form mt-1 mb-1">
                                                <input type="text" [id]="'insurance_policy_price_' + i"  class="form-control" [formControl]="priceinput_insurance_policyControlAtIndex(i)" mdbInput mdbValidate>
                                                <label [htmlFor]="'insurance_policy_price_' + i">cena ({{ getMathParsedValue(getPolicyPriceFormula(avail_policy))|currency: 'PLN':'symbol-narrow' :'1.2-2':'pl-PL'}})</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <p *ngIf="!avail_policy.insurance_policy.is_active" class="alert alert-danger">
                                                Polisa nieaktywna!
                                            </p>
                                            <p>
                                                Wystawca: {{ avail_policy.insurance_policy.issuing_company_name }}
                                            </p>
                                            <p>
                                                <b>Sugerowana cena: {{ getMathParsedValue(getPolicyPriceFormula(avail_policy))|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="md-form mt-1 mb-1">
                                    <input type="text" id="insurance_policy_comment" class="form-control" formControlName="insurance_policy_comment" mdbInput mdbValidate>
                                    <label for="insurance_policy_comment">Obowiązkowy komentarz</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center">
                                <button *ngIf="!networkOperationInProgress" mdbBtn type="button" color="primary" rounded="true" outline="true" mdbWavesEffect (click)="addOrRemoveSelectedInsurancePolicies('ADD')">Dodaj wybrane polisy</button>
                                <button *ngIf="networkOperationInProgress" mdbBtn type="button" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                     Przesyłam dane na serwer...
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </span>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Wartość łącznie:</strong>
            </div>

            <div class="col-12 col-md-9">
                <strong>{{(reservation.price_extra_components.INSURANCE * 1)|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</strong>
            </div>
        </div>



        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Podsumowanie</h5>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Cena obozu:</strong>
            </div>

            <div class="col-12 col-md-9">
                <strong>{{(reservation.price * 1)|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</strong>&nbsp;&nbsp;
                <small *ngIf="(reservation.totalPaymentsSum * 1)">zapłacono {{(reservation.totalPaymentsSum * 1)|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</small>
            </div>
        </div>

        <form [formGroup]="form_change_price">
            <div class="row mt-1 p-1" *ngIf="currentUser.is_admin || currentUser.is_manager">
                <div class="col-12 col-md-3 orange lighten-4 pt-3">
                    <strong class="deep-purple-text">Nowa cena:</strong>
                </div>

                <div class="col-12 col-md-2 orange lighten-5 py-2">
                    <div class="md-form mt-1 mb-1">
                        <input type="text" id="new_reservation_price_"  class="form-control" [formControl]="new_price_ChangePriceForm" mdbInput mdbValidate>
                        <label for="new_reservation_price_">nowa cena</label>
                    </div>
                </div>

                <div class="col-12 col-md-5 orange lighten-5 py-2">
                    <div class="md-form mt-1 mb-1">
                        <input type="text" id="new_reservation_price_comment" class="form-control" [formControl]="new_price_comment_ChangePriceForm" mdbInput mdbValidate>
                        <label for="new_reservation_price_comment">Obowiązkowy komentarz</label>
                    </div>
                </div>

                <div class="col-12 col-md-2 pt-3 text-center orange lighten-5">
                    <button *ngIf="!networkOperationInProgress" mdbBtn size="sm" type="button" color="warning" rounded="true" outline="true" mdbWavesEffect
                            (click)="changeReservationPrice()">
                        Zatwierdź
                    </button>
                    <button *ngIf="networkOperationInProgress" mdbBtn size="sm" type="button" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Przesyłam dane na serwer...
                    </button>
                </div>
            </div>
        </form>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3 reservation-text-bigger">
                <strong class="deep-purple-text">Łącznie do zapłaty:</strong>
            </div>

            <div class="col-12 col-md-9 reservation-text-bigger">
                <strong>{{(reservation.price * 1 + reservation.price_extra_options * 1)|currency: 'PLN':'code':'1.2-2':'pl-PL'}}</strong>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Wykorzystane kody promocyjne:</strong>
            </div>

            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('PROMO_CODES', reservation.promo_codes.value.trim())" *ngIf="reservation.promo_codes.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('PROMO_CODES', reservation.promo_codes.value.trim())">{{reservation.promo_codes.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('PROMO_CODES', reservation.promo_codes.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'kody promocyjne'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.promo_codes.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.promo_codes.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('PROMO_CODES', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('PROMO_CODES', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-1 p-1">
            <div class="col-12 col-md-3">
                <strong class="deep-purple-text">Dodatkowe uwagi:</strong>
            </div>

            <div class="col-12 col-md-9">
                <p (dblclick)="editDescriptiveField('GENERAL_REMARKS', reservation.general_remarks.value.trim())" *ngIf="reservation.general_remarks.value.trim() == ''">BRAK</p>
                <p (dblclick)="editDescriptiveField('GENERAL_REMARKS', reservation.general_remarks.value.trim())">{{reservation.general_remarks.value}}</p>
                <div class="row">
                    <div class="col-12">
                        <div class="change-request-button" (click)="issue_change_request('GENERAL_REMARKS', reservation.general_remarks.value.trim())">
                            <mdb-icon far icon="edit"></mdb-icon> Zgłoś zmianę w polu 'dodatkowe uwagi'
                        </div>
                    </div>
                </div>
                <span *ngIf="reservation.general_remarks.change_requests.length">
                    <div class="row mt-3">
                        <div class="col-12 awaiting-changes-title">
                            Zgłoszone poprawki oczekujące na akceptację organizatora:
                        </div>
                    </div>
                    <ng-template ngFor let-singleRequest [ngForOf]="reservation.general_remarks.change_requests" let-i="index">
                        <div class="row animated fadeIn mt-2">
                            <div class="col-12 change-request-user-and-date" [style]="changeRequestStyle(singleRequest.id)"
                                 (mousedown)="startTimerOnChangeRequestMouseDown('GENERAL_REMARKS', singleRequest.id, $event)"
                                 (mouseup)="stopTimerOnChangeRequestMouseUp($event)"
                                 (touchstart)="startTimerOnChangeRequestMouseDown('GENERAL_REMARKS', singleRequest.id, $event)"
                                 (touchend)="stopTimerOnChangeRequestMouseUp($event)">
                                <span> <mdb-icon fas icon="user-alt"></mdb-icon>&nbsp; {{ singleRequest.user_full_name }}</span> &nbsp;
                                <span> <mdb-icon fas icon="calendar-alt"></mdb-icon>&nbsp; {{ singleRequest.date_created }} </span>
                            </div>
                        </div>
                        <div class="row animated fadeIn mb-1 pl-2" >
                            <div class="col-12 change-request-requested-changes" [style]="changeRequestStyle(singleRequest.id)">
                                {{ singleRequest.requested_changes }}
                            </div>
                        </div>
                    </ng-template>
                </span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 blue-gradient">
                <h5 class="mt-2 white-text">Harmonogram płatności</h5>
            </div>
        </div>

        <span *ngIf="reservation.contract_sign_date == null">
             <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Raty:</strong>
                </div>

                <div class="col-12 col-md-9">
                    <div class="row">
                        <div class="col-12">
                            <h5>Szczegółowy harmonogram płatności zostanie wygenerowany w chwili podpisania umowy.</h5>
                            <p>
                                W chwili zawarcia umowy w zakładce Płatności dostępna będzie lista nadchodzących i zrealizowanych
                                płatności związanych z tą rezerwacją wygenerowana wg poniższego harmonogramu. Zawierać będzie ona dokładne daty i kwoty
                                poszczególnych rat.
                            </p>
                            <p>
                                Poniższa lista jest także częścią "Umowy o udział w imprezie turystycznej - w obozie AlphaCamp"
                            </p>
                        </div>
                    </div>
                    <div *ngFor="let single_payment_plan_entry of payment_plan_snapshot_entries; let i = index;" class="row">
                        <div class="col-12">
                            <p>
                                <strong> {{i + 1}}. </strong> {{ single_payment_plan_entry.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <span *ngIf="reservation.contract_sign_date !== null && reservation.contract_sign_date != ''">
            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Rachunek do przelewów:</strong>
                </div>

                <div class="col-12 col-md-9">
                    <div *ngIf="contract_details_data_snapshot && contract_details_data_snapshot.bank_account">
                        <strong>{{ contract_details_data_snapshot['bank_account'] }}</strong>
                    </div>
                    <div>
                        Prosimy pamiętać o wpisaniu w opisie przelewu imienia i nazwiska dziecka oraz obozu, którego dotyczy wpłata.
                    </div>

                </div>
            </div>

            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                    <strong class="deep-purple-text">Raty:</strong>
                </div>

                <div class="col-12 col-md-9">
                    <div *ngFor="let single_payment_plan_entry of reservation.payment_plan_entries; let i = index;" class="row">
                        <div class="col-12">
                            <p>
                                <strong> {{i + 1}}. </strong> {{ single_payment_plan_entry.date_due|formatDate: 'DD-MM-YYYY' }} kwota raty: {{ single_payment_plan_entry.amount|currency: 'PLN':'code':'1.2-2':'pl-PL'  }} , pozostało do zapłaty: {{ single_payment_plan_entry.amount_left|currency: 'PLN':'code':'1.2-2':'pl-PL'  }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1 p-1">
                <div class="col-12 col-md-3">
                </div>
                <div class="col-12 col-xl-3">
                    <button mdbBtn type="button" color="primary" rounded="true" block="true" mdbWavesEffect (click)="showPaymentDetails(reservation)">Historia wpłat i faktury</button>
                </div>
            </div>
        </span>

        <div class="row mb-3" *ngIf="reservation.status == 'CONTRACT_SIGNED' && reservation.camp.data_confirmation_required && !reservation.is_data_confirmed && !reservation.pending_changes">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        [color]="getConfirmationButtonColor(7)"
                        autocomplete="off"
                        [(ngModel)]="data_confirmation_buttons_states.section_7"
                        mdbCheckbox
                        btnCheckboxTrue="1"
                        btnCheckboxFalse="0"
                        size="sm"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    {{ getConfirmationButtonText(7) }}
                </button>
            </div>
        </div>

        <div class="row mt-3" *ngIf="canShowDataConfirmationButton()">
            <div class="col-12 peach-gradient">
                <h5 class="mt-2 white-text">Potwierdzenie danych w formularzu</h5>
            </div>
        </div>

        <div class="row mb-3 mt-3" *ngIf="canShowDataConfirmationButton()">
            <div class="col-12 col-md-3">
                <!-- Spacer //-->
            </div>

            <div *ngIf="!networkOperationInProgress"class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        color="success"
                        autocomplete="off"
                        mdbWavesEffect
                        (click)="updateReservationIsDataConfirmed(true)"
                >
                    Potwierdzam, że wszystkie dane są poprawne
                </button>
            </div>

            <div *ngIf="networkOperationInProgress" class="col-12 col-md-9 text-center">
                <button
                        mdbBtn
                        type="button"
                        color="success"
                        autocomplete="off"
                        mdbWavesEffect
                >
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Potwierdzam, że wszystkie dane są poprawne
                </button>
            </div>

        </div>

        <span *ngIf="reservation.status == 'ENROLLED'">
            <div class="row mt-3">
                <div class="col-12 purple-gradient">
                    <h5 class="mt-2 white-text">Podpisz umowę kodem</h5>
                </div>
            </div>

            <form [formGroup]="form_client_sign_contract">
                <div class="row my-2">
                    <div class="col-12 col-md-3 mt-3">
                        <strong class="deep-purple-text">Złóż podpis</strong>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="md-form mt-1 mb-1">
                            <input type="text" id="contract_sign_code" class="form-control" [formControl]="confirmation_code" mdbInput mdbValidate>
                            <label for="contract_sign_code">Kod do podpisania umowy</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="success" rounded="true" outline="true" mdbWavesEffect (click)="clientSignContract()">AKCEPTUJĘ</button>
                        <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             Poczekaj...
                        </button>
                    </div>
                </div>
            </form>
        </span>

        <span *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
            <div class="row mt-3">
                <div class="col-12 peach-gradient">
                    <h5 class="mt-2 white-text">Operacje</h5>
                </div>
            </div>

            <form [formGroup]="form_change_status">
                <div class="row my-2">
                    <div class="col-12 col-md-3 mt-3">
                        <strong class="deep-purple-text"></strong>
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="md-form mt-1 mb-1">
                            <input type="text" id="status_change_comment" class="form-control" [formControl]="status_change_comment" mdbInput mdbValidate>
                            <label for="status_change_comment">Obowiązkowy komentarz</label>
                        </div>
                    </div>
                </div>
            </form>

            <div *ngIf="reservation.status == 'SUBMITTED'" class="row my-2">
                <div class="col-12 col-md-3 mt-3">
                    <strong class="deep-purple-text">Decyzja:</strong>
                </div>
                <div class="col-12 col-md-4 my-1 my-md-0">
                    <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="success" rounded="true" outline="true" mdbWavesEffect (click)="changeReservationStatus('ENROLLED')">Zaakceptuj zgłoszenie</button>
                    <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         Przesyłam dane na serwer...
                    </button>
                </div>
                <div class="col-12 col-md-4 my-1 my-md-0">
                    <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="warning" rounded="true" outline="true" mdbWavesEffect (click)="changeReservationStatus('WAITING_LIST')">Lista rezerwowa</button>
                    <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         Przesyłam dane na serwer...
                    </button>
                </div>
            </div>

            <div *ngIf="reservation.status == 'ENROLLED'" class="row my-2">
                <div class="col-12 col-md-3 mt-3">
                    <strong class="deep-purple-text">Maile z przypomnieniami:</strong>
                </div>
                <div class="col-12 col-lg-4 my-1 my-lg-0">
                    <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="success" rounded="true" outline="true" mdbWavesEffect (click)="sendEmailEnrolledReminder(reservation)">Przypomnienie o zakwalifkowaniu</button>
                    <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         Przesyłam dane na serwer...
                    </button>
                </div>
            </div>

            <div *ngIf="(reservation.status == 'ENROLLED' || reservation.status == 'CONTRACT_SIGNED') && (currentUser.is_admin || currentUser.is_manager)" class="row my-2">
                <div class="col-12 col-md-3 mt-3">
                    <strong class="deep-purple-text">Operacje specjalne:</strong>
                </div>
                <div class="col-12 col-lg-4 my-1 my-lg-0">
                    <button *ngIf="!networkOperationInProgress" mdbBtn type="button" block="true" color="danger" rounded="true" outline="true" mdbWavesEffect (click)="rollbackReservationStatusToSubmitted()">Cofnij status rezerwacji do 'zarejestrowana w systemie'</button>
                    <button *ngIf="networkOperationInProgress" mdbBtn type="button" block="true" color="info" outline="true" rounded="true" mdbWavesEffect disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                         Przesyłam dane na serwer...
                    </button>
                </div>
            </div>


            <div class="row my-3">
                <div class="col-12 peach-gradient">
                    <h5 class="mt-2 white-text">Zdarzenia - widok pracownika</h5>
                </div>
            </div>

            <div *ngFor="let event of events;" class="row my-1 p-1">
                <div class="col-6 col-md-2">
                    {{ event.createdTimestamp|formatDate: 'DD-MM-YYYY HH:mm:ss' }}
                </div>

                <div class="col-6 col-md-3">
                    {{ event.createdByName }} (id: {{ event.createdById }})
                </div>

                <div class="col-12 col-md-7" [innerHTML]="event.message | nl2br">

                </div>
            </div>
        </span>


    </div>
</div>
