import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'nl2br'
})

export class NewlinePipe implements PipeTransform {
	transform(value : string) : string {
		if (!value) {
			return '';
		}

		return value.replace(/(\r)\n/g, '<br/>');
	}
}