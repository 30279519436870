import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

import {User} from '../_models';
import {UserService, AuthenticationService} from '../_services';

import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Component({templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit, OnDestroy {
	public  currentUser: User;
	private currentUserSubscription: Subscription;
	public  users: User[] = [];

	constructor(
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private apollo: Apollo
	) {
		this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		});
	}

	ngOnInit() {
		// this.loadAllUsers();
	}

	ngOnDestroy() {
		// unsubscribe to ensure no memory leaks
		this.currentUserSubscription.unsubscribe();
	}

	deleteUser(id: number) {
		/*
		this.userService.delete(id).pipe(first()).subscribe(() => {
			this.loadAllUsers();
		});
		*/
	}

	fetchData() {
		this.apollo
			.watchQuery({
				query: gql`
          {
            user(id: 1) {
              id
              email
              firstName
              lastName
            }
          }
        `,
			})
			.valueChanges
			.subscribe(result => {
				if (result.data && result['data']['user']) {
					console.log(result['data']['user']);
				}
			});
	}

	private loadAllUsers() {
		/*
		this.userService.getAll().pipe(first()).subscribe(users => {
			this.users = users;
		});
	   */
	}
}
