<div class="row mx-1 mb-2">
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div class="row mx-1 mx-md-3 mx-xl-5">
    <div class="col-12">
        <h3 class="h3-responsive light-blue-text mb-3">Lista wygenerowanych zgłoszeń ubezpieczonych</h3>
        <div class="table-responsive-md">
            <table *ngIf="!loading" class="table table-striped mx-1">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 40px">L.p.</th>
                    <th scope="col" style="min-width: 150px">Data</th>
                    <th scope="col" style="width: 100%; min-width: 300px;">Polisa</th>
                    <th scope="col" style="min-width: 180px">Dokument</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let singleSumbission of insuranceSubmissions; let i = index;">
                    <td> {{ i + 1 }}.</td>
                    <td> {{ singleSumbission.created_timestamp | formatDate: 'DD-MM-YYYY HH:mm' }}</td>
                    <td> {{ singleSumbission.insurance_policy.name }} - {{ singleSumbission.insurance_policy.issuing_company_name }}</td>
                    <td>
                        <a (click)="downloadSubmissionDocument(singleSumbission.id)" class="badge badge-info text-white">ZGŁOSZENIE</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>