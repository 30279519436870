import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {MDBModalService} from "ng-uikit-pro-standard";
import {ModalGenericHandler} from "../modal-generic-handler";
import {User} from "../_models";
import {AllInsurancesubmissionsQueryResult} from "../graphQL/results";
import {AllInsurancesubmissionsQuery, AllPaymentsQuery} from "../graphQL/queries";
import {Subscription} from "rxjs";
import {InsuranceSubmission} from "../_models/insurancesubmission";
import {InsuranceSubmissionDataManipulations} from "../graphQL/data-manipulation/insurance-submission-data-manipulations";

@Component({
	templateUrl: './all-insurancesubmissions.component.html',
	styleUrls: ['./all-insurancesubmissions.component.scss']
})
export class AllInsurancesubmissionsComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public networkOperationInProgress: boolean;

	private loggedInUserSubscription: Subscription;
	private dataSubscription: Subscription;

	public currentUser: User;

	private genericModalHandler: ModalGenericHandler;
	private insuranceSubmissionDataManipulator: InsuranceSubmissionDataManipulations;

	private insuranceSubmissions: InsuranceSubmission[];

	constructor(private router: Router,
	            private route: ActivatedRoute,
	            private apollo: Apollo,
	            private authService: AuthenticationService,
	            private modalService: MDBModalService) {

		this.genericModalHandler = new ModalGenericHandler();
		this.loading = false;
		this.networkOperationInProgress = false;
		this.insuranceSubmissionDataManipulator = new InsuranceSubmissionDataManipulations(apollo);
	}

	ngOnInit(): void {
		this.networkOperationInProgress = false;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.insuranceSubmissions = [];
				}
				else {
					this.loading = true;
					this.dataSubscription = this.apollo.watchQuery<AllInsurancesubmissionsQueryResult>({
						query: AllInsurancesubmissionsQuery,
						fetchPolicy: "network-only"
					})
						.valueChanges
						.subscribe(
							(({ data, loading}) => {
								this.loading = loading;
								this.insuranceSubmissions = data.all_insurancesubmissions.insurancesubmissions;
							})
						);
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.dataSubscription) this.dataSubscription.unsubscribe();
	}

	private downloadSubmissionDocument(submissionID: number){
		if (this.networkOperationInProgress || submissionID === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.insuranceSubmissionDataManipulator.downloadDocumentForInsuranceSubmission(submissionID).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania dokumentu ze zgłoszeniem ubezpieczonych wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}
}