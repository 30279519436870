import {Apollo} from "apollo-angular";
import {NetworkOperationResult} from "../../types";
import {Observable, Subject, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {MDBModalService} from "ng-uikit-pro-standard";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Address} from "../../_models";
import {
  Generate_presigned_post_attributes_for_reservation_attachment_downloadResult,
  Generate_presigned_post_attributes_for_reservation_attachment_uploadResult, ReservationReviewDetailsQueryResult,
  UserAddressesQueryResult
} from "../results";
import {
  Generate_presigned_post_attributes_for_reservation_attachment_upload,
  Generate_presigned_url_for_reservations_attachment_download,
  getUserAddressesQuery,
  refreshReservationsAttachmentsQuery
} from "../queries";

@Injectable({providedIn: "root"})
export class S3FileManipulations {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private modalService: MDBModalService) {
  }

  uploadReservationAttachment(reservationID: number, attachmentFile: File, remoteName: string) : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let presignedPOSTSubscription : Subscription = this.apollo.watchQuery<Generate_presigned_post_attributes_for_reservation_attachment_uploadResult>({
      query: Generate_presigned_post_attributes_for_reservation_attachment_upload,
      fetchPolicy: "network-only",
      variables: {
        camp_participant_id: reservationID,
        file_name: remoteName
      }
    })
      .valueChanges
      .subscribe(
        ({data, loading}) => {
          if (loading) {
            return;
          }

          presignedPOSTSubscription.unsubscribe();

          if (data.generate_presigned_post_attributes_for_reservation_attachment_upload.status == "OK"){
            let formAttributes = JSON.parse(data.generate_presigned_post_attributes_for_reservation_attachment_upload.formAttributes);
            let formInputs = JSON.parse(data.generate_presigned_post_attributes_for_reservation_attachment_upload.formInputs);

            // now upload the file with given credentials
            let uploadSubscription = this.genericFilePOSTUploader(attachmentFile, formAttributes, formInputs).subscribe(
              (data) => {
                if (data.status == "OK"){
                  retVal.next({status: "OK", message: "Plik został przesłany na serwer."});
                  retVal.complete();
                }
                else {
                  retVal.next({status: "ERROR", message: "Podczas przesyłania pliku na serwer wystąpił błąd. Spróbuj ponownie."});
                  retVal.complete();
                }

              },
              (error) => {
                retVal.next({status: "ERROR", message: "Podczas przesyłania pliku na serwer wystąpił błąd. Spróbuj ponownie."});
                retVal.complete();
              },
              () => {
                uploadSubscription.unsubscribe();
              }
            );
          }
          else {
            retVal.next({status: "ERROR", message: "Podczas próby uzyskania danych do przesłania pliku na serwer wystąpił błąd. Spróbuj ponownie."});
            retVal.complete();
            return;
          }
        },
        (error) => {
          retVal.next({status: "ERROR", message: "Podczas próby wysyłania pliku na serwer wystąpił błąd. Spróbuj ponownie."});
          retVal.complete();
        },
        () => {
          presignedPOSTSubscription.unsubscribe();
          presignedPOSTSubscription = null;
        });

    return retVal.asObservable();
  }

  genericFilePOSTUploader(file: File, formAttributes: any, formInputs: any) : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let formData = new FormData();
    for (let key in formInputs){
      formData.append(key, formInputs[key]);
    }
    formData.append("file", file);


    this.http.post(formAttributes.action, formData)
      .subscribe(
        (data) => {
          retVal.next({status: "OK", message: "Plik został przesłany na serwer."});
          retVal.complete();
        },
        (error) => {
          console.log("error: ", error);
          retVal.next({status: "ERROR", message: "Podczas próby wysyłania pliku na serwer wystąpił błąd. Spróbuj ponownie."});
          retVal.complete();
        },
        () => {

        });

    return retVal.asObservable();
  }

  public refreshReservationsAttachments(reservationID: number) : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let reservationSubscription : Subscription = this.apollo.watchQuery<ReservationReviewDetailsQueryResult>({
      query: refreshReservationsAttachmentsQuery,
      fetchPolicy: "network-only",
      variables: {
        camp_participant_id: reservationID,
      }
    })
      .valueChanges
      .subscribe(
        ({data, loading}) => {
          if (loading) {
            return;
          }

          reservationSubscription.unsubscribe();
          let reservationData = data.reservation_review_details.camp_participant;
          retVal.next({status: "OK", message: "Załączniki zostały odświeżone."});
        },
        (error) => {
          retVal.next({status: "ERROR", message: "Podczas próby odświeżenia załączników wystąpił błąd. Spróbuj ponownie."});
          retVal.complete();
        },
        () => {
          reservationSubscription.unsubscribe();
          reservationSubscription = null;
        });

    return retVal.asObservable();
  }

  public downloadReservationAttachment(reservationID: number, key: string, file_name: string) : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let presignedURLSubscription : Subscription = this.apollo.watchQuery<Generate_presigned_post_attributes_for_reservation_attachment_downloadResult>({
      query: Generate_presigned_url_for_reservations_attachment_download,
      fetchPolicy: "network-only",
      variables: {
        camp_participant_id: reservationID,
        key: key
      }
    })
      .valueChanges
      .subscribe(
        ({data, loading}) => {
          if (loading) {
            return;
          }

          presignedURLSubscription.unsubscribe();

          if (data.generate_presigned_url_for_reservations_attachment_download.status == "OK"){
            let presignedURL = data.generate_presigned_url_for_reservations_attachment_download.url;

            // use HTML5 feature of a href download
            var link = document.createElement('a');
            link.download = file_name;
            link.href = presignedURL;
            link.setAttribute('target', '_blank');
            link.click();
            link.remove();


            retVal.next({status: "OK", message: "Pomyślnie uzyskano adres URL.", data: presignedURL});
            retVal.complete();
          }
          else {
            retVal.next({status: "ERROR", message: "Podczas próby uzyskania adresu URL wystąpił błąd. Spróbuj ponownie."});
            retVal.complete();
            return;
          }
        },
        (error) => {
          retVal.next({status: "ERROR", message: "Podczas próby uzyskania adresu URL wystąpił błąd. Spróbuj ponownie."});
          retVal.complete();
        },
        () => {
          presignedURLSubscription.unsubscribe();
          presignedURLSubscription = null;
        });

    return retVal.asObservable();
  }
}
