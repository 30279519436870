<div class="row px-2 px-sm-0 mb-4 deep-blue-text">
    <div class="col-12">
        <h2>Lista uczestników</h2>
        <p class="lead">
            Aby zapisać uczestnika na obóz należy najpierw dodać jego dane do poniższej listy.
        </p>
        <p>
            Dane uczestników mogą być edytowane w dowolnym momencie. Aby usunąć uczestnika z listy kliknij na ikonie kosza w prawym górnym rogu tabelki z jego danymi.
            Edycja danych istniejącego uczestnika możliwa jest po kliknięciu ikony z ołówkiem.
        </p>
        <p>
            Pamiętaj jednak, że po przesłaniu formularza zgłoszenia na obóz edycja danych uczestnika na tej liście nie spowoduje
            aktualizacji danych w zgłoszeniu! Aby zaktualizować informacje w wysłanym zgłoszeniu skontaktuj się z nami.
        </p>
    </div>
</div>

<mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

<div *ngIf="!loading" class="row px-2 px-sm-0">
    <div *ngFor="let participant of participants; trackBy: trackByFn" class="col-12 col-sm-6 col-lg-4">
        <mdb-card class="mb-3" borderColor="border-light">

            <mdb-card-header class="grey lighten-5">
                {{ participant.name_first }} {{ participant.name_middle }} {{ participant.name_last }}

                <div class="float-right hoverable-address-links">
                    <a title="Usuń uczestnika" (click)="deleteParticipant(participant)"><mdb-icon far icon="trash-alt"></mdb-icon></a>
                    &nbsp;&nbsp;
                    <a title="Edytuj uczestnika" (click)="editParticipant(participant)"><mdb-icon far icon="edit"></mdb-icon></a>
                </div>
            </mdb-card-header>

            <mdb-card-body>
                <!--
                <mdb-card-title>
                  <h5>Secondary Panel title</h5>
                </mdb-card-title>
                -->
                <mdb-card-text>
                    <div class="row">
                        <div class="col-12 col-sm-5">
                            <strong>Data urodzenia</strong>
                        </div>
                        <div class="col-12 col-sm-7">
                            {{ participant.date_birth }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-5">
                            <strong>PESEL</strong>
                        </div>
                        <div class="col-12 col-sm-7">
                            {{ participant.national_id_number }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <strong>Rodzice i opiekunowie:</strong>
                        </div>
                    </div>

                    <div class="row mt-2" *ngFor="let parent of participant.parents_list">
                        <div class="col-12">
                            <strong>{{ parent.name_full }}</strong><br/>
                            {{ parent.address }}<br/>
                            {{ parent.phone_number }}
                        </div>
                    </div>

                  <div class="row mt-2">
                    <div class="col-12">
                      <div *ngIf="participant.parents_list.length == 1" class="alert alert-warning" role="alert">
                        <strong>Uwaga!</strong> Uczestnik ma przypisanego tylko jednego rodzica/opiekuna. Prosimy uzupełnić
                        dane (jeśli sa dostępne).
                      </div>
                    </div>
                  </div>

                </mdb-card-text>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>


<div *ngIf="!loading" class="row">
    <div class="col-12">
        <button mdbBtn color="primary" size="sm" (click)="addParticipant()">Dodaj uczestnika</button>
    </div>
</div>
