import {Component, EventEmitter, Input, Output, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MDBModalService} from "ng-uikit-pro-standard";
import {ModalGenericHandler} from "../modal-generic-handler";
import {NumericValueType, RxwebValidators} from "@rxweb/reactive-form-validators";
import {ReservationsTransportOptions} from "../_models/reservationsTransportOptions";
import {TransportOption} from "../_models/transportoption";
import * as _ from "underscore";
import {Subscription} from "rxjs";


@Component({
  selector: 'reservations-transport-options-editor-form',
  templateUrl: './reservations-transport-options-editor-form.component.html',
  styleUrls: ['./reservations-transport-options-editor-form.component.css']
})
export class ReservationsTransportOptionsEditorFormComponent implements OnDestroy {
  @Output() onCommitChanges = new EventEmitter<ReservationsTransportOptions | null>();

  @Input()
  get camp_transport_options_to(){
    return this._camp_transport_options_to;
  }
  set camp_transport_options_to(newCampOptionsTo: TransportOption[]){
    this._camp_transport_options_to = newCampOptionsTo;
    this.updateFormControls();
  }

  @Input()
  get camp_transport_options_from(){
    return this._camp_transport_options_from;
  }
  set camp_transport_options_from(newCampOptionsFrom: TransportOption[]){
    this._camp_transport_options_from = newCampOptionsFrom;
    this.updateFormControls();
  }

  @Input()
  get reservationsSelectedTransportOptions() : ReservationsTransportOptions{
    return this._reservationsSelectedTransportOptions;
  }

  set reservationsSelectedTransportOptions(newTransportOptions: ReservationsTransportOptions){
    this._reservationsSelectedTransportOptions = newTransportOptions;
    this.updateFormControls();
  }

  @Input()
  get lockControls() : boolean {
    return this._lockControls;
  }

  set lockControls(newValue: boolean){
    this._lockControls = newValue;
  }


  public transportOptionsEditorForm: FormGroup;   //form controls
  public optionsCampTransportOptionsTo: Array<any>;
  public optionsCampTransportOptionsFrom: Array<any>;


  private genericModalHandler: ModalGenericHandler;

  private _camp_transport_options_to?: TransportOption[];
  private _camp_transport_options_from?: TransportOption[];
  private _reservationsSelectedTransportOptions?: ReservationsTransportOptions;

  public _lockControls: boolean = false;

  private toTransportOptionSubscription: Subscription;
  private fromTransportOptionSubscription: Subscription;
  private updateReservationPriceSubscription: Subscription;

  public showPriceUpdateAlert: boolean;  // if updateReservationPrice checkbox is set to false this flag is set to true

  constructor(private _formBuilder: FormBuilder, private modalService: MDBModalService) {
    this.genericModalHandler = new ModalGenericHandler();

    this._camp_transport_options_to = null;
    this._camp_transport_options_from = null
    this._reservationsSelectedTransportOptions = null;

    this.optionsCampTransportOptionsTo = [];
    this.optionsCampTransportOptionsFrom = [];

    this.toTransportOptionSubscription = null;
    this.fromTransportOptionSubscription = null;

    this.transportOptionsEditorForm = this._formBuilder.group({
      transportOptionToCode: this._formBuilder.control('', [Validators.required]),
      transportOptionToPrice: this._formBuilder.control('', [Validators.required, RxwebValidators.numeric({acceptValue:NumericValueType.PositiveNumber, allowDecimal:true })]),
      transportOptionToRemarks: this._formBuilder.control('', []),
      transportOptionFromCode: this._formBuilder.control('', [Validators.required]),
      transportOptionFromPrice: this._formBuilder.control('', [Validators.required, RxwebValidators.numeric({acceptValue:NumericValueType.PositiveNumber, allowDecimal:true })]),
      transportOptionFromRemarks: this._formBuilder.control('', []),
      transportOptionUpdateReservationPrice: this._formBuilder.control('', [])
    });

    this.showPriceUpdateAlert = true;
  }

  ngOnDestroy() {
    this.unsubscribeFormControlChanges();
  }

  private unsubscribeFormControlChanges(){
    if (this.toTransportOptionSubscription) this.toTransportOptionSubscription.unsubscribe();
    if (this.fromTransportOptionSubscription) this.fromTransportOptionSubscription.unsubscribe();
    if (this.updateReservationPriceSubscription) this.updateReservationPriceSubscription.unsubscribe();
  }

  private updateFormControls() : void {
    // don't update if both camp config and selected form controls haven't been provided yet
    if (this._reservationsSelectedTransportOptions === null || this._camp_transport_options_to === null || this._camp_transport_options_from === null) return;

    // unsubsribe from value changes
    this.unsubscribeFormControlChanges();

    // create lists of available transport options
    this.optionsCampTransportOptionsTo = [];
    this.optionsCampTransportOptionsFrom = [];


    this._camp_transport_options_to.forEach((value) => {
      this.optionsCampTransportOptionsTo.push({
        'value': value.code,
        'label': '(' + value.code + ') ' + value.short_description
      });
    });

    this.f.transportOptionToCode.setValue(this._reservationsSelectedTransportOptions.to.code);
    this.f.transportOptionToRemarks.setValue(this._reservationsSelectedTransportOptions.to.remarks);
    this.f.transportOptionToPrice.setValue(this._reservationsSelectedTransportOptions.to.price);


    this._camp_transport_options_from.forEach((value) => {
      this.optionsCampTransportOptionsFrom.push({
        'value': value.code,
        'label': '(' + value.code + ') ' + value.short_description
      });
    });


    this.f.transportOptionFromCode.setValue(this._reservationsSelectedTransportOptions.from.code);
    this.f.transportOptionFromRemarks.setValue(this._reservationsSelectedTransportOptions.from.remarks);
    this.f.transportOptionFromPrice.setValue(this._reservationsSelectedTransportOptions.from.price);


    this.f.transportOptionUpdateReservationPrice.setValue(false);
    this.showPriceUpdateAlert = true;


    // reactivate value change subscriptions
    this.toTransportOptionSubscription = this.f.transportOptionToCode.valueChanges.subscribe((value) => {
      this.onSelectValueChanged('to', value);
    });

    this.fromTransportOptionSubscription = this.f.transportOptionFromCode.valueChanges.subscribe((value) => {
      this.onSelectValueChanged('from', value);
    });

    this.updateReservationPriceSubscription = this.f.transportOptionUpdateReservationPrice.valueChanges.subscribe((value) => {
      this.showPriceUpdateAlert = !value;
    })
  }

  public onSelectValueChanged(direction: "to" | "from", value : any){
    let sourceData : TransportOption[];

    switch (direction) {
      case "to":
        sourceData = this._camp_transport_options_to;
        break;

      case "from":
        sourceData = this._camp_transport_options_from;
        break;
    }

    let index = _.findIndex(sourceData,{'code': value});

    // update prices, but leave comments untouched
    if (index !== -1){
      switch (direction){
        case "to":
          this.f.transportOptionToPrice.setValue(sourceData[index].price);
          break;

        case 'from':
          this.f.transportOptionFromPrice.setValue(sourceData[index].price);
          break;
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.transportOptionsEditorForm.controls;
  }



  public save() : void {
    this.transportOptionsEditorForm.markAllAsTouched();
    this.transportOptionsEditorForm.markAsDirty();

    // make sure select fields contain correct information
    let incorrectTransportSelected: boolean = true;

    incorrectTransportSelected = (_.findIndex(this.camp_transport_options_to,{'code': this.f.transportOptionToCode.value}) == -1) ||
                                  (_.findIndex(this.camp_transport_options_from,{'code': this.f.transportOptionFromCode.value}) == -1);

    if (!this.transportOptionsEditorForm.valid || incorrectTransportSelected) {

      // show error
      let msgSub = this.genericModalHandler.showModal("Niepoprawne dane!", "<p>Dane w formularzu zawierają błędy.</p>",
          this.modalService, "WARNING", "OK", null, null, null).subscribe(
          data => {
            msgSub.unsubscribe();
          }
      );
      return;
    }

    this.transportOptionsEditorForm.markAsUntouched();
    this.transportOptionsEditorForm.markAsPristine();

    // now create a new object holding updated information
    let newData : ReservationsTransportOptions = {
      to: {
        code: this.f.transportOptionToCode.value,
        price: this.f.transportOptionToPrice.value.toString(),
        remarks: this.f.transportOptionToRemarks.value.trim()
      },
      from: {
        code: this.f.transportOptionFromCode.value,
        price: this.f.transportOptionFromPrice.value.toString(),
        remarks: this.f.transportOptionFromRemarks.value.trim()
      },
      update_reservation_price: this.f.transportOptionUpdateReservationPrice.value,
      change_requests: []
    }

    this.onCommitChanges.emit(newData);
  }

  public cancel() : void {
    this.onCommitChanges.emit(null);
  }
}
