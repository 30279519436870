<div class="table-responsive">
  <table class="table" data-testid="list_of_all_users" small="true">
    <thead>
    <tr>
      <th scope="col" style="width: 15px;">
        <!-- <mdb-checkbox [default]="true"></mdb-checkbox> //-->
      </th>
      <th scope="col" style="width: 200px;"> e-mail </th>
      <th scope="col" style="width: 150px"> Imię </th>
      <th scope="col" style="width: 150px"> Nazwisko </th>
      <th scope="col" style="width: 150px"> Telefon </th>
      <th scope="col" style="width: 160px"> Uprawnienia </th>
      <th scope="col" style="width: 100px"> Status </th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users | async; let i = index">
        <td><!-- <mdb-checkbox [default]="true"></mdb-checkbox> --> {{ i + 1 }}</td>
        <td><span (blur)="updateAdminField(user, 'email', $event)" (keydown)="keyDownHandler(user, 'email', $event)" [contentEditable]="true">{{ user.email }}</span></td>
        <td><span style="padding:5px" (blur)="updateBasicFields(user, 'firstName', $event)" (keydown)="keyDownHandler(user, 'firstName', $event)" contenteditable="true">{{ user.firstName }}</span></td>
        <td><span style="padding:5px" (blur)="updateBasicFields(user, 'lastName', $event)" (keydown)="keyDownHandler(user, 'lastName', $event)" contenteditable="true">{{ user.lastName }}</span></td>
        <td><span style="padding:5px" (blur)="updateBasicFields(user, 'phone_number', $event)" (keydown)="keyDownHandler(user, 'phone_number', $event)" contenteditable="true">{{ user.phone_number }}</span></td>
        <td>
          <mdb-icon fab icon="android" (click)="toggleRolePermission(user, 'is_admin')" [ngClass]="{'red-text': user.is_admin, 'grey-text': !user.is_admin}" class="mr-1" title="administrator"></mdb-icon>
          <mdb-icon fas icon="chart-bar" (click)="toggleRolePermission(user, 'is_manager')" [ngClass]="{'blue-text': user.is_manager, 'grey-text': !user.is_manager}"  class="mr-1" title="menedżer"></mdb-icon>
          <mdb-icon far icon="id-card" (click)="toggleRolePermission(user, 'is_backoffice_employee')" [ngClass]="{'blue-text': user.is_backoffice_employee, 'grey-text': !user.is_backoffice_employee}"  class="mr-1" title="back-office"></mdb-icon>
          <mdb-icon fas icon="futbol" (click)="toggleRolePermission(user, 'is_camp_employee')" [ngClass]="{'blue-text': user.is_camp_employee, 'grey-text': !user.is_camp_employee}"  class="mr-1" title="wychowawca lub edukator"></mdb-icon>
          <mdb-icon fas icon="portrait" (click)="toggleRolePermission(user, 'is_client')" [ngClass]="{'blue-text': user.is_client, 'grey-text': !user.is_client}" class="mr-1" title="klient"></mdb-icon>
        </td>
        <td style="width: 20px;">
          <mdb-icon fas icon="circle" (click)="toggleRolePermission(user, 'is_active')" [ngClass]="{'blue-text': user.is_active}" class="mr-1" title="konto aktywne"></mdb-icon>
          <mdb-icon far icon="envelope" (click)="toggleRolePermission(user, 'is_confirmed')" [ngClass]="{'blue-text': user.is_confirmed}" class="mr-1" title="adres e-mail potwierdzony"></mdb-icon>
        </td>
        <td>
          <div class="btn-group" mdbDropdown>
            <button type="button" mdbBtn color="info" class="waves-light" size="sm" mdbWavesEffect>Szczegóły</button>
            <button type="button" mdbDropdownToggle mdbBtn color="info" size="sm" class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
                    mdbWavesEffect></button>

            <div class="dropdown-menu">
              <a class="dropdown-item">Edytuj</a>
              <a class="dropdown-item">Usuń</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="sendActivationReminder(user)">Wyślij przypomnienie o aktywacji</a>
              <a class="dropdown-item">Wyślij link zmiany hasła</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
