import {
	NumericalValueCountKeyValuePair,
	NumericalValueCountKeyValuePair_EmptyEntity
} from "./numerical_value_count_key_value_pair";
import {PaymentsSummary, PaymentsSummary_EmptyEntity} from "./payments_summary";
import {
	StringValueCountKeyValuePair,
	StringValueCountKeyValuePair_EmptyEntity
} from "./string_value_count_key_value_pair";
import moment from "moment";
import {AgeStatistics, AgeStatistics_EmptyEntity} from "./agestatistics";
import {TransportOption} from "./transportoption";

export class Camp {
	id: number;
	name: string;
	description_full: string;
	description_short: string;
	image_src: string;
	location_name: string;
	url: string;
	date_start: Date;
	date_end: Date;
	duration_days?: number;
	age_min: number;
	age_max: number;
	age_statistics: AgeStatistics;
	price: string;
	average_price_statistics: number;
	payment_statistics: PaymentsSummary;
	price_special: string;
	participants_capacity: number;
	participants_count_statistics: StringValueCountKeyValuePair[];
	transport_options_to: TransportOption[];
	transport_options_from: TransportOption[];
	insurance_options: string;
	camp_details_public: string;
	camp_details_public_decoded?: string;
	camp_group_name_tags?: string[];
	camp_details_backoffice: string;
	camp_details_backoffice_decoded?: string;
	config_object: string;
	is_active: boolean;
	enrollment_active: boolean;
	contract_details_data_snapshot: string;
	data_confirmation_required: boolean;

	public decodeJSONFields(){
		if (this.camp_details_public){
			this.camp_details_public_decoded = JSON.parse(this.camp_details_public);
		}

		if (this.camp_details_public_decoded && this.camp_details_public_decoded["camp_group_name_tags"]){
			this.camp_group_name_tags = this.camp_details_public_decoded["camp_group_name_tags"].split("|");
		}

		if (this.camp_details_backoffice){
			this.camp_details_backoffice_decoded = JSON.parse(this.camp_details_backoffice);
		}

		if (this.date_start){
			this.date_start = new Date(this.date_start);
		}

		if (this.date_end){
			this.date_end = new Date(this.date_end);
		}

		if (this.date_start && this.date_end){
			this.duration_days = (((this.date_end.valueOf() - this.date_start.valueOf()) / (1000 * 60 * 60 * 24)) + 1);
		}
		else this.duration_days = 0;
	}

	/**
	 * Returns details about a transport option based on option's code and travel direction
	 *
	 * @param sourceObject
	 * @param direction
	 * @param code
	 */
	public static findTransportOptionByCode(sourceObject: Camp, direction: "to" | "from", code: string) : TransportOption | null{
		if (sourceObject !== null){
			let sourceArray : TransportOption[] = direction == "to" ? sourceObject.transport_options_to : sourceObject.transport_options_from;

			if (sourceArray.length){
				for (let a = 0; a < sourceArray.length; a++){
					if (sourceArray[a].code == code) return sourceArray[a];
				}
			}
		}

		return null;
	}
}

export const Camp_EmptyEntity = {
	id: -1,
	name: '',
	description_full: '',
	description_short: '',
	image_src: '',
	location_name: '',
	url: '',
	date_start: new Date(),
	date_end: new Date(),
	age_min: 0,
	age_max: 0,
	age_statistics: AgeStatistics_EmptyEntity,
	price: "",
	average_price_statistics: 0,
	payment_statistics: PaymentsSummary_EmptyEntity,
	price_special: "",
	participants_capacity: 0,
	participants_count_statistics: StringValueCountKeyValuePair_EmptyEntity,
	transport_options_to: [],
	transport_options_from: [],
	insurance_options: "[]",
	camp_details_public: "[]",
	camp_details_backoffice: "[]",
	config_object: "[]",
	is_active: true,
	enrollment_active: true,
	contract_details_data_snapshot: "[]",
	data_confirmation_required: false
} as Camp;


