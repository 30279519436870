<!--Header-->
<div class="modal-header">
    <p class="heading lead">{{ title }}</p>

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="white-text">&times;</span>
    </button>
</div>

<!--Body-->
<div class="modal-body">
    <div *ngIf="message !== null" class="text-justify">
        <p [innerHTML]="message"></p>
    </div>

    <div *ngIf="unsanitizedMessage !== null" [innerHTML]="unsanitizedMessage | keepHtml"></div>
</div>

<!--Footer-->
<div class="modal-footer justify-content-right">
    <a *ngIf="buttonsToShow.OK" type="button" mdbBtn color="primary" class="ml-2 waves-effect" mdbWavesEffect (click)="close('OK')">{{ buttonsCaptions.OK }}</a>
    <a *ngIf="buttonsToShow.Cancel" type="button" mdbBtn color="info" outline="true" class="ml-2 waves-effect" mdbWavesEffect (click)="close('Cancel')">{{ buttonsCaptions.Cancel }}</a>
    <a *ngIf="buttonsToShow.Yes" type="button" mdbBtn color="success" outline="true" class="ml-2 waves-effect" mdbWavesEffect (click)="close('Yes')">{{ buttonsCaptions.Yes }}</a>
    <a *ngIf="buttonsToShow.No" type="button" mdbBtn color="danger" outline="true" class="ml-2 waves-effect" mdbWavesEffect (click)="close('No')">{{ buttonsCaptions.No }}</a>
</div>
