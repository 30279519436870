import { Component, OnInit, ElementRef, HostListener, ViewChild, Output, EventEmitter } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {Parent, ParentStatusSelectConfig, Participant} from "../_models";
import { IMyOptions, MDBModalRef } from "ng-uikit-pro-standard";
import * as _ from "underscore";


@Component({
	templateUrl: './modal-text-edit.component.html',
	styleUrls: ['./modal-text-edit.component.scss']
})
export class ModalTextEditComponent implements OnInit {
	@ViewChild('focusInput') focusInput: ElementRef;

	public windowTitle: string;
	public textareaHintText: string;
	public descriptionText: string;
	public currentValue: string;

	public newValue: string;

	public textEditForm: FormGroup;   //form controls

	public saveButtonPressed: boolean;

	constructor(public modalRef: MDBModalRef, private _formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.textEditForm = this._formBuilder.group({
			textValue: [this.currentValue, [Validators.required]],
		});

		setTimeout(() => {
			this.focusInput.nativeElement.focus();
		}, 0);
	}

	setFocusToInput() {
		this.focusInput.nativeElement.focus();
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.textEditForm.controls;
	}

	get textValue() {
		return this.f.textValue as FormControl;
	}

	saveData(){
		if (this.textEditForm.valid){
			this.saveButtonPressed = true;

			this.newValue = this.textValue.value.trim();

			this.modalRef.hide();
		}
	}
}