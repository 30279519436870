import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (this.authenticationService){
            // add authorization header with jwt token if available
            let token = this.authenticationService.currentJWTToken;
            if (token) {
                //if request url matches pattern https://s3. then some part and then .amazonaws.com then do not add Authorization header
                if (request.url.match(/https:\/\/s3\..*\.amazonaws\.com/)){
                    return next.handle(request);
                }

                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    },
                    /*
                        the following code has been added to cope with OVH proxy deleting authorization header
                     */
                    params: request.params.set(
                        "Bearer",
                        token
                    )
                });
            }
        }

        return next.handle(request);
    }
}
