import { Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router} from "@angular/router";
import { Apollo} from "apollo-angular";
import {AuthenticationService, ModalHelpersService} from "../_services";
import { IMyOptions, MDBDatePickerComponent, MDBModalService} from "ng-uikit-pro-standard";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { of, Subscription} from "rxjs";
import {Extra_Invoice_Status_Type, ExtraInvoice, User} from "../_models";
import { ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import { switchMap} from "rxjs/operators";
import { ReservationPaymentsDetailsResult } from "../graphQL/results";
import { ReservationPaymentsDetailsQuery} from "../graphQL/queries";
import { Camp_Participant } from "../_models/camp_participant";
import {InvoiceTypeEnum, Payment, PaymentHelper, PaymentMethodEnum, PaymentStatusEnum} from "../_models/payment";
import { EntityEvent } from "../_models/entityevent";
import { create, all } from 'mathjs';
import * as currency from "currency.js";
import {PaymentDataManipulations} from "../graphQL/data-manipulation/payment-data-manipulations";
import {Camp_participantDataManipulations} from "../graphQL/data-manipulation";
import {Payment_plan_entry} from "../_models/payment_plan_entry";

@Component({
	templateUrl: './reservation-payments.component.html',
	styleUrls: ['./reservation-payments.component.scss']
})
export class ReservationPaymentsComponent implements OnInit, OnDestroy {
	@ViewChild('paymentDatePicker') myDatePicker: MDBDatePickerComponent;

	private loggedInUserSubscription: Subscription;
	private reservationSubscription: Subscription;

	public currentUser: User;

	private genericModalHandler: ModalGenericHandler;
	private paymentsDataManipulator: PaymentDataManipulations;

	public loading: boolean;
	public itemsBeingProcessed: any;
	public networkOperationInProgress: boolean;
	public formsBeingValidated: boolean;

	private reservationID: number;

	// members of query response object
	public reservation: Camp_Participant;
	public contract_details_data_snapshot: any;        //JSON serialized object containing some of camps configuration variables
	public events: EntityEvent[];                      // array of deserialized Entity Event objects associated with this reservation

	private math: any;
	private paymentHelper: PaymentHelper;
	public datePickerOptions: IMyOptions;

	public form_regenerate_payment_plan: FormGroup;       // for writing comment when regenerating contract documents
	public formPaymentPlanEntries: FormGroup;
	public formNewPayment: FormGroup;

	private paymentFormChangeSubscription: Subscription;
	private paymentPlanEntriesFormChangeSubscription: Subscription;

	public newPaymentsSum: number;



	constructor(private router: Router,
	            private route: ActivatedRoute,
	            private apollo: Apollo,
	            private authService: AuthenticationService,
	            private modalService: MDBModalService,
	            private _formBuilder: FormBuilder,
				private modalsHelper: ModalHelpersService,
	            private location: Location,
	            private reservationDataManipulator: Camp_participantDataManipulations) {

		this.math = create(all, {});
		this.paymentHelper = new PaymentHelper();
		this.genericModalHandler = new ModalGenericHandler();

		this.paymentsDataManipulator = new PaymentDataManipulations(apollo);

		this.form_regenerate_payment_plan = this._formBuilder.group({
			comment: this._formBuilder.control("", [Validators.required]),
		});

		this.formPaymentPlanEntries = this._formBuilder.group({
			payment_plan_entries: this._formBuilder.array([]),
		});

		this.formNewPayment = new FormGroup({
			transaction_timestamp: new FormControl(null, Validators.required),
			bank_account: new FormControl('', Validators.required),
			amount: new FormControl('', Validators.required),
		});

		this.paymentFormChangeSubscription = null;
		this.paymentPlanEntriesFormChangeSubscription = null;
	}

	get regenerate_payment_plan_comment(){
		return this.form_regenerate_payment_plan.get('comment');
	}

	ngOnInit(): void {
		this.datePickerOptions = {
			dayLabels: {su: 'Nd', mo: 'Pon', tu: 'Wt', we: 'Śr', th: 'Czw', fr: 'Pt', sa: 'Sob'},
			monthLabelsFull: {1: 'Styczeń', 2:'Luty', 3: 'Marzec', 4: 'Kwiecień', 5: 'Maj', 6:'Czerwiec', 7:'Lipiec', 8:'Sierpień', 9:'Wrzesień', 10:'Październik', 11:'Listopad', 12:'Grudzień'},
			firstDayOfWeek: 'mo',
			dateFormat: 'yyyy-mm-dd',
			showClearDateBtn: false,
			showTodayBtn: true,
			todayBtnTxt: 'Dziś',
			closeBtnTxt: 'Zamknij',
			closeAfterSelect: true,
			minYear: 2020,
		};

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user) {
					this.reservation                     = null;
					this.events                          = null;
					this.contract_details_data_snapshot  = null;
				} else {
					// react to change of id parameter
					this.route.paramMap.pipe(
						switchMap(params => {
							if (params.get('id') !== null){
								this.reservationID = +params.get('id'); // + converts value to number :)
								this.loading = true;

								// fetch reservation data
								if (this.reservationSubscription) this.reservationSubscription.unsubscribe();
								this.reservationSubscription = this.apollo.watchQuery<ReservationPaymentsDetailsResult>({
									query: ReservationPaymentsDetailsQuery,
									variables: {
										camp_participant_id: this.reservationID,
									},
								})
									.valueChanges
									.subscribe(
										(({ data, loading}) => {
											this.loading = loading;

											this.reservation = data.reservation_review_details.camp_participant;

											// if this reservation isn't signed then get out :)
											if (!this.reservation.contract_sign_date || this.reservation.contract_sign_date === null){
												this.router.navigate(['/reservations', 'client', {camp_status: 'ACTIVE'}]);
											}

											if (this.reservation.events_array != null){
												this.events = JSON.parse(this.reservation.events_array);
											}

											if (this.reservation.camp.contract_details_data_snapshot != null){
												this.contract_details_data_snapshot = JSON.parse(this.reservation.camp.contract_details_data_snapshot);
											}

											this.resetForms();
										})
									);
							}

							return of('');
						})
					).subscribe();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) {
			this.loggedInUserSubscription.unsubscribe();
			this.loggedInUserSubscription = null;
		}

		if (this.reservationSubscription) {
			this.reservationSubscription.unsubscribe();
			this.reservationSubscription = null;
		}

		if (this.paymentFormChangeSubscription) {
			this.paymentFormChangeSubscription.unsubscribe();
			this.paymentFormChangeSubscription = null;
		}

		if (this.paymentPlanEntriesFormChangeSubscription){
			this.paymentPlanEntriesFormChangeSubscription.unsubscribe();
			this.paymentPlanEntriesFormChangeSubscription = null;
		}
	}

	get payment_plan_entries_array() : FormArray {
		return this.formPaymentPlanEntries.get('payment_plan_entries') as FormArray;
	}

	get transaction_timestamp() : FormControl {
		return this.formNewPayment.get('transaction_timestamp') as FormControl;
	}

	amount_input_payment_plan_entryControlAtIndex(index: number) : FormControl {
		return this.payment_plan_entries_array.at(index).get('amount_input_payment_plan_entry') as FormControl;
	}

	resetForms(){
		if (this.reservation.payment_plan_entries && this.reservation.payment_plan_entries.length){
			this.payment_plan_entries_array.clear();

			for (let a = 0; a < this.reservation.payment_plan_entries.length; a++){
				this.payment_plan_entries_array.push(this._formBuilder.group({
					amount_input_payment_plan_entry: this._formBuilder.control("", [Validators.required, Validators.min(0), Validators.max(this.reservation.payment_plan_entries[a].amount_left)]),
				}))
			}
		}

		// set default account number which is taken from camp entity setting
		this.formNewPayment.get('bank_account').setValue(this.contract_details_data_snapshot && this.contract_details_data_snapshot.bank_account ? this.contract_details_data_snapshot.bank_account.trim() : '');

		this.newPaymentsSum = 0;

		// listen to amount changes
		if (this.paymentFormChangeSubscription) this.paymentFormChangeSubscription.unsubscribe();
		this.paymentFormChangeSubscription = this.formNewPayment.valueChanges.subscribe(
			val => {
				let tmpAmount = val.amount;

				if (tmpAmount === null || tmpAmount == "") tmpAmount = "0";

				// don't update forms while validation is running
				if (this.formsBeingValidated) return;

				let amount_left = this.math.bignumber(tmpAmount);
				let index = 0;

				while (index < this.payment_plan_entries_array.length){
					if (this.reservation.payment_plan_entries[index].amount_left){
						if (this.reservation.payment_plan_entries[index].amount_left < this.math.number(amount_left)){
							this.payment_plan_entries_array.at(index).get('amount_input_payment_plan_entry').setValue(this.reservation.payment_plan_entries[index].amount_left * 1);
							amount_left = this.math.subtract(amount_left, this.reservation.payment_plan_entries[index].amount_left);
						}
						else {
							this.payment_plan_entries_array.at(index).get('amount_input_payment_plan_entry').setValue(this.math.number(amount_left));
							amount_left = this.math.bignumber(0);
						}
					}
					else this.payment_plan_entries_array.at(index).get('amount_input_payment_plan_entry').setValue(0);

					index++;
				}
			}
		);

		if (this.paymentPlanEntriesFormChangeSubscription) this.paymentPlanEntriesFormChangeSubscription.unsubscribe();
		this.paymentPlanEntriesFormChangeSubscription = this.formPaymentPlanEntries.valueChanges.subscribe(
			val => {
				// don't update forms while validation is running
				if (this.formsBeingValidated) return;

				if (val === null) return;

				let sum = this.math.bignumber(0);

				for (let a = 0; val.payment_plan_entries && a < val.payment_plan_entries.length; a++){
					let tmpAmount = "0";
					if (val.payment_plan_entries[a].amount_input_payment_plan_entry !== null && val.payment_plan_entries[a].amount_input_payment_plan_entry != ""){
						tmpAmount = val.payment_plan_entries[a].amount_input_payment_plan_entry;
					}
					
					sum = this.math.add(sum, tmpAmount);
				}

				this.newPaymentsSum = this.math.number(sum);
			}
		);
	}

	clearPaymentForm(){
		this.transaction_timestamp.setValue('');
		this.myDatePicker.clearDate();
		this.formNewPayment.get('amount').setValue(0);
	}

	getPaymentPlanValue(valueType: "INSTALLMENT_SUM" | "LEFT_SUM" | "NEW_PAYMENTS_SUM"): string{

		let sum = this.math.bignumber(0);

		switch (valueType){
			case "INSTALLMENT_SUM":
				for (let i = 0; this.reservation.payment_plan_entries && i < this.reservation.payment_plan_entries.length; i++){
					sum = this.math.add(sum, this.math.bignumber(this.reservation.payment_plan_entries[i].amount));
				}
				break;

			case "LEFT_SUM":
				for (let i = 0; this.reservation.payment_plan_entries && i < this.reservation.payment_plan_entries.length; i++){
					sum = this.math.add(sum, this.math.bignumber(this.reservation.payment_plan_entries[i].amount_left));
				}
				break;

			case "NEW_PAYMENTS_SUM":
				return "0";

			default:
				return "";
		}

		return currency(this.math.number(sum), {separator: " ", decimal: ",", precision: 2, symbol: "PLN", pattern: "# !"}).format();
	}

	humanReadablePaymentMethod(method: PaymentMethodEnum) : string {
		return this.paymentHelper.humanReadableMethod(method);
	}

	humanReadablePaymentStatus(status: PaymentStatusEnum) : string {
		return this.paymentHelper.humanReadableStatus(status);
	}

	savePayment(){
		if (this.networkOperationInProgress) return;

		// this will prevent from updating amounts on payment plan entries
		this.formsBeingValidated = true;

		this.formNewPayment.updateValueAndValidity();
		this.formPaymentPlanEntries.updateValueAndValidity();

		// validate both forms
		if (!this.formPaymentPlanEntries.valid || !this.formNewPayment.valid) {
			this.formsBeingValidated = false;
			return;
		}

		// make sure amounts are ok and valid
		let new_payment_amount = this.math.bignumber(this.formNewPayment.get('amount').value);
		let amount_left = this.math.bignumber(0);

		for (let a = 0; a < this.reservation.payment_plan_entries.length; a++){
			amount_left = this.math.add(amount_left, this.math.bignumber(this.reservation.payment_plan_entries[a].amount_left));
		}

		// payment amount is too large - we should only account for as much as we need, the rest of the should be sent back to client
		if (this.math.number(amount_left) < this.math.number(new_payment_amount)){
			let dlgTitle = "Błąd!";
			let dlgMessage = "Nie mogę zaksięgować przelewu w kwocie wyższej, niż pozostała do spłaty rat!";
			let dlgStyle: ModalStyleType = "DANGER";

			this.genericModalHandler.showModal(dlgTitle, dlgMessage,
				this.modalService, dlgStyle, "OK", null, null, null);

			this.formsBeingValidated = false;
			return;
		}

		// make sure the whole payment amount has been distributed among payment plan entries
		let amount_distributed = this.math.bignumber(0);
		for (let a = 0; a < this.reservation.payment_plan_entries.length; a++){
			amount_distributed = this.math.add(amount_distributed, this.math.bignumber(this.amount_input_payment_plan_entryControlAtIndex(a).value));

			if (this.amount_input_payment_plan_entryControlAtIndex(a).value > this.reservation.payment_plan_entries[a].amount_left){

				this.genericModalHandler.showModal("Błąd!", "Kwota wpłaty dla danej raty nie może być wyższa niż kwota pozostała do spłaty w ramach tej raty!",
					this.modalService, "DANGER", "OK", null, null, null);

				this.formsBeingValidated = false;
				return;
			}
		}

		if (this.math.number(amount_distributed) != this.math.number(new_payment_amount)){

			this.genericModalHandler.showModal("Błąd!", "Kwota wpłaty musi być identyczna z sumą wpłąt dopisywanych do poszczególnych rat!",
				this.modalService, "DANGER", "OK", null, null, null);

			this.formsBeingValidated = false;
			return;
		}


		// OK - everything is fine, save the payment to the database
		const inputData = {
			camp_participant_id: this.reservation.id,
			amount: this.math.number(new_payment_amount),
			transaction_timestamp: this.formNewPayment.get('transaction_timestamp').value,
			is_income: true,
			bank_account: this.formNewPayment.get('bank_account').value,
			method: 'BANK_TRANSFER',
			status: 'COMPLETE',
			payment_plan_entries_updates: []
		};

		for (let a = 0;  a < this.reservation.payment_plan_entries.length; a++){
			inputData.payment_plan_entries_updates.push({
				paymentplanentry_id: this.reservation.payment_plan_entries[a].id,
				amount: this.amount_input_payment_plan_entryControlAtIndex(a).value,
			})
		}

		this.networkOperationInProgress = true;

		let localSub = this.paymentsDataManipulator.newReservationPayment(inputData).subscribe(
			data => {
				this.networkOperationInProgress = false;
				this.formsBeingValidated = false;

				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas zapisu danych do bazy wystąpił nieoczekiwany błąd. Spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
				else {
					this.clearPaymentForm();
				}
			}
		)
	}

	issueInvoice(payment: Payment, invoice_type: InvoiceTypeEnum){
		if (this.networkOperationInProgress || payment.invoice_number !== null) return;

		// make sure parent reservation has an order assigned to it
		if (this.reservation.order_number === null){
			this.genericModalHandler.showModal("Błąd!", "Do rezerwacji nie wystawiono zamówienia. Najpierw utwórz zamówienie, a następnie wystaw faktury.",
				this.modalService, "DANGER", "OK", null, null, null);
			return;
		}

		let invoiceTypeStr = "";
		switch (invoice_type) {
			case "advance":
				invoiceTypeStr = " <b>ZALICZKOWEJ</b> ";
				break;

			case "final":
				invoiceTypeStr = " <b>KOŃCOWEJ</b> ";
				break;

			default:
				return;
		}
		// confirm
		let msgSub = this.genericModalHandler.showModal("Operacja nieodwracalna!", "Czy potwierdzasz wystawienie faktury" + invoiceTypeStr + "dla tej płatności?",
			this.modalService, "WARNING", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data == "No") return;

				this.networkOperationInProgress = true;

				let localSub = this.paymentsDataManipulator.issueInvoiceForPayment(payment.id, invoice_type).subscribe(
					data => {
						this.networkOperationInProgress = false;
						localSub.unsubscribe();

						if (data.status == "ERROR"){
							this.genericModalHandler.showModal("Błąd!", "Podczas wystawiania faktury wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
								this.modalService, "DANGER", "OK", null, null, null);
						}
					}
				)
			}
		)
	}

	downloadInvoice(payment: Payment){
		if (this.networkOperationInProgress || payment.invoice_number === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.paymentsDataManipulator.downloadInvoiceForPayment(payment.id).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania faktury wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}

	downloadExtraInvoice(invoice_id: string){
		if (this.networkOperationInProgress || invoice_id === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationDataManipulator.downloadExtraInvoice(this.reservation, invoice_id).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania faktury wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}

	issueOrderForReservation(){
		if (this.networkOperationInProgress || this.reservation.order_number !== null) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Operacja nieodwracalna!", "Czy potwierdzasz wystawienie zamówienia do tej rezerwacji?",
			this.modalService, "WARNING", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data == "No") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationDataManipulator.issueOrderForReservation(this.reservation.id).subscribe(
					data => {
						this.networkOperationInProgress = false;
						localSub.unsubscribe();

						if (data.status == "ERROR"){
							this.genericModalHandler.showModal("Błąd!", "Podczas wystawiania zamówienia wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
								this.modalService, "DANGER", "OK", null, null, null);
						}
					}
				)
			}
		)
	}

	downloadOrder(){
		if (this.networkOperationInProgress || this.reservation.order_number === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationDataManipulator.downloadOrderForPayment(this.reservation.id).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania zamówienia wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}

	regeneratePaymentPlan(updatePaymentPlanSnapshot: boolean){
		if (this.networkOperationInProgress || !this.form_regenerate_payment_plan.valid) return;

		// confirm
		let text = "";
		if (updatePaymentPlanSnapshot){
			text = " pobranie nowego harmongoramu z konfiguracji obozu, oraz";
		}

		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz" + text + " ponowne wygenerowanie harmonogramu płatności z komentarzem: <strong>"
			+ this.form_regenerate_payment_plan.get('comment').value.toString().trim()
			+ "</strong>?</p><p><strong>OPERACJA JEST NIEODWRACALNA!!!</strong></p>",
			this.modalService, "DANGER", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationDataManipulator.regeneratePaymentPlan(
					this.reservation.id,
					updatePaymentPlanSnapshot,
					this.form_regenerate_payment_plan.get('comment').value.toString().trim()
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							//this.form_regenerate_documents.get('new_contract_documents_comment').setValue("");
							this.form_regenerate_payment_plan.reset();

							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);
	}

	assignExtraInvoice(){
		this.modalsHelper.addExtraInvoiceDialog(this.reservation);
	}

	editExtraInvoice(extraInvoice: ExtraInvoice){
		const data : ExtraInvoice = {
			id: extraInvoice.id,
			created_timestamp: extraInvoice.created_timestamp,
			provider: extraInvoice.provider,
			invoice_number: extraInvoice.invoice_number,
			invoice_foreign_id: extraInvoice.invoice_foreign_id,
			invoice_date_issued: new Date(),
			invoice_date_due: new Date(),
			invoice_amount_total: 1,
			invoice_amount_paid: 1,
			invoice_include_in_summary: extraInvoice.invoice_include_in_summary,
			invoice_status: extraInvoice.invoice_status,
			invoice_items: [],
			comment: extraInvoice.comment
		};

		this.modalsHelper.editExtraInvoiceDialog(this.reservation, data);
	}

	autoUpdateExtraInvoice(extraInvoice: ExtraInvoice){
		if (this.networkOperationInProgress) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationDataManipulator.autoUpdateExtraInvoice(
			this.reservation.id,
			extraInvoice.id
		).subscribe(
			(data) => {
				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}

				localSub.unsubscribe();
			}
		);
	}

	deleteExtraInvoice(extraInvoice: ExtraInvoice){
		if (this.networkOperationInProgress) return;

		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz usunięcie faktury z rezerwacji?</p>",
			this.modalService, "DANGER", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationDataManipulator.deleteExtraInvoice(
					this.reservation.id,
					extraInvoice.id
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}

						localSub.unsubscribe();
					}
				);
			});
	}

	invoiceStatusHumanReadable(status: Extra_Invoice_Status_Type) : string {
		switch (status){
			case "ISSUED":
				return "Faktura wystawiona - nieopłacona.";

			case "PARTIALLY_PAID":
				return "Faktura częściowo opłacona.";

			case "COMPLETE":
				return "Faktura opłacona."
		}
	}

	extraInvoiceAmountLeftAbsolute(extraInvoice: ExtraInvoice) : number | null {
		if (!extraInvoice) return null;

		return Math.abs(extraInvoice.invoice_amount_total) - Math.abs(extraInvoice.invoice_amount_paid);
	}
}