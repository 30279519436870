import { deleteAddressesMutation, updateOrCreateAddressMutation } from "../mutations";
import { getUserAddressesQuery } from "../queries";
import {Address, Address_EmptyEntity, AddressType, Participant, User} from "../../_models";
import { Apollo } from "apollo-angular";
import {AddressInput, AddressInputLiteral} from "../inputs";
import * as _ from "underscore";
import {ModalAddressEditComponent} from "../../modal-address-edit";
import {Tools} from "../../_helpers/tools";
import {MDBModalService} from "ng-uikit-pro-standard";
import {Observable, Subject, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {ModalAddressSelectComponent} from "../../modal-address-select/modal-address-select.component";
import {NetworkOperationResult} from "../../types";
import {UserAddressesQueryResult} from "../results";

@Injectable({providedIn: "root"})
export class AddressDataManipulations {
  constructor(private apollo: Apollo, private modalService: MDBModalService) {
  }

	addAddress(addressData: AddressInput, expectedResponseData: any, userId: number): Observable<Address>{
		let retVal = new Subject<Address>();

		let localSub = this.apollo.mutate({
			mutation: updateOrCreateAddressMutation,
			variables: {
				address: addressData
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateOrCreateAddress: {
					...expectedResponseData,
					__typename: 'Address'
				},
			},
			update: (proxy, { data: { updateOrCreateAddress }}) => {
				// Read the data from our cache for this query.
				const data = proxy.readQuery({ query: getUserAddressesQuery, variables: { user_id: userId } });
				let dataCopy = JSON.parse(JSON.stringify(data));    // data['user_addresses'] is immutable so we must make a DEEP clone of data!

				// Add our address from the mutation to the end.
				dataCopy['user_addresses'].push(updateOrCreateAddress);

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserAddressesQuery, variables: { user_id: userId }, data: dataCopy });
			},
		}).subscribe(
			({ data }) => {
				retVal.next(data.updateOrCreateAddress);
				retVal.complete();

				localSub.unsubscribe();
			},
			error => {
				retVal.next(null);
				retVal.complete();

				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	editAddress(addressData: AddressInput, expectedResponseData: any, userId: number){
		this.apollo.mutate({
			mutation: updateOrCreateAddressMutation,
			variables: {
				address: addressData
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateOrCreateAddress: {
					...expectedResponseData,
					__typename: 'Address'
				},
			},
		}).subscribe();
	}

	deleteAddress(addressId: number, userId: number){
		this.apollo.mutate({
			mutation: deleteAddressesMutation,
			variables: {
				deleteIDs: [addressId]
			},
			optimisticResponse: {
				__typename: 'Mutation',
				deleteAddresses: [addressId],
			},
			update: (proxy, { data: { deleteAddresses }}) => {
				// Read the data from our cache for this query.
				let data = proxy.readQuery({ query: getUserAddressesQuery, variables: { user_id: userId } });

				//create a hash object which will have deleted ids as its keys
				const idsHash = deleteAddresses.reduce((a, b) => {a[b] = ''; return a}, {});

				//now leave only those addresses which haven't been deleted
				data['user_addresses'] = data['user_addresses'].filter((address: Address) => {
					return !idsHash.hasOwnProperty(address.id);
				});

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserAddressesQuery, variables: { user_id: userId }, data });
			},
		}).subscribe();
	}

	editAddressDialog(address: Address){
		/*
			  Configure the modal
		*/
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				address: _.clone(address),
				saveButtonPressed: false  //very important!!!
			}
		};

		/*
			  Show the modal
		*/
		let modalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

		/*
			  Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		*/
		let addressEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				//save address to the database

				//make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
				const tools = new Tools();
				const extractAddressInput = tools.extract<AddressInput>(AddressInputLiteral);
				let data = extractAddressInput(modalRef.content.address);
				data.user_id = modalRef.content.address.user.id;

				this.editAddress(data, modalRef.content.address, data.user_id);
			}

			// don't react to further events coming from the modal
			addressEditSubscription.unsubscribe();
		});
	}

  selectNewOrUpdatedAddressDialog(addressType: AddressType, addressOwner: number, currentlySelectedAddressId?: number, replaceNameWith?: string) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let dialogTitle: string;
    let addressTypeName: string;
    let dialogMessage: string;

    switch (addressType){
      case AddressType.MAILING:
        dialogTitle = 'Wybierz adres korespondencyjny';
        addressTypeName = AddressType.MAILING;
        break;

      case AddressType.BILLING:
        dialogTitle = 'Wybierz adres do rozliczeń';
        addressTypeName = AddressType.BILLING;
        break;

      case AddressType.PARTICIPANT:
        dialogTitle = 'Wybierz adres uczestnika';
        addressTypeName = AddressType.PARTICIPANT;
        break;
    }

    /*
      Dialogs are backend agnostic, so we need to provide all necessary data to the dialog before showing it.
     */
    let addressesSubscription : Subscription = this.apollo.watchQuery<UserAddressesQueryResult>({
      query: getUserAddressesQuery,
      fetchPolicy: "network-only",
      variables: {
        user_id: addressOwner
      }
    })
      .valueChanges
      .subscribe(
        ({data, loading}) => {
          // hanlde data only once
          addressesSubscription.unsubscribe();

          /*
            Configure the modal
           */
          let modalOptions = {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: false,
            ignoreBackdropClick: false,
            class: 'modal-dialog-scrollable modal-notify modal-info',
            containerClass: '',
            animated: true,
            data: {
              saveButtonPressed: false,
              dialogTitle: dialogTitle,
              addressTypeName: addressTypeName,
              defaultAddressID: currentlySelectedAddressId,
              replaceAddressNameWith: replaceNameWith,
              dialogMessage: dialogMessage,
              addresses: data.user_addresses,
            }
          };

          /*
            Show the modal
           */
          let modalRef = this.modalService.show(ModalAddressSelectComponent, modalOptions);
          let addressSelectSubscription = this.modalService.closed.subscribe(() => {
            if (modalRef.content.saveButtonPressed){
              retVal.next({status: "CONFIRMED", message: 'Wybrano nowy adres.', data: modalRef.content.selectedAddressID});
              retVal.complete();
            }
            else {
              retVal.next({status: "OK", message: 'Anulowano wybór adresu.'});
              retVal.complete();
            }
          });

        },
        (error) => {
          retVal.next({status: "ERROR", message: 'Nie udało się zmienić adresu użytkownika.'});
          retVal.complete();
        },
        () => {
          addressesSubscription.unsubscribe();
          retVal.complete();
        });








    return retVal.asObservable();
  }

	addAddressDialog(owner: User): Observable<Address>{
		let retVal = new Subject<Address>();

		/*
			Prepare an empty address object ready for editing
		*/
		const newEmptyAddress = {
			..._.clone(Address_EmptyEntity),
			user: owner
		};


		/*
			Configure the modal
		 */
		let modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				address: newEmptyAddress,
				saveButtonPressed: false
			}
		};

		/*
			Show the modal
		 */
		let modalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		const addressEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				// save address to the database

				// make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
				const tools = new Tools();
				const extractAddressInput = tools.extract<AddressInput>(AddressInputLiteral);
				let data = extractAddressInput(modalRef.content.address);
				const user_id = modalRef.content.address.user.id;
				data.user_id = user_id;

				let resultSubscription = this.addAddress(data, modalRef.content.address, user_id)
					.subscribe(
						(data) => {
							retVal.next(data);
							retVal.complete();
						}
					);
			}
			else {
				retVal.next(null);
				retVal.complete();
			}

			//don't react to further events coming from the modal
			addressEditSubscription.unsubscribe();
		});

		return retVal.asObservable();
	}
}
