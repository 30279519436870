import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../_models";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../_services";
import {ReportsDataManipulations} from "../graphQL/data-manipulation";
import {ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {MDBModalService} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public  currentUser: User;
  private currentUserSubscription: Subscription;
  private genericModalHandler: ModalGenericHandler;
  protected networkOperationInProgress: boolean = false;

  constructor(
      private authenticationService: AuthenticationService,
      private reportsDataManipulations: ReportsDataManipulations,
      private modalService: MDBModalService,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.genericModalHandler = new ModalGenericHandler();
  }

  ngOnInit() {
    this.networkOperationInProgress = false;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
  }

  public isNaukowcowDwochManager(): boolean {
    let allowedIDs: number[] =[359, 372, 129];

    return allowedIDs.includes(Number(this.currentUser.id));
  }

  public downloadMaturalniReport() {
    if (this.networkOperationInProgress){
      return;
    }

    this.networkOperationInProgress = true;

    let localSub = this.reportsDataManipulations.downloadMaturalniReport().subscribe(
      (data) => {
        let dlgTitle: string;
        let dlgMessage: string;
        let dlgStyle: ModalStyleType;


        if (data.status == "ERROR"){
          dlgTitle = "Błąd!";
          dlgMessage = data.message;
          dlgStyle = "WARNING";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, dlgStyle, "OK", null, null, null);

          return;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.networkOperationInProgress = false;
        localSub.unsubscribe();
        localSub = null;
      });
  }

  public downloadAllActiveParticipantsReport() {
    if (this.networkOperationInProgress){
      return;
    }

    this.networkOperationInProgress = true;

    let localSub = this.reportsDataManipulations.downloadAllActiveParticipantsReport().subscribe(
      (data) => {
        let dlgTitle: string;
        let dlgMessage: string;
        let dlgStyle: ModalStyleType;


        if (data.status == "ERROR"){
          dlgTitle = "Błąd!";
          dlgMessage = data.message;
          dlgStyle = "WARNING";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, dlgStyle, "OK", null, null, null);

          return;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.networkOperationInProgress = false;
        localSub.unsubscribe();
        localSub = null;
      });
  }

  public downloadIssuedInvoicesReport() {
    if (this.networkOperationInProgress){
      return;
    }

    this.networkOperationInProgress = true;

    let localSub = this.reportsDataManipulations.downloadIssuedInvoicesReport().subscribe(
      (data) => {
        let dlgTitle: string;
        let dlgMessage: string;
        let dlgStyle: ModalStyleType;


        if (data.status == "ERROR"){
          dlgTitle = "Błąd!";
          dlgMessage = data.message;
          dlgStyle = "WARNING";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, dlgStyle, "OK", null, null, null);

          return;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.networkOperationInProgress = false;
        localSub.unsubscribe();
        localSub = null;
      });
  }
}
