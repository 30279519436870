import {User} from "./user";

// enum list containing address types
export enum AddressType {
  BILLING = 'BILLING',
  MAILING = 'MAILING',
  PARTICIPANT = 'PARTICIPANT',
}

export class Address {
  id: number;
  user: User;
  is_business: boolean;
  name_first: string;
  name_last: string;
  company_name: string;
  street_name: string;
  street_number: string;
  flat_number: string;
  zip_code: string;
  city: string;
  country: string;
  vat_number: string;
  phone_number: string;

  constructor() {
  }
}

export class AddressHelper {
  public singleString(address: Address) : string {
    let retVal : string = '';

    retVal += address.name_first + " " + address.name_last + ", ";

    if (address.is_business && address.company_name.trim() !== ''){
      retVal += '"' + address.company_name + '",';
    }

    if (address.street_name.trim() !== ''){
      retVal += " " + address.street_name;
    }

    if (address.street_number.trim() !== ''){
      retVal += " " + address.street_number;
    }

    if (address.flat_number.trim() !== ''){
      retVal += " / " + address.flat_number;
    }

    if (address.zip_code.trim() !== ''){
      retVal += ", " + address.zip_code + " " + address.city.trim();
    }

    if (address.country.trim() !== ''){
      retVal += ", " + address.country;
    }

    return retVal;
  }

  public singleStringWithoutName(address: Address) : string {
    let retVal : string = '';

    if (address.street_name.trim() !== ''){
      retVal += " " + address.street_name;
    }

    if (address.street_number.trim() !== ''){
      retVal += " " + address.street_number;
    }

    if (address.flat_number.trim() !== ''){
      retVal += " / " + address.flat_number;
    }

    if (address.zip_code.trim() !== ''){
      retVal += ", " + address.zip_code + " " + address.city.trim();
    }

    if (address.country.trim() !== ''){
      retVal += ", " + address.country;
    }

    return retVal;
  }
}

/*
export const Address_EmptyEntity = <Address>{
  id: -1, //-1 means this is a new address which needs to be added to database!!!
  user: null,
  is_business: false,
  name_first: "",
  name_last: "",
  company_name: "",
  street_name: "",
  street_number: "",
  flat_number: "",
  zip_code: "",
  city: "",
  country: "",
  vat_number: "",
  phone_number: "",
};
*/

export const Address_EmptyEntity = {
  id: -1, // -1 means this is a new address which needs to be added to database!!!
  user: null,
  is_business: false,
  name_first: "",
  name_last: "",
  company_name: "",
  street_name: "",
  street_number: "",
  flat_number: "",
  zip_code: "",
  city: "",
  country: "",
  vat_number: "",
  phone_number: "",
} as Address;
