<div class="row mx-1 mb-2" >
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>


<form [formGroup]="search_form">
    <div class="row mx-1 mb-2">
        <div class="col-12 col-md-4 col-lg-2">
            <div class="md-form mt-1 mb-1">
                <input type="text" id="camps_statistics_search_string" class="form-control" formControlName="search_string" mdbInput mdbValidate>
                <label for="camps_statistics_search_string">Szukaj...</label>
            </div>
        </div>
    </div>
</form>

<cprncs-single-active-camp-statistics *ngIf="cumulative_camp_statistics_object" [camp]="cumulative_camp_statistics_object"></cprncs-single-active-camp-statistics>

<span *ngFor="let singleCamp of queried_camps; let i = index;">
    <cprncs-single-active-camp-statistics [camp]="singleCamp"></cprncs-single-active-camp-statistics>
</span>
