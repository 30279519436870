import {
	NumericalValueCountKeyValuePair,
	NumericalValueCountKeyValuePair_EmptyEntity
} from "./numerical_value_count_key_value_pair";

export class AgeStatistics {
	F: NumericalValueCountKeyValuePair[];
	M: NumericalValueCountKeyValuePair[];
	O: NumericalValueCountKeyValuePair[];
	Total: NumericalValueCountKeyValuePair[];
}

export const AgeStatistics_EmptyEntity = {
	F: NumericalValueCountKeyValuePair_EmptyEntity,
	M: NumericalValueCountKeyValuePair_EmptyEntity,
	O: NumericalValueCountKeyValuePair_EmptyEntity,
	Total: NumericalValueCountKeyValuePair_EmptyEntity
} as AgeStatistics;