import {Apollo} from "apollo-angular";
import {Injectable} from "@angular/core";

import b64toblob from "b64-to-blob";
import {saveAs as importedSaveAs} from "file-saver";
import {Observable, Subject} from "rxjs";
import {NetworkOperationResult} from "../../types";
import {
  OrderDownloadResult,
  Report_all_active_participantsQueryResult, Report_issued_invoicesQueryResult,
  Report_maturalni_participantsQueryResult
} from "../results";
import {
  OrderDownloadQuery,
  Report_all_active_participants,
  Report_issued_invoices,
  Report_maturalni_participants
} from "../queries";

@Injectable({providedIn: "root"})
export class ReportsDataManipulations {
  constructor(private apollo: Apollo) {
  }

  public downloadMaturalniReport() : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.query<Report_maturalni_participantsQueryResult>({
      query: Report_maturalni_participants,
      fetchPolicy: "network-only",
      variables: {
      }
    }).subscribe(({data}) => {
        if (data.maturalni_camps_download.status.startsWith('Error')){
          console.error(data.maturalni_camps_download.status);

          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas pobierania raportu!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie pobrano dokument!"
          });
          retVal.complete();

          // create a string containing current date
          let date = new Date();
          // create strings with leading zeros if needed
          let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
          let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1).toString();

          let dateString = date.getFullYear() + "-" + month + "-" + day;

          // @ts-ignore
          let blob = b64toblob(data.maturalni_camps_download.report_file_contents, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
          importedSaveAs(blob, dateString + ' uczestnicy_obozow_maturalnych.xlsx');
        }
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas pobierania raportu!"
        });
        retVal.complete();
      },
      () => {
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
  }

  public downloadAllActiveParticipantsReport() : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.query<Report_all_active_participantsQueryResult>({
      query: Report_all_active_participants,
      fetchPolicy: "network-only",
      variables: {
      }
    }).subscribe(({data}) => {
        if (data.active_camps_participants_download.status.startsWith('Error')){
          console.error(data.active_camps_participants_download.status);

          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas pobierania raportu!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie pobrano dokument!"
          });
          retVal.complete();

          // create a string containing current date
          let date = new Date();
          // create strings with leading zeros if needed
          let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
          let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1).toString();

          let dateString = date.getFullYear() + "-" + month + "-" + day;

          // @ts-ignore
          let blob = b64toblob(data.active_camps_participants_download.report_file_contents, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
          importedSaveAs(blob, dateString + ' uczestnicy_obozow.xlsx');
        }
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas pobierania raportu!"
        });
        retVal.complete();
      },
      () => {
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
  }


  public downloadIssuedInvoicesReport() : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.query<Report_issued_invoicesQueryResult>({
      query: Report_issued_invoices,
      fetchPolicy: "network-only",
      variables: {
      }
    }).subscribe(({data}) => {
        if (data.issued_invoices_report_download.status.startsWith('Error')){
          console.error(data.issued_invoices_report_download.status);

          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas pobierania raportu!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie pobrano dokument!"
          });
          retVal.complete();

          // create a string containing current date
          let date = new Date();
          // create strings with leading zeros if needed
          let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
          let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1).toString();

          let dateString = date.getFullYear() + "-" + month + "-" + day;

          // @ts-ignore
          let blob = b64toblob(data.issued_invoices_report_download.report_file_contents, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
          importedSaveAs(blob, dateString + ' wystawione faktury.xlsx');
        }
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas pobierania raportu!"
        });
        retVal.complete();
      },
      () => {
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
  }
}
