import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	trigger,
	state,
	style,
	animate,
	transition,
	sequence,
} from '@angular/animations';
import { Subscription } from "rxjs";
import { InsuranceSubmissionPolicyConfig, User } from "../_models";
import { ModalGenericHandler } from "../modal-generic-handler";
import { InsuranceSubmissionDataManipulations } from "../graphQL/data-manipulation/insurance-submission-data-manipulations";
import { ActivatedRoute, Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "../_services";
import { MDBModalService } from "ng-uikit-pro-standard";
import { Insurance_submission_generator_configResult } from "../graphQL/results";
import { Insurance_submission_generator_configQuery } from "../graphQL/queries";
import * as _ from "underscore";
import {Form, FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {InsuranceSubmissionMutationInput} from "../_models/insurancesubmission";

@Component({
	templateUrl: './insurance-submission-generator.component.html',
	styleUrls: ['./insurance-submission-generator.component.scss'],
	animations: [
		trigger('openClose', [
			// ...
			state('open', style({
				opacity: 1,
				height: '*',
			})),
			state('closed', style({
				height: '0px',
				opacity: 0,
				overflow: 'hidden',
				padding: '0px 0px 0px 0px'
			})),
			transition('open => closed', [
				animate('0.3s'),
			]),
			transition('closed => open', [
				sequence([
					animate('0.3s', style({
						'padding-top': '0.75rem',
						'padding-right': '1.25rem',
						'padding-bottom': '0.75rem',
						'padding-left': '1.25rem',
						height: '*',
						opacity: 1,
					})),
				])
			]),
		]),
	]
})
export class InsuranceSubmissionGeneratorComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public networkOperationInProgress: boolean;

	private loggedInUserSubscription: Subscription;
	private dataSubscription: Subscription;

	public currentUser: User;

	private genericModalHandler: ModalGenericHandler;
	private insuranceSubmissionDataManipulator: InsuranceSubmissionDataManipulations;

	public insuranceSubmissionPoliciesConfig: InsuranceSubmissionPolicyConfig[];

	public selectedPolicy: InsuranceSubmissionPolicyConfig;

	public radioForm: FormGroup;
	public checkboxForm: FormGroup;

	constructor(private router: Router,
	            private route: ActivatedRoute,
	            private apollo: Apollo,
	            private authService: AuthenticationService,
	            private modalService: MDBModalService,
	            private _formBuilder: FormBuilder,) {

		this.genericModalHandler = new ModalGenericHandler();
		this.loading = false;
		this.networkOperationInProgress = false;
		this.insuranceSubmissionDataManipulator = new InsuranceSubmissionDataManipulations(apollo);

		this.selectedPolicy = null;

		this.radioForm = new FormGroup({
			campRadioSelectControl: new FormControl(-1)
		});

		this.checkboxForm = _formBuilder.group({
			checkCamps: _formBuilder.array([])
		});
	}


	ngOnInit(): void {
		this.networkOperationInProgress = false;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.insuranceSubmissionPoliciesConfig = [];
				}
				else {
					this.loading = true;
					this.dataSubscription = this.apollo.watchQuery<Insurance_submission_generator_configResult>({
						query: Insurance_submission_generator_configQuery,
						fetchPolicy: "network-only"
					})
						.valueChanges
						.subscribe(
							(({ data, loading}) => {
								this.loading = loading;
								this.insuranceSubmissionPoliciesConfig = data.insurance_submission_generator_config;
							})
						);
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.dataSubscription) this.dataSubscription.unsubscribe();
	}

	get checkCamps() : FormArray {
		return this.checkboxForm.get('checkCamps') as FormArray;
	}

	getCheckCampsControlAtIndex(i: number){
		if (this.checkCamps && i < this.checkCamps.length)
			return this.checkCamps.at(i);

		return null;
	}

	selectPolicy(policy: InsuranceSubmissionPolicyConfig){
		this.selectedPolicy = policy;

		this.checkCamps.clear();
		if (this.shouldShowCheckboxForm(policy)){
			for (let i = 0; policy.camps && i < policy.camps.length; i++){
				this.checkCamps.push(this._formBuilder.control(false))
			}
		}
	}

	/**
	 * To show a list of camps with radio buttons policy config accepted_submission_types
	 *
	 *  MUST HAVE:      SINGLE_CAMP
	 *  CAN'T HAVE:     CAMPS
	 *
	 * @param policy
	 */
	shouldShowRadioForm(policy: InsuranceSubmissionPolicyConfig){
		if (_.indexOf(policy.accepted_submission_types, 'SINGLE_CAMP') !== -1 && _.indexOf(policy.accepted_submission_types, 'CAMPS') === -1)
			return true;

		return false;
	}

	/**
	 * To show a list of camps with checkboxes policy config accepted_submission_types
	 *
	 *  MUST HAVE:      CAMPS or PARTICIPANTS
	 *  CAN'T HAVE:     SINGLE_CAMP
	 *
	 * @param policy
	 */
	shouldShowCheckboxForm(policy: InsuranceSubmissionPolicyConfig){
		if ((_.indexOf(policy.accepted_submission_types, 'CAMPS') !== -1 || _.indexOf(policy.accepted_submission_types, 'PARTICIPANTS') !== -1)
		&& _.indexOf(policy.accepted_submission_types, 'SINGLE_CAMP') == -1) return true;

		return false;
	}

	/**
	 * To show a list of camps policy config accepted_submission_types
	 *
	 *  MUST HAVE:      CAMPS or SINGLE_CAMP or PARTICIPANTS
	 *
	 * @param policy
	 */
	shouldShowCampsSelectors(policy: InsuranceSubmissionPolicyConfig){
		return _.indexOf(policy.accepted_submission_types, 'CAMPS') !== -1 || _.indexOf(policy.accepted_submission_types, 'PARTICIPANTS') !== -1
			|| _.indexOf(policy.accepted_submission_types, 'SINGLE_CAMP') !== -1;
	}

	canIssueSubmissionForPolicyID(policy: InsuranceSubmissionPolicyConfig){
		return _.indexOf(policy.accepted_submission_types, 'POLICY') !== -1;
	}

	issueSubmissionForPolicy(policy: InsuranceSubmissionPolicyConfig){
		if (this.networkOperationInProgress) return;

		let data : InsuranceSubmissionMutationInput = {
			insurancepolicy_id: policy.insurancepolicy_id,
			id_type: 'POLICY',
			ids: [ policy.insurancepolicy_id ],
		};

		let questionMessage = "<p>Czy potwierdzasz stworzenie dokumentu zgłoszenia ubezpieczonych?</p><p><strong>Do dokumentu zostaną przypisani WSZYSCY uczestnicy obozów obsługiwanych przez wybraną polisę, którzy nie zostali wcześniej zgłoszeni do wybranego ubezpieczenia.</strong></p>";

		this.issueSubmissionGeneric(data, questionMessage);
	}

	issueSubmissionForRadioCamp(){
		if (this.networkOperationInProgress) return;

		let data : InsuranceSubmissionMutationInput = {
			insurancepolicy_id: this.selectedPolicy.insurancepolicy_id,
			id_type: 'SINGLE_CAMP',
			ids: [ this.radioForm.get('campRadioSelectControl').value ],
		};

		let questionMessage = "<p>Czy potwierdzasz stworzenie dokumentu zgłoszenia ubezpieczonych?</p><p><strong>Do dokumentu zostaną przypisani WSZYSCY uczestnicy zaznaczonego obozu, którzy nie zostali wcześniej zgłoszeni do wybranego ubezpieczenia.</strong></p>";

		this.issueSubmissionGeneric(data, questionMessage);
	}

	issueSubmissionForCheckedCamps(){
		if (this.networkOperationInProgress) return;

		// get selected camp ids
		let selectedCamps: number[] = [];
		for (let i = 0; i < this.checkCamps.length; i++){
			if (this.checkCamps.at(i).value){
				selectedCamps.push(this.selectedPolicy.camps[i].camp_id);
			}
		}

		if (!selectedCamps.length) return;

		let data : InsuranceSubmissionMutationInput = {
			insurancepolicy_id: this.selectedPolicy.insurancepolicy_id,
			id_type: 'CAMPS',
			ids: selectedCamps,
		};

		let questionMessage = "<p>Czy potwierdzasz stworzenie dokumentu zgłoszenia ubezpieczonych?</p><p><strong>Do dokumentu zostaną przypisani WSZYSCY uczestnicy zaznaczonych obozów, którzy nie zostali wcześniej zgłoszeni do wybranego ubezpieczenia.</strong></p>";

		this.issueSubmissionGeneric(data, questionMessage);
	}

	issueSubmissionGeneric(inputData: InsuranceSubmissionMutationInput, message: string){
		if (this.networkOperationInProgress) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", message,
			this.modalService, "WARNING", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data == "No") return;

				this.networkOperationInProgress = true;

				let localSub = this.insuranceSubmissionDataManipulator.issueInsuranceSubmission(inputData).subscribe(
					data => {
						this.networkOperationInProgress = false;
						localSub.unsubscribe();

						if (data.status.startsWith("ERROR")){
							this.genericModalHandler.showModal("Błąd!", "<p>Podczas wystawiania dokumentu wystąpił błąd:</p><p><strong>" + data.message + "</strong></p>",
								this.modalService, "DANGER", "OK", null, null, null);
						}
						else {
							this.genericModalHandler.showModal("Gratulujemy!", "Dokument został wystawiony!",
								this.modalService, "SUCCESS", "OK", null, null, null);
						}
					}
				)
			}
		)
	}
}