import {Component, OnDestroy, OnInit} from "@angular/core";

@Component({
	templateUrl: './all-camps.component.html',
	styleUrls: ['./all-camps.component.scss']
})

export class AllCampsComponent {
	public loading: boolean = false;

}