<form [formGroup]="documentsToSubmitForm" (ngSubmit)="save()">
    <div formArrayName="documentsToSubmitList" *ngFor="let document of documentsToSubmitList.controls; let i = index;" class="mb-2 p-2" [ngClass]="{'orange lighten-5': i % 2}">
        <div [formGroupName]="i">
            <div class="form-row">
                <div class="col">
                    <div class="form-row">
                        <div [ngClass]="{
                            'col-9': true,
                            'col-md-7': _view_style != 'RESERVATION_SHORT',
                            'col-md-7': _view_style == 'RESERVATION_SHORT'
                        }">
                            <div class="md-form mt-0 mb-2">
                                <input type="text" [id]="'documentsToSubmitForm-document_name-' + i" formControlName="documentEditFormDocumentName" class="form-control" mdbInput mdbValidate>
                                <label [htmlFor]="'documentsToSubmitForm-document_name-' + i">Tytuł</label>
                            </div>
                        </div>
                        <div *ngIf="_view_style != 'RESERVATION_SHORT'" class="col-3 col-md-1">
                            <div class="md-form mt-0 mb-2">
                                <input type="text" [id]="'documentsToSubmitForm-document_id-' + i" formControlName="documentEditFormDocumentId" class="form-control" mdbInput mdbValidate>
                                <label [htmlFor]="'documentsToSubmitForm-document_id-' + i">ID</label>
                            </div>
                        </div>
                        <div *ngIf="_view_style == 'RESERVATION_SHORT'" class="col-3 col-md-2">
                            <div class="md-form mt-0 mb-2">
                                <mdb-select [options]="documentsToSubmitStatusesConfig" formControlName="documentEditFormDocumentSubmittedStatus" placeholder="Wybierz status"></mdb-select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_view_style != 'RESERVATION_SHORT'" class="form-row">
                        <div class="col-4">
                            <div class="md-form mt-0 mb-2">
                                <mdb-select [options]="documentsToSubmitStatusesConfig" formControlName="documentEditFormDocumentSubmittedStatus" placeholder="Wybierz status"></mdb-select>
                            </div>
                        </div>
                        <div class="col-4 pt-3">
                            <mdb-checkbox [id]="'documentsToSubmitForm-document_required-' + i" name="documentEditFormDocumentRequired" formControlName="documentEditFormDocumentRequired" >
                                wymagany
                            </mdb-checkbox>
                        </div>
                        <div class="col-4 pt-3">
                            <mdb-checkbox [id]="'documentsToSubmitForm-document_required_original-' + i" name="documentEditFormDocumentRequiredOriginal" formControlName="documentEditFormDocumentRequiredOriginal" >
                                oryginał
                            </mdb-checkbox>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 col-md-9">
                            <div class="md-form mt-0 mb-2">
                                <input type="text" [id]="'documentsToSubmitForm-document_comment-' + i" formControlName="documentEditFormDocumentComment" class="form-control" mdbInput mdbValidate>
                                <label [htmlFor]="'documentsToSubmitForm-document_comment-' + i">Komentarz</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="_view_style != 'RESERVATION_SHORT'" class="col-1 d-flex justify-content-center align-items-center">
                    <div class="md-form mt-0 mb-2 mt-3">
                        <a><mdb-icon fas icon="minus-circle" title="Usuń" class="red-text" size="lg" (click)="deleteDocument(i)"></mdb-icon></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
                <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="addNewDocument()" mdbWavesEffect>Dodaj dokument</button>

                <button mdbBtn size="sm" type="submit" color="warning" rounded="true" outline="true" mdbWavesEffect>
                    Zapisz dane
                </button>
            </div>
        </div>
    </div>
</form>
