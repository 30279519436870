<!-- Page header -->
<div class="jumbotron card card-image" style="background-image: url(assets/images/gradient1.jpg); background-size: cover">
  <div class="text-white text-center ">
    <div>
      <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Zaplanuj kreatywne wakacje dla swojego dziecka</strong></h2>
      <p class="mx-5">
        Obozy AlphaCamp oraz obozy "Naukowców Dwóch" powstają z prawdziwą pasją. Za pomocą sytetmu Copernicus możesz zarządzać swoimi rejestracjami, zamówieniami oraz płatnościami.
      </p>
    </div>
  </div>
</div>


<div class="row justify-content-center mx-1">
  <div class="col-12 col-md-8 col-lg-5">
    <!-- Material form login -->
    <div class="card">

      <h5 class="card-header info-color white-text text-center py-4">
        <strong>Logowanie</strong>
      </h5>

      <!--Card content-->
      <div class="card-body px-lg-5 pt-0">

        <!-- Form -->
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="text-center" style="color: #757575;">

          <!-- Email -->
          <div class="md-form">
            <input mdbInput type="email" formControlName="email" id="materialLoginFormEmail" class="form-control">
            <label for="materialLoginFormEmail">E-mail</label>
          </div>

          <!-- Password -->
          <div class="md-form">
            <input mdbInput type="password" formControlName="password" id="materialLoginFormPassword" class="form-control">
            <label for="materialLoginFormPassword">Hasło</label>
          </div>

          <div class="d-flex justify-content-around">
            <!--
            <div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="materialLoginFormRemember">
                <label class="form-check-label" for="materialLoginFormRemember">Zapamiętaj mnie</label>
              </div>
            </div>
            //-->
            <div>
              <!-- Forgot password -->
              <a [routerLink]="['/password-reset']">Zapomniałaś(-eś) hasła?</a>
            </div>
          </div>

          <!-- Sign in button -->
          <button mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect type="submit">Zaloguj się</button>

          <!-- Register -->
          <p>Nie masz konta?
            <a [routerLink]="['/register']">Zarejestruj się</a>
          </p>

          <!-- Social login -->

          <!--
          <p>albo zaluguj się używając konta:</p>
          <a type="button" class="btn-floating btn-fb btn-sm">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a type="button" class="btn-floating btn-tw btn-sm">
            <i class="fab fa-twitter"></i>
          </a>
          <a type="button" class="btn-floating btn-li btn-sm">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a type="button" class="btn-floating btn-git btn-sm">
            <i class="fab fa-github"></i>
          </a>
          -->

        </form>
        <!-- Form -->

      </div>

    </div>
  </div>
</div>
<!-- Material form login -->

<div mdbModal #modalErrorMessage="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Uwaga</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalErrorMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="fas fa-exclamation-triangle fa-4x mb-3 animated bounce fast"></i>
          <p [innerHTML]="errorMessage"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="modalErrorMessage.hide()">Zamknij</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


