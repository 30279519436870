<div *ngIf="viewType=='client'" class="row mb-2 text-center">
    <div class="col-4 text-center">
        <button mdbBtn [disabled]="campStatus=='ACTIVE'" (click)="changeCampStatus('ACTIVE')" type="button" color="primary" [outline]="campStatus != 'ACTIVE'" size="sm" mdbWavesEffect>aktywne obozy</button>
    </div>
    <div class="col-4 text-center">
        <button mdbBtn [disabled]="campStatus=='NOT_ACTIVE'" (click)="changeCampStatus('NOT_ACTIVE')" type="button" color="primary" [outline]="campStatus!='NOT_ACTIVE'" size="sm" mdbWavesEffect>zakończone obozy</button>
    </div>
    <div class="col-4 text-center">
        <button mdbBtn [disabled]="campStatus=='ALL'" (click)="changeCampStatus('ALL')" type="button" color="primary" [outline]="campStatus!='ALL'" size="sm" mdbWavesEffect>wszytkie rezerwacje</button>
    </div>
</div>



<div *ngIf="viewType=='admin'" class="row mb-2 mx-1 mx-md-1">
    <div class="col-12 col-lg-2">
        <mdb-select [(ngModel)]="reservationStatus" (ngModelChange)="changeReservationStatus($event)" [options]="optionsReservationStatuses" placeholder="status..."></mdb-select>
    </div>
</div>


<div class="row">
    <div class="col-12">
        <mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table *ngIf="!loading" class="table w-auto mx-1" small="true">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 30px;"> L.p. </th>
                    <th scope="col" style="min-width: 220px;"> Uczestnik </th>
                    <th scope="col" style="min-width: 200px;"> Obóz i lokalizacja</th>
                    <th scope="col" style="min-width: 180px"> Termin </th>
                    <th scope="col" style="min-width: 100px"> Cena </th>
                    <th scope="col" style="min-width: 150px"> Data wpłynięcia </th>
                    <th scope="col" style="min-width: 200px"> Status </th>
                    <th scope="col" style="width: 100%"></th>
                    <!-- usuń to //-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let reservation of reservations; let i = index;">
                    <td>
                        {{ i + 1 }}.
                    </td>
                    <td>
                        {{ reservation.participant_name_first }} {{ reservation.participant_name_middle }} {{ reservation.participant_name_last }}<br/>
                        <small>{{ reservation.client_name_full }}</small>
                    </td>
                    <td>
                        {{ reservation.camp_name }}<br/>
                        <small>{{ reservation.camp_location_name }}</small>
                    </td>
                    <td>
                        {{ reservation.camp_date_start | formatDate: 'Do MMM' }} - {{ reservation.camp_date_end | formatDate: 'Do MMM YYYY' }}
                    </td>
                    <td>
                        {{  reservation.total_price|currency: 'PLN':'code':'1.2-2':'pl-PL' }}
                        <span *ngIf="reservation.amount_overdue !== null && reservation.amount_overdue != 0" style="color: red" mdbTooltip="Należność po terminie" placement="top">
                            <br/> {{ reservation.amount_overdue|currency: 'PLN':'code':'1.2-2':'pl-PL' }}
                        </span>
                    </td>
                    <td>
                        {{ reservation.created_timestamp|formatDate: 'DD-MM-YYYY HH:mm'}}
                    </td>
                    <td>
                        {{ humanReadableStatus(reservation.status) }}
                        <span *ngIf="viewType=='admin'">
                            <mdb-badge *ngIf="reservation.pending_changes && !reservation.camp_data_confirmation_required" pill="true" primary="true">Oczekujące zmiany</mdb-badge>
                            <mdb-badge *ngIf="!reservation.pending_changes && !reservation.is_data_confirmed && reservation.camp_data_confirmation_required && reservation.status == 'CONTRACT_SIGNED'" pill="true" warning="true">Czeka na potwierdzenie</mdb-badge>
                            <mdb-badge *ngIf="reservation.unaccepted_attachments" pill="true" warning="true" style="cursor: pointer;" (click)="navigateToAttachmentsManager(reservation.id)">Załączniki do zatw.</mdb-badge>
                            <mdb-badge *ngIf="!reservation.pending_changes && reservation.is_data_confirmed && reservation.camp_data_confirmation_required" pill="true" success="true">Dane potwierdzone</mdb-badge>
                            <mdb-badge *ngIf="reservation.pending_changes && reservation.camp_data_confirmation_required" pill="true" danger="true">Oczekujące zmiany</mdb-badge>
                        </span>

                        <span *ngIf="viewType!='admin'">
                            <mdb-badge *ngIf="!reservation.is_data_confirmed && reservation.camp_data_confirmation_required" pill="true" warning="true">Potwierdź dane</mdb-badge>
                            <mdb-badge *ngIf="reservation.is_data_confirmed && reservation.camp_data_confirmation_required" pill="true" success="true">Dane potwierdzone</mdb-badge>
                        </span>
                    </td>
                    <td>
                        <div *ngIf="!itemsBeingProcessed || !itemsBeingProcessed.hasOwnProperty(reservation.id)" class="btn-group" [isDisabled]="networkOperationInProgress" mdbDropdown>
                            <button type="button" mdbBtn color="info" class="waves-light" size="sm" mdbWavesEffect (click)="showReservationDetails(reservation)" [disabled]="networkOperationInProgress">Szczegóły</button>
                            <button type="button" mdbDropdownToggle mdbBtn color="info" size="sm" class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
                                    mdbWavesEffect></button>
                            <div class="dropdown-menu">
                                <a *ngIf="reservation.status === 'DRAFT' || reservation.status === 'SUBMITTED' || reservation.status === 'WAITING_LIST'" class="dropdown-item" (click)="cancelReservation(reservation)">Anuluj rezerwację</a>
                                <a *ngIf="reservation.status === 'ENROLLED'" class="dropdown-item" (click)="sendEmailEnrolledReminder(reservation)">Wyślij potwierdzenie kwalifikacji</a>
                                <a class="dropdown-item" (click)="showReservationDetails(reservation)">Szczegóły</a>
                                <a *ngIf="reservation.contract_sign_date != null" class="dropdown-item" (click)="showPaymentDetails(reservation)">Wpłaty</a>

                                <!--
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Wyślij kod aktywacyjny</a>
                                <a class="dropdown-item" href="#">Wyślij link zmiany hasła</a>
                                -->
                            </div>
                        </div>

                        <span *ngIf="itemsBeingProcessed && itemsBeingProcessed.hasOwnProperty(reservation.id)" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </td>
                </tr>
                <tr>
                    <td style="min-height: 300px;">&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Nav Tabs -->
<!--
<mdb-tabset [buttonClass]="'nav md-pills pills-secondary mb-2'" [justified]="true" [contentClass]="'card'">
    <mdb-tab *ngFor="let tab of optionsReservationStatuses" [heading]="tab.label">

    </mdb-tab>
</mdb-tabset>
//-->
