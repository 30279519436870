import {Component, OnDestroy, OnInit, Input } from "@angular/core";
import {Camp} from "../_models";
import * as _ from "underscore";

@Component({
	selector: 'cprncs-single-active-camp-statistics',
	templateUrl: './single-camp-statistics.component.html',
	styleUrls: ['./single-camp-statistics.component.scss']
})
export class SingleCampStatisticsComponent implements OnInit, OnDestroy {
	@Input()
	get camp(): Camp { return this._camp; }
	set camp(newCamp: Camp) {
		let anyStatisticsFound : boolean = false;

		this._camp = newCamp;

		// now update the participants count dataset
		anyStatisticsFound = false;
		this.totalActiveParticipants = 0;
		let newDataSet = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);

		if (newCamp.participants_count_statistics){
			for (let singleCount of newCamp.participants_count_statistics) {
				let datasetIndex = -1;

				switch (singleCount.string_value) {
					case "DRAFT":
						datasetIndex = 0;
						break;

					case "SUBMITTED":
						datasetIndex = 1;
						this.totalActiveParticipants += singleCount.items_count;
						break;

					case "ENROLLED":
						datasetIndex = 2;
						this.totalActiveParticipants += singleCount.items_count;
						break;

					case "WAITING_LIST":
						datasetIndex = 3;
						this.totalActiveParticipants += singleCount.items_count;
						break;

					case "CLIENT_RESIGNED":
						datasetIndex = 4;
						break;

					case "CANCELED":
						datasetIndex = 5;
						break;

					case "CONTRACT_SIGNED":
						datasetIndex = 6;
						this.totalActiveParticipants += singleCount.items_count;
						break;

					case "FINISHED":
						datasetIndex = 7;
						this.totalActiveParticipants += singleCount.items_count;
						break;

					default:
						datasetIndex = -1;
						break;
				}

				if (datasetIndex >= 0){
					newDataSet[datasetIndex] = singleCount.items_count;
					anyStatisticsFound = true;
				}
			}
		}

		if (anyStatisticsFound){
			newDataSet[8] = newCamp.participants_capacity - this.totalActiveParticipants;
			this.participantsCountChartDatasets = [
				{ data: newDataSet, label: 'Liczba uczestników' }
			];
		}
		else {
			this.participantsCountChartDatasets = [
				{ data: [0, 0, 0, 0, 0, 0, 0, 0, newCamp.participants_capacity - this.totalActiveParticipants], label: 'Liczba uczestników' }
			];
		}


		// update payments information
		anyStatisticsFound = false;
		let paymentsDataset = new Array(0, 0, 0);

		this.totalCampValue = Number(newCamp.payment_statistics.total);

		if (this.totalCampValue){
			// there are some sales, so the numbers are non-zero

			paymentsDataset[0] = Number(newCamp.payment_statistics.paid);       // paid
			paymentsDataset[1] = Number(newCamp.payment_statistics.overdue);    // overdue
			paymentsDataset[2] = this.totalCampValue - (paymentsDataset[0] + paymentsDataset[1]); // left
		}
		else {
			// no sales
			paymentsDataset[2] = -1;
		}
		this.paymentsChartDatasets = [
			{ data: paymentsDataset, label: 'Płatności' }
		];


		// update age statistics information
		let newAgeStatisticsVals = [];      // total
		let newFAgeStatisticsVals = [];     // female
		let newMAgeStatisticsVals = [];     // male
		let newOAgeStatisticsVals = [];     // other
		let newAgeStatisticsLabels = [];

		if (newCamp.age_statistics && newCamp.age_statistics.Total.length){
			/*
				Age_statistics object contains 4 arrays having the exact same number of elements (age groups)
				The only difference is the value of items_count in each element of the array,
				but all 4 arrays have the same numerical_value at the same index.

				This is why we can iterate over one array only and assume all other arrays hold the same age
				group (numerical_value) at any given index
			 */
			this.maleParticipantsCount = 0;
			this.femaleParticipantsCount = 0;
			this.otherParticipantsCount = 0;

			for (let i: number = 0; i < newCamp.age_statistics.Total.length; i++){
				newMAgeStatisticsVals.push(newCamp.age_statistics.M[i].items_count);
				this.maleParticipantsCount += newCamp.age_statistics.M[i].items_count;

				newFAgeStatisticsVals.push(newCamp.age_statistics.F[i].items_count);
				this.femaleParticipantsCount += newCamp.age_statistics.F[i].items_count;

				newOAgeStatisticsVals.push(newCamp.age_statistics.O[i].items_count);
				this.otherParticipantsCount += newCamp.age_statistics.O[i].items_count;

				newAgeStatisticsLabels.push(newCamp.age_statistics.Total[i].numerical_value.toString());
			}

			// calculate average participant age
			this.averageParticipantsAge = Math.round(_.reduce<Array<any>, number>(
				newCamp.age_statistics.Total,
				(memo, dataEntry) =>
						memo + dataEntry.numerical_value * dataEntry.items_count,
				0
				) / _.reduce<Array<any>, number>(
				newCamp.age_statistics.Total,
				(memo_1, dataEntry) =>
					(memo_1 + dataEntry.items_count),
				0
			));

		}
		else {
			for (let a = newCamp.age_min; a <= newCamp.age_max; a++){
				newAgeStatisticsVals.push(0);
				newAgeStatisticsLabels.push(a.toString());
			}

			this.averageParticipantsAge = 0;
		}

		this.participantsAgeChartDatasets[0].data = newMAgeStatisticsVals;
		this.participantsAgeChartDatasets[1].data = newFAgeStatisticsVals;
		this.participantsAgeChartDatasets[2].data = newOAgeStatisticsVals;
		this.participantsAgeChartLabels = newAgeStatisticsLabels;
	}

	private _camp : Camp = null;

	public totalActiveParticipants : number = 0;    // number of participants after conversion
	public totalCampValue: number = 0;              // total amount of money from all contracts
	public averageParticipantsAge : number = 0;
	public femaleParticipantsCount: number = null;
	public maleParticipantsCount: number   = null;
	public otherParticipantsCount: number  = null;


	/*
		Participants count chart settings
	 */
	public participantsCountChartType: string = 'doughnut';
	public participantsCountChartColors: Array<any> = [
		{
			backgroundColor: [
				'#d0d0d0', // DRAFT
				'#46e2f7', // SUBMITTED
				'#98d280', // ENROLLED
				'#d9bc54', // WAITING_LIST
				'#bd1313', // CLIENT_RESIGNED
				'#4D5360', // CANCELED
				'#38ad25', // CONTRACT_SIGNED
				'#3b3b3b', // FINISHED
				'#dbdbdb', // UNUSED
			],
			//hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
			borderWidth: 2,
		}
	];

	public participantsCountChartDatasets: Array<any> = [
		{ data: [], label: 'Liczba uczestników' }
	];

	public participantCountChartLabels: Array<any> = [
		'Robocze',
		'Zarejestrowane',
		'Zakwalifikowani',
		'Rezerwowi',
		'Rezygnacje',
		'Anulowane',
		'Podpisane',
		'Zakończone',
		'Niewykorzystane',
	];


	/*
				Payments chart settings
	 */
	public paymentsChartType: string = 'doughnut';
	public paymentsChartColors: Array<any> = [
		{
			backgroundColor: [
				'#51c452', // PAID
				'#cb4b4b', // OVERDUE
				'#dbdbdb', // LEFT
			],
			borderWidth: 2,
		}
	];

	public paymentsChartDatasets: Array<any> = [
		{ data: [], label: 'Płatności' }
	];

	public paymentsChartLabels: Array<any> = [
		'Zapłacono',
		'Przeterminowane',
		'Pozostało',
	];


	/*
		Participants age chart settings
	 */
	public participantsAgeChartType: string = 'bar';
	public participantsAgeChartColors: Array<any> = [
		{
			backgroundColor: [
				'rgba(65,145,203,0.68)'
			],
			//hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
			borderWidth: 2,
		},
		{
			backgroundColor: [
				'rgba(199,12,213,0.55)',
			],
			//hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
			borderWidth: 2,
		},
		{
			backgroundColor: [
				'rgba(37,37,37,0.56)'
			],
			//hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774'],
			borderWidth: 2,
		}
	];

	public participantsAgeChartDatasets: Array<any> = [
		{
			data: [],
			label: 'Chłopcy'
		},
		{
			data: [],
			label: 'Dziewczynki'
		},
		{
			data: [],
			label: 'Pozostali'
		}
	];

	public participantsAgeChartLabels: Array<any> = [];



	public commonDoughnutChartOptions: any = {
		responsive: true,
		cutout: '60%',
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				displayColors: false,
				caretSize: 3,
				bodyFont: {
					weight: 'normal',
					size: 10,
				}
			}
		}
	};

	public commonBarChartOptions: any = {
		responsive: true,
		maxBarThickness: 25,
		scales: {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				displayColors: false,
				caretSize: 3,
				bodyFont: {
					weight: 'normal',
					size: 10,
				}
			}
		}
	};


	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
	}

}