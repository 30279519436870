import gql from "graphql-tag";

export const userLoginQuery = gql`query user_login($email: Email!, $password: String!) {
          user_login(email: $email, password: $password) {
            user {
                id
                firstName
                lastName
                email
                phone_number
                billing_address {
                  id
                }
                mailing_address {
                  id
                }
                is_admin
                is_manager
                is_backoffice_employee
                is_camp_employee
                is_client
             }
            status
            error
            token
          }
        }`;

export const remindUserAccountConfirmationCode = gql`query user_account_remind_confirmation_code($email: Email!) {
	user_account_remind_confirmation_code(email: $email) {
		status
		error
	}
}`;

export const getUserByIdQuery = gql`query user_by_id($id: ID!) {
    user(id: $id) {
        id
        firstName
        lastName
        email
        phone_number
        billing_address {
          id
        }
        mailing_address {
          id
        }
        is_admin
        is_manager
        is_backoffice_employee
        is_camp_employee
        is_client
    }
}`;

export const getAllUsersQuery = gql`
    {
      users {
        id
        firstName
        lastName
        email
        phone_number
        is_confirmed
        is_active
        last_login_timestamp
        billing_address {
          id
        }
        mailing_address {
          id
        }
        is_admin
        is_manager
        is_backoffice_employee
        is_camp_employee
        is_client
      }
    }`;

export const getUserAddressesQuery = gql`query userAddresses($user_id: ID!) {
    user_addresses(user_id: $user_id) {
      id
      user {
        id
      }
      is_business
      name_first
      name_last
      company_name
      street_name
      street_number
      flat_number
      zip_code
      city
      country
      vat_number
      phone_number
    }
  }`;

export const getUserParticipantsQuery = gql`query userParticipants($user_id: ID!) {
    user_participants(user_id: $user_id) {
        id
        user {
          id
        }
        name_first
        name_middle
        name_last
        date_birth
        national_id_number
        gender
        parents_list {
          name_full
          status
          phone_number
          address
        }
        camp_ids
    }
  }`;

export const getAllActiveCampsQuery = gql`{
	active_camps {
		id
	    name
	    description_full
	    description_short
	    date_end
	    date_start
	    image_src
	    location_name
	    url
	    price
	    price_special
	    participants_capacity
	    age_min
	    age_max
	    is_active
	    enrollment_active
	    camp_details_public
	    camp_details_backoffice
	    data_confirmation_required
	}
}`;

export const getAllActiveAndEnrollingCampsQuery = gql`query active_camps_enrollment_active($enrollment_active: Boolean){
  active_camps(enrollment_active: $enrollment_active) {
	id
    name
    description_full
    description_short
    date_end
    date_start
    image_src
    location_name
    url
    price
    price_special
    participants_capacity
    age_min
    age_max
    is_active
    enrollment_active
    data_confirmation_required
  }
}`;

export const getSignupElementsDataQuery = gql`query signupElementsData($user_id: ID!, $enrollment_active: Boolean){
	user_addresses(user_id: $user_id) {
      id
      user {
        id
      }
      is_business
      name_first
      name_last
      company_name
      street_name
      street_number
      flat_number
      zip_code
      city
      country
      vat_number
      phone_number
    }

    user_participants(user_id: $user_id) {
        id
        user {
          id
        }
        name_first
        name_middle
        name_last
        date_birth
        national_id_number
        gender
        parents_list {
          name_full
          status
          phone_number
          address
        }
        camp_ids
    }

    active_camps(enrollment_active: $enrollment_active) {
		id
	    name
	    description_full
	    description_short
	    date_end
	    date_start
	    image_src
	    location_name
	    url
	    price
	    price_special
	    participants_capacity
	    age_min
	    age_max
	    transport_options_to {
	        code
	        short_description
	        long_description
	        price
	    }
	    transport_options_from {
	        code
	        short_description
	        long_description
	        price
	    }
	    insurance_options
	    is_active
	    camp_details_public
	    camp_details_backoffice
	    data_confirmation_required
	}
}`;

export const getUserReservationsQuery = gql`
	query user_reservations($user_id: ID!, $camp_status: Camp_Status!){
		user_reservations(user_id: $user_id, camp_status: $camp_status){
			id
		    status
		    price
		    price_extra_options
		    total_price
		    contract_sign_date
		    created_timestamp
		    participant_id
		    participant_name_first
		    participant_name_middle
		    participant_name_last
		    user_id
		    user_firstName
		    user_lastName
		    camp_id
		    camp_name
		    camp_location_name
		    camp_date_start
		    camp_date_end
		    amount_overdue
		}
	}
`;

export const getAllReservationsQuery = gql`
	query all_reservations($camp_participant_status: Reservation_Status!){
		all_reservations(camp_participant_status: $camp_participant_status){
			id

			participant {
				id
				user {
					id
				}
				name_first
				name_middle
				name_last
				date_birth
			}

			camp {
				id
				name
				location_name
				date_start
				date_end
				price
				is_active
				data_confirmation_required
		    }

		    participant_snapshot

		    clients_mailing_address_snapshot
		    clients_billing_address_snapshot
		    participants_address_snapshot

		    client_name_full
		    promo_codes
		    terms_accepted
		    rodo_accepted
		    data_is_true_statement
		    electronic_invoice_accepted
		    created_timestamp
		    status
		    contract_sign_type
		    contract_sign_date
		    vaccinations_snapshot
		    insurance_snapshot
		    payment_plan_snapshot
		    events_array
		    price
		    total_price
		}
	}
`;

export const getReservationSummaries = gql`
	query reservation_summaries($camp_participant_status: Reservation_Status!){
		  reservation_summaries(camp_participant_status: $camp_participant_status) {
		    id
		    status
		    price
		    price_extra_options
		    total_price
		    contract_sign_date
		    created_timestamp
		    participant_id
		    participant_name_first
		    participant_name_middle
		    participant_name_last
		    client_name_full
		    pending_changes
		    is_data_confirmed
		    unaccepted_attachments
		    user_id
		    user_firstName
		    user_lastName
		    camp_id
		    camp_name
		    camp_location_name
		    camp_date_start
		    camp_date_end
		    camp_data_confirmation_required
		    amount_overdue
		  }
}`;

export const refreshReservationsAttachmentsQuery = gql`
  query refresh_reservations_attachments($camp_participant_id: ID!){
    reservation_review_details(camp_participant_id: $camp_participant_id){
      camp_participant {
        id
        unaccepted_attachments
        attachments {
          id
          key
          file_name
          date_created
          size
          accepted
        }
        events_array
      }
    }
  }
`;

export const getReservationReviewDetailsQuery = gql`
	query reservation_review_details($camp_participant_id: ID!){
       reservation_review_details(camp_participant_id: $camp_participant_id){
         camp_participant {
           id

           participant {
             id
             name_first
             name_middle
             name_last
             date_birth
             national_id_number
             gender
             parents_list {
               name_full
               status
               phone_number
               address
             }
             camp_ids
             user {
               id
               firstName
               lastName
               email
               phone_number
               is_admin
               is_manager
               is_backoffice_employee
               is_camp_employee
               is_client
             }
           }

           camp {
             id
             name
             location_name
             description_short
             description_full
             url
             date_start
             date_end
             age_min
             age_max
             price
             price_special
             participants_capacity
             is_active
             contract_details_data_snapshot
             data_confirmation_required
             transport_options_to {
			        code
			        short_description
			        long_description
			        price
			        means_of_transport
			        dropoff_pickup_information
			    }
			    transport_options_from {
			        code
			        short_description
			        long_description
			        price
			        means_of_transport
			        dropoff_pickup_information
			    }
           }

            insurance_policies {
              id
              insurance_policy {
                  id
                  name
                  description
                  issuing_company_name
              }
              submission_document {
                id
              }
              client_price
            }

            payment_plan_entries {
              id
              date_due
              amount
              amount_left
              events_array
            }

            payments {
              id
              amount
              transaction_timestamp
              is_income
              bank_account
              status
              method
              created_timestamp
              invoice_number
              description
            }

           participant_snapshot
           clients_mailing_address {
             id
           }
           clients_mailing_address_snapshot

           clients_billing_address {
             id
           }
           clients_billing_address_snapshot

           participants_address {
             id
           }
           participants_address_snapshot

           client_name_full

           health_issues {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           dietary_needs {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           special_needs {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           promo_codes {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           general_remarks {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           accommodation {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           authorizations {
             value
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }

           pending_changes
           is_data_confirmed

           terms_accepted
           rodo_accepted
           data_is_true_statement
           electronic_invoice_accepted
           created_timestamp
           status
           contract_sign_type
           contract_sign_date
           vaccinations_snapshot
           selected_transport_options {
            to {
                code
                price
                remarks
                }
            from {
                code
                price
                remarks
                }
             change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }
           insurance_snapshot
           payment_plan_snapshot
           events_array
           price
           price_extra_options
           price_extra_components {
            INSURANCE
            TRANSPORTATION
           }
           totalPaymentsSum
           documents_to_submit {
                document_id
                document_name
                document_required
                document_required_original
                document_submitted_status
                document_submitted_date
                comment
           }
           attachments {
            id
            key
            file_name
            size
            date_created
            accepted
           }
           unaccepted_attachments
         }

         addresses_used {
           id
           user {
             id
           }
           is_business
           name_first
           name_last
           company_name
           street_name
           street_number
           flat_number
           zip_code
           city
           country
           vat_number
           phone_number
         }

         available_insurance_policies {
           id
           camp {
          id
        }
        insurance_policy {
          id
          name
          description
          issuing_company_name
          config_object
          is_active
        }
         }
       }
}`;

export const ReservationDocumentsLinkQuery = gql`
query reservation_documents_link($camp_participant_id: ID!){
	reservation_documents_link(camp_participant_id: $camp_participant_id)
}`;

export const ReservationPaymentsDetailsQuery = gql`
	query reservation_review_details($camp_participant_id: ID!){
	 reservation_review_details(camp_participant_id: $camp_participant_id){
	  camp_participant {
	    id

	    participant{
	      id
	      name_first
	      name_middle
	      name_last
	    }

	    camp {
	      id
	      contract_details_data_snapshot
	      name
	      location_name
	      date_start
	      date_end
	    }

	    payments {
	      id
	      amount
	      transaction_timestamp
	      is_income
	      bank_account
	      status
	      created_timestamp
	      method
	      invoice_number
	      invoice_type
	      description
	    }

	    payment_plan_entries{
	      id
	      date_due
	      amount
	      amount_left
	    }

	    extra_invoices {
	        id
	        created_timestamp
	        provider
	        invoice_number
	        invoice_foreign_id
	        invoice_date_issued
	        invoice_date_due
	        invoice_amount_total
	        invoice_amount_paid
	        invoice_include_in_summary
	        invoice_status
	        invoice_items
	        comment
	    }
	    extra_invoices_total_amount
	    extra_invoices_amount_left

	    contract_sign_date
	    price
	    price_extra_options
	    total_price
	    totalPaymentsSum
	    order_number

	    events_array
	  }
	}
}`;

export const InvoiceDownloadQuery = gql`
query invoice_download($payment_id: ID, $camp_participant_id: ID, $invoice_id: String) {
  invoice_download(payment_id: $payment_id, camp_participant_id: $camp_participant_id, invoice_id: $invoice_id){
    status
    pdf_file
  }
}`;

export const OrderDownloadQuery = gql`
query order_download($camp_participant_id: ID!) {
  order_download(camp_participant_id: $camp_participant_id){
    status
    pdf_file
  }
}`;

export const AllPaymentsQuery = gql`query all_payments{
  all_payments{
    payment_id
    camp_participant_id
    client_id
    camp_id
    payment_created_timestamp
    payment_transaction_timestamp
    payment_is_income
    payment_amount
    payment_status
    payment_method
    payment_invoice_number
    payment_invoice_type
    payment_description
    client_name_full
    participant_name_full
    camp_name
    camp_location_name
    camp_date_start
    camp_date_end
  }
}`;

export const Reservations_insurances_browserQuery = gql`query reservations_insurances_browser($insurance_policy_ids: [ID]!, $camp_ids: [ID]!) {
  reservations_insurances_browser(insurance_policy_ids: $insurance_policy_ids, camp_ids: $camp_ids){
    id
    name
    problem_flag
    camp_participants {
      camp_participant_id
      participant_id
      insurance_snapshot
      name_first
      name_middle
      name_last
      problem_flag
      insurance_policies {
        campparticipant_campinsurance_id
        campparticipant_campinsurance_insurancesubmission_id
        camp_insurancepolicy_id
        insurancepolicy_id
        insurancepolicy_name
      }
    }
  }
}`;

export const Insurance_submission_downloadQuery = gql`query insurance_submission_download ($insurancesubmission_id: ID!) {
  insurance_submission_download (insurancesubmission_id: $insurancesubmission_id){
    status
    file
  }
}`;

export const AllInsurancesubmissionsQuery = gql`query all_insurancesubmissions {
  all_insurancesubmissions {
    insurancesubmissions {
      id
      insurance_policy {
        id
        name
        description
        issuing_company_name
      }
      created_timestamp
      events_array
    }
  }
}`;

export const Insurance_submission_generator_configQuery = gql`query insurance_submission_generator_config {
  insurance_submission_generator_config  {
    insurancepolicy_id
    insurancepolicy_name
    insurancepolicy_issuing_company_name
    accepted_submission_types
    camps {
      camp_id
      camp_name
      camp_date_start
      camp_date_end
    }
  }
}`;

export const Active_camps_statisticsQuery = gql`query active_camps_statistics{
	active_camps{
		id
		name
		location_name
		date_start
		date_end
		age_min
		age_max
		age_statistics {
			F {
				numerical_value
				items_count
			}
			M {
				numerical_value
				items_count
			}
			O {
				numerical_value
				items_count
			}
			Total {
				numerical_value
				items_count
			}
		}
		price
		average_price_statistics
		payment_statistics {
			paid
			overdue
			upcoming
			total
		}
		price_special
		participants_capacity
		participants_count_statistics{
			string_value
			items_count
		}
		enrollment_active
	}
}`;

export const Invoice_lookupQuery = gql`query invoice_lookup($invoice_foreign_id: String!, $provider: String!){
	invoice_lookup(invoice_foreign_id: $invoice_foreign_id, provider: $provider) {
		status
		provider
		invoice_number
		invoice_foreign_id
		invoice_date_issued
		invoice_date_due
		invoice_amount_total
		invoice_amount_paid
		client_summary
		items_summary
	}
}`;

export const Report_maturalni_participants = gql`query maturalni_camps_download{
  maturalni_camps_download {
    status
    report_file_contents
  }
}`;

export const Report_all_active_participants = gql`query active_camps_participants_download{
  active_camps_participants_download {
    status
    report_file_contents
  }
}`;

export const Report_issued_invoices = gql`query issued_invoices_report_download{
  issued_invoices_report_download {
    status
    report_file_contents
  }
}`;

export const Generate_presigned_post_attributes_for_reservation_attachment_upload = gql`
  query generate_presigned_post_attributes_for_reservation_attachment_upload($camp_participant_id: ID!, $file_name: String!)
  {
    generate_presigned_post_attributes_for_reservation_attachment_upload(camp_participant_id: $camp_participant_id, file_name: $file_name)
    {
      status
      formInputs
      formAttributes
    }
  }`

export const Generate_presigned_url_for_reservations_attachment_download = gql`
  query generate_presigned_url_for_reservations_attachment_download ($camp_participant_id: ID!, $key: String!)
  {
    generate_presigned_url_for_reservations_attachment_download(camp_participant_id: $camp_participant_id, key: $key)
    {
      status
      url
    }
  }`;


