export enum JSONHandlerStatuses {
  ERROR_GENERIC             = 'GENERIC ERROR',
  ERROR_EMAIL_EXISTS        = 'EMAIL_EXISTS',
  ERROR_INVALID_CONFIRMATION_CODE = 'INVALID_CONFIRMATION_CODE',
  ERROR_INVALID_EMAIL       = 'INVALID_EMAIL',
  CONFIRMED                 = 'CONFIRMED',
  ERROR_EMAIL_DOESNT_EXIST  = 'ERROR_EMAIL_DOESNT_EXIST',
  ERROR_ACCOUNT_NOT_ACTIVE  = 'ERROR_ACCOUNT_NOT_ACTIVE',
  ERROR_WRONG_PASSWORD      = 'ERROR_WRONG_PASSWORD',
  ERROR_CODE_INVALID        = 'ERROR_CODE_INVALID',
}
