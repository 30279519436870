import { Apollo } from "apollo-angular";
import {
  addOrEditExtraInvoiceMutation,
  autoUpdateExtraInvoiceMutation,
  cancelCampReservationMutation,
  changeReservationAddressMutation,
  changeReservationStatusMutation,
  clientSignContractOnlineMutation, Delete_reservation_attachmentMutation,
  deleteExtraInvoiceMutation,
  deleteReservationsDescriptiveFieldChangeRequestMutation,
  editReservationInsurancePoliciesMutation,
  editReservationPriceMutation,
  editReservationsDescriptiveFieldMutation,
  editReservationSelectedTransportOptions,
  issueChangeRequestMutation,
  issueOrderForReservationMutation,
  regenerateReservationContractDocumentsMutation,
  regenerateReservationPaymentPlanMutation,
  rollbackReservationStatusToSubmittedMutation,
  signUpParticipantToCampMutation,
  Toggle_reservation_attachment_acceptanceMutation,
  updateReservationIsDataConfirmedMutation,
  updateReservationsDocumentsToSubmitFromCampConfigMutation,
  updateReservationsDocumentsToSubmitMutation,
  updateReservationsParticipantInfoRequestMutation
} from "../mutations";
import { Observable, Subject } from "rxjs";
import { NetworkOperationResult } from "../../types";
import * as _ from "underscore";
import {
  getUserReservationsQuery, Invoice_lookupQuery, InvoiceDownloadQuery,
  OrderDownloadQuery,
} from "../queries";
import {
	Camp_Participant,
	Camp_Participant_Status,
	Contract_Signature_Type
} from "../../_models/camp_participant";
import {DocumentToSubmitInputType, IdPricePairInputType} from "../inputs";
import {
  addOrEditExtraInvoiceMutationResult,
  autoUpdateExtraInvoiceMutationResult,
  changeReservationAddressMutationResult,
  ChangeReservationStatusResult,
  ClientSignContractOnlineResult, Delete_reservation_attachmentMutationResult,
  deleteExtraInvoiceMutationResult,
  deleteReservationsDescriptiveFieldChangeRequestMutationResult,
  EditReservationInsurancePoliciesResult,
  EditReservationPriceResult,
  editReservationsDescriptiveFieldMutationResult,
  editReservationSelectedTransportOptionsMutationResult,
  Invoice_lookupQueryResult,
  InvoiceDownloadResult,
  issueOrderForReservationResult,
  issueReservationDescriptiveFieldChangeRequestMutationResult,
  OrderDownloadResult,
  RegenerateReservationContractDocumentsResult,
  regenerateReservationPaymentPlanMutationResult,
  rollbackReservationStatusToSubmittedMutationResult, Toggle_reservation_attachment_acceptanceResult,
  UpdateReservationIsDataConfirmedResult,
  updateReservationsDocumentsToSubmitFromCampConfigResult,
  updateReservationsDocumentsToSubmitMutationResult,
  updateReservationsParticipantInfoRequestMutationResult
} from "../results";
import b64toblob from "b64-to-blob";
import {saveAs as importedSaveAs} from "file-saver";
import {
  AddressType,
  Extra_Invoice_Status_Type,
  Parent,
  Participant,
  Participant_EmptyEntity,
  User
} from "../../_models";
import {Injectable} from "@angular/core";
import {ReservationsTransportOptions} from "../../_models/reservationsTransportOptions";

@Injectable({providedIn: "root"})
export class Camp_participantDataManipulations {
	constructor(private apollo: Apollo) {
	}

	humanReadableStatus(status: Camp_Participant_Status){
		switch (status) {
			case "DRAFT":
				return 'wersja robocza';
				break;

			case "SUBMITTED":
				return 'zarejestrowano zgłoszenie';
				break;

			case "ENROLLED":
				return 'uczestnik zakwalifikowany';
				break;

			case "WAITING_LIST":
				return 'miejsce na liście rezerwowej';
				break;

			case "CLIENT_RESIGNED":
				return 'rezygnacja Klienta';
				break;

			case "CANCELED":
				return 'anulowano';
				break;

			case "FINISHED":
				return 'umowa zakończona i rozliczona';
				break;

			case "CONTRACT_SIGNED":
				return 'umowa podpisana i w trakcie realizacji';
				break;

			default:
				return 'błędny status - prosimy poinformować obsługę AlphaCamp';
				break;
		}
	}

	humanReadableContractSignType(contractSignType: Contract_Signature_Type): string {
		switch (contractSignType) {
			case "IN_APP":
				return "w aplikacji on-line";

			case "IN_PERSON":
				return "osobiście";

			case "MAIL":
				return "umowa przesłana pocztą";

			case "PAYMENT":
				return "wpłata pierwszej raty";

			case "SCAN":
				return "przesłanie skanu podpisanej umowy";
		}
	}

	signUpParticipantToCamp(inputData: any) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate({
			mutation: signUpParticipantToCampMutation,
			variables: {
				...inputData
			}
			}).subscribe((data) => {
				if (data.data.hasOwnProperty('errors')){
					retVal.next({
						status: "ERROR",
						message: "Something went wrong during participant sign up. Please try again.",
						data: data.errors
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Participant signed up successfully",
						data: data.data
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Network or GraphQL error while signing participant up. Please try again."
				});
				retVal.complete();
				localSub.unsubscribe();
			}
			);

		return retVal.asObservable();
	}

	cancelReservation(camp_participant_id: number, camp_id: number, /* participant: Participant,*/ currentUserId: number, currentCampStatus: string): Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		/*
		const optimisticParticipantResponse = {
			__typename: 'Participant',
			id: participant.id,
			camp_ids: _.without(participant.camp_ids, camp_id)
		};
		*/


		let localSub = this.apollo.mutate({
			mutation: cancelCampReservationMutation,
			variables: {
				camp_participant_id
			},
			/*
			Quit using optimistic response in this case, so that user can witness the progress of canceling operation
			optimisticResponse: {
				__typename: 'Mutation',
				cancelCampReservation: {
					__typename: 'cancelCampReservationResult',
					deletedID: camp_participant_id,
					updatedParticipant: optimisticParticipantResponse,
				}
			},
			 */
			// @ts-ignore
			update: (proxy, { data: { cancelCampReservation }}) => {
				// Read the data from our cache for this query.
				let data = proxy.readQuery({
					query: getUserReservationsQuery,
					variables: {
						camp_status: currentCampStatus,
						user_id: currentUserId,
					}
				});

				// Remove reservation from the collection
				let newReservations = _.filter(data['user_reservations'],
					(item: Camp_Participant) => {
						return item.id !== cancelCampReservation.deletedID;
					});

				data['user_reservations'] = newReservations;

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserReservationsQuery, variables: { user_id: currentUserId, camp_status: currentCampStatus }, data });
			}
		}).subscribe((data) => {
				if (data.data.hasOwnProperty('errors')){
					retVal.next({
						status: "ERROR",
						message: "Something went wrong during canceling the reservation. Please try again.",
						data: data.errors
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Reservation was canceled successfully",
						data: data.data
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
		},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Network or GraphQL error while cancelling reservation. Please try again."
				});
				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	/**
	 * Assigns or deletes insurance policies assigned to given reservation
	 *
	 * @param camp_participant_id
	 * @param policies_to_assign
	 * @param assign_comment
	 * @param policies_to_delete
	 * @param delete_comment
	 */
	editReservationInsurancePolicies(
		camp_participant_id: number,
		policies_to_assign: IdPricePairInputType[],
		assign_comment: string,
		policies_to_delete: number[],
		delete_comment: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<EditReservationInsurancePoliciesResult>({
			mutation: editReservationInsurancePoliciesMutation,
			variables: {
				camp_participant_id,
				policies_to_assign,
				assign_comment,
				policies_to_delete,
				delete_comment,
			}
		}).subscribe((data) => {
				if (!data.data.hasOwnProperty('id')){
					retVal.next({
						status: "ERROR",
						message: "Something went wrong during editing reservation's policies. Please try again.",
						data: data.errors
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Reservation's insurance policies updated successfully",
						data: data.data
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Network or GraphQL error while editing reservation's policies. Please try again."
				});
				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	editReservationSelectedTransportOptions(
		camp_participant_id: number,
		updated_transport_options: ReservationsTransportOptions): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<editReservationSelectedTransportOptionsMutationResult>({
			mutation: editReservationSelectedTransportOptions,
			variables: {
				camp_participant_id,
				updated_transport_options
			}
		}).subscribe(({data}) => {
				if (data.editReservationSelectedTransportOptions.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: "Something went wrong during editing reservation's transport options. Please try again.",
						data: data.editReservationSelectedTransportOptions.status
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Reservation's transport options updated successfully",
						data: data.editReservationSelectedTransportOptions.status
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Network or GraphQL error while editing reservation's transport options. Please try again."
				});
				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	editReservationPrice(
		camp_participant_id: number,
		new_price: string,
		comment: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<EditReservationPriceResult>({
			mutation: editReservationPriceMutation,
			variables: {
				camp_participant_id,
				new_price,
				comment,
			}
		}).subscribe(({data}) => {
				if (data.editReservationPrice.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.editReservationPrice.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wykonano zmianę ceny.",
					})
				}

				retVal.complete();
				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	changeStatus(
		camp_participant_id: number,
		new_status: Camp_Participant_Status,
		comment: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<ChangeReservationStatusResult>({
			mutation: changeReservationStatusMutation,
			variables: {
				camp_participant_id,
				new_status,
				comment,
			}
		}).subscribe( ( {data}) => {
			if (data.changeReservationStatus.status.startsWith('Error: ')){
				retVal.next({
					status: "ERROR",
					message: data.changeReservationStatus.status.replace('Error: ', ''),
				});
			}
			else {
				retVal.next({
					status: "SUCCESS",
					message: "Pomyślnie wykonano zmianę statusu rezerwacji.",
				})
			}

			retVal.complete();
			localSub.unsubscribe();
		},
			error => {
			retVal.next({
				status: "ERROR",
				message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
			});

			retVal.complete();
			localSub.unsubscribe();
		});

		return retVal.asObservable();
	}

	public updateReservationIsDataConfirmed(
		camp_participant_id: number,
		is_data_confirmed: boolean
	){
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<UpdateReservationIsDataConfirmedResult>({
			mutation: updateReservationIsDataConfirmedMutation,
			variables: {
				camp_participant_id,
				is_data_confirmed,
			}
		}).subscribe(({data}) => {
			if (data.updateReservationIsDataConfirmed.status.startsWith('Error: ')){
				retVal.next({
					status: "ERROR",
					message: data.updateReservationIsDataConfirmed.status.replace('Error: ', ''),
				});
			}
			else {
				retVal.next({
					status: "SUCCESS",
					message: "Potwierdzono nowy status formularza.",
				})
			}

			retVal.complete();
			localSub.unsubscribe();
		},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	public regenerateContractDocuments(
		camp_participant_id: number,
		comment: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<RegenerateReservationContractDocumentsResult>({
			mutation: regenerateReservationContractDocumentsMutation,
			variables: {
				camp_participant_id,
				comment,
			}
		}).subscribe( ( {data}) => {
				if (data.regenerateReservationContractDocuments.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.regenerateReservationContractDocuments.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zakończono generowanie nowych dokumentów dla rezerwacji.",
					})
				}

				retVal.complete();
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	clientSignContractOnline(
		camp_participant_id: number,
		confirmation_code: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<ClientSignContractOnlineResult>({
			mutation: clientSignContractOnlineMutation,
			variables: {
				camp_participant_id,
				confirmation_code,
			}
		}).subscribe( ( {data}) => {
				if (data.clientSignContractOnline.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: "Podczas podpisywania umowy wystąpiły nieoczekiwane błędy. Spórbuj jeszcze raz.",
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Gratulacje! Umowa została podpisana!",
					})
				}

				retVal.complete();
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	rollbackReservationStatusToSubmitted(
		camp_participant_id: number,
		comment: string): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<rollbackReservationStatusToSubmittedMutationResult>({
			mutation: rollbackReservationStatusToSubmittedMutation,
			variables: {
				camp_participant_id,
				comment,
			}
		}).subscribe( ( {data}) => {
				if (data.rollbackReservationStatusToSubmitted.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: "<p>Podczas zmiany statusu wystąpiły nieoczekiwane błędy.</p><p><strong>" +
						data.rollbackReservationStatusToSubmitted.status.replace('Error: ', '') + "</strong></p>",
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Gratulacje! Status umowy został zmieniony!",
					})
				}

				retVal.complete();
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	issueOrderForReservation(camp_participant_id: number) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<issueOrderForReservationResult>({
			mutation: issueOrderForReservationMutation,
			variables: {
				camp_participant_id
			}
		}).subscribe(({data}) => {
				if (data.issueOrderForReservation.status.startsWith('Error')){
					console.error(data.issueOrderForReservation.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas wystawiania zamówienia w zewnętrznej aplikacji Fakturownia.pl!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wystawiono zamówienie!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas wystawiania zamówienia w zewnętrznej aplikacji Fakturownia.pl!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	downloadOrderForPayment(camp_participant_id: number) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<OrderDownloadResult>({
			mutation: OrderDownloadQuery,
			variables: {
				camp_participant_id
			}
		}).subscribe(({data}) => {
				if (data.order_download.status.startsWith('Error')){
					console.error(data.order_download.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas pobierania dokumentu zamówienia z zewnętrznej aplikacji Fakturownia.pl!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie pobrano dokument zamówienia!"
					});
					retVal.complete();

					// @ts-ignore
					let blob = b64toblob(data.order_download.pdf_file, "application/pdf");
					importedSaveAs(blob, 'Zamówienie AlphaCamp.pdf');
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas pobierania zamówienia z zewnętrznej aplikacji Fakturownia.pl!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	/*
		OBSOLETE
	toggleConfirmDocumentsReceived(camp_participant_id: number, document_type: Document_Type, comment: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<toggleConfirmDocumentsReceivedResult>({
			mutation: toggleConfirmDocumentsReceived,
			variables: {
				camp_participant_id,
				document_type,
				comment
			}
		}).subscribe(({data}) => {
				if (data.toggleConfirmDocumentsReceived.status.startsWith('Error')){
					console.error(data.toggleConfirmDocumentsReceived.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas potwierdzania otrzymania dokumentów!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zaktualizowano potwierdzenie otrzymania dokumentów!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas potwierdzania otrzymania dokumentów!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}
	 */

	regeneratePaymentPlan(camp_participant_id: number, update_payment_plan_snapshot: boolean, comment: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<regenerateReservationPaymentPlanMutationResult>({
			mutation: regenerateReservationPaymentPlanMutation,
			variables: {
				camp_participant_id,
				update_payment_plan_snapshot,
				comment
			}
		}).subscribe(({data}) => {
				if (data.regenerateReservationPaymentPlan.status.startsWith('Error')){
					console.error(data.regenerateReservationPaymentPlan.status);

					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas aktualizacji harmonogramu płatności!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zaktualizowano harmonogram płatności!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas aktualizacji harmonogramu płatności!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}


	updateReservationsDocumentsToSubmitFromCampConfig(
		camp_participant_id: number): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<updateReservationsDocumentsToSubmitFromCampConfigResult>({
			mutation: updateReservationsDocumentsToSubmitFromCampConfigMutation,
			variables: {
				camp_participant_id
			}
		}).subscribe(({data}) => {
				if (data.updateReservationsDocumentsToSubmitFromCampConfig.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.updateReservationsDocumentsToSubmitFromCampConfig.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zaktualizowano listę dokumentów.",
					})
				}

				retVal.complete();

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	updateReservationsDocumentsToSubmit(
		documents_to_submit: DocumentToSubmitInputType[],
		camp_participant_id: number): Observable<NetworkOperationResult>{

		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<updateReservationsDocumentsToSubmitMutationResult>({
			mutation: updateReservationsDocumentsToSubmitMutation,
			variables: {
				documents_to_submit,
				camp_participant_id
			}
		}).subscribe(({data}) => {
				if (data.updateReservationsDocumentsToSubmit.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.updateReservationsDocumentsToSubmit.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zaktualizowano listę nadesłanych dokumentów.",
					})
				}

				retVal.complete();

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			});

		return retVal.asObservable();
	}

	extraInvoiceLookup(invoice_foreign_id: string, provider: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.watchQuery<Invoice_lookupQueryResult>({
			query: Invoice_lookupQuery,
			fetchPolicy: "network-only",
			variables: {
				invoice_foreign_id,
				provider
			}
		}).valueChanges
			.subscribe(({data, loading}) => {
				if (data.invoice_lookup.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.invoice_lookup.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Dane faktury wystawionej w zewnętrznym systemie zostały pomyślnie pobrane.",
						data: data.invoice_lookup
					})
				}

				retVal.complete();

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	addOrEditExtraInvoice(camp_participant_id: number,
	                      provider: string,
	                      invoice_foreign_id: string,
	                      invoice_status: Extra_Invoice_Status_Type,
	                      invoice_include_in_summary: boolean,
	                      comment: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<addOrEditExtraInvoiceMutationResult>({
			mutation: addOrEditExtraInvoiceMutation,
			variables: {
				camp_participant_id,
				provider,
				invoice_foreign_id,
				invoice_status,
				invoice_include_in_summary,
				comment
			}
		}).subscribe(({data}) => {
					if (data.addOrEditExtraInvoice.status.startsWith('Error: ')){
						retVal.next({
							status: "ERROR",
							message: data.addOrEditExtraInvoice.status.replace('Error: ', ''),
						});
					}
					else {
						console.log(data.addOrEditExtraInvoice.camp_participant);

						retVal.next({
							status: "SUCCESS",
							message: "Zaktualizowano informację o fakturze zewnętrznej.",
							data: data.addOrEditExtraInvoice
						})
					}

					retVal.complete();

					// don't forget to unsubscribe
					localSub.unsubscribe();
				},
				error => {
					retVal.next({
						status: "ERROR",
						message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
					});

					retVal.complete();
					localSub.unsubscribe();
				}
			);

		return retVal.asObservable();
	}

	autoUpdateExtraInvoice(camp_participant_id: number,
	                      invoice_id: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<autoUpdateExtraInvoiceMutationResult>({
			mutation: autoUpdateExtraInvoiceMutation,
			variables: {
				camp_participant_id,
				invoice_id
			}
		}).subscribe(({data}) => {
				if (data.autoUpdateExtraInvoice.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.autoUpdateExtraInvoice.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Zaktualizowano informację o fakturze zewnętrznej.",
						data: data.autoUpdateExtraInvoice
					})
				}

				retVal.complete();

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	deleteExtraInvoice(camp_participant_id: number,
	                       invoice_id: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<deleteExtraInvoiceMutationResult>({
			mutation: deleteExtraInvoiceMutation,
			variables: {
				camp_participant_id,
				invoice_id
			}
		}).subscribe(({data}) => {
				if (data.deleteExtraInvoice.status.startsWith('Error: ')){
					retVal.next({
						status: "ERROR",
						message: data.deleteExtraInvoice.status.replace('Error: ', ''),
					});
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Usunięto fakturę zewnętrzną z rezerwacji.",
						data: data.deleteExtraInvoice
					})
				}

				retVal.complete();

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Błąd sieci lub serwera. Spróbuj ponownie lub skontaktuj się z administratorem."
				});

				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	downloadExtraInvoice(reservation: Camp_Participant, invoice_id: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<InvoiceDownloadResult>({
			mutation: InvoiceDownloadQuery,
			variables: {
				camp_participant_id: reservation.id,
				invoice_id
			}
		}).subscribe(({data}) => {
				if (data.invoice_download.status.startsWith('Error')){
					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas pobierania faktury w zewnętrznej aplikacji!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie pobrano fakturę!"
					});
					retVal.complete();

					// @ts-ignore
					let blob = b64toblob(data.invoice_download.pdf_file, "application/pdf");
					importedSaveAs(blob, 'AlphaCamp - faktura za dodatkowe usługi.pdf');
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas pobierania faktury w zewnętrznej aplikacji!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	issueChangeRequest(reservation: Camp_Participant, field_name: string, requested_changes: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<issueReservationDescriptiveFieldChangeRequestMutationResult>({
			mutation: issueChangeRequestMutation,
			variables: {
				camp_participant_id: reservation.id,
				field_name,
				requested_changes
			}
		}).subscribe(({data}) => {
				if (data.issue_change_request_to_reservations_descriptive_field.status.startsWith('Error')){
					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas zgłaszania zmian w rezerwacji!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie zgłoszono zmiany!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas zgłaszania zmian w rezerwacji!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	editDescriptiveField(reservation: Camp_Participant, field_name: string, new_value: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<editReservationsDescriptiveFieldMutationResult>({
			mutation: editReservationsDescriptiveFieldMutation,
			variables: {
				camp_participant_id: reservation.id,
				field_name,
				new_value
			}
		}).subscribe(({data}) => {
				if (data.edit_reservations_descriptive_field.status.startsWith('Error')){
					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas edycji danych rezerwacji!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wprowadzono zmiany!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas wprowadzania zmian w rezerwacji!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	deleteReservationsDescriptiveFieldChangeRequest(reservation: Camp_Participant, field_name: string, request_id: string) : Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

		let localSub = this.apollo.mutate<deleteReservationsDescriptiveFieldChangeRequestMutationResult>({
			mutation: deleteReservationsDescriptiveFieldChangeRequestMutation,
			variables: {
				camp_participant_id: reservation.id,
				field_name,
				request_id
			}
		}).subscribe(({data}) => {
				if (data.delete_reservations_descriptive_field_change_request.status.startsWith('Error')){
					retVal.next({
						status: "ERROR",
						message: "Coś poszło nie tak podczas usuwania zgłoszenia zmiany danych!",
					});
					retVal.complete();
				}
				else {
					retVal.next({
						status: "SUCCESS",
						message: "Pomyślnie wprowadzono zmiany!"
					});
					retVal.complete();
				}

				// don't forget to unsubscribe
				localSub.unsubscribe();
			},
			error => {
				retVal.next({
					status: "ERROR",
					message: "Coś poszło nie tak podczas usuwania zgłoszenia zmiany danych!"
				});
				retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

  updateReservationsParticipantInfoRequest(reservation: Camp_Participant) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.mutate<updateReservationsParticipantInfoRequestMutationResult>({
      mutation: updateReservationsParticipantInfoRequestMutation,
      variables: {
        camp_participant_id: reservation.id,
      }
    }).subscribe(({data}) => {
        if (data.update_reservations_participant_info_request.status.startsWith('Error')){
          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas aktualizacji danych uczestnika!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie wprowadzono zmianę danych uczestnika!"
          });
          retVal.complete();
        }

        // don't forget to unsubscribe
        localSub.unsubscribe();
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas zmiany danych! Spróbuj ponownie lub skontaktuj się z nami."
        });
        retVal.complete();
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
  }

  changeReservationAddress(reservation: Camp_Participant, address_type: AddressType, new_address_id: number) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.mutate<changeReservationAddressMutationResult>({
      mutation: changeReservationAddressMutation,
      variables: {
        camp_participant_id: reservation.id,
        address_type: address_type,
        new_address_id: new_address_id
      }
    }).subscribe(({data}) => {
        if (data.changeReservationAddress.status.startsWith('Error')){
          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas zmiany adresu w rezerwacji!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie wprowadzono zmianę danych adresowych!"
          });
          retVal.complete();
        }

        // don't forget to unsubscribe
        localSub.unsubscribe();
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas zmiany danych! Spróbuj ponownie lub skontaktuj się z nami."
        });
        retVal.complete();
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
  }

  // This method is used to toggle 'accepted' flag of an attachment with given key
  public toggleAttachmentAcceptance(reservationId: number, attachment_key: string) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.mutate<Toggle_reservation_attachment_acceptanceResult>({
      mutation: Toggle_reservation_attachment_acceptanceMutation,
      variables: {
        camp_participant_id: reservationId,
        key: attachment_key
      }
    }).subscribe(({data}) => {
        if (data.toggle_reservation_attachment_acceptance.status.startsWith('Error')){
          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas zmiany statusu załącznika!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "OK",
            message: "Pomyślnie zmieniono status załącznika!"
          });
          retVal.complete();
        }

        // don't forget to unsubscribe
        localSub.unsubscribe();
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas zmiany statusu załącznika! Spróbuj ponownie lub skontaktuj się z nami."
        });
        retVal.complete();
        localSub.unsubscribe();
      },
      () => {
        localSub.unsubscribe();
        localSub = null;
      }
    );

    return retVal.asObservable();
  }

  // method to delete attachment with given key from reservation
  public deleteAttachment(reservationId: number, attachment_key: string, comment?: string) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let localSub = this.apollo.mutate<Delete_reservation_attachmentMutationResult>({
      mutation: Delete_reservation_attachmentMutation,
      variables: {
        camp_participant_id: reservationId,
        key: attachment_key,
        comment
      }
    }).subscribe(({data}) => {
        if (data.delete_reservation_attachment.status.startsWith('Error')){
          retVal.next({
            status: "ERROR",
            message: "Coś poszło nie tak podczas usuwania załącznika!",
          });
          retVal.complete();
        }
        else {
          retVal.next({
            status: "OK",
            message: "Pomyślnie usunięto załącznik!"
          });
          retVal.complete();
        }

        // don't forget to unsubscribe
        localSub.unsubscribe();
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Coś poszło nie tak podczas usuwania załącznika! Spróbuj ponownie lub skontaktuj się z nami."
        });
        retVal.complete();
        localSub.unsubscribe();
      },
      () => {
        localSub.unsubscribe();
        localSub = null;
      }
    );

    return retVal.asObservable();
  }
}
