import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Observable, of, Subscription} from "rxjs";
import {Address, AddressType, Camp, FileAttachment, Participant, User} from "../_models";
import {Camp_Participant, Camp_Participant_Status, Contract_Signature_Type} from "../_models/camp_participant";
import {ButtonPressedType, ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";
import {
  AddressDataManipulations,
  Camp_participantDataManipulations,
  MailingDataManipulations
} from "../graphQL/data-manipulation";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {MDBModalService} from "ng-uikit-pro-standard";
import {switchMap} from "rxjs/operators";
import {ReservationDocumentsLinkResult, ReservationReviewDetailsQueryResult} from "../graphQL/results";
import {getReservationReviewDetailsQuery, ReservationDocumentsLinkQuery} from "../graphQL/queries";
import {Camp_InsurancePolicy} from "../_models/camp_insurancepolicy";
import {Vaccinations} from "../_models/vaccinations";
import {EntityEvent} from "../_models/entityevent";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {IdPricePairInputType} from "../graphQL/inputs";
import * as moment from "moment";
import {all, create} from 'mathjs';
import * as currency from "currency.js";
import {Payment_plan_entry_snapshot} from "../_models/payment_plan_entry_snapshot";
import {HttpClient} from "@angular/common/http";
import {saveAs as importedSaveAs} from "file-saver";
import {DocumentToSubmit} from "../_models/documenttosubmit";
import {DocumentsToSubmitFormViewStyleType} from "../documents-to-submit-form/documents-to-submit-form.component";
import {ModalTextEditComponent} from "../modal-text-edit";
import {TransportOption} from "../_models/transportoption";
import {
  ReservationsTransportOptionsEditorFormComponent
} from "../reservations-transport-options-editor-form/reservations-transport-options-editor-form.component";
import {ReservationsTransportOptions} from "../_models/reservationsTransportOptions";
import {InsurancePolicy} from "../_models/insurancepolicy";
import {S3FileManipulations} from "../graphQL/data-manipulation/s3-file-manipulations";



@Component({
	templateUrl: './reservation-review.component.html',
	styleUrls: ['./reservation-review.component.scss']
})
export class ReservationReviewComponent implements OnInit, OnDestroy {

	@ViewChild(ReservationsTransportOptionsEditorFormComponent, {static: false})	transportOptionsEditor!: ReservationsTransportOptionsEditorFormComponent;

	objectKeys = Object.keys;   //a little helper :)

	private loggedInUserSubscription: Subscription;
	private reservationSubscription: Subscription;

	private tmrChangeRequestTimeoutID: number = null;
	public  clickedChangeRequestOpacity: number = 1;
	public  clickedChangeRequestID: string = null;
	public  changeRequestBeingDeletedID: string = null;

	public currentUser: User;

	private genericModalHandler: ModalGenericHandler;
	private mailingDataManipulator: MailingDataManipulations;

	public loading: boolean;
	public itemsBeingProcessed: any;
	public networkOperationInProgress: boolean;

	private reservationID: number;

	private reservationStatus: string;

	// members of query response object
	public reservation: Camp_Participant;
	public addresses: Address[];
	public availableInsurancePolicies: Camp_InsurancePolicy[];

	public client_mailing_address_snapshot: Address;   // JSON serialized address selected on registration form
	public client_billing_address_snapshot: Address;   // JSON serialized address selected on registration form
	public participant_address_snapshot: Address;      // JSON serialized address selected on registration form
	public participant_snapshot: Participant;          // JSON serialized participant info selected on registration form
	public participant_vaccinations: Vaccinations;     // JSON serialized object containing information about particiant's vaccinations
	public transport_options: any;                     // JSON serialized transport option selected on registration form
	public insurance_options: any;                     // JSON serialized insurance options selected on registration form
														// - these are NOT references to insurance entities in database!
	public payment_plan_snapshot_entries: Payment_plan_entry_snapshot[];
	protected insurance_hash: any;                        // hash containing IDs of insurance policies user already has
	public events: EntityEvent[];                      // array of deserialized Entity Event objects associated with this reservation
  public sortedAttachments: FileAttachment[] = [];

	public contract_details_data_snapshot: any;        //JSON serialized object containing some of camps configuration variables

	public form_regenerate_documents: FormGroup;       // for writing comment when regenerating contract documents
	public form_confirm_documents_received: FormGroup; // for commenting on receiving documents such as qualification card or vaccinations
	public form_insurances: FormGroup;                 // for choosing new policies to assign to reservation
	public form_assigned_insurances: FormGroup;        // for editing (removin) insurances already assigned to reservation
	public form_change_price: FormGroup;               // for editing client price for the reservation
	public form_change_status: FormGroup;              // for commenting status changes
	public form_client_sign_contract: FormGroup;       // for signing contract by the client

	public documents_to_submit_edit_form_visible: boolean = false;
	public documents_to_submit_edit_form_style : DocumentsToSubmitFormViewStyleType = 'RESERVATION_SHORT';

	public showTransportOptionsEditorForm: boolean = false;

	private math;
	private dataConfirmationMessageBoxResult: Observable<ButtonPressedType> = null;

	public data_confirmation_buttons_states: Object = {
		section_1: "0",
		section_2: "0",
		section_3: "0",
		section_4: "0",
		section_5: "0",
		section_6: "0",
		section_7: "0"
	}


  constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apollo: Apollo,
		private authService: AuthenticationService,
		private modalService: MDBModalService,
		private _formBuilder: FormBuilder,
		private http: HttpClient,
		private reservationsDataManipulator: Camp_participantDataManipulations,
    private addressDataManipulator: AddressDataManipulations,
    private s3FileManipulations: S3FileManipulations,
	) {
		this.genericModalHandler = new ModalGenericHandler();
		this.loading = false;
		this.networkOperationInProgress = false;

		this.mailingDataManipulator      = new MailingDataManipulations(apollo);
		this.itemsBeingProcessed = {};

		this.reservationID = null;

		this.reservation = null;
		this.addresses = [];
		this.availableInsurancePolicies = [];

		this.client_mailing_address_snapshot = null;
		this.client_billing_address_snapshot = null;
		this.participant_address_snapshot    = null;
		this.participant_snapshot            = null;
		this.participant_vaccinations        = null;
		this.transport_options               = null;
		this.insurance_options               = null;
		this.insurance_hash                  = {};
		this.payment_plan_snapshot_entries   = null;
		this.events                          = null;
		this.contract_details_data_snapshot  = null;
    this.sortedAttachments = [];

		this.math = create(all, {});

		this.form_regenerate_documents = this._formBuilder.group({
			new_contract_documents_comment: this._formBuilder.control("", [Validators.required]),
		});

		this.form_confirm_documents_received = this._formBuilder.group({
			contract_documents_received_comment: this._formBuilder.control("", [Validators.required]),
		});

		this.form_insurances = this._formBuilder.group({
			insurance_policy_comment: this._formBuilder.control("", [Validators.required]),
			insurance_policies: this._formBuilder.array([]),
		});

		this.form_assigned_insurances = this._formBuilder.group({
			insurance_policy_comment: this._formBuilder.control("", [Validators.required]),
			insurance_policies: this._formBuilder.array([]),
		});

		this.form_change_price = new FormGroup({
			new_price: new FormControl('', [Validators.required]),
			comment: new FormControl('', [Validators.required]),
		});

		this.form_change_status = new FormGroup({
			status_change_comment: new FormControl('', [Validators.required]),
		});

		this.form_client_sign_contract = new FormGroup({
			confirmation_code: new FormControl('', [Validators.required]),
		});
	}

	ngOnInit(): void {
		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user) {
					this.reservation = null;
					this.addresses = [];
					this.availableInsurancePolicies = [];

					this.client_mailing_address_snapshot = null;
					this.client_billing_address_snapshot = null;
					this.participant_address_snapshot    = null;
					this.participant_snapshot            = null;
					this.participant_vaccinations        = null;
					this.transport_options               = null;
					this.insurance_options               = null;
					this.insurance_hash                  = {};
					this.payment_plan_snapshot_entries   = null;
					this.events                          = null;
					this.contract_details_data_snapshot  = null;
          this.sortedAttachments               = [];

				} else {
					// react to change of id parameter
					this.route.paramMap.pipe(
						switchMap(params => {
							if (params.get('id') !== null){
								this.reservationID = +params.get('id'); // + converts value to number :)
								this.loading = true;

								// fetch reservation data
								if (this.reservationSubscription) this.reservationSubscription.unsubscribe();
								this.reservationSubscription = this.apollo.watchQuery<ReservationReviewDetailsQueryResult>({
									query: getReservationReviewDetailsQuery,
									variables: {
										camp_participant_id: this.reservationID,
									},
								})
									.valueChanges
									.subscribe(
										(({ data, loading}) => {
											this.loading = loading;

											this.reservation = data.reservation_review_details.camp_participant;
											this.addresses = data.reservation_review_details.addresses_used;
											this.availableInsurancePolicies = data.reservation_review_details.available_insurance_policies;

											if (this.reservation.clients_mailing_address_snapshot != null){
												this.client_mailing_address_snapshot = JSON.parse(this.reservation.clients_mailing_address_snapshot) as Address;
											}

											if (this.reservation.clients_billing_address_snapshot != null){
												this.client_billing_address_snapshot = JSON.parse(this.reservation.clients_billing_address_snapshot) as Address;
											}

											if (this.reservation.participants_address_snapshot != null){
												this.participant_address_snapshot = JSON.parse(this.reservation.participants_address_snapshot) as Address;
											}

											if (this.reservation.participant_snapshot != null){
												this.participant_snapshot = JSON.parse(this.reservation.participant_snapshot) as Participant;
											}

											if (this.reservation.vaccinations_snapshot != null){
												this.participant_vaccinations = JSON.parse(this.reservation.vaccinations_snapshot) as Vaccinations;
											}

											if (this.reservation.insurance_snapshot != null){
												this.insurance_options = JSON.parse(this.reservation.insurance_snapshot);
											}

											if (this.reservation.events_array != null){
												this.events = JSON.parse(this.reservation.events_array);
											}

											if (this.reservation.camp.contract_details_data_snapshot != null){
												this.contract_details_data_snapshot = JSON.parse(this.reservation.camp.contract_details_data_snapshot);
											}

											// build hash with users insurance policies
											this.insurance_hash = {};
											for (let a = 0; a < this.reservation.insurance_policies.length; a++){
												this.insurance_hash[this.reservation.insurance_policies[a].insurance_policy.id] = true;
											}

											if (this.reservation.payment_plan_snapshot != null){
												this.payment_plan_snapshot_entries = JSON.parse(this.reservation.payment_plan_snapshot);
											}

                      // sort attachments by file_name
                      this.sortedAttachments = this.reservation.attachments.slice().sort((a, b) => {
                        return a.file_name.localeCompare(b.file_name);
                      });

                      // filter out attachments that are not accepted
                      this.sortedAttachments = this.sortedAttachments.filter((attachment) => {
                        return attachment.accepted;
                      });

											this.resetForms();

											this.showConfirmDataReminder();
										})
									);
							}

							return of('');
						})
					).subscribe();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.reservationSubscription) this.reservationSubscription.unsubscribe();
	}

	/**
	 * If camp.data_confirmation_required flag is set to true, this method will display a reminder dialog
	 * to ask client to confirm the data
	 * @protected
	 */
	protected showConfirmDataReminder(){
		// don't show the messagebox if it is still open
		if (this.dataConfirmationMessageBoxResult) return;

		// confirmation must be required by camp and contract must be signed
		if (!(this.currentUser.is_admin || this.currentUser.is_manager) &&
			this.reservation.camp.data_confirmation_required && this.reservation.status == "CONTRACT_SIGNED"){
			if (!this.reservation.is_data_confirmed && this.reservation.pending_changes){
				let dlgTitle = "Wymagane potwierdzenie danych";
				let dlgMessage = "<p>Organizator poprosił o weryfikację i potwierdzenie danych w zgłoszeniu.</p>" +
										 "<p>Ponieważ jednak nie wszystkie zgłoszone przez Ciebie zmiany zostały wprowadzone przez organizatora," +
										 " poczekaj na ich zatwierdzenie i wróć tutaj niebawem.</p>";
				let dlgStyle : ModalStyleType = "INFO";

				this.dataConfirmationMessageBoxResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
					this.modalService, dlgStyle, "OK", null, null, null);

				let localSub = this.dataConfirmationMessageBoxResult.subscribe((buttonPressed:ButtonPressedType) => {
					localSub.unsubscribe();
					this.dataConfirmationMessageBoxResult = null;
				});
			}
			else if (!this.reservation.is_data_confirmed){
				let dlgTitle = "Wymagane potwierdzenie danych";
				let dlgMessage = "<p>Organizator poprosił o weryfikację i potwierdzenie danych w zgłoszeniu.</p>" +
					"<p>Instrukcja powtwierdzania danych znajduje się na górze strony - przeczytaj ją uważnie " +
					"i potwierdź dane możliwie jak najszybciej.</p>";
				let dlgStyle : ModalStyleType = "WARNING";

				this.dataConfirmationMessageBoxResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
					this.modalService, dlgStyle, "OK", null, null, null);

				let localSub = this.dataConfirmationMessageBoxResult.subscribe((buttonPressed:ButtonPressedType) => {
					localSub.unsubscribe();
					this.dataConfirmationMessageBoxResult = null;
				});
			}
		}
	}

	public getConfirmationButtonColor(section_number: number) : string {
		if (this.data_confirmation_buttons_states.hasOwnProperty("section_" + section_number)){
			switch (this.data_confirmation_buttons_states["section_" + section_number]) {
				case "1":
					return "success";

				case "0":
					return "danger";

				default:
					return "danger";
			}
		}

		return "danger";
	}

	public getConfirmationButtonText(section_number: number) : string {
		if (this.data_confirmation_buttons_states.hasOwnProperty("section_" + section_number)){
			switch (this.data_confirmation_buttons_states["section_" + section_number]) {
				case "1":
					return "Dane potwierdzone";

				case "0":
					switch (section_number) {
						case 1:
							return "Potwierdzam info o dokumentach";

						case 2:
							return "Potwierdzam dane klienta i nr telefonu";

						case 3:
							return "Potwierdzam dane uczestnika i kontakty";

						case 4:
							return "Potwierdzam dodatkowe informacje (w tym dieta i upoważnienia)"

						case 5:
							return "Potwierdzam transport (+ PUNKTY ODBIORU)";

						case 6:
							return "Potwierdzam ubezpieczenia";

						case 7:
							return "Potwierdzam rozliczenia";
					}

				default:
					return "Naciśnij aby potwierdzić poprawność";
			}
		}

		return "Naciśnij aby potwierdzić poprawność";
	}

	public canShowDataConfirmationButton() : boolean {
		if (this.reservation.status == "CONTRACT_SIGNED" && !this.reservation.pending_changes &&
			this.reservation.camp.data_confirmation_required && !this.reservation.is_data_confirmed) {

			//now check if all confirmation buttons have been pressed
			for (const [key, value] of Object.entries(this.data_confirmation_buttons_states)){
				if (value != '1') return false; // return false if any button hasn't been pressed
			}

			return true;
		}
		return false;
	}

	get insurance_policies_array() : FormArray {
		return this.form_insurances.get('insurance_policies') as FormArray;
	}

	get assigned_insurance_policies_array() : FormArray {
		return this.form_assigned_insurances.get('insurance_policies') as FormArray;
	}

	public checkbox_insurance_policyControlAtIndex(i: number){
		return this.insurance_policies_array.at(i).get('checkbox_insurance_policy');
	}

	public checkbox_assigned_insurance_policyControlAtIndex(i: number){
		return this.assigned_insurance_policies_array.at(i).get('checkbox_insurance_policy');
	}

	public priceinput_insurance_policyControlAtIndex(i: number){
		return this.insurance_policies_array.at(i).get('insurance_policy_price');
	}

	get new_price_ChangePriceForm(){
		return this.form_change_price.get('new_price');
	}

	get new_price_comment_ChangePriceForm(){
		return this.form_change_price.get('comment');
	}

	get status_change_comment(){
		return this.form_change_status.get('status_change_comment');
	}

	get confirmation_code(){
		return this.form_client_sign_contract.get('confirmation_code');
	}

	public resetForms() {
		this.form_insurances.get('insurance_policy_comment').setValue("Ubezpiecznia obowiązkowe oraz dodatkowe opcje wybrane przez użytkownika podczas rejestracji.");

		if (this.availableInsurancePolicies && this.availableInsurancePolicies.length){
			this.insurance_policies_array.clear();

			for (let a = 0; a < this.availableInsurancePolicies.length; a++){
				const clientPrice = this.getMathParsedValue(this.getPolicyPriceFormula(this.availableInsurancePolicies[a]));
				const clientPriceDisplay = currency(clientPrice, {separator: "", decimal: ",", precision: 2, symbol: ""}).format();

				this.insurance_policies_array.push(this._formBuilder.group({
					checkbox_insurance_policy: {value: false, disabled: !this.availableInsurancePolicies[a].insurance_policy.is_active},
					insurance_policy_price: this._formBuilder.control(clientPriceDisplay, [Validators.required]),
					camp_insurancepolicy_id: [this.availableInsurancePolicies[a].id],
				}))
			}
		}

		this.form_assigned_insurances.get('insurance_policy_comment').setValue("");

		if (this.reservation.insurance_policies && this.reservation.insurance_policies.length){
			this.assigned_insurance_policies_array.clear();

			for (let a = 0; a < this.reservation.insurance_policies.length; a++){

				this.assigned_insurance_policies_array.push(this._formBuilder.group({
					checkbox_insurance_policy: [false],
					camp_insurancepolicy_id: [this.reservation.insurance_policies[a].id],
				}))
			}
		}

		const clientPriceDisplay = currency(1 * this.reservation.price, {separator: "", decimal: ",", precision: 2, symbol: ""}).format();
		this.form_change_price.get('new_price').setValue(clientPriceDisplay);
		this.form_change_price.get('comment').setValue('');

		this.form_change_status.get('status_change_comment').setValue('Brak dodatkowego komentarza');
	}

	public participantAgeAtCampStart() : number | null {
		const participant_birth_date = moment(this.participant_snapshot.date_birth);
		const camp_start_date = moment(this.reservation.camp.date_start);

		let dateDiff = moment.duration(camp_start_date.diff(participant_birth_date)).as('years');
		return  Math.floor(dateDiff);
	}

	public humanReadableStatus(status: Camp_Participant_Status){
		return this.reservationsDataManipulator.humanReadableStatus(status);
	}

	public humanReadableContractSignType(signType: Contract_Signature_Type): string {
		return this.reservationsDataManipulator.humanReadableContractSignType(signType);
	}

	public getTransportOptionByCode(direction: "to" | "from", code: string) : TransportOption | null {
		let optionFound : TransportOption = null;

		switch (direction) {
			case "to":
				optionFound = Camp.findTransportOptionByCode(this.reservation.camp, "to", this.reservation.selected_transport_options.to.code);
				break;

			case "from":
				optionFound = Camp.findTransportOptionByCode(this.reservation.camp, "from", this.reservation.selected_transport_options.from.code);
				break;
		}

		return optionFound;
	}

	public getPolicyPriceFormula(policy: Camp_InsurancePolicy) : string {
		return InsurancePolicy.getPrice_formula(policy.insurance_policy);
	}

	public getMathParsedValue(expression: string){
		if (!expression) return '';

		let mathScope: any = {
			campPrice: Number(this.reservation.camp.price),
			clientCampPrice: Number(this.reservation.price),
			clientTransportationPrice: Camp_Participant.getPriceForTransportation(this.reservation)
		};

		return this.math.evaluate(expression, mathScope);
	}

	public addOrRemoveSelectedInsurancePolicies(operation: "ADD" | "REMOVE"){
		if (this.networkOperationInProgress) return;

		let policiesToAssign: Array<IdPricePairInputType> = [];
		let policiesToDelete: Array<number> = [];

		if (operation == 'ADD'){
			if (this.form_insurances.invalid) return;

			for (let a = 0; a < this.insurance_policies_array.length; a++){
				const group = this.insurance_policies_array.at(a);

				if (group.get('checkbox_insurance_policy').value){
					// this will make our value compatible with server-side scripts
					let clientPrice = group.get('insurance_policy_price').value.toString().replace(",", ".");

					policiesToAssign.push({
						id: group.get('camp_insurancepolicy_id').value,
						price: currency(clientPrice, {separator: "", decimal: ".", precision: 2, symbol: ""}).format(),
					});
				}
			}

			if (!policiesToAssign.length) return;
		}
		else if (operation == 'REMOVE'){
			if (this.form_assigned_insurances.invalid) return;

			for (let a = 0; a < this.assigned_insurance_policies_array.length; a++){
				const group = this.assigned_insurance_policies_array.at(a);

				if (group.get('checkbox_insurance_policy').value){
					policiesToDelete.push(group.get('camp_insurancepolicy_id').value);
				}
			}

			if (!policiesToDelete.length) return;
		}


		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.editReservationInsurancePolicies(
			this.reservation.id,
			policiesToAssign.length ? policiesToAssign : null,
			this.form_insurances.get('insurance_policy_comment').value,
			policiesToDelete.length ? policiesToDelete : null,
			this.form_assigned_insurances.get('insurance_policy_comment').value,
		).subscribe(
			(data) => {
				// reset checkboxes in available policies form
				for (let a = 0; a < this.insurance_policies_array.length; a++){
					const group = this.insurance_policies_array.at(a);

					group.get('checkbox_insurance_policy').setValue(false);
				}

				this.networkOperationInProgress = false;
				localSub.unsubscribe();
			}
		);
	}

	public isInsurancePolicyAvailableForUser(policy: Camp_InsurancePolicy) : boolean {
		return !(this.insurance_hash && this.insurance_hash.hasOwnProperty(policy.insurance_policy.id));
	}

	public changeReservationPrice(){
		if (this.networkOperationInProgress || !this.form_change_price.valid) return;

		// this will make our value compatible with server-side scripts
		let clientPrice = this.form_change_price.get('new_price').value.toString().replace(",", ".");

		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.editReservationPrice(
			this.reservation.id,
			currency(clientPrice, {separator: "", decimal: ".", precision: 2, symbol: ""}).format(),
			this.form_change_price.get('comment').value.toString().trim()
		).subscribe(
			(data) => {

				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
				else {
					//this.form_regenerate_documents.get('new_contract_documents_comment').setValue("");
					this.form_regenerate_documents.reset();

					dlgTitle = "Sukces!";
					dlgMessage = data.message;
					dlgStyle = "SUCCESS";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
			}
		)
	}

	public updateReservationIsDataConfirmed(newValue: boolean){
		if (this.networkOperationInProgress) return;

		// check if all sections have been confirmed - this is a chceck for automatic confirmation
		// after the last section has been confirmed by user
		if (newValue && !this.canShowDataConfirmationButton()){
			return;
		}

		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.updateReservationIsDataConfirmed(
			this.reservation.id,
			newValue
		).subscribe(
			(data) => {
				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
				else {
					//this.form_regenerate_documents.get('new_contract_documents_comment').setValue("");
					this.form_regenerate_documents.reset();

					dlgTitle = "Sukces!";
					dlgMessage = data.message;
					dlgStyle = "SUCCESS";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
			}
		);
	}

	public regenerateContractDocuments(){
		if (this.networkOperationInProgress || !this.form_regenerate_documents.valid) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz PONOWNE WYGENEROWANIE DOKUMENTÓW z komentarzem: <strong>"
			+ this.form_regenerate_documents.get('new_contract_documents_comment').value.toString().trim()
			+ "</strong>?</p><p><strong>OPERACJA JEST NIEODWRACALNA!!!</strong></p>"
			+ "<p>Sugerujemy wcześniejsze pobranie i zachowanie aktualnych dokumentów.</p>",
			this.modalService, "DANGER", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.regenerateContractDocuments(
					this.reservation.id,
					this.form_regenerate_documents.get('new_contract_documents_comment').value.toString().trim()
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							//this.form_regenerate_documents.get('new_contract_documents_comment').setValue("");
							this.form_regenerate_documents.reset();

							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);
	}

	public changeReservationStatus(newStatus: Camp_Participant_Status){
		if (this.networkOperationInProgress || !this.form_change_status.valid) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.changeStatus(
			this.reservation.id,
			newStatus,
			this.form_change_status.get('status_change_comment').value.toString().trim()
		).subscribe(
			(data) => {
				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
				else {
					dlgTitle = "Sukces!";
					dlgMessage = data.message;
					dlgStyle = "SUCCESS";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
			}
		);
	}

	public rollbackReservationStatusToSubmitted(){
		if (this.networkOperationInProgress || !this.form_change_status.valid) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz COFNIĘCIE statusu do: 'zgłoszenie zarejestrowane'?</p><p><strong>OPERACJA JEST NIEODWRACALNA!!!</strong></p>",
			this.modalService, "DANGER", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.rollbackReservationStatusToSubmitted(
					this.reservation.id,
					this.form_change_status.get('status_change_comment').value.toString().trim()
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);
	}

	public clientSignContract(){
		if (this.networkOperationInProgress || !this.form_client_sign_contract.valid) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.clientSignContractOnline(
			this.reservation.id,
			this.form_client_sign_contract.get('confirmation_code').value.toString().trim()
		).subscribe(
			(data) => {
				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
				else {
					dlgTitle = "Sukces!";
					dlgMessage = data.message;
					dlgStyle = "SUCCESS";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
			}
		);
	}

	public downloadDocuments(){
		if (!this.reservation && this.reservation.contract_sign_date === null && this.reservation.status != 'ENROLLED') return;

		if (this.networkOperationInProgress) return;
		this.networkOperationInProgress = true;

		let locaSub = this.apollo.watchQuery<ReservationDocumentsLinkResult>({
			query: ReservationDocumentsLinkQuery,
			variables: {
				camp_participant_id: this.reservation.id,
			},
			fetchPolicy: "network-only",    // don't cache this response!!!
		})
			.valueChanges
			.subscribe(
				({data}) => {
					this.networkOperationInProgress = false;
					locaSub.unsubscribe();

					if (data.reservation_documents_link){
						this.http.get('/api/documents_server.php?resource=' + data.reservation_documents_link, {responseType: 'blob' })
							.subscribe(
								(data) => {
									importedSaveAs(data as Blob, 'Dokumenty AlphaCamp.zip');
								}
							)
					}
				},
				error => {
					this.networkOperationInProgress = false;
					locaSub.unsubscribe();
				}
			)
	}

	public showPaymentDetails(reservation: Camp_Participant){
		this.router.navigate(['/reservation-payments', reservation.id]);
	}

	public sendEmailEnrolledReminder(reservation: Camp_Participant){
		if (this.networkOperationInProgress) return;

		// check if such reminder hasn't already been sent
		let reminderSentAlreadyWarning = "";
		for (let a = 0; a < this.events.length; a++){
			if (this.events[a].eventType == "RESERVATION_EMAIL_ENROLLED_REMINDER_SENT"){
				reminderSentAlreadyWarning = "<p><strong>UWAGA!<br/>Mail przypominający został już wcześniej wysłany do tego uczestnika (sprawdź na liście zdarzeń poniżej)</strong></p>";
			}
		}

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy chcesz wysłać maila przypominającego z informacją o zakwalifikowaniu uczestnika wraz z dokumentami?</p>" + reminderSentAlreadyWarning,
			this.modalService, "INFO", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.mailingDataManipulator.sendEmailParticipantEnrolledReminder(reservation.id).subscribe(
					data => {
						this.networkOperationInProgress = false;
						localSub.unsubscribe();

						if (data.status.startsWith("ERROR")){
							this.genericModalHandler.showModal("Błąd!", "Podczas wysyłania wiadomości wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
								this.modalService, "DANGER", "OK", null, null, null);
						}
						else {
							this.genericModalHandler.showModal("Gratulujemy!", "Wiadomość została wysłana!",
								this.modalService, "SUCCESS", "OK", null, null, null);
						}
					}
				)
			}
		)
	}

	/*
	public toggleConfirmDocumentsReceived(document_type: Document_Type){
		if (this.networkOperationInProgress || !this.form_confirm_documents_received.valid) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy potwierdzasz potwierdzenie/cofnięcie potwierdzenia otrzymania dokumentów</p>",
			this.modalService, "DANGER", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.toggleConfirmDocumentsReceived(
					this.reservation.id,
					document_type,
					this.form_confirm_documents_received.get('contract_documents_received_comment').value.toString().trim()
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							// clear the input box
							this.form_confirm_documents_received.get('contract_documents_received_comment').setValue("");
							this.form_confirm_documents_received.get('contract_documents_received_comment').markAsUntouched();
							this.form_confirm_documents_received.get('contract_documents_received_comment').markAsPristine();

							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);
	}
	 */

	public updateReservationsDocumentsToSubmitFromCampConfig(){
		if (this.networkOperationInProgress) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy chcesz zaktualizować listę dokumentów?</p>",
			this.modalService, "INFO", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.updateReservationsDocumentsToSubmitFromCampConfig(
					this.reservation.id
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);
	}

	public editDocumentsToSubmit(showFullForm: boolean = false){
		this.documents_to_submit_edit_form_style = showFullForm ? "RESERVATION_FULL" : "RESERVATION_SHORT";
		this.documents_to_submit_edit_form_visible = true;
	}

	public onDocumentsToSubmitCommitChanges(newDocuments: DocumentToSubmit[]){
		if (this.networkOperationInProgress) return;

		// confirm
		let msgSub = this.genericModalHandler.showModal("Potwierdź!", "<p>Czy chcesz zaktualizować listę dokumentów i ich statusy?</p>",
			this.modalService, "INFO", null, null, "Tak", "Nie").subscribe(
			data => {
				msgSub.unsubscribe();

				if (data != "Yes") return;

				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.updateReservationsDocumentsToSubmit(
					newDocuments,
					this.reservation.id
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
						else {
							dlgTitle = "Sukces!";
							dlgMessage = data.message;
							dlgStyle = "SUCCESS";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}
		);

		this.documents_to_submit_edit_form_visible = false;
	}

	public issue_change_request(fieldName: string, currentValue: string){
		if (this.networkOperationInProgress) return;


		let descriptionText: string = "";
		let hint: string = 'Wpisz tekst...';
		switch (fieldName){
			case 'HEALTH':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'uwagi dotyczące zdrowia'</span>.";
				break;

			case 'DIET':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'uwagi dotyczące diety'</span>.";
				break;

			case 'ACCOMMODATION':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'zakwaterowanie'</span>.";
				break;

			case 'AUTHORIZATIONS':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'upoważnienia'</span>.";
				break;

			case 'SPECIAL_NEEDS':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'specjalne potrzeby'</span>.";
				break;

			case 'GENERAL_REMARKS':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'dodatkowe uwagi'</span>.";
				break;

			case 'PROMO_CODES':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'kody promocyjne'</span>.";
				break;

			case 'TRANSPORT':
				descriptionText = "Poniżej proszę opisać zmiany, które nasz pracownik wprowadzi w polu <span class=\"boldtext\">'transport'</span> (na miejsce lub z powrotem - prosimy o jasne wskazówki).";
				break;

			default:
				return;
		}


		/*
			Prepare modal for editing data
		 */
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog modal-dialog-scrollable modal-notify modal-info',
			containerClass: '',
			animated: true,
			data: {
				windowTitle: "Aktualizacja danych",
				textareaHintText: hint,
				descriptionText: descriptionText,
				currentValue: currentValue,
				saveButtonPressed: false,       //very important
			}
		};

		/*
			Show the modal
		 */
		let modalRef = this.modalService.show(ModalTextEditComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let textEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.issueChangeRequest(
					this.reservation,
					fieldName,
					modalRef.content.newValue
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}

			//don't react to further events coming from the modal
			textEditSubscription.unsubscribe();
		});
	}

	public editDescriptiveField(fieldName: string, currentValue: string){
		if (this.networkOperationInProgress) return;

		if (!this.currentUser.is_admin && !this.currentUser.is_manager) return;

		let descriptionText: string = "";
		let hint: string = 'Wpisz tekst...';
		switch (fieldName){
			case 'HEALTH':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'uwagi dotyczące zdrowia'</span>.";
				break;

			case 'DIET':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'uwagi dotyczące diety'</span>.";
				break;

			case 'ACCOMMODATION':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'zakwaterowanie'</span>.";
				break;

			case 'AUTHORIZATIONS':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'upoważnienia'</span>.";
				break;

			case 'SPECIAL_NEEDS':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'specjalne potrzeby'</span>.";
				break;

			case 'GENERAL_REMARKS':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'dodatkowe uwagi'</span>.";
				break;

			case 'PROMO_CODES':
				descriptionText = "Wprowadź nową wartość pola <span class=\"boldtext\">'kody promocyjne'</span>.";
				break;

			default:
				return;
		}

		/*
			Prepare modal for editing data
		 */
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog modal-dialog-scrollable modal-notify modal-info',
			containerClass: '',
			animated: true,
			data: {
				windowTitle: "Aktualizacja danych",
				textareaHintText: hint,
				descriptionText: descriptionText,
				currentValue: currentValue,
				saveButtonPressed: false,       //very important
			}
		};

		/*
			Show the modal
		 */
		let modalRef = this.modalService.show(ModalTextEditComponent, modalOptions);

		/*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
		let textEditSubscription = this.modalService.closed.subscribe(() => {
			if (modalRef.content.saveButtonPressed){
				this.networkOperationInProgress = true;

				let localSub = this.reservationsDataManipulator.editDescriptiveField(
					this.reservation,
					fieldName,
					modalRef.content.newValue
				).subscribe(
					(data) => {
						this.networkOperationInProgress = false;

						let dlgTitle: string;
						let dlgMessage: string;
						let dlgStyle: ModalStyleType;

						if (data.status == "ERROR"){
							dlgTitle = "Błąd!";
							dlgMessage = data.message;
							dlgStyle = "WARNING";

							const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
								this.modalService, dlgStyle, "OK", null, null, null);
						}
					}
				);
			}

			//don't react to further events coming from the modal
			textEditSubscription.unsubscribe();
		});
	}

	public deleteDescriptiveFieldChangeRequest(fieldName: string, request_id: string){
		if (this.networkOperationInProgress) return;

		this.networkOperationInProgress = true;

		let localSub = this.reservationsDataManipulator.deleteReservationsDescriptiveFieldChangeRequest(
			this.reservation,
			fieldName,
			request_id
		).subscribe(
			(data) => {
				this.networkOperationInProgress = false;

				let dlgTitle: string;
				let dlgMessage: string;
				let dlgStyle: ModalStyleType;

				this.changeRequestBeingDeletedID = null;

				if (data.status == "ERROR"){
					dlgTitle = "Błąd!";
					dlgMessage = data.message;
					dlgStyle = "WARNING";

					const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
						this.modalService, dlgStyle, "OK", null, null, null);
				}
			}
		);
	}

	public startTimerOnChangeRequestMouseDown(fieldName: string, requestID: string, event: any){
		if (this.networkOperationInProgress ||
			this.changeRequestBeingDeletedID !== null ||
			(!this.currentUser.is_admin &&
			!this.currentUser.is_manager &&
			!this.currentUser.is_backoffice_employee)) return;

		let self = this;
		let framesTotal = 25 * 3;
		let framesToGo = framesTotal;

		event.stopPropagation();
		event.preventDefault();

		let countdownFunction = function(){
			self.clickedChangeRequestOpacity = framesToGo / framesTotal;

			if (framesToGo-- <= 0){
				clearTimeout(self.tmrChangeRequestTimeoutID);
				self.tmrChangeRequestTimeoutID = null;
				self.clickedChangeRequestID = null;
				self.changeRequestBeingDeletedID = requestID;

				self.deleteDescriptiveFieldChangeRequest(fieldName, self.changeRequestBeingDeletedID);
			}
			else {
				self.tmrChangeRequestTimeoutID = setTimeout(countdownFunction, 20);
			}
		}

		this.clickedChangeRequestID = requestID;
		countdownFunction();
	}

	public stopTimerOnChangeRequestMouseUp(event: any){
		event.stopPropagation();
		event.preventDefault();

		if (this.tmrChangeRequestTimeoutID !== null){
			this.clickedChangeRequestID = null;
			clearTimeout(this.tmrChangeRequestTimeoutID);
		}
	}

	public changeRequestStyle(requestID: string){
		if (requestID == this.clickedChangeRequestID){
			return {
				opacity: this.clickedChangeRequestOpacity
			};
		}
		else if (requestID == this.changeRequestBeingDeletedID){
			return {
				opacity: 0
			};
		}
	}

	public edit_trasport_options(){
		if (this.networkOperationInProgress) return;

		this.showTransportOptionsEditorForm = true;
	}

	public transportOptionsEditorFormSave(updatedData?: ReservationsTransportOptions) {
		if (updatedData !== null){
			if (this.networkOperationInProgress) return;

			this.networkOperationInProgress = true;

			if ('change_requests' in updatedData){
				delete updatedData['change_requests'];
			}

			let localSub = this.reservationsDataManipulator.editReservationSelectedTransportOptions(
				this.reservation.id,
				updatedData
			).subscribe(
				(data) => {
					this.networkOperationInProgress = false;


					let dlgTitle: string;
					let dlgMessage: string;
					let dlgStyle: ModalStyleType;

					this.changeRequestBeingDeletedID = null;

					if (data.status == "ERROR"){
						dlgTitle = "Błąd!";
						dlgMessage = data.message;
						dlgStyle = "WARNING";

						const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
							this.modalService, dlgStyle, "OK", null, null, null);

						return;
					}
					else this.showTransportOptionsEditorForm = false;
				},
        () => {
          this.networkOperationInProgress = false;
        },
        () => {
          this.networkOperationInProgress = false;
          localSub.unsubscribe();
        }
			);

			return;
		}

		// just hide the form
		this.showTransportOptionsEditorForm = false;
	}

  /**
   * Shows a modal with information about missing parent info
   */
  showMissingParentInfo() {

    let dlgTitle: string;
    let dlgMessage: string;
    let dlgStyle: ModalStyleType;


    dlgTitle = "Informacja";
    dlgMessage = "<p>Pamiętaj, że jako organizator wiemy tylko tyle, ile rejestrując uczestnika przekazałaś(-eś) nam w formularzu zgłoszeniowym.</p>" +
      "<p>Jeżeli drugi rodzic/opiekun ma mieć możliwość kontaktowania się z nami, wglądu do dokumentacji, możliwość odwiedzania " +
      "lub odbioru uczestnika po zakończonym obozie, musi być wpisany jako rodzic w danych uczestnika i figurować " +
      "jako taki/taka na karcie kwalifikacyjnej.</p>" +
      "<p>Dane uczestnika (w tym informacje o rodzicach/opiekunach) możesz edytować wybierając opcję 'moi uczestnicy' w menu.</p>" +
      "<p>W przypadku jakichkolwiek pytań prosimy o kontakt.</p>";
    dlgStyle = "INFO";

    const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
      this.modalService, dlgStyle, "OK", null, null, null);
  }

  updateAddress(addressType: AddressType) {
    // abort if other network operation is in progress
    if (this.networkOperationInProgress) return;

    // first make sure that current user is allowed to perform this operation
    let operationAllowed: boolean = false;

    if (this.currentUser.is_admin || this.currentUser.is_manager || this.currentUser.is_backoffice_employee) {
      operationAllowed = true;
    }
    // is this user's parent
    else if (this.reservation.participant.user.id == this.currentUser.id) {
      // only before reservation is confirmed
      if (this.reservation.status == 'DRAFT' || this.reservation.status == 'SUBMITTED' || this.reservation.status == 'WAITING_LIST') {
        operationAllowed = true;
      }
    }

    // if not allowed - show warning and abort
    if (!operationAllowed) {
      let dlgTitle: string;
      let dlgMessage: string;
      let dlgStyle: ModalStyleType;

      dlgTitle = "Operacja niedozwolona";
      dlgMessage = "<p>Na tym etapie obsługi rezerwacji nie możesz samodzielnie zaktualizować wybranego adresu, " +
        "ponieważ dokumenty zostały już wygenerowane.</p>" +
        "<p>Prosimy o pilny kontakt z biurem - pomożemy w aktualizacji wszelkich danych oraz prześlemy nowy zestaw dokumentów, " +
        "jeśli będzie to konieczne.</p>";
      dlgStyle = "DANGER";

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, dlgStyle, "OK", null, null, null);

      return;
    }

    // get current id of address to be changed
    let addressID: number = null;
    let nameReplacement: string = null;

    switch (addressType) {
      case AddressType.MAILING:
        addressID = this.client_mailing_address_snapshot?.id;
        break;

      case AddressType.BILLING:
        addressID = this.client_billing_address_snapshot?.id;
        break;

      case AddressType.PARTICIPANT:
        addressID = this.participant_address_snapshot?.id;
        nameReplacement = this.participant_snapshot?.name_first + " " + this.participant_snapshot?.name_last;
        break;

    }

    this.networkOperationInProgress = true;

    let localSub: Subscription = this.addressDataManipulator.selectNewOrUpdatedAddressDialog(addressType,
      this.reservation.participant.user.id, addressID, nameReplacement).subscribe(
    (data) => {
      this.networkOperationInProgress = false;

      if (data.status == "CONFIRMED") {
        this.networkOperationInProgress = true;

        let executorSub = this.reservationsDataManipulator.changeReservationAddress(
          this.reservation,
          addressType,
          data.data
        ).subscribe(
          (data) => {
            this.networkOperationInProgress = false;


            let dlgTitle: string;
            let dlgMessage: string;
            let dlgStyle: ModalStyleType;

            this.changeRequestBeingDeletedID = null;

            if (data.status == "ERROR"){
              dlgTitle = "Błąd!";
              dlgMessage = data.message;
              dlgStyle = "WARNING";

              const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
                this.modalService, dlgStyle, "OK", null, null, null);

              return;
            }
          },
          () => {
            this.networkOperationInProgress = false;
          },
          () => {
            this.networkOperationInProgress = false;
            executorSub.unsubscribe();
          }
        );
      }
    },
    (error) => {
      this.networkOperationInProgress = false;
      localSub.unsubscribe();
    },
    () => {
      this.networkOperationInProgress = false;
      localSub.unsubscribe();
    });
  }

  updateParticipantInfo() {
    // abort if other network operation is in progress
    if (this.networkOperationInProgress) return;

    let updateOperation = () => {
      this.networkOperationInProgress = true;

      let localSub = this.reservationsDataManipulator.updateReservationsParticipantInfoRequest(
        this.reservation
      ).subscribe(
        (data) => {
          this.networkOperationInProgress = false;


          let dlgTitle: string;
          let dlgMessage: string;
          let dlgStyle: ModalStyleType;

          this.changeRequestBeingDeletedID = null;

          if (data.status == "ERROR"){
            dlgTitle = "Błąd!";
            dlgMessage = data.message;
            dlgStyle = "WARNING";

            const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
              this.modalService, dlgStyle, "OK", null, null, null);

            return;
          }
          else this.showTransportOptionsEditorForm = false;
        }
      );
    }


    // make sure current user is allowed to perform this operation
    // first check if current user is participants parent - in this case update is allowed only before reservation is confirmed
    if (this.reservation.participant.user.id == this.currentUser.id &&
      (this.reservation.status != 'DRAFT' && this.reservation.status != 'SUBMITTED' && this.reservation.status != 'WAITING_LIST')) {
      let dlgTitle: string;
      let dlgMessage: string;
      let dlgStyle: ModalStyleType;


      dlgTitle = "Operacja niedozwolona";
      dlgMessage = "<p>Na tym etapie obsługi rezerwacji nie możesz samodzielnie zaktualizować informacji o uczestniku, " +
        "ponieważ dokumenty zostały już wygenerowane i zawierają one przesłane przez Ciebie wcześniej dane wyświetlone w tym podglądzie.</p>" +
        "<p>Prosimy o pilny kontakt z biurem - pomożemy w aktualizacji wszelkich danych oraz prześlemy nowy zestaw dokumentów, " +
        "jeśli będzie to konieczne.</p>";
      dlgStyle = "DANGER";

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, dlgStyle, "OK", null, null, null);

      return;
    }
    // if current user is an admin or manager and reservation has been already accepted and documents generated - notify
    // of reservation's status and the need to regenerate documents
    else if ((this.currentUser.is_admin || this.currentUser.is_manager || this.currentUser.is_backoffice_employee) &&
      (this.reservation.status != 'DRAFT' && this.reservation.status != 'SUBMITTED' && this.reservation.status != 'WAITING_LIST')) {
      let dlgTitle: string;
      let dlgMessage: string;
      let dlgStyle: ModalStyleType;


      dlgTitle = "Potwierdź operację";
      dlgMessage = "<p>Dokumenty do wybranej rezerwacji zotały już przesłane Klientowi i zmiana danych uczestnika będzie wymagała " +
        "ich ponownego wygenerowania oraz podpisania.</p>" +
        "<p>Czy chcesz kontynuować?</p>";
      dlgStyle = "WARNING";

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, dlgStyle, null, null, "TAK", "NIE").subscribe((usersDecision) => {
        if (usersDecision == "Yes"){
          updateOperation();
        } else {
          return;
        }
      });

      return;
    }
    // simply ask for confirmation if user is authorized to perform this operation
    else if (this.currentUser.is_admin || this.currentUser.is_manager || this.currentUser.is_backoffice_employee
      || this.reservation.participant.user.id == this.currentUser.id){

      // show confirmation dialog
      let dlgTitle: string;
      let dlgMessage: string;
      let dlgStyle: ModalStyleType;


      dlgTitle = "Potwierdź operację";
      dlgMessage = "<div class=\"row\"><div class=\"col-12\" class='test-justify'>" +
        "<p>Czy na pewno chcesz zaktualizować dane uczestnika?</p>" +
        "<p style='font-size: 0.8em; color:#898989' ><span style='font-weight: 800'>Podpowiedź:</span> najpierw " +
        "skorzystaj z menu 'moi uczestnicy' - tam możesz edytować dane. Następnie wróć do podglądu " +
        "zgłoszenia i naciśnij przycisk <i class=\"fas fa-sync-alt\"></i> aby skopiować zaktualizowane dane " +
        "do rezerwacji.</p>" +
        "</div></div>";
      dlgStyle = "WARNING";

      const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
        this.modalService, dlgStyle, null, null, "TAK", "NIE", true).subscribe((usersDecision) => {
        if (usersDecision == "Yes"){
          updateOperation();
        } else {
          return;
        }
      });

      return;
    }
  }

  public navigateToAttachmentsManager(){
    this.router.navigate(['/attachments-manager', this.reservation.id]);
  }

  /*
    This method first requests the server to generate a presigned URL for the file download, and then downloads the file.
   */
  public downloadAttachmentFile(attachment: FileAttachment){
    if (this.networkOperationInProgress) return;

    let downloadSubscription = this.s3FileManipulations.downloadReservationAttachment(this.reservationID, attachment.key, attachment.file_name).subscribe(
      (data) => {
        downloadSubscription.unsubscribe();

        if (data.status !== "OK"){
          let dlgTitle = "Błąd!";
          let dlgMessage = "Podczas próby pobrania pliku wystąpił błąd. Spróbuj ponownie.";

          const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
            this.modalService, "DANGER", "OK", null, null, null);
        }
      },
      (error) => {
        downloadSubscription.unsubscribe();

        let dlgTitle = "Błąd!";
        let dlgMessage = "Podczas próby pobrania pliku wystąpił błąd. Spróbuj ponownie.";

        const myResult = this.genericModalHandler.showModal(dlgTitle, dlgMessage,
          this.modalService, "DANGER", "OK", null, null, null);
      },
      () => {
        downloadSubscription.unsubscribe();
      }
    )
  }

  // I don't know what this is :)
  protected readonly Address = Address;
  protected readonly AddressType = AddressType;
}
