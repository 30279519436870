import {Component, OnDestroy, OnInit} from "@angular/core";
import {ReservationInsuranceBrowserItem, User} from "../_models";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "../_services";
import {MDBModalService} from "ng-uikit-pro-standard";
import {switchMap} from "rxjs/operators";
import {Reservations_insurances_browserResult} from "../graphQL/results";
import { Reservations_insurances_browserQuery } from "../graphQL/queries";
import * as _ from "underscore";
import {ModalGenericHandler} from "../modal-generic-handler";
import {InsuranceSubmissionDataManipulations} from "../graphQL/data-manipulation/insurance-submission-data-manipulations";
import {typeCheckFilePath} from "@angular/compiler-cli/src/ngtsc/typecheck";


@Component({
	templateUrl: './reservation-insurance-browser.component.html',
	styleUrls: ['./reservation-insurance-browser.component.scss']
})
export class ReservationInsuranceBrowserComponent implements OnInit, OnDestroy {
	private loggedInUserSubscription: Subscription;
	private reservationInsuranceItemsSubscription: Subscription;

	private currentUser: User;

	public loading: boolean;
	public networkOperationInProgress: boolean;

	private genericModalHandler: ModalGenericHandler;
	private insuranceSubmissionDataManipulator: InsuranceSubmissionDataManipulations;

	public reservationInsuranceItems: ReservationInsuranceBrowserItem[];

	public selectedCampIDS: number[];
	public selectedInsuranceIDS: number[];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apollo: Apollo,
		private authService: AuthenticationService,
		private modalService: MDBModalService
	) {
		this.loading = false;
		this.genericModalHandler = new ModalGenericHandler();
		this.insuranceSubmissionDataManipulator = new InsuranceSubmissionDataManipulations(apollo);
	}

	ngOnInit(): void {
		this.selectedCampIDS = null;
		this.selectedInsuranceIDS = null;

		let parametersChanged: boolean = false;

		this.loggedInUserSubscription = this.authService.currentUser.subscribe(
			(user: User) => {
				this.currentUser = user;

				if (!user){
					this.reservationInsuranceItems = [];
				}
				else {
					// react to change of camp_status parameter
					this.route.paramMap.pipe(
						switchMap(params => {
							let tmpCampIDS = [];
							if (params.get('camp_ids') == "all"){
								if (!_.isEqual(this.selectedCampIDS, tmpCampIDS)){
									this.selectedCampIDS = tmpCampIDS;
									parametersChanged = true;
								}
							}
							else {
								tmpCampIDS = _.sortBy(this.stringToArrayOfIntegers(params.get('camp_ids')));
								if (!_.isEqual(tmpCampIDS, _.sortBy(this.selectedCampIDS))){
									this.selectedCampIDS = tmpCampIDS;
									parametersChanged = true;
								}
							}

							let tmpInsuranceIDS = [];
							if (params.get('insurance_ids') == 'all'){
								if (!_.isEqual(this.selectedInsuranceIDS, tmpInsuranceIDS)){
									this.selectedInsuranceIDS = tmpInsuranceIDS;
									parametersChanged = true;
								}
							}
							else {
								tmpInsuranceIDS = _.sortBy(this.stringToArrayOfIntegers(params.get('insurance_ids')));
								if (!_.isEqual(tmpInsuranceIDS, _.sortBy(this.selectedInsuranceIDS))){
									this.selectedInsuranceIDS = tmpInsuranceIDS;
									parametersChanged = true;
								}
							}

							if (parametersChanged) this.fetchBrowserItems();

							return [];
						})
					).subscribe();
				}
			});
	}

	ngOnDestroy(): void {
		if (this.loggedInUserSubscription) this.loggedInUserSubscription.unsubscribe();
		if (this.reservationInsuranceItemsSubscription) this.reservationInsuranceItemsSubscription.unsubscribe();

	}

	private fetchBrowserItems(){
		this.loading = true;

		if (this.reservationInsuranceItemsSubscription) this.reservationInsuranceItemsSubscription.unsubscribe();

		this.reservationInsuranceItemsSubscription = this.apollo.watchQuery<Reservations_insurances_browserResult>({
			query: Reservations_insurances_browserQuery,
			fetchPolicy: "network-only",
			variables: {
				"insurance_policy_ids": this.selectedInsuranceIDS,
				"camp_ids": this.selectedCampIDS,
			}
		})
			.valueChanges
			.subscribe(
				(({ data, loading}) => {
					this.loading = loading;



					//since 'data' is readonly and we need to deserialize some of its fields, we need to clone it
					let items : Reservations_insurances_browserResult = JSON.parse(JSON.stringify(data));

					// to save time let's JSON.parse all participants' insurance snapshots only this one time
					for (let i = 0; items.reservations_insurances_browser && i < items.reservations_insurances_browser.length; i++){
						for (let n = 0; items.reservations_insurances_browser[i].camp_participants && n < items.reservations_insurances_browser[i].camp_participants.length; n++){
							if (items.reservations_insurances_browser[i].camp_participants[n].insurance_snapshot
							&& typeof items.reservations_insurances_browser[i].camp_participants[n].insurance_snapshot === 'string'){
								items.reservations_insurances_browser[i].camp_participants[n].insurance_snapshot = JSON.parse(items.reservations_insurances_browser[i].camp_participants[n].insurance_snapshot);
							}
						}
					}

					this.reservationInsuranceItems = items.reservations_insurances_browser;
				})
			);
	}

	private getParticipantsSelectedPolicies(insuranceSnapshot: string){
		return _.keys(insuranceSnapshot).join(", ");
	}

	private stringToArrayOfIntegers(source: string) : number[] {
		let initialArray = source.split(',');
		let outputArray = [];

		initialArray.forEach((value, index, array) => {
			if (Number.isInteger(parseInt(value))){
				outputArray.push(parseInt(value));
			}
		});

		return outputArray;
	}

	private downloadSubmissionDocument(submissionID: number){
		if (this.networkOperationInProgress || submissionID === null) return;

		this.networkOperationInProgress = true;

		let localSub = this.insuranceSubmissionDataManipulator.downloadDocumentForInsuranceSubmission(submissionID).subscribe(
			data => {
				this.networkOperationInProgress = false;
				localSub.unsubscribe();

				if (data.status == "ERROR"){
					this.genericModalHandler.showModal("Błąd!", "Podczas pobierania dokumentu ze zgłoszeniem ubezpieczonych wystąpił błąd. Odśwież stronę i spróbuj jeszcze raz lub powiadom administratora.",
						this.modalService, "DANGER", "OK", null, null, null);
				}
			}
		)
	}
}
