import {Address} from "./address";

export type ParentStatus = "PARENT" | "GUARDIAN";

export class Parent {
	name_full: string;
	status: ParentStatus;
	phone_number: string;
	address: string;
}

export const Parent_EmptyEntity = {
	name_full: '',
	status: "PARENT",
	phone_number: '',
	address: '',
} as Parent;

export const ParentStatusSelectConfig = [
	{ value: 'PARENT', label: 'Rodzic' },
	{ value: 'GUARDIAN', label: 'Opiekun' }
];


export class ParentHelper {
	createParentFromAddressObject(source: Address) : Parent {
		if (source === null){
			return Parent_EmptyEntity;
		}

		let fullAddress: string = '';

		fullAddress += source.street_name.trim() + ' ' + source.street_number.trim();
		if (source.flat_number && source.flat_number.trim() != ''){
			fullAddress += ' / ' + source.flat_number.trim();
		}

		fullAddress += ', ' + source.zip_code.trim() + ' ' + source.city.trim() + ', ' + source.country.trim();

		return {
			name_full: source.name_first.trim() + ' ' + source.name_last.trim(),
			address: fullAddress,
			phone_number: source.phone_number.trim(),
			status: "PARENT",
		};
	}
}