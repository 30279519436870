<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Edycja danych uczestnika</h4>
</div>
<div class="modal-body">
    <form [formGroup]="participantEditForm">
        <div class="form-row">
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <input #participant_name_first type="text" id="participantEditModalForm-name_first" [formControl]="participantEditModalNameFirst" class="form-control" mdbInput mdbValidate>
                    <label for="participantEditModalForm-name_first">Imię</label>
                    <mdb-error *ngIf="participantEditModalNameFirst.invalid && (participantEditModalNameFirst.dirty || participantEditModalNameFirst.touched)">Błąd</mdb-error>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <input type="text" id="participantEditModalForm-name_middle" [formControl]="participantEditModalNameMiddle" class="form-control" mdbInput mdbValidate>
                    <label for="participantEditModalForm-name_middle">Drugie imię (imiona)</label>
                    <mdb-error *ngIf="participantEditModalNameMiddle.invalid && (participantEditModalNameMiddle.dirty || participantEditModalNameMiddle.touched)">Błąd</mdb-error>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <input type="text" id="participantEditModalForm-name_last" [formControl]="participantEditModalNameLast" class="form-control" mdbInput mdbValidate>
                    <label for="participantEditModalForm-name_last">Nazwisko</label>
                    <mdb-error *ngIf="participantEditModalNameLast.invalid && (participantEditModalNameLast.dirty || participantEditModalNameLast.touched)">Błąd</mdb-error>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <mdb-date-picker id="participantEditModalForm-date-birth" [inline]="true" name="mydate" [label]="'Data urodzenia (np. 2010-09-21)'" [options]="dateBirthDatePickerOptions" [placeholder]="'Data urodzenia'" [formControl]="participantEditModalDateBirth" required></mdb-date-picker>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <input type="text" id="participantEditModalForm-national_id_number" [formControl]="participantEditModalNationalIdNumber" class="form-control" mdbInput mdbValidate>
                    <label for="participantEditModalForm-national_id_number">PESEL</label>
                    <mdb-error *ngIf="participantEditModalNationalIdNumber.invalid && (participantEditModalNationalIdNumber.dirty || participantEditModalNationalIdNumber.touched)">Błąd</mdb-error>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="md-form">
                    <mdb-select id="participantEditModalForm-gender" [options]="genderOptions" formControlName="participantEditModalGender" placeholder="Wybierz"></mdb-select>
                    <label for="participantEditModalForm-gender">Płeć</label>
                </div>
            </div>
        </div>


        <p class="h6" class="text-primary">Dane rodziców i opiekunów</p>

        <div class="mb-3">
          <p>
            Poniższe dane posłużą do nawiązania kontaktu w celu realizacji umowy. Rodzice/opiekunowie są automatycznie
            upowaznieni do odbierania uczestnika z obozu i dostępu do wszelkich posiadanych przez nas informacji na
            jego/jej temat.
          </p>
        </div>



        <div formArrayName="participantEditModalParentsList" *ngFor="let parent of participantEditModalParentsList.controls; let i = index;" class="animated fadeInUp mt-2">
            <div [formGroupName]="i">
                <div class="form-row">
                    <div class="col">
                        <div class="form-row">
                            <div class="col-12 col-md-6">
                                <div class="md-form mt-0 mb-2">
                                    <input type="text" [id]="'participantEditModalForm-parent-name-full-' + i" formControlName="participantEditModalParentsListFullName" class="form-control" mdbInput mdbValidate>
                                    <label [htmlFor]="'participantEditModalForm-parent-name-full-' + i">Imię i nazwisko</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="md-form mt-0 mb-2">
                                    <input type="text" [id]="'participantEditModalForm-parent-phone-numbers-' + i" formControlName="participantEditModalParentsListPhoneNumbers" class="form-control" mdbInput mdbValidate>
                                    <label [htmlFor]="'participantEditModalForm-parent-phone-numbers-' + i">Numery telefonów</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-2">
                                <div class="md-form mt-0 mb-2">
                                    <mdb-select [id]="'participantEditModalForm-parent-status-' + i" [options]="parentStatuses" formControlName="participantEditModalParentsListStatus" placeholder="Wybierz status"></mdb-select>
                                    <label [htmlFor]="'participantEditModalForm-parent-status-' + i">Status</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="md-form mt-0 mb-2">
                                    <input type="text" [id]="'participantEditModalForm-parent-address-' + i" formControlName="participantEditModalParentsListAddress" class="form-control" mdbInput mdbValidate>
                                    <label [htmlFor]="'participantEditModalForm-parent-address-' + i">Adres (ulica, kod pocztowy, miasto)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="md-form mt-0 mb-2 mt-3" style="cursor: pointer;" mdbTooltip="Usuń pozycję z listy" placement="left">
                            <mdb-icon fas icon="trash-alt" class="grey-text" size="sm" (click)="deleteParent(i)"></mdb-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="participantEditModalParentsList.length <= 1" class="mb-2 mt-2">
          <div class="note note-warning">
            <strong>Uwaga!</strong>
            <p>
              Prosimy wpisać dane wszystkich rodziców/opiekunów prawnych. Pozostaw na liście tylko jedną osobę, jeśli
              uczestnik jest pełnoletni lub nie ma drugiego rodzica/opiekuna prawnego.
            </p>
          </div>
        </div>

        <div>
            <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="addNewParent()" mdbWavesEffect>Dodaj rodzica</button>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button type="button" mdbBtn color="secondary" size="sm" class="waves-light" aria-label="Close" (click)="cancelAndClose()" mdbWavesEffect>Anuluj</button>
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" [disabled]="!participantEditForm.valid" (click)="saveData()" mdbWavesEffect>Zapisz zmiany</button>
</div>
