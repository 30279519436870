import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { AuthUser } from '../_models';
import {Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JSONHandlerActions, JSONHandlerStatuses, LoginResult, RegisterResult} from '../types';
import {Apollo} from "apollo-angular";
import {
    RemindUserAccountConfirmationCodeResult,
    UserConfirmAccountResult,
    UserLoginResult,
    UserRegisterResult, UserResetPasswordResult
} from "../graphQL/results";
import {userConfirmAccountMutation, userRegisterMutation, userResetPasswordMutation} from "../graphQL/mutations";
import {remindUserAccountConfirmationCode, userLoginQuery} from "../graphQL/queries";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({ providedIn: 'root' })
export class UserService {
    static STATUS_ERROR = 'ERROR';
    static STATUS_OK    = 'OK';

    constructor(private http: HttpClient, private apollo: Apollo) { }

    register(firstName: string, lastName: string, email: string, password: string): Observable<RegisterResult> {

        const registerInfo = {
          action: JSONHandlerActions.REGISTER,
          data: {
            firstName, lastName, email, password
          }
        };

        let registerResult : Subject<RegisterResult> = new Subject<RegisterResult>();

        let userRegisterSubscription = this.apollo.mutate<UserRegisterResult>({
            mutation: userRegisterMutation,
            variables: {
                email,
                password,
                firstName,
                lastName,
            },
            errorPolicy: "all",
        })
        .subscribe( ({ data: { user_register }, errors}) => {
            let retVal: RegisterResult;

            if (errors && errors.length){
                retVal = {
                    status: 'ERROR',
                    message: 'Podczas rejestracji wystąpił błąd - spróbuj jeszcze raz.'
                };
            }
            else if (user_register.error && user_register.error !== '') {
                retVal = {
                    status: 'ERROR',
                    message: '',
                };

                if ((user_register.error as string).startsWith(JSONHandlerStatuses.ERROR_EMAIL_EXISTS)) {
                    retVal.message = '<p>Adres email podany podczas rejestracji istnieje już w naszej bazie.</p>' +
                        '<p>Aby zacząć korzystanie z systemu musisz najpierw skorzystać z linku aktywacyjnego, który wysłaliśmy mailem.</p>' +
                        '<p>Jeżeli nie możesz znaleźć w swojej skrzynce wiadomości z linkiem aktywacyjnym, naciśnij przycisk' +
                        ' "Wyślij link aktywacyjny" na formularzu</p>';
                }
                else if ((user_register.error as string).startsWith(JSONHandlerStatuses.ERROR_GENERIC)) {
                    retVal.message = 'Podczas rejestracji wystąpił błąd - spróbuj jeszcze raz.';
                }
            }
            else if (user_register.status && user_register.status === JSONHandlerStatuses.CONFIRMED) {
                retVal = {
                    status: 'OK',
                    message: '<p>Gratulujemy! Właśnie zarejestrowałaś(-łeś) się w naszym systemie.</p>' +
                        '<p>Sprawdź teraz swoją pocztę i kilknij w link aktywacyjny, aby zacząć korzystać ze wszystkich funkcji systemu.</p>'
                };
            } else {
                retVal = {
                    status: 'ERROR',
                    message: 'Podczas rejestracji wystąpił błąd - spróbuj jeszcze raz.'
                };
            }

            registerResult.next(retVal);

            userRegisterSubscription.unsubscribe();
        },
        (error) => {
            let retVal = {
                status: 'ERROR',
                message: 'Podczas rejestracji wystąpił błąd - spróbuj jeszcze raz.'
            } as RegisterResult;

            registerResult.next(retVal);
            userRegisterSubscription.unsubscribe();
        });

        return registerResult.asObservable();
    }

    confirmRegistration(confirmationCode: string): Observable<RegisterResult>{
        let registerResult : Subject<RegisterResult> = new Subject<RegisterResult>();

        let userRegisterSubscription = this.apollo.mutate<UserConfirmAccountResult>({
            mutation: userConfirmAccountMutation,
            variables: {
                confirmationCode
            },
            errorPolicy: "all",
        })
        .subscribe( ({ data: { user_confirm_account }, errors}) => {
                let retVal: RegisterResult;

                if (errors && errors.length){
                    retVal = {
                        status: 'ERROR',
                        message: 'Podczas potwierdzania konta wystąpił błąd - spróbuj jeszcze raz.'
                    };
                }
                else if (user_confirm_account.error && user_confirm_account.error !== ''){
                    retVal = {
                        status: 'ERROR',
                        message: '',
                    };

                    if (user_confirm_account.error.startsWith(JSONHandlerStatuses.ERROR_INVALID_CONFIRMATION_CODE)) {
                        retVal.message = '<p>Podany kod aktywacyjny jest niepoprawny.</p>' +
                            '<p>Sprawdź czy w polu \'kod aktywacyjny\' został wpisany właściwy kod.</p>' +
                            '<p>Możliwe, że konto jest już aktywne - spróbuj się zalogować.</p>';
                    }
                    else {
                        retVal = {
                            status: 'ERROR',
                            message: 'Podczas aktywacji konta wystąpił błąd - spróbuj jeszcze raz.'
                        };
                    }
                }
                else if (user_confirm_account.status && user_confirm_account.status === JSONHandlerStatuses.CONFIRMED) {
                    retVal = {
                        status: 'OK',
                        message: '<p>Gratulujemy!</p><p>Twoje konto w naszym systemie jest już aktywne.</p>' +
                            '<p>Przeniesiemy Cię teraz na stronę logowania abyś mogła/mógł zacząć korzystanie ze wszystkich funkcji systemu.</p>'
                    };
                } else {
                    retVal = {
                        status: 'ERROR',
                        message: 'Podczas aktywacji konta wystąpił błąd - spróbuj jeszcze raz.'
                    }
                }

                registerResult.next(retVal);

                userRegisterSubscription.unsubscribe();
            },
            (error) => {
                let retVal = {
                    status: 'ERROR',
                    message: 'Podczas rejestracji wystąpił błąd - spróbuj jeszcze raz.'
                } as RegisterResult;

                registerResult.next(retVal);
                userRegisterSubscription.unsubscribe();
            });

        return registerResult.asObservable();
    }

    remindRegistrationCode(email: string): Observable<RegisterResult>{
        let registerResult : Subject<RegisterResult> = new Subject<RegisterResult>();

        let userRemindCodeSubscription = this.apollo.watchQuery<RemindUserAccountConfirmationCodeResult>({
            query: remindUserAccountConfirmationCode,
            variables: {
                email
            }
        })
            .valueChanges
            .pipe(
                map(result => {
                    userRemindCodeSubscription.unsubscribe();

                    let retVal: RegisterResult = {
                        status: 'ERROR',
                        message: '',
                    };

                    if (result.data.user_account_remind_confirmation_code.error !== null) {
                        retVal = {
                            status: 'ERROR',
                            message: ''
                        };

                        if ((result.data.user_account_remind_confirmation_code.error).startsWith(JSONHandlerStatuses.ERROR_INVALID_EMAIL)) {
                            retVal.message = '<p>Twój adres albo nie figuruje w bazie danych Copernicus, albo konto jest już aktywne.</p>' +
                                '<p>Spróbuj się zalogować, a w razie niepowodzenia - skontaktuj się z nami.</p>';
                        }
                        else if ((result.data.user_account_remind_confirmation_code.error).startsWith(JSONHandlerStatuses.ERROR_GENERIC)) {
                            retVal.message = 'Ponowne wysłanie kodu aktywacyjnego nie powiodło się - spróbuj jeszcze raz.';
                        }
                    } else if (result.data.user_account_remind_confirmation_code.status == JSONHandlerStatuses.CONFIRMED) {
                        retVal = {
                            status: 'OK',
                            message: '<p>Gratulujemy!</p><p>Na Twój adres email wysłaliśmy wiadomość z kodem aktywacyjnym.</p>' +
                                '<p>Skorzystaj z kodu aby zakończyć proces rejestracji nowego konta.</p>'
                        };
                    } else {
                        retVal = {
                            status: 'ERROR',
                            message: 'Ponowne wysłanie kodu aktywacyjnego nie powiodło się - spróbuj jeszcze raz.',
                        };
                    }

                    registerResult.next(retVal);
                })
            )
            .subscribe();

        return registerResult.asObservable();
    }

    resetPassword(email: string, resetCode?: string, newPassword?: string): Observable<RegisterResult>{
        let registerResult : Subject<RegisterResult> = new Subject<RegisterResult>();

        let userResetPasswordSubscription = this.apollo.mutate<UserResetPasswordResult>({
            mutation: userResetPasswordMutation,
            variables: {
                email,
                resetCode,
                newPassword,
            },
            errorPolicy: "all",
        })
            .subscribe( ({ data: { user_reset_password }, errors}) => {
                    let retVal: RegisterResult;

                    if (errors && errors.length){
                        retVal = {
                            status: 'ERROR',
                            message: 'Podczas potwierdzania konta wystąpił błąd - spróbuj jeszcze raz.'
                        };
                    }
                    else if (user_reset_password.status && user_reset_password.status !== JSONHandlerStatuses.CONFIRMED){
                        retVal = {
                            status: 'ERROR',
                            message: '',
                        };

                        if (user_reset_password.status.startsWith(JSONHandlerStatuses.ERROR_INVALID_EMAIL)) {
                            retVal.message = '<p>Podany adres email jest niepoprawny.</p>' +
                                '<p>Wpisany adres nie istnieje w naszej bazie lub jest przypisany do konta, które nie zostało jeszcze aktywowane.</p>' +
                                '<p>W przypadku problemów z dostępem do konta skontaktuj się z naszym pracownikiem.</p>';
                        }
                        else if (user_reset_password.status.startsWith(JSONHandlerStatuses.ERROR_CODE_INVALID)) {
                            retVal.message = '<p>Podany kode jest niepoprawny.</p>' +
                                '<p>Kod służący do zmiany hasła ważny jest przez 2 godziny od momentu jego wygenerowania.</p>' +
                                '<p>Spróbuj zresetować hasło ponownie i uzyskać nowy kod (naciśnij przycisk NIE MAM KODU na formularzu). W przypadku powtarzających się problemów skontaktuj się naszym pracownikiem.</p>';
                        }
                        else {
                            retVal = {
                                status: 'ERROR',
                                message: 'Podczas resetowania hasła wystąpił błąd - spróbuj jeszcze raz.'
                            };
                        }
                    }
                    else if (user_reset_password.status && user_reset_password.status === JSONHandlerStatuses.CONFIRMED) {
                        //return different messsage depending on the value of newPassword
                        if (newPassword){
                            retVal = {
                                status: 'OK',
                                message: '<p>Gratulujemy!</p><p>Twoje hasło zostało zaktualizowane.</p>' +
                                    '<p>Przeniesiemy Cię teraz na stronę logowania.</p>'
                            };
                        }
                        else {
                            // this was a request to generate change password link
                            retVal = {
                                status: 'OK',
                                message: '<p>Gratulujemy!</p><p>Na Twój adres e-mail wysłaliśmy wiadomość z linkiem do zmiany hasła. Uwaga - link jest ważny przez najbliższe 2 godziny.</p>',
                            };
                        }

                    } else {
                        retVal = {
                            status: 'ERROR',
                            message: 'Podczas próby zmiany hasła wystąpił błąd - spróbuj jeszcze raz.'
                        }
                    }

                    registerResult.next(retVal);

                    userResetPasswordSubscription.unsubscribe();
                },
                (error) => {
                    let retVal = {
                        status: 'ERROR',
                        message: 'Podczas próby zmiany hasła nastąpił błąd - spróbuj jeszcze raz.'
                    } as RegisterResult;

                    registerResult.next(retVal);
                    userResetPasswordSubscription.unsubscribe();
                });

        return registerResult.asObservable();
    }
}
