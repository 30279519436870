import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ConfirmComponent} from './confirm';
import { AuthGuard } from './_guards';
import { RemindActivationCodeComponent } from './remind-activation-code';
import { AllUsersComponent } from './all-users/all-users.component';
import { MyAddressesComponent } from "./my-addresses";
import { MyParticipantsComponent } from "./my-participants";
import { ParticipantsSignupComponent } from "./participant-signup";
import { PasswordResetComponent } from "./password-reset";
import { ReservationsComponent } from "./reservations";
import {ReservationReviewComponent} from "./reservation-review";
import {ReservationPaymentsComponent} from "./reservation-payments";
import {AllPaymentsComponent} from "./all-payments";
import {ReservationInsuranceBrowserComponent} from "./reservation-insurance-browser";
import {AllInsurancesubmissionsComponent} from "./all-insurancesubmissions";
import {InsuranceSubmissionGeneratorComponent} from "./insurance-submission-generator";
import {ActiveCampsStatisticsComponent} from "./active-camps-statistics";
import {AllCampsComponent} from "./all-camps/all-camps.component";
import {AttachmentsManagerComponent} from "./file-upload/attachments-manager.component";



const appRoutes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'allusers', component: AllUsersComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'allcamps', component: AllCampsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'myaddresses', component: MyAddressesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'myparticipants', component: MyParticipantsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'reservations/:view_type', component: ReservationsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'reservations/:view_type/:status', component: ReservationsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'reservation-review/:id', component: ReservationReviewComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'attachments-manager/:id', component: AttachmentsManagerComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'reservation-payments/:id', component: ReservationPaymentsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'file-upload/:id', component: AttachmentsManagerComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'participantsignup', component: ParticipantsSignupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'payments', component: AllPaymentsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'reservation-insurance-browser/:camp_ids/:insurance_ids', component: ReservationInsuranceBrowserComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'insurancesubmissions', component: AllInsurancesubmissionsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'generateinsurancesubmission', component: InsuranceSubmissionGeneratorComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'activecampsstats', component: ActiveCampsStatisticsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'remind', component: RemindActivationCodeComponent },
    { path: 'confirm/:code', component: ConfirmComponent },
    { path: 'confirm', component: ConfirmComponent },
    { path: 'password-reset/:code', component: PasswordResetComponent },
    { path: 'password-reset', component: PasswordResetComponent },

    // otherwise redirect to home
{ path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
