<div class="cprncs-single-active-camp-statistics">
    <div class="row mx-1 p-1 grey lighten-4">
        <div class="col-12 camp-name-column">
            <p class="h3 camp-name"> {{ camp.name }}</p>
        </div>
    </div>
    <div class="row mx-1 mb-2 p-1 grey lighten-4 camp-details-header-row">
        <div class="col-6 col-md-4 camp-location-column">
            <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{ camp.location_name }}
        </div>
        <div class="col-6 col-md-4">
            <mdb-icon far icon="calendar-alt"></mdb-icon>
            {{ camp.date_start | formatDate: 'Do MMM YYYY' }} -
            {{ camp.date_end | formatDate: 'Do MMM YYYY' }}
        </div>
        <div class="col-6 col-md-4">
            <mdb-icon fas icon="money-bill-wave"></mdb-icon>&nbsp;
            <span *ngIf="camp.price_special !== null">
                <span class="oldprice">
                    {{ camp.price | currency: 'zł':'code':'1.2-2':'pl-PL'}}
                </span>
                <strong>{{ camp.price_special | currency: 'zł':'code':'1.2-2':'pl-PL'}}</strong>
                &nbsp; <mdb-badge pill="true" danger="true">PROMOCJA!</mdb-badge>
            </span>
            <span *ngIf="camp.price_special === null">
                    {{ camp.price | currency: 'zł':'code':'1.2-2':'pl-PL'}}
            </span>

        </div>
    </div>

    <div class="row mx-1 mb-5">
        <!-- PARTICIPANT COUNT STATISTICS -->
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-6">
                    <div style="display: block; width: 150px; height: 150px">
                        <canvas mdbChart
                                [chartType]="participantsCountChartType"
                                [datasets]="participantsCountChartDatasets"
                                [labels]="participantCountChartLabels"
                                [colors]="participantsCountChartColors"
                                [options]="commonDoughnutChartOptions"
                                [legend]="false">
                        </canvas>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="h6"><small>Uczestnicy</small></div>
                            <div class="h3"> {{ totalActiveParticipants }}<small> / {{ camp.participants_capacity }}</small></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- PAYMENT STATISTICS -->
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-6">
                    <div style="display: block; width: 150px; height: 150px">
                        <canvas mdbChart
                                [chartType]="paymentsChartType"
                                [datasets]="paymentsChartDatasets"
                                [labels]="paymentsChartLabels"
                                [colors]="paymentsChartColors"
                                [options]="commonDoughnutChartOptions"
                                [legend]="false">
                        </canvas>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="h6"><small>Średnia cena</small></div>
                            <div *ngIf="camp.average_price_statistics" class="h4"> {{ camp.average_price_statistics | currency: 'zł':'code':'1.0-0':'pl-PL' }}</div>
                            <div *ngIf="!camp.average_price_statistics" class="h4"> b.d. </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="h6"><small>Wartość obozu</small></div>
                            <div class="h4"> {{ totalCampValue | currency: 'zł':'code':'1.0-0':'pl-PL' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- PARTICIPANT AGE STATISTICS -->
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-6">
                    <div style="display: block;">
                        <canvas mdbChart
                                [chartType]="participantsAgeChartType"
                                [datasets]="participantsAgeChartDatasets"
                                [labels]="participantsAgeChartLabels"
                                [colors]="participantsAgeChartColors"
                                [options]="commonBarChartOptions"
                                [legend]="false">
                        </canvas>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="h6"><small>Średni wiek</small></div>
                            <div *ngIf="averageParticipantsAge" class="h4"> {{ averageParticipantsAge }}</div>
                            <div *ngIf="!averageParticipantsAge" class="h4"> b.d. </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="h6"><small>Płeć uczestników</small></div>
                            <div *ngIf="femaleParticipantsCount !== null" class="h4">
                                <span class="femaleParticipantsCountNumber">{{ femaleParticipantsCount }}</span> /
                                <span class="maleParticipantsCountNumber">{{ maleParticipantsCount }}</span> /
                                <span class="otherParticipantsCountNumber">{{ otherParticipantsCount }}</span>
                            </div>
                            <div *ngIf="femaleParticipantsCount === null" class="h4"> b.d. </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>