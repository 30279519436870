<!-- Page header -->
<div class="jumbotron card card-image" style="background-image: url(assets/images/gradient1.jpg); background-size: cover">
  <div class="text-white text-center ">
    <div>
      <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Dołącz do grona klientów AlphaCamp</strong></h2>
      <p class="mx-5">AlphaCamp to obozy naukowe tworzone z pasją. Za pomocą sytetmu Copernicus możesz zarządzać zamówieniami, korzystać z najlepszych ofert i kontaktować się z pracownikami AlphaCamp.
      </p>
    </div>
  </div>
</div>

<!-- Form -->
<div class="row justify-content-center">
  <div class="col-12 col-md-8 col-lg-6">
    <!-- Material form login -->
    <div class="card">

      <h5 class="card-header info-color white-text text-center py-4">
        <strong>Rejestracja</strong>
      </h5>

      <!--Card content-->
      <div class="card-body px-lg-5 pt-0">

        <!-- Form -->
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="text-center" style="color: #757575;">

          <!-- Email -->
          <div class="md-form">
            <input mdbInput [mdbValidate]="false" type="email" formControlName="email" id="registerFormEmail" class="form-control">
            <label for="registerFormEmail">E-mail</label>
            <div *ngIf="f.email.invalid && f.email.dirty && f.email.touched" class="alert alert-danger animated fadeIn">
              <div *ngIf="f.email.errors.required">
                Adres e-mail jest wymagany.
              </div>
              <div *ngIf="f.email.errors.pattern">
                Wpisz adres w poprawnym formacie.
              </div>
            </div>
          </div>

          <!-- First and last name -->
          <div class="form-row">
            <!-- First name -->
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput [mdbValidate]="false" type="text" formControlName="firstName" id="registerFormFirstName" class="form-control">
                <label for="registerFormFirstName">Imię</label>
              </div>
            </div>

            <!-- Last name -->
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput [mdbValidate]="false" type="text" formControlName="lastName" id="registerFormLastName" class="form-control">
                <label for="registerFormLastName">Nazwisko</label>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div *ngIf="f.firstName.invalid && f.firstName.dirty && f.firstName.touched" class="alert alert-danger animated fadeIn">
                <div *ngIf="f.firstName.errors.required">
                  Wpisz swoje imię
                </div>
              </div>

              <div *ngIf="f.lastName.invalid && f.lastName.dirty && f.lastName.touched" class="alert alert-danger animated fadeIn">
                <div *ngIf="f.lastName.errors.required">
                  Wpisz swoje nazwisko
                </div>
              </div>
            </div>
          </div>


          <!-- Password and Confirm password -->
          <div class="form-group">
            <div class="form-row">

              <!-- Password -->
              <div class="col-12 col-md-6">
                <div class="md-form">
                  <input mdbInput [mdbValidate]="false" type="password" formControlName="password" id="registerFormPassword" class="form-control">
                  <label for="registerFormPassword">Hasło</label>
                </div>
              </div>

              <!-- Confirm Password -->
              <div class="col-12 col-md-6">
                <div class="md-form">
                  <input mdbInput [mdbValidate]="false" type="password" formControlName="confirmPassword" id="registerFormConfirmPassword" class="form-control">
                  <label for="registerFormConfirmPassword">Powtórz hasło</label>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div *ngIf="f.password.invalid && f.password.dirty && f.password.touched" class="alert alert-danger animated fadeIn">
                  <div *ngIf="f.password.errors.required || f.password.errors.passwordStrengthValidator">
                    Hasło musi się składać co najmniej z 8 znaków, w tym jednej cyfry i jednego znaku specjalnego (przecinek, kropka, itp.)
                  </div>
                </div>

                <div *ngIf="f.confirmPassword.invalid && f.confirmPassword.dirty && f.confirmPassword.touched" class="alert alert-danger animated fadeIn">
                  <div *ngIf="f.confirmPassword.errors.noPasswordMatch">
                    W obu polach wpisz to samo hasło.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sign in button -->
          <button *ngIf="!loading" mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect type="submit">Zarejestruj się</button>
          <button *ngIf="loading" mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect disabled>
            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            Copernicus analizuje Twoje dane...
          </button>

          <!-- Register -->
          <p>
            Masz już konto? <a [routerLink]="['/login']">Zaloguj się</a>
          </p>

          <!-- Remind activation code -->
          <p>
            Nie otrzymałaś(-łeś) linku aktywacyjnego? <a [routerLink]="['/remind']">Wyślij link</a>
          </p>

          <!-- Social login -->

          <!--
          <p>albo zaluguj się używając konta:</p>
          <a type="button" class="btn-floating btn-fb btn-sm">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a type="button" class="btn-floating btn-tw btn-sm">
            <i class="fab fa-twitter"></i>
          </a>
          <a type="button" class="btn-floating btn-li btn-sm">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a type="button" class="btn-floating btn-git btn-sm">
            <i class="fab fa-github"></i>
          </a>
          -->

        </form>
        <!-- Form -->

      </div>

    </div>
  </div>
</div>
<!-- Material form login -->


<div mdbModal #modalErrorMessage class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Uwaga</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalErrorMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="fas fa-exclamation-triangle fa-4x mb-3 animated bounce fast"></i>
          <p [innerHTML]="errorMessage"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="modalErrorMessage.hide()">Zamknij</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #modalSuccessMessage class="modal fade top" id="frameModalTop1" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" (closed)="onSuccessModalClosed($event)" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Gratulacje!</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalSuccessMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="fas fa-exclamation-triangle fa-4x mb-3 animated bounce fast"></i>
          <p [innerHTML]="successMessage"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="success" outline="true" class="waves-effect" mdbWavesEffect (click)="modalSuccessMessage.hide()">Zamknij</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!--
<h2>Register</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="username">Username</label>
        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">Register</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
    </div>
</form>
-->
