import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {Parent, ParentStatusSelectConfig, Participant, ParticipantGenderSelectConfig} from "../_models";
import {IMyOptions, MDBModalRef, MDBModalService} from "ng-uikit-pro-standard";
import * as _ from "underscore";
import {ButtonPressedType, ModalGenericHandler, ModalStyleType} from "../modal-generic-handler";


@Component({
	templateUrl: './modal-participant-edit.component.html',
	styleUrls: ['./modal-participant-edit.component.scss']
})
export class ModalParticipantEditComponent implements OnInit {
	@ViewChild('participant_name_first', {static: true}) participant_name_first: ElementRef<HTMLInputElement>;

	public participantEditForm: FormGroup;   //form controls
	public participant: Participant; //source data
	public genderOptions;
	public dateBirthDatePickerOptions: IMyOptions;
	public parentStatuses;

	public saveButtonPressed: boolean;
  public modalComplete: boolean;  //  this flag is used as a workaround for MDB sending close events to listeners even
                                  //  if modals open by this modal have been closed, but this modal is still open


  private genericModalHandler: ModalGenericHandler;

	constructor(public modalRef: MDBModalRef, private _formBuilder: FormBuilder, private modalService: MDBModalService) {
    this.genericModalHandler = new ModalGenericHandler();
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.participant_name_first.nativeElement.focus();
		});

		this.dateBirthDatePickerOptions = {
			dayLabels: {su: 'Nd', mo: 'Pon', tu: 'Wt', we: 'Śr', th: 'Czw', fr: 'Pt', sa: 'Sob'},
			monthLabelsFull: {1: 'Styczeń', 2:'Luty', 3: 'Marzec', 4: 'Kwiecień', 5: 'Maj', 6:'Czerwiec', 7:'Lipiec', 8:'Sierpień', 9:'Wrzesień', 10:'Październik', 11:'Listopad', 12:'Grudzień'},
			firstDayOfWeek: 'mo',
			dateFormat: 'yyyy-mm-dd',
			showClearDateBtn: false,
			showTodayBtn: false,
			closeBtnTxt: 'Zamknij',
			todayBtnTxt: 'Dziś',
			closeAfterSelect: true,
			minYear: 1990,
		};

		this.participantEditForm = this._formBuilder.group({
			participantEditModalNameFirst: [this.participant.name_first, [Validators.required]],
			participantEditModalNameMiddle: [this.participant.name_middle],
			participantEditModalNameLast: [this.participant.name_last, [Validators.required]],
			participantEditModalDateBirth: [this.participant.date_birth, [Validators.required]],
			participantEditModalNationalIdNumber: [this.participant.national_id_number, [Validators.required]],
			participantEditModalGender: [this.participant.gender, [Validators.required]],
			participantEditModalParentsList: this._formBuilder.array([])
		});

		//initialize parents array
		for (let i = 0; i < this.participant.parents_list.length; i++){
			this.participantEditModalParentsList.push(this.createParent(this.participant.parents_list[i]));
		}

		//there must be at least one parent assigned to a child
		if (!this.participant.parents_list.length){
			this.addNewParent();
		}

		this.genderOptions  = ParticipantGenderSelectConfig;
		this.parentStatuses = ParentStatusSelectConfig;
    this.saveButtonPressed = false;
    this.modalComplete = false;
	}

	private createParent(parent: Parent) : FormGroup {
		return this._formBuilder.group({
			participantEditModalParentsListFullName: [parent.name_full, [Validators.required]],
			participantEditModalParentsListStatus: [parent.status, [Validators.required]],
			participantEditModalParentsListPhoneNumbers: [parent.phone_number, [Validators.required]],
			participantEditModalParentsListAddress: [parent.address, [Validators.required]],
		})
	}

	public addNewParent() {
		this.participantEditModalParentsList.push(this.createParent({
			name_full: '',
			status: "PARENT",
			phone_number: '',
			address: '',
		}));
	}

	public deleteParent(i: number){
		if (this.participantEditModalParentsList.length <= 1) return;   //there must be at least one parent

		this.participantEditModalParentsList.removeAt(i);
	}

	private readParents() : Parent[] {
		let parents: Array<Parent> = [];

		for (let i = 0; i < this.participantEditModalParentsList.length; i++){
			parents.push({
				name_full: this.participantEditModalParentsList.at(i).value.participantEditModalParentsListFullName,
				status: this.participantEditModalParentsList.at(i).value.participantEditModalParentsListStatus,
				phone_number: this.participantEditModalParentsList.at(i).value.participantEditModalParentsListPhoneNumbers,
				address: this.participantEditModalParentsList.at(i).value.participantEditModalParentsListAddress,
			});
		}

		return parents;
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.participantEditForm.controls;
	}

	get participantEditModalNameFirst() {
		return this.f.participantEditModalNameFirst as FormControl;
	}

	get participantEditModalNameMiddle(){
		return this.f.participantEditModalNameMiddle as FormControl;
	}

	get participantEditModalNameLast(){
		return this.f.participantEditModalNameLast as FormControl;
	}

	get participantEditModalDateBirth(){
		return this.f.participantEditModalDateBirth as FormControl;
	}

	get participantEditModalNationalIdNumber(){
		return this.f.participantEditModalNationalIdNumber as FormControl;
	}

	get participantEditModalGender(){
		return this.f.participantEditModalGender as FormControl;
	}

	get participantEditModalParentsList(){
		return this.f.participantEditModalParentsList as FormArray;
	}

  cancelAndClose(){
    this.saveButtonPressed = false;
    this.modalComplete = true;

    this.modalRef.hide();
  }

	saveData(){
    this.saveButtonPressed = false;


    let _localRef = this;
		let _saveFunction = () => {
      _localRef.saveButtonPressed = true;
      _localRef.modalComplete = true;

      //copy form values to address object
      const tmpObject = {
        name_first: _localRef.participantEditModalNameFirst.value,
        name_middle: _localRef.participantEditModalNameMiddle.value,
        name_last: _localRef.participantEditModalNameLast.value,
        date_birth: _localRef.participantEditModalDateBirth.value,
        national_id_number: _localRef.participantEditModalNationalIdNumber.value,
        gender: _localRef.participantEditModalGender.value,
        parents_list: _localRef.readParents(),
      };

      //overwrite participant object properties with new values leaving fields such as id untouched
      _.extend(_localRef.participant, tmpObject);

      _localRef.modalRef.hide();
    }

    if (this.participantEditForm.valid){
      // check if only one parent was added
      if (this.participantEditModalParentsList.length == 1){
        // ask user if he wants to add another parent using modal generic component
        let dlgTitle = "Potwierdź poprawność danych rodziców";
        let dlgMessage = "Wprowadzono dane tylko jednego rodzica. Czy na pewno chcesz zapisać te dane?";
        let dlgStyle : ModalStyleType = "WARNING";

        let localSub = this.genericModalHandler.showModal(dlgTitle, dlgMessage, this.modalService, dlgStyle, null, null, "Tak", "Nie").subscribe((buttonPressed:ButtonPressedType) => {
          localSub.unsubscribe();

          if (buttonPressed == 'Yes'){
            _saveFunction();
          }
        });
      }
      else {
        _saveFunction();
      }
		}
	}
}
