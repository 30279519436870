<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Dane faktury z systemu zewnętrznego</h4>
</div>

<div class="modal-body">
    <div *ngIf="this.cMode != 'LOOKUP'" class="row pb-2">
        <div class="col-12">
            <strong class="grey-text">ID faktury w systemie zewnętrznym ({{extraInvoiceEditorData.provider}}):</strong> {{extraInvoiceEditorData.invoice_foreign_id}}
        </div>
    </div>

    <form [formGroup]="extraInvoiceIDForm" (keyup.enter)="invoiceLookupButtonPressed()">
        <div class="form-row d-flex justify-content-between">
            <div class="flex-grow-1">
                <div *ngIf="this.cMode == 'LOOKUP'" class="md-form">
                    <input type="text" id="extraInvoiceEditModalForm-extraInvoiceID" [formControl]="extraInvoiceID" class="form-control" mdbInput mdbValidate>
                    <label for="extraInvoiceEditModalForm-extraInvoiceID">ID faktury</label>
                    <mdb-error *ngIf="extraInvoiceID.invalid && (extraInvoiceID.dirty || extraInvoiceID.touched)">Błąd</mdb-error>
                </div>
            </div>
            <div *ngIf="cMode == 'LOOKUP'" class="ml-md-2 mt-3">
                <button mdbBtn type="button" color="info" rounded="true" size="md" (click)="invoiceLookupButtonPressed()" mdbWavesEffect><mdb-icon fas icon="search"></mdb-icon> Szukaj</button>
            </div>
        </div>
    </form>

    <div class="row" *ngIf="bLookupOperationError">
        <div class="col-12">
            <blockquote class="blockquote bq-warning">
                <p class="bq-title">Błąd</p>
                <p>{{cLookupErrorMessage}}</p>
            </blockquote>
        </div>
    </div>

    <div class="row" *ngIf="networkOperationInProgress">
        <div class="col-12">
            <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
        </div>
    </div>

    <span *ngIf="bShowExtraInvoiceDetails">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12"><small class="grey-text">Nr faktury</small></div>
                </div>
                <div class="row">
                    <div class="col-12">{{ invoiceLookupData.invoice_number }}</div>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="row">
                    <div class="col-12"><small class="grey-text">Data wystawienia</small></div>
                </div>
                <div class="row">
                    <div class="col-12">{{ invoiceLookupData.invoice_date_issued | date: 'dd-MM-YYYY' }}</div>
                </div>
            </div>

            <div class="col-6 col-md-4">
                <div class="row">
                    <div class="col-12"><small class="grey-text">Termin płatności</small></div>
                </div>
                <div class="row">
                    <div class="col-12">{{ invoiceLookupData.invoice_date_due | date: 'dd-MM-YYYY' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-8">
                <div class="row">
                    <div class="col-12"><small class="grey-text">Dane klienta:</small></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        {{ invoiceLookupData.client_summary }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 grey lighten-3 pt-2">
            <div class="col-12"><p class="h6 text-black-50">Pozycje faktury:</p></div>
        </div>

        <div class="row">
            <div class="col-12">
                <ol>
                    <li *ngFor="let singleItem of invoiceLookupData.items_summary; let i = index">
                        {{ singleItem }}
                    </li>
                </ol>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-end">
            <div class="div">
                <div class="row">
                    <div class="col-12"><small class="grey-text">Do zapłaty / zapłacono</small></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4>
                            {{ invoiceLookupData.invoice_amount_total | currency: 'zł':'code':'1.2-2':'pl-PL' }} /
                            <small [ngClass]="{'deep-orange-text': checkCalculatedInvoiceStatus('ISSUED'), 'amber-text': checkCalculatedInvoiceStatus('PARTIALLY_PAID'), 'grey-text': checkCalculatedInvoiceStatus('COMPLETE')}">{{ invoiceLookupData.invoice_amount_paid | currency: 'zł':'code':'1.2-2':'pl-PL' }}</small>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </span>

    <div *ngIf="bShowExtraInvoiceDetails">
        <div class="row mt-4 grey lighten-3 pt-2">
            <div class="col-12"><p class="h6 text-black-50">Opis faktury:</p></div>
        </div>

        <form [formGroup]="extraInvoiceEditForm">
            <div class="form-row mt-2">
                <div class="col-12 col-md-6">
                    <div class="md-form mt-0 mb-2">
                        <mdb-select [options]="invoiceStatuses" [formControl]="invoice_status" placeholder="Wybierz status"></mdb-select>
                    </div>
                </div>

                <div class="col-12 col-md-6 mt-md-2">
                    <mdb-checkbox [formControl]="include_in_summary">
                        Uwzględnij w rozliczeniu
                    </mdb-checkbox>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <div class="md-form my-2">
                        <input type="text" id="extraInvoiceEditForm-comment" [formControl]="comment" class="form-control" mdbInput mdbValidate>
                        <label for="extraInvoiceEditForm-comment">Komentarz</label>
                        <mdb-error *ngIf="extraInvoiceID.invalid && (extraInvoiceID.dirty || extraInvoiceID.touched)">Błąd</mdb-error>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal-footer">
    <button type="button" mdbBtn color="secondary" size="sm" class="waves-light" aria-label="Close" (click)="modalRef.hide()" mdbWavesEffect>Zamknij</button>
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" [disabled]="!extraInvoiceEditForm.valid && !bShowExtraInvoiceDetails" (click)="saveData()" mdbWavesEffect>Zapisz zmiany</button>
</div>