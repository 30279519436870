import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first, switchMap} from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../_services';

import { ModalDirective } from 'ng-uikit-pro-standard';
import { RegisterResult } from '../types';
import { Observable } from 'rxjs';

@Component({templateUrl: 'confirm.component.html'})
export class ConfirmComponent implements OnInit {
    @ViewChild('modalErrorMessage', { static: true }) public modalErrorMessage: ModalDirective;
    @ViewChild('modalSuccessMessage', { static: true }) public modalSuccessMessage: ModalDirective;

    public confirmForm: FormGroup;
    public loading = false;
    public submitted = false;
    public errorMessage = '';
    public successMessage = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.confirmForm = this.formBuilder.group({
            confirmationCode: ['', Validators.required]
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            this.confirmForm.patchValue({confirmationCode: params.get('code')});
            if (params.get('code').trim() !== '') {
              this.onSubmit();
            }
          });
    }

    // convenience getter for easy access to form fields
    get f() { return this.confirmForm.controls; }

    onSubmit() {
        if (this.loading) {
          return;
        }

        // stop here if form is invalid
        if (this.confirmForm.invalid) {
            this.errorMessage = 'Formularz zawiera błędy. Uzupełnij pola przed naciśnięciem przycisku \'Dokończ rejestrację\'.';
            this.modalErrorMessage.show();
            return;
        }

        this.submitted = true;

        this.loading = true;
        this.userService.confirmRegistration(this.f.confirmationCode.value)
            .pipe(first())
            .subscribe(
                data => {
                    // this.alertService.success('Registration successful', true);
                    this.loading = false;

                    // show modal
                    const regResult: RegisterResult = { ...data };

                    if (regResult.status === 'OK') {
                      this.successMessage = regResult.message;
                      this.modalSuccessMessage.show();
                    } else {
                      this.errorMessage = regResult.message;
                      this.modalErrorMessage.show();
                    }
                },
                error => {
                    this.errorMessage = error.message;
                    this.modalErrorMessage.show();
                    this.loading = false;
                });
    }

  onSuccessModalClosed(event: any) {
    this.router.navigate(['/login']);
  }
}
