import {MDBModalService} from "ng-uikit-pro-standard";
import {ModalGenericComponent} from "../modal-generic";
import {Observable, Subject, Subscription} from "rxjs";

export type ButtonsToShowType = {
	'OK': boolean;
	'Cancel': boolean,
	'Yes': boolean,
	'No' : boolean
};

export type ButtonsCaptionsType = {
	'OK': string;
	'Cancel': string,
	'Yes': string,
	'No' : string
};

export type ButtonPressedType = 'OK' | 'Cancel' | 'Yes' | 'No';

export type ModalStyleType = 'SUCCESS' | 'INFO' | 'DANGER' | 'WARNING';

export class ModalGenericHandler {
	showModal(title: string, message: string, modalService: MDBModalService, style: ModalStyleType,
	          okButton: string | null, cancelButton: string | null,
	          yesButton: string | null, noButton: string | null, showUnsanitizedMessage: boolean = false) : Observable<ButtonPressedType> {

		let styleClass = '';

		switch (style){
			case "INFO":
				styleClass = 'modal-notify modal-info';
				break;

			case "DANGER":
				styleClass = 'modal-notify modal-danger';
				break;

			case "SUCCESS":
				styleClass = 'modal-notify modal-success';
				break;

			case "WARNING":
				styleClass = 'modal-notify modal-warning';
				break;

			default:
				styleClass = 'modal-notify modal-success';
				break;
		}
		/*
			Prepare modal for editing data
		 */
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: styleClass,
			containerClass: '',
			animated: true,
			data: {
				title: title,
				message: showUnsanitizedMessage ? null : message,
        unsanitizedMessage: showUnsanitizedMessage ? message : null,
				buttonsToShow: {
					'OK': !!okButton,
					'Cancel': !!cancelButton,
					'Yes': !!yesButton,
					'No' : !!noButton
				} as ButtonsToShowType,
				buttonCaptions: {
					'OK': okButton ? okButton : '',
					'Cancel': cancelButton ? cancelButton : '',
					'Yes': yesButton ? yesButton : '',
					'No' : noButton ? noButton : '',
				} as ButtonsCaptionsType,
				buttonPressed: 'Cancel'
			}
		};

		/*
			Show the modal
		 */
		let modalRef = modalService.show(ModalGenericComponent, modalOptions);
		let mySubject = new Subject<ButtonPressedType>();

		let mySubscription =  modalService.closed.subscribe( () => {
			mySubscription.unsubscribe();

			mySubject.next(modalRef.content.buttonPressed);
		});

		return mySubject.asObservable();
	}
}
