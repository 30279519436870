export class InsurancePolicyReservationBrowserItem {
	campparticipant_campinsurance_id: number;
	campparticipant_campinsurance_insurancesubmission_id: number;
	camp_insurancepolicy_id: number;
	insurancepolicy_id: number;
	insurancepolicy_name: string;
}

export class CampParticipantReservationBrowserItem {
	camp_participant_id: number;
	participant_id: number;
	insurance_snapshot: string;
	name_first: string;
	name_middle?: string;
	name_last: string;
	problem_flag: boolean;
	insurance_policies: InsurancePolicyReservationBrowserItem[];
}

export class ReservationInsuranceBrowserItem {
	id: number;
	name: string;
	problem_flag: boolean;
	camp_participants: CampParticipantReservationBrowserItem[];
}