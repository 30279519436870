import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {first} from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../_services';

import { ModalDirective } from 'ng-uikit-pro-standard';
import { RegisterResult } from '../types';

@Component({templateUrl: 'remind-activation-code.component.html'})
export class RemindActivationCodeComponent implements OnInit {
    @ViewChild('modalErrorMessage', { static: true }) public modalErrorMessage: ModalDirective;
    @ViewChild('modalSuccessMessage', { static: true }) public modalSuccessMessage: ModalDirective;

    public remindCodeForm: FormGroup;
    public loading = false;
    protected submitted = false;
    public errorMessage = '';
    public successMessage = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.remindCodeForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.remindCodeForm.controls; }

    onSubmit() {
        if (this.loading) {
          return;
        }

        // stop here if form is invalid
        if (this.remindCodeForm.invalid) {
            this.errorMessage = 'Formularz zawiera błędy. Uzupełnij swój adres email.';
            this.modalErrorMessage.show();
            return;
        }

        this.submitted = true;

        this.loading = true;
        this.userService.remindRegistrationCode(this.f.email.value)
            .pipe(first())
            .subscribe(
                data => {
                    // this.alertService.success('Registration successful', true);
                    this.loading = false;

                    // show modal
                    const regResult: RegisterResult = { ...data };

                    if (regResult.status === 'OK') {
                      this.successMessage = regResult.message;
                      this.modalSuccessMessage.show();
                    } else {
                      this.errorMessage = regResult.message;
                      this.modalErrorMessage.show();
                    }
                },
                error => {
                    this.errorMessage = error.message;
                    this.modalErrorMessage.show();
                    this.loading = false;
                });
    }

  onSuccessModalClosed(event: any) {
    this.router.navigate(['/login']);
  }
}
