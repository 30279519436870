import {Parent} from "../_models";
import {DocumentToSubmitStatus} from "../_models/documenttosubmit";

export interface BasicUsersFieldsInput {
  id: number,
  firstName: string,
  lastName:  string,
  phone_number: string,
  billing_address_id: number,
  mailing_address_id: number,
}

export const BasicUsersFieldsLiteral = {
  id: true,
  firstName: true,
  lastName: true,
  phone_number: true,
  billing_address_id: true,
  mailing_address_id: true,
};

export interface AdminUsersFieldsInput {
  id: number,
  email: string,
  is_confirmed: boolean,
  is_active: boolean,
  is_admin: boolean,
  is_manager: boolean,
  is_backoffice_employee: boolean,
  is_camp_employee: boolean,
  is_client: boolean,
}

/*
 *      ADDRESS
 */
export interface AddressInput {
  id: number,
  user_id: number,
  is_business: boolean,
  name_first?: string | null,
  name_last?: string | null,
  company_name?: string | null,
  street_name: string,
  street_number?: string | null,
  flat_number?: string | null,
  zip_code: string,
  city: string,
  country: string
  vat_number?: string | null,
  phone_number?: string | null
}

export const AddressInputLiteral = {
  id: true,
  user_id: true,
  is_business: true,
  name_first: true,
  name_last: true,
  company_name: true,
  street_name: true,
  street_number: true,
  flat_number: true,
  zip_code: true,
  city: true,
  country: true,
  vat_number: true,
  phone_number: true,
};


/*
 *      PARTICIPANT
 */
export interface ParticipantInput {
  id: number,
  user_id: number,
  name_first: string,
  name_middle: string,
  name_last: string,
  date_birth: string,
  national_id_number: string,
  gender: string,
  parents_list: [Parent],
}

export const ParticipantInputLiteral = {
  id: true,
  user_id: true,
  name_first: true,
  name_middle: true,
  name_last: true,
  date_birth: true,
  national_id_number: true,
  gender: true,
  parents_list: true,
};


/*
  CAMP_PARTICIPANT
*/
export interface IdPricePairInputType {
  id: number,
  price: string,
}

export interface DocumentToSubmitInputType {
  document_id: string;
  document_name: string;
  document_required: boolean;
  document_required_original: boolean;
  document_submitted_status?: DocumentToSubmitStatus;
  document_submitted_date?: Date;
  comment?: string;
}