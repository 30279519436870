import {Address, User} from "../../_models";
import {Tools} from "../../_helpers/tools";
import {BasicUsersFieldsInput, BasicUsersFieldsLiteral} from "../inputs";
import {updateUsersBasicFieldsMutation} from "../mutations";
import {Apollo} from "apollo-angular";

export class UserDataManipulations {
	setAddress(apollo: Apollo, currentUser: User, address: Address, type: "mailing" | "billing") {
		const tools = new Tools();
		const extractBasicUserInput = tools.extract<BasicUsersFieldsInput>(BasicUsersFieldsLiteral);

		// @ts-ignore
		const data = extractBasicUserInput(currentUser);

		// billing and mailing address must be ids not address objects, so clean data object
		data.billing_address_id = currentUser.billing_address ? currentUser.billing_address.id : null;
		data.mailing_address_id = currentUser.mailing_address ? currentUser.mailing_address.id : null;

		let mailingAddress = currentUser.mailing_address;
		let billingAddress = currentUser.billing_address;

		// finally update (toggle style) address field
		switch (type) {
			case "billing":
				data.billing_address_id = data['billing_address'] === address.id ? null : address.id;
				billingAddress = address;
				break;

			case "mailing":
				data.mailing_address_id = data['mailing_address'] === address.id ? null : address.id;
				mailingAddress = address;
				break;

			default:
				return;
		}

		this.updateUsersBasicFieldsWithAddresses(apollo, currentUser, data, mailingAddress, billingAddress);
	}

	updateUsersBasicFieldsWithAddresses(apollo: Apollo, updatedUser: User, userUpdate: BasicUsersFieldsInput, mailingAddress: Address, billingAddress: Address) {
		apollo.mutate({
			mutation: updateUsersBasicFieldsMutation,
			variables: {
				updatedData: userUpdate
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateUsersBasicFields: {
					is_active: null,
					is_confirmed: null,
					last_login_timestamp: null,
					...updatedUser,
					billing_address: billingAddress,
					mailing_address: mailingAddress
				},
			},
		}).subscribe();
	}

	updateUsersBasicFields(apollo: Apollo, updatedUser: User, userUpdateInput: BasicUsersFieldsInput) {
		apollo.mutate({
			mutation: updateUsersBasicFieldsMutation,
			variables: {
				updatedData: userUpdateInput
			},
			optimisticResponse: {
				__typename: 'Mutation',
				updateUsersBasicFields: {
					...updatedUser,
					...userUpdateInput
				},
			}
		}).subscribe();
	}
}