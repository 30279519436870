<!-- Page header -->
<div class="jumbotron card card-image" style="background-image: url(assets/images/gradient1.jpg);  background-size: cover">
  <div class="text-white text-center ">
    <div>
      <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Dołącz do grona klientów AlphaCamp</strong></h2>
      <p class="mx-5">AlphaCamp to obozy naukowe tworzone z pasją. Za pomocą sytetmu Copernicus możesz zarządzać zamówieniami, korzystać z najlepszych ofert i kontaktować się z pracownikami AlphaCamp.
      </p>
    </div>
  </div>
</div>

<!-- Form -->
<div class="row justify-content-center">
  <div class="col-12 col-md-5">
    <!-- Material form login -->
    <div class="card">

      <h5 class="card-header info-color white-text text-center py-4">
        <strong>Przypomnienie kodu rejestracyjnego</strong>
      </h5>

      <!--Card content-->
      <div class="card-body px-lg-5 pt-0">

        <!-- Form -->
        <form [formGroup]="remindCodeForm" (ngSubmit)="onSubmit()" class="text-center" style="color: #757575;">

          <!-- Email -->
          <div class="md-form">
            <input mdbInput [mdbValidate]="false" type="email" formControlName="email" id="email" class="form-control">
            <label for="email">Twój adres e-mail</label>
            <div *ngIf="f.email.invalid && f.email.dirty && f.email.touched" class="alert alert-danger">
              <div *ngIf="f.email.errors.required">
                Adres email jest wymagany.
              </div>

              <div *ngIf="f.email.errors.pattern">
                Wpisz swój email w poprawnym formacie.
              </div>
            </div>
          </div>

          <!-- Sign in button -->
          <button *ngIf="!loading" mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect type="submit">Wyślij kod</button>
          <button *ngIf="loading" mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect disabled>
            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
             Copernicus analizuje Twoje dane...
          </button>

          <!-- Register -->
          <p>Masz już aktywne konto?
            <a [routerLink]="['/login']">Zaloguj się</a>
          </p>

        </form>
        <!-- Form -->

      </div>

    </div>
  </div>
</div>
<!-- Material form confirm -->


<div mdbModal #modalErrorMessage="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Uwaga</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalErrorMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="fas fa-exclamation-triangle fa-4x mb-3 animated bounce fast"></i>
          <p [innerHTML]="errorMessage"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="modalErrorMessage.hide()">Zamknij</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #modalSuccessMessage="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" (closed)="onSuccessModalClosed($event)" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Gratulacje!</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalSuccessMessage.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <i class="fas fa-exclamation-triangle fa-4x mb-3 animated bounce fast"></i>
          <p [innerHTML]="successMessage"></p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="success" outline="true" class="waves-effect" mdbWavesEffect (click)="modalSuccessMessage.hide()">Zamknij</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!--
<h2>Register</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="username">Username</label>
        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">Register</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
    </div>
</form>
-->
