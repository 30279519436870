<form [formGroup]="rejectionReasonsForm">
  <div class="form-row mt-3">
    <div *ngFor="let messageOption of _messageFormOptions; let i = index" class="col-12 col-md-6">
      <div class="md-form mt-0 mb-2">
        <mdb-checkbox [id]="'chk-reason-' + i" [name]="'chk-reason-' + i" [formControlName]="'chk-reason-' + i" >
          {{ messageOption['label'] }}
        </mdb-checkbox>
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-12">
      <div class="md-form mt-0 mb-2">
        <textarea id="customReason" formControlName="customReason" class="md-textarea md-textarea-auto form-control" mdbInput rows="3"></textarea>
        <label for="customReason">Wiadomość do użytkownika</label>
      </div>
    </div>
  </div>
</form>

<div class="row mt-3">
  <div class="col-12">
    <div class="h5">Tekst wyjaśnienia:</div>
    <div style="color: #e73434;" [innerHTML]="compiledRejectionMessage | keepHtml">
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12">
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" (click)="cancel()" mdbWavesEffect>Anuluj</button>
    <button type="button" mdbBtn color="danger" size="sm" class="relative waves-light" (click)="completeMessage()" mdbWavesEffect>Usuń plik</button>
  </div>
</div>
