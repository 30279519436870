import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export interface StrengthParameters {
  minLettersCount: number;
  minNumbersCount: number;
  minSpecialCharsCount: number;
}

export function passwordStrengthValidator(params: StrengthParameters): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    const error = {
      passwordStrengthValidator: {
        valid: false
      }
    };

    const letters = control.value.match(/[a-ząćęłńóśźż]/uig);
    const numbers = control.value.match(/[\d]/g);
    const specialChars = control.value.match(/[\W_]/g);

    if (params.minLettersCount > -1 && (!letters || !letters.length || letters.length < params.minLettersCount)) {
      return error;
    }

    if (params.minNumbersCount > -1 && (!numbers || !numbers.length || numbers.length < params.minNumbersCount)) {
      return error;
    }

    if (params.minSpecialCharsCount > -1 && (!specialChars || !specialChars.length || specialChars.length < params.minSpecialCharsCount)) {
      return error;
    }

    // if true, return no error (no error), else return error passed in the second parameter
    return null;
  };
}
