<div class="row">
    <div class="col-12">
        <blockquote class="blockquote bq-warning">
            <p id="wip" class="bq-title">Uwaga! Prace wciąż trwają!</p>
            <p>
                Szanowni Państwo, rozwijamy się dla Państwa i dla dzieciaków. Rozwijamy ofertę, rozwijamy narzędzia i rozwijamy... się :)!
                Rozwój wymaga ciężkiej pracy i cierpliwości. System Copernicus, który oddajemy w Państwa ręce, jest narzędziem,
                które wyeliminuje wiele niedogodności, które były dla nas wszystkich uciążliwe podczas pierwszej edycji obozów.
            </p>
            <p>
                Copernicus jest jednak - na obecnym etapie - dopiero zalążkiem docelowego systemu. Na razie udostępminiamy Państwu możliwość
                dokonywania rezerwacji. Wkrótce będą Państwo mieli także dostęp do historii rozliczeń i wielu innych
                funkcji, które pomogą nam we wzajemnej komunikacji.
            </p>
            <p>
                Prosimy o zgłaszanie wszelkich problemów i uwag dotyczących działania systamu na ten mail:
                <a href="mailto:info@alphacamp.pl">info@alphacamp.pl</a>.
            </p>
            <p>
                Pozostajemy do Państwa dyspozycji,
            </p>
            <p>
                Zespół AlphaCamp i Naukowców Dwóch
            </p>
        </blockquote>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12">
        <a routerLink="/participantsignup" mdbBtn type="button" color="success" block="true" rounded="true" mdbWavesEffect>
            <mdb-icon fas icon="plus-square" class="mr-1"></mdb-icon>Zapisz dziecko na obóz
        </a>
    </div>
</div>

